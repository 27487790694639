import React, { createContext, useState, useEffect, useCallback, useRef } from 'react';
import { getFirestore, collection, getDocs, doc, getDoc } from 'firebase/firestore';

export const TourContext = createContext();

// Cache durations
const CACHE_DURATION = {
  TOUR_DATA: 30 * 24 * 60 * 60 * 1000,    // 30 days for tour data
  TOUR_EXPENSES: 30 * 24 * 60 * 60 * 1000,     // 30 days for expense data
};

// Cache prefix
const CACHE_PREFIX = 'tourCache_';

// Cache helper functions
const saveToCache = (tourId, data, type = 'tour') => {
  const prefix = type === 'expense' ? `${CACHE_PREFIX}expense_` : CACHE_PREFIX;
  const duration = type === 'expense' ? CACHE_DURATION.TOUR_EXPENSES : CACHE_DURATION.TOUR_DATA;
  
  const cacheData = {
    data,
    timestamp: Date.now(),
    type,
    duration
  };
  localStorage.setItem(`${prefix}${tourId}`, JSON.stringify(cacheData));
};

const getFromCache = (tourId, type = 'tour') => {
  const prefix = type === 'expense' ? `${CACHE_PREFIX}expense_` : CACHE_PREFIX;
  const cached = localStorage.getItem(`${prefix}${tourId}`);
  if (!cached) return null;

  const cacheData = JSON.parse(cached);
  if (Date.now() - cacheData.timestamp > cacheData.duration) {
    localStorage.removeItem(`${prefix}${tourId}`);
    return null;
  }

  return cacheData.data;
};

const clearCache = (tourId = null, type = null) => {
  if (tourId) {
    if (type === 'expense') {
      localStorage.removeItem(`${CACHE_PREFIX}expense_${tourId}`);
    } else if (type === 'tour') {
      localStorage.removeItem(`${CACHE_PREFIX}${tourId}`);
    } else {
      localStorage.removeItem(`${CACHE_PREFIX}${tourId}`);
      localStorage.removeItem(`${CACHE_PREFIX}expense_${tourId}`);
    }
  } else {
    Object.keys(localStorage)
      .filter(key => key.startsWith(CACHE_PREFIX))
      .forEach(key => localStorage.removeItem(key));
  }
};

export const TourProvider = ({ children }) => {
  const [selectedTour, setSelectedTour] = useState(null);
  const [tours, setTours] = useState([]);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [currentSection, setCurrentSection] = useState(null);
  const [expenseHistory, setExpenseHistory] = useState({});
  const [isLoadingExpenses, setIsLoadingExpenses] = useState(false);

  const selectedTourRef = useRef();
  
  useEffect(() => {
    selectedTourRef.current = selectedTour;
  }, [selectedTour]);

  // Fetch full tour data
  const fetchTourDetails = useCallback(async (tourId) => {
    if (!tourId) return null;

    try {
      const cachedTour = getFromCache(tourId);
      if (cachedTour) {
        return cachedTour;
      }

      const db = getFirestore();
      const tourDoc = await getDoc(doc(db, 'Tours', tourId));
      
      if (tourDoc.exists()) {
        const tourData = tourDoc.data();
        
        // Fetch tour dates
        const tourDatesSnapshot = await getDocs(
          collection(db, 'Tours', tourId, 'tourDates')
        );
        
        const tourDates = tourDatesSnapshot.docs.map(dateDoc => ({
          id: dateDoc.id,
          ...dateDoc.data(),
        }));

        // Fetch pending invitations
        let pendingInvitationsData = [];
        if (tourData.pendingInvitations?.length > 0) {
          const invitationsPromises = tourData.pendingInvitations.map(async (invitationId) => {
            const invitationDoc = await getDoc(doc(db, 'TourInvitations', invitationId));
            return invitationDoc.exists() ? {
              id: invitationDoc.id,
              ...invitationDoc.data()
            } : null;
          });
          pendingInvitationsData = (await Promise.all(invitationsPromises)).filter(Boolean);
        }

        const fullTourData = {
          id: tourId,
          ...tourData,
          tourDates,
          artistDetails: tourData.artistDetails || [],
          crewDetails: tourData.crewDetails || [],
          pendingInvitations: tourData.pendingInvitations || [],
          pendingInvitationsData
        };

        saveToCache(tourId, fullTourData);
        return fullTourData;
      }
      return null;
    } catch (error) {
      console.error('Error fetching tour details:', error);
      return null;
    }
  }, []);

  // Fetch all tours data
  const fetchToursData = useCallback(async (userData) => {
    if (!userData?.userId) return;
  
    setIsLoading(true);
    try {
      const db = getFirestore();
      
      const userDoc = await getDoc(doc(db, 'users', userData.userId));
      const tourIds = userDoc.data()?.tourIds || [];
      
      if (!tourIds.length) {
        setTours([]);
        return;
      }

      // Fetch full data for all tours
      const toursData = await Promise.all(
        tourIds.map(tourId => fetchTourDetails(tourId))
      );

      const validTours = toursData.filter(Boolean);
      setTours(validTours);

      // Update selected tour if needed
      if (selectedTourRef.current) {
        const updatedSelectedTour = validTours.find(t => t.id === selectedTourRef.current.id);
        if (updatedSelectedTour) {
          setSelectedTour(updatedSelectedTour);
        }
      }

    } catch (error) {
      console.error('Error fetching tours data:', error);
    } finally {
      setIsLoading(false);
    }
  }, [fetchTourDetails]);

  // Expense handling
  const fetchExpenseHistory = useCallback(async (tourId) => {
    if (!tourId) return;

    setIsLoadingExpenses(true);
    try {
      const cachedExpenses = getFromCache(tourId, 'expense');
      if (cachedExpenses) {
        setExpenseHistory(prevHistory => ({
          ...prevHistory,
          [tourId]: cachedExpenses
        }));
        return;
      }

      const db = getFirestore();
      const expenseRef = doc(db, 'ExpenseRecords', tourId);
      const expenseDoc = await getDoc(expenseRef);
      
      const expenseData = expenseDoc.exists() 
        ? (expenseDoc.data().tourDates || {})
        : {};

      setExpenseHistory(prevHistory => ({
        ...prevHistory,
        [tourId]: expenseData
      }));
      saveToCache(tourId, expenseData, 'expense');

    } catch (error) {
      console.error('Error fetching expense history:', error);
      setExpenseHistory(prevHistory => ({
        ...prevHistory,
        [tourId]: {}
      }));
    } finally {
      setIsLoadingExpenses(false);
    }
  }, []);

  const updateExpenseHistory = useCallback(async (tourId) => {
    clearCache(tourId, 'expense');
    await fetchExpenseHistory(tourId);
  }, [fetchExpenseHistory]);

  // Tour updates
  const updateTourData = useCallback(async (tourId) => {
    try {
      clearCache(tourId, 'tour');
      const freshData = await fetchTourDetails(tourId);
      
      if (freshData) {
        setTours(prev => prev.map(tour => 
          tour.id === tourId ? freshData : tour
        ));

        if (selectedTourRef.current?.id === tourId) {
          setSelectedTour(freshData);
        }
      }
    } catch (error) {
      console.error('Error updating tour data:', error);
    }
  }, [fetchTourDetails]);

  const updateTourDate = useCallback(async (tourId, dateId) => {
    try {
      const db = getFirestore();
      const tourDateRef = doc(db, 'Tours', tourId, 'tourDates', dateId);
      const tourDateSnap = await getDoc(tourDateRef);

      if (tourDateSnap.exists()) {
        clearCache(tourId, 'tour');
        await updateTourData(tourId);
      }
    } catch (error) {
      console.error('Error updating tour date:', error);
    }
  }, [updateTourData]);

  // Handle tour selection
  const handleTourSelection = useCallback(async (tour) => {
    if (!tour) {
      setSelectedTour(null);
      return;
    }

    const fullTourData = await fetchTourDetails(tour.id);
    if (fullTourData) {
      setSelectedTour(fullTourData);
    }
  }, [fetchTourDetails]);

  // Utility functions
  const selectTourDate = useCallback((tourId, dateId) => {
    const tour = tours.find(t => t.id === tourId);
    if (tour) {
      setSelectedTour(tour);
      setSelectedDate(dateId);
    }
  }, [tours]);

  const getSelectedTourDate = useCallback(() => {
    if (!selectedTour?.tourDates || !selectedDate) return null;
    return selectedTour.tourDates.find(date => date.id === selectedDate) || null;
  }, [selectedTour, selectedDate]);

  const getSelectedSectionData = useCallback((section) => {
    const tourDate = getSelectedTourDate();
    if (!tourDate) return null;

    switch (section) {
      case 'schedule': return tourDate.schedule || {};
      case 'event': return tourDate.event || {};
      case 'hotel': return tourDate.hotel || [];
      case 'route':
        if (!tourDate.schedule?.scheduleList) return [];
        return tourDate.schedule.scheduleList.filter(
          schedule => schedule.isTravelling && schedule.fromLocation && schedule.toLocation
        );
      default: return null;
    }
  }, [getSelectedTourDate]);

  // Cache cleanup
  useEffect(() => {
    return () => clearCache();
  }, []);

  return (
    <TourContext.Provider value={{
      selectedTour,
      setSelectedTour: handleTourSelection,
      tours,
      setTours,
      selectedDate,
      setSelectedDate,
      isLoading,
      fetchToursData,
      updateTourData,
      updateTourDate,
      selectTourDate,
      getSelectedTourDate,
      getSelectedSectionData,
      currentSection,
      setCurrentSection,
      expenseHistory,
      isLoadingExpenses,
      fetchExpenseHistory,
      updateExpenseHistory,
    }}>
      {children}
    </TourContext.Provider>
  );
};

export default TourContext;