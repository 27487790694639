import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faGift, faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './SuccessPopup.module.css';

// In SuccessPopup.js
const SuccessPopup = ({ isOpen, onClose, tierName, benefits, isUpgrade = false, isDowngrade = false }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  
  useEffect(() => {
    if (isOpen) {
      setIsAnimating(true);
      // Auto close after 10 seconds
      const timer = setTimeout(() => {
        handleClose();
      }, 10000);
      
      // Prevent body scrolling when popup is open
      document.body.style.overflow = 'hidden';
      
      return () => {
        clearTimeout(timer);
        document.body.style.overflow = '';
      };
    }
  }, [isOpen]);
  
  const handleClose = () => {
    setIsAnimating(false);
    // Delay actual closing to allow exit animation
    setTimeout(() => {
      onClose();
    }, 300);
  };
  
  if (!isOpen) return null;
  
  return (
    <div className={`${styles.overlay} ${isAnimating ? styles.visible : styles.hidden}`}>
      <div className={`${styles.popup} ${isAnimating ? styles.animateIn : styles.animateOut}`}>
        <button className={styles.closeButton} onClick={handleClose} aria-label="Close">
          <FontAwesomeIcon icon={faTimes} />
        </button>
        
        <div className={styles.popupContent}>
          <div className={styles.successIcon}>
            <FontAwesomeIcon icon={faCheckCircle} className={styles.checkIcon} />
            <div className={styles.confetti}></div>
          </div>
          
          <h2 className={styles.title}>Success!</h2>
          <p className={styles.message}>
            {isUpgrade ? (
              <>You've successfully upgraded to <span className={styles.tierName}>{tierName}</span> membership!</>
            ) : isDowngrade ? (
              <>You've successfully changed to <span className={styles.tierName}>{tierName}</span> plan. 
              You'll retain access to your previous plan until the end of your billing cycle.</>
            ) : (
              <>You're now on the <span className={styles.tierName}>{tierName}</span> membership!</>
            )}
          </p>
          
          <div className={styles.benefitsContainer}>
            <h3 className={styles.benefitsTitle}>
              <FontAwesomeIcon icon={faGift} className={styles.giftIcon} />
              {isUpgrade ? 'Your New Benefits' : 'Your Plan Benefits'}
            </h3>
            <ul className={styles.benefitsList}>
              {benefits.map((benefit, index) => (
                <li key={index} className={styles.benefitItem}>
                  <span className={styles.checkMark}>✓</span>
                  {benefit}
                </li>
              ))}
            </ul>
          </div>
          
          <button className={styles.exploreButton} onClick={handleClose}>
            {isDowngrade ? 'Continue' : 'Explore Your Membership'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SuccessPopup;