import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import TopBar from '../TopBar';
import LeftMenu from '../LeftMenu';
import RightMenu from '../RightMenu';
import UserContext from '../../context/UserContext';
import styles from './ProfileSettings.module.css';

const ProfileSettings = () => {
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);

  const handleLogout = async () => {
    try {
      setIsLoggingOut(true);
      // Add your logout logic here
    } catch (error) {
      console.error('Error during logout:', error);
    } finally {
      setIsLoggingOut(false);
    }
  };

  const getValueColor = (value, paidValue, verifiedValue, pendingValue) => {
    if (value === verifiedValue) {
      return '#10b981'; // green
    } else if (value === pendingValue) {
      return '#f59e0b'; // orange
    } else {
      return '#ef4444'; // red
    }
  };

  const handleVerificationClick = () => {
    if (userData?.membershipTier === 'Free') {
      navigate('/membership');
    } else {
      navigate('/verification');
    }
  };

  const menuOptions = [
    { 
      title: 'Account Details', 
      path: '/account-details'
    },
    {
      title: 'Verification Status',
      value: userData?.idVerificationStatus || 'Not Verified',
      valueColor: getValueColor(
        userData?.idVerificationStatus, 
        '', 
        'Verified', 
        'Pending'
      ),
      onClick: handleVerificationClick
    },
    { 
      title: 'Help Centre', 
      path: '/help-centre'
    },
    { 
      title: 'Terms of Service', 
      path: '/terms-of-service'
    },
    { 
      title: 'Privacy Policy', 
      path: '/privacy-policy'
    },
    { 
      title: 'Logout', 
      onClick: handleLogout 
    }
  ];

  if (isLoggingOut) {
    return (
      <div className={styles.loadingOverlay}>
        <div className={styles.loadingSpinner} />
        <div className={styles.loadingText}>Logging out...</div>
      </div>
    );
  }

  return (
    <div className={styles.mainLayout}>
      <TopBar />
      <LeftMenu />
      <div className={styles.profileSettingsMain}>
        <div className={styles.profileSettingsContent}>
          <div className={styles.profileSettingsHeader}>
            <h1 className={styles.profileSettingsTitle}>Profile Settings</h1>
          </div>

          <div className={styles.menuOptions}>
            {menuOptions.map((option, index) => (
              <div key={index}>
                {option.onClick ? (
                  <button
                    className={styles.menuItem}
                    onClick={option.onClick}
                  >
                    <div className={styles.menuItemContent}>
                      <span className={styles.menuItemText}>{option.title}</span>
                      {option.value && (
                        <span 
                          className={styles.menuItemValue}
                          style={{ color: option.valueColor }}
                        >
                          {option.value}
                        </span>
                      )}
                    </div>
                    <FontAwesomeIcon icon={faArrowRight} className={styles.menuItemIcon} />
                  </button>
                ) : (
                  <Link
                    to={option.path}
                    className={styles.menuItem}
                  >
                    <div className={styles.menuItemContent}>
                      <span className={styles.menuItemText}>{option.title}</span>
                      {option.value && (
                        <span 
                          className={styles.menuItemValue}
                          style={{ color: option.valueColor }}
                        >
                          {option.value}
                        </span>
                      )}
                    </div>
                    <FontAwesomeIcon icon={faArrowRight} className={styles.menuItemIcon} />
                  </Link>
                )}
              </div>
            ))}
          </div>

          <div className={styles.versionInfo}>
            <span className={styles.versionText}>Version 1.0</span>
          </div>
        </div>
      </div>
      <RightMenu />
    </div>
  );
};

export default ProfileSettings;