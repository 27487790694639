import React, { useState, useContext, useEffect } from 'react';
import { collection, query, where, getDocs, doc, getDoc, updateDoc, limit, setDoc } from 'firebase/firestore';
import { sendPasswordResetEmail, signOut, verifyBeforeUpdateEmail, onAuthStateChanged } from 'firebase/auth';
import { getFirestoreInstance, firebaseInstances } from '../../utils/firebaseHelper';
import { UserContext } from '../../context/UserContext';
import { TourContext } from '../../context/TourContext';
import TopBar from '../TopBar';
import LeftMenu from '../LeftMenu';
import RightMenu from '../RightMenu';
import styles from './AccountDetails.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faSearch, faTimes, faChevronDown, faCalendar, faChevronUp, faCheckCircle, faTimesCircle, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import defaultProfilePic from '../../assets/Images/defaultUserProfilePicture.png';
import verifiedBadge from '../../assets/Images/profileverifiedtick.png';
import { liteClient } from 'algoliasearch/lite';

// Initialize Algolia client
const client = liteClient('6DJNAZSX4P', '342932cbe26f7751ec87fa2b1ff32e4c');


const AccountDetails = () => {
  const { userData, setUserData } = useContext(UserContext);
  const { tours, updateTourData } = useContext(TourContext);
  const [email, setEmail] = useState('');
  const [userName, setUserName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [gender, setGender] = useState('');
  const [showEmailDialog, setShowEmailDialog] = useState(false);
  const [showUsernameDialog, setShowUsernameDialog] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [userSubType, setUserSubType] = useState('');
  const [subTypeOptions, setSubTypeOptions] = useState([]);
  const [membershipTier, setMembershipTier] = useState('');
  const [emailHidden, setEmailHidden] = useState(false);
  const [isAccountPrivate, setIsAccountPrivate] = useState(false);
  const [allowManagers, setAllowManagers] = useState(false);
  const [birthday, setBirthday] = useState('');
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [showManagerSearchModal, setShowManagerSearchModal] = useState(false);
  const [managerSearchTerm, setManagerSearchTerm] = useState('');
  const [managerSearchResults, setManagerSearchResults] = useState([]);
  const [selectedManagers, setSelectedManagers] = useState([]);
  const [isSearching, setIsSearching] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);
  const [ShowDeleteDialog, setShowDeleteDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    title: '',
    message: '',
    type: '', // 'success', 'error', 'warning'
    onConfirm: null,
    showCancel: false
  });

  const genderOptions = [
    { label: "Select Gender", value: "" },
    { label: "Male", value: "male" },
    { label: "Female", value: "female" },
    { label: "Other", value: "other" }
  ];


  useEffect(() => {
    if (userData) {
      // Set basic user data
      setEmail(userData.email || '');
      setUserName(userData.userName || '');
      setPhoneNumber(userData.phoneNumber || '');
      setCity(userData.city || '');
      setState(userData.state || '');
      setCountry(userData.country || '');
      setGender(userData.gender || '');
      setUserSubType(userData.userSubType || '');
      setMembershipTier(userData.membershipTier || '');
      setEmailHidden(userData.emailHidden || false);
      setIsAccountPrivate(userData.accountVisibility === 'Private');
      setAllowManagers(userData.allowManagers || false);
      setBirthday(userData.birthday ? new Date(userData.birthday) : null);
 
                   
      if (userData.allowedManagers && Array.isArray(userData.allowedManagers)) {
        setSelectedManagers(userData.allowedManagers);
      }
      
      // Handle birthday timestamp properly
      if (userData.birthday) {
        if (userData.birthday.seconds) {
          // If it's a Firestore timestamp, convert to local timezone
          const date = new Date(userData.birthday.seconds * 1000);
          // Adjust for timezone offset to ensure correct local date
          const timezoneOffset = date.getTimezoneOffset() * 60000;
          const localDate = new Date(date.getTime() - timezoneOffset);
          setBirthday(localDate);
        } else if (typeof userData.birthday === 'string') {
          // If it's a string date, parse and adjust for timezone
          const date = new Date(userData.birthday);
          const timezoneOffset = date.getTimezoneOffset() * 60000;
          const localDate = new Date(date.getTime() - timezoneOffset);
          setBirthday(localDate);
        } else if (userData.birthday instanceof Date) {
          // If it's already a Date object, adjust for timezone
          const timezoneOffset = userData.birthday.getTimezoneOffset() * 60000;
          const localDate = new Date(userData.birthday.getTime() - timezoneOffset);
          setBirthday(localDate);
        } else {
          // If no valid date, set to null
          setBirthday(null);
        }
      } else {
        setBirthday(null);
      }


      // Set sub type options
      const options = getSubTypeOptions(userData.industry, userData.userType);
      setSubTypeOptions(options);
      
      // Validate userSubType against available options
      if (userData.userSubType && options.length > 0) {
        const subTypeExists = options.some(option => option.value === userData.userSubType);
        if (!subTypeExists) {
          setUserSubType(''); // Reset if current subtype is not in options

        }
      }
    }
  }, [userData]);

  const getSubTypeOptions = (industry, userType) => {
    const options = {
      Comedy: {
        Artist: [
          { label: "Comedy Writer", value: "Comedy Writer" },
          { label: "Improv Comedian", value: "Improv Comedian" },
          { label: "Sketch Artist", value: "Sketch Artist" },
          { label: "Stand-Up Comedian", value: "Stand-Up Comedian" },
        ],
        Crew: [
          { label: "Artist Manager", value: "Artist Manager" },
          { label: "Light Engineer", value: "Light Engineer" },
          { label: "Lighting Technician", value: "Lighting Technician" },
          { label: "Photographer", value: "Photographer" },
          { label: "Security Personnel", value: "Security Personnel" },
          { label: "Sound Engineer", value: "Sound Engineer" },
          { label: "Stage Manager", value: "Stage Manager" },
          { label: "Videographer", value: "Videographer" },
        ],
        Manager: [
          { label: "Tour Manager", value: "Tour Manager" },
          { label: "Artist Manager", value: "Artist Manager" },
        ],
      },
      Music: {
        Artist: [
          { label: "Bass Guitarist", value: "Bass Guitarist" },
          { label: "Cellist", value: "Cellist" },
          { label: "DJ", value: "DJ" },
          { label: "Dhol Player", value: "Dhol Player" },
          { label: "Dholak Player", value: "Dholak Player" },
          { label: "Drummer", value: "Drummer" },
          { label: "Flutist", value: "Flutist" },
          { label: "Ghatam Player", value: "Ghatam Player" },
          { label: "Guitarist", value: "Guitarist" },
          { label: "Harmonium Player", value: "Harmonium Player" },
          { label: "Keyboardist", value: "Keyboardist" },
          { label: "Mridangam Player", value: "Mridangam Player" },
          { label: "Music Producer", value: "Music Producer" },
          { label: "Percussionist", value: "Percussionist" },
          { label: "Rapper", value: "Rapper" },
          { label: "Santoor Player", value: "Santoor Player" },
          { label: "Sarod Player", value: "Sarod Player" },
          { label: "Saxophonist", value: "Saxophonist" },
          { label: "Shehnai Player", value: "Shehnai Player" },
          { label: "Sitar Player", value: "Sitar Player" },
          { label: "Tabla Player", value: "Tabla Player" },
          { label: "Trumpeter", value: "Trumpeter" },
          { label: "Veena Player", value: "Veena Player" },
          { label: "Violinist", value: "Violinist" },
          { label: "Vocalist", value: "Vocalist" },
        ],
        Crew: [
          { label: "Artist Manager", value: "Artist Manager" },
          { label: "Backline Technician", value: "Backline Technician" },
          { label: "Dresser", value: "Dresser" },
          { label: "Drum Tech", value: "Drum Tech" },
          { label: "Guitar Tech", value: "Guitar Tech" },
          { label: "Keyboard Tech", value: "Keyboard Tech" },
          { label: "Light Engineer", value: "Light Engineer" },
          { label: "Lighting Technician", value: "Lighting Technician" },
          { label: "Makeup Artist", value: "Makeup Artist" },
          { label: "Merchandiser", value: "Merchandiser" },
          { label: "Monitor Engineer", value: "Monitor Engineer" },
          { label: "Photographer", value: "Photographer" },
          { label: "Rigger", value: "Rigger" },
          { label: "Road Manager", value: "Road Manager" },
          { label: "Security Personnel", value: "Security Personnel" },
          { label: "Sound Engineer", value: "Sound Engineer" },
          { label: "Stage Manager", value: "Stage Manager" },
          { label: "Stage Tech", value: "Stage Tech" },
          { label: "Tour Bus Driver", value: "Tour Bus Driver" },
          { label: "Videographer", value: "Videographer" },
        ],
        Manager: [
          { label: "Tour Manager", value: "Tour Manager" },
          { label: "Artist Manager", value: "Artist Manager" },
        ],
        Vendor: [
          { label: "Backline Provider", value: "Backline Provider" },
          { label: "Light Vendor", value: "Light Vendor" },
          { label: "Sound Vendor", value: "Sound Vendor" },
          { label: "Stage and Production Vendor", value: "Stage and Production Vendor" },
        ],
      },
    };
  
    // Return empty array if industry or userType is not provided
    if (!industry || !userType) return [];
  
    // Return options for the specific industry and userType, or empty array if not found
    return options[industry]?.[userType] || [];
  };

  

  const handleEmailUpdate = async () => {
    try {
      const auth = firebaseInstances.auth;
      const currentUser = auth.currentUser;
  
      if (!currentUser) {
        throw new Error('No authenticated user found');
      }
  
      // Send verification email to new email address
      await verifyBeforeUpdateEmail(currentUser, email);
  
      showCustomAlert({
        title: 'Verification Email Sent',
        message: 'A verification link has been sent to the new email address. Please check your inbox and click the link to complete the change.',
        type: 'success'
      });
    } catch (error) {
      console.error('Error initiating email update:', error);
      let errorMessage = 'Failed to send verification email. Please try again.';
      
      // Handle specific Firebase errors
      if (error.code === 'auth/requires-recent-login') {
        errorMessage = 'For security reasons, please log out and log in again before changing your email.';
      } else if (error.code === 'auth/invalid-email') {
        errorMessage = 'Please enter a valid email address.';
      } else if (error.code === 'auth/email-already-in-use') {
        errorMessage = 'This email is already associated with another account.';
      }
  
      showCustomAlert({
        title: 'Error',
        message: errorMessage,
        type: 'error'
      });
    }
  };
  
  // Add auth state listener to update Firestore when email is verified
  useEffect(() => {
    const auth = firebaseInstances.auth;
    
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        try {
          const db = getFirestoreInstance();
          const userRef = doc(db, 'users', user.uid);
          const userDoc = await getDoc(userRef);
          
          // If the auth email is different from the Firestore email
          if (userDoc.exists() && userDoc.data().email !== user.email) {
            console.log('Updating email in Firestore:', user.email);
            
            // Update email in user document
            await updateDoc(userRef, {
              email: user.email
            });
  
            // If this user is in any tours, update their email there too
            if (userData?.tourIds?.length > 0) {
              for (const tourId of userData.tourIds) {
                const tourRef = doc(db, 'Tours', tourId);
                const tourDoc = await getDoc(tourRef);
  
                if (tourDoc.exists()) {
                  const tourData = tourDoc.data();
                  let needsUpdate = false;
                  let updatedTourData = { ...tourData };
  
                  // Helper function to update email in arrays
                  const updateEmailInArray = (array) => {
                    if (!array) return array;
                    return array.map(item => {
                      if (item.userId === user.uid) {
                        needsUpdate = true;
                        return { ...item, email: user.email };
                      }
                      return item;
                    });
                  };
  
                  // Update all relevant arrays based on user type
                  if (userData.userType === 'Artist' && updatedTourData.artistDetails) {
                    updatedTourData.artistDetails = updateEmailInArray(updatedTourData.artistDetails);
                  }
                  if (userData.userType === 'Crew' && updatedTourData.crewDetails) {
                    updatedTourData.crewDetails = updateEmailInArray(updatedTourData.crewDetails);
                  }
                  if (userData.userType === 'Vendor' && updatedTourData.vendorDetails) {
                    updatedTourData.vendorDetails = updateEmailInArray(updatedTourData.vendorDetails);
                  }
                  if (userData.userType === 'Staff' && updatedTourData.staffDetails) {
                    updatedTourData.staffDetails = updateEmailInArray(updatedTourData.staffDetails);
                  }
  
                  // Update createdBy if user is manager
                  if (userData.userType === 'Manager' && 
                      tourData.createdBy?.userId === user.uid) {
                    needsUpdate = true;
                    updatedTourData.createdBy = {
                      ...tourData.createdBy,
                      email: user.email
                    };
                  }
  
                  if (needsUpdate) {
                    console.log('Updating tour:', tourId);
                    await updateDoc(tourRef, updatedTourData);
                    if (updateTourData) {
                      await updateTourData(tourId);
                    }
                  }
                }
              }
            }
  
            // Update local state
            setUserData(prev => ({
              ...prev,
              email: user.email
            }));
            setEmail(user.email);
          }
        } catch (error) {
          console.error('Error updating email across collections:', error);
        }
      }
    });
  
    return () => unsubscribe();
  }, [userData?.email]);
  
  

const isValidUsername = (username) => {
  const usernameRegex = /^[a-z0-9_]+$/;
  return usernameRegex.test(username);
};

// Username availability check
const checkUsernameAvailability = async (username) => {
  const firestore = getFirestoreInstance();
  const usersRef = collection(firestore, 'users');
  const q = query(
    usersRef,
    where('userName', '==', username),
    limit(1)
  );
  try {
    const querySnapshot = await getDocs(q);
    return querySnapshot.empty;
  } catch (error) {
    console.error('Error checking username availability:', error);
    return false;
  }
};

// Update username function
const handleUpdateUsername = async () => {
  if (!isValidUsername(userName)) {
    showCustomAlert({
      title: 'Invalid Username',
      message: 'Username can only contain lowercase letters, numbers, and "_". No spaces or "@" symbol allowed.',
      type: 'error'
    });
    return;
  }

  if (userName === userData.userName) {
    showCustomAlert({
      title: 'No Change',
      message: 'The new username is the same as the current one.',
      type: 'warning'
    });
    return;
  }

  setIsLoading(true);
  console.log('Starting username update');
  
  try {
    const isAvailable = await checkUsernameAvailability(userName);
    if (!isAvailable) {
      showCustomAlert({
        title: 'Username Taken',
        message: 'This username is already in use. Please choose a different one.',
        type: 'error'
      });
      return;
    }

    const newProfileUrl = `https://ontour.co.in/${userName}`;
    const db = getFirestoreInstance();
    const userRef = doc(db, 'users', userData.userId);

    // Update user document
    await updateDoc(userRef, {
      userName,
      profileUrl: newProfileUrl
    });
    console.log('Username updated in users collection');

    // Update all associated tours
    if (userData.tourIds && userData.tourIds.length > 0) {
      console.log('Updating username in tours:', userData.tourIds);
      
      for (const tourId of userData.tourIds) {
        const tourRef = doc(db, 'Tours', tourId);
        const tourDoc = await getDoc(tourRef);

        if (tourDoc.exists()) {
          const tourData = tourDoc.data();
          let needsUpdate = false;
          let updatedTourData = { ...tourData };

          // Helper function to update username in arrays
          const updateUsernameInArray = (array) => {
            if (!array) return array;
            return array.map(item => {
              if (item.userId === userData.userId) {
                needsUpdate = true;
                return { 
                  ...item, 
                  userName,
                  profileUrl: newProfileUrl
                };
              }
              return item;
            });
          };

          // Update all relevant arrays based on user type
          if (userData.userType === 'Artist' && updatedTourData.artistDetails) {
            updatedTourData.artistDetails = updateUsernameInArray(updatedTourData.artistDetails);
          }
          if (userData.userType === 'Crew' && updatedTourData.crewDetails) {
            updatedTourData.crewDetails = updateUsernameInArray(updatedTourData.crewDetails);
          }
          if (userData.userType === 'Vendor' && updatedTourData.vendorDetails) {
            updatedTourData.vendorDetails = updateUsernameInArray(updatedTourData.vendorDetails);
          }
          if (userData.userType === 'Staff' && updatedTourData.staffDetails) {
            updatedTourData.staffDetails = updateUsernameInArray(updatedTourData.staffDetails);
          }

          // Update createdBy if user is manager
          if (userData.userType === 'Manager' && 
              tourData.createdBy?.userId === userData.userId) {
            needsUpdate = true;
            updatedTourData.createdBy = {
              ...tourData.createdBy,
              userName,
              profileUrl: newProfileUrl
            };
          }

          if (needsUpdate) {
            console.log('Updating tour:', tourId);
            await updateDoc(tourRef, updatedTourData);
            if (updateTourData) {
              await updateTourData(tourId);
            }
          }
        }
      }
    }

    // Update context
    setUserData(prev => ({
      ...prev,
      userName,
      profileUrl: newProfileUrl
    }));

    setShowUsernameDialog(false);
    showCustomAlert({
      title: 'Success',
      message: 'Username updated successfully',
      type: 'success'
    });
  } catch (error) {
    console.error('Error updating username:', error);
    showCustomAlert({
      title: 'Error',
      message: 'Failed to update username. Please try again.',
      type: 'error'
    });
  } finally {
    setIsLoading(false);
  }
};

  const handleAccountVisibilityChange = () => {
    if (membershipTier === 'Elite') {
      setIsAccountPrivate(!isAccountPrivate);
    } else {
      showUpgradeAlert('account privacy');
    }
  };

  const handleEmailHiddenChange = () => {
    if (membershipTier === 'Elite') {
      setEmailHidden(!emailHidden);
    } else {
      showUpgradeAlert('email visibility');
    }
  };

  const handleAllowManagersChange = () => {
    if (membershipTier === 'Elite') {
      setAllowManagers(!allowManagers);
    } else {
      showUpgradeAlert('manager permissions');
    }
  };

  const showUpgradeAlert = (feature) => {
    const alertModal = document.createElement('div');
    alertModal.innerHTML = `
      <div class="${styles.alertModal}">
        <h3>Upgrade Required</h3>
        <p>Please upgrade to Elite membership to access ${feature} settings.</p>
        <button onclick="this.parentElement.remove()">OK</button>
      </div>
    `;
    document.body.appendChild(alertModal);
  };

  // Update the handleManagerSearch function in AccountDetails.js
const handleManagerSearch = async () => {
  if (managerSearchTerm.trim()) {
    setIsLoading(true);
    try {
      let algoliaFilters = [];
      // Always filter for Manager user type
      algoliaFilters.push('userType:"Manager"');

      const filterString = algoliaFilters.join(' AND ');

      const { results } = await client.search([{
        indexName: 'users_index',
        query: managerSearchTerm,
        filters: filterString,
        hitsPerPage: 10
      }]);

      setManagerSearchResults(results[0].hits.map(hit => ({
        userId: hit.objectID,
        fullName: hit.fullName,
        userName: hit.userName,
        userSubType: hit.userSubType,
        profilePictureUrl: hit.profilePictureUrl,
        userType: hit.userType,
        idVerificationStatus: hit.idVerificationStatus,
        city: hit.city,
        state: hit.state,
        country: hit.country,
      })));
      setHasSearched(true);
    } catch (error) {
      console.error('Search error:', error);
    } finally {
      setIsLoading(false);
    }
  }
};

  const handleManagerSelection = (manager) => {
    if (!selectedManagers.some(m => m.userId === manager.userId)) {
      setSelectedManagers(prev => [...prev, manager]);
    }
  };

  const removeSelectedManager = (managerId) => {
    setSelectedManagers(prev => prev.filter(m => m.userId !== managerId));
  };


  const handleResetPassword = async () => {
    try {
      const auth = firebaseInstances.auth;
      await sendPasswordResetEmail(auth, email);
      showCustomAlert({
        title: 'Success',
        message: 'Password reset link has been sent to your email',
        type: 'success'
      });
    } catch (error) {
      console.error('Error sending password reset:', error);
      showCustomAlert({
        title: 'Error',
        message: 'Failed to send password reset email',
        type: 'error'
      });
    }
  };


  const showCustomAlert = (config) => {
    setAlertConfig(config);
    setShowAlert(true);
  };

  // Handle logout
  const handleLogout = async () => {
    try {
      const auth = firebaseInstances.auth;
      await signOut(auth);
      // Clear any local storage items if needed
      localStorage.clear();
      // Redirect to login page
      window.location.href = '/login';
    } catch (error) {
      showCustomAlert({
        title: 'Error',
        message: 'Failed to logout. Please try again.',
        type: 'error'
      });
    }
  };


  // Add account deletion logic
  const handleDeleteAccount = async () => {
    try {
      setIsLoading(true);
      const db = getFirestoreInstance();
      const userRef = doc(db, 'users', userData.userId);

      // Mark account for deletion
      await updateDoc(userRef, {
        accountDeletionRequestedAt: new Date().toISOString()
      });

      // Logout
      await handleLogout();
    } catch (error) {
      console.error('Error initiating account deletion:', error);
      showCustomAlert({
        title: 'Error',
        message: 'Failed to initiate account deletion. Please try again.',
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  // Update the handleUpdateDetails function
  const handleUpdateDetails = async () => {
    setIsLoading(true);
    console.log('Starting handleUpdateDetails');
    
    try {
      const db = getFirestoreInstance();
      const userRef = doc(db, 'users', userData.userId);
  
      // Basic user document update (excluding email and username)
      const updateData = {
        phoneNumber,
        city,
        state,
        country,
        gender,
        userSubType,
        emailHidden,
        accountVisibility: isAccountPrivate ? 'Private' : 'Public',
        allowManagers,
        allowedManagers: selectedManagers.map(m => ({
          userId: m.userId,
          fullName: m.fullName,
          profilePhotoUrl: m.profilePhotoUrl || null, // Handle undefined
          userName: m.userName,
        })),
      };
  
      // Handle birthday
      if (birthday instanceof Date && !isNaN(birthday)) {
        const utcDate = new Date(Date.UTC(
          birthday.getFullYear(),
          birthday.getMonth(),
          birthday.getDate(),
          0, 0, 0
        ));
        updateData.birthday = utcDate.toISOString();
      }
  
      console.log('Updating user document with data:', updateData);
      await updateDoc(userRef, updateData);
  
      // Update all associated tours
      if (userData.tourIds && userData.tourIds.length > 0) {
        console.log('Updating tour documents for userSubType change');
        
        for (const tourId of userData.tourIds) {
          const tourRef = doc(db, 'Tours', tourId);
          const tourDoc = await getDoc(tourRef);
  
          if (tourDoc.exists()) {
            const tourData = tourDoc.data();
            let needsUpdate = false;
            let updatedTourData = { ...tourData };
  
            // Helper function to update userSubType in arrays
            const updateSubTypeInArray = (array) => {
              if (!array) return array;
              return array.map(item => {
                if (item.userId === userData.userId) {
                  needsUpdate = true;
                  return { ...item, userSubType };
                }
                return item;
              });
            };
  
            // Update all relevant arrays
            ['artistDetails', 'crewDetails', 'staffDetails', 'vendorDetails'].forEach(arrayName => {
              if (tourData[arrayName]) {
                updatedTourData[arrayName] = updateSubTypeInArray(tourData[arrayName]);
              }
            });
  
            // Update createdBy if user is manager
            if (userData.userType === 'Manager' && 
                tourData.createdBy?.userId === userData.userId) {
              needsUpdate = true;
              updatedTourData.createdBy = {
                ...tourData.createdBy,
                userSubType
              };
            }
  
            if (needsUpdate) {
              console.log('Updating tour:', tourId);
              await updateDoc(tourRef, updatedTourData);
              await updateTourData(tourId);
            }
          }
        }
      }
  
      // Update UserContext
      setUserData(prev => ({ ...prev, ...updateData }));
      
      showCustomAlert({
        title: 'Success',
        message: 'Profile updated successfully',
        type: 'success'
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      showCustomAlert({
        title: 'Error',
        message: 'Failed to update profile. Please try again.',
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  
  const AlertModal = () => {
    const getAlertIcon = () => {
      switch (alertConfig.type) {
        case 'success':
          return faCheckCircle;
        case 'error':
          return faTimesCircle;
        case 'warning':
          return faExclamationTriangle;
        default:
          return faInfoCircle;
      }
    };
  
    return (
      <div className={styles.alertModalOverlay}>
        <div className={styles.alertModal}>
          <div className={styles.alertHeader}>
            <FontAwesomeIcon 
              icon={getAlertIcon()} 
              className={`${styles.alertIcon} ${styles[alertConfig.type]}`}
            />
            <h3 className={styles.alertTitle}>{alertConfig.title}</h3>
          </div>
          <p className={styles.alertMessage}>{alertConfig.message}</p>
          <div className={styles.alertActions}>
            {alertConfig.showCancel && (
              <button 
                className={`${styles.alertButton} ${styles.cancel}`}
                onClick={() => setShowAlert(false)}
              >
                Cancel
              </button>
            )}
            <button 
              className={`${styles.alertButton} ${styles[alertConfig.type]}`}
              onClick={() => {
                if (alertConfig.onConfirm) alertConfig.onConfirm();
                setShowAlert(false);
              }}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    );
  };
  

  // Add Loading Spinner Component
  const LoadingSpinner = () => (
    <div className={styles.loadingOverlay}>
      <div className={styles.spinner}></div>
    </div>
  );

  

  return (
    <div className={styles.mainLayout}>
      <TopBar />
      <LeftMenu />
      <div className={styles.accountDetailsMain}>
        <div className={styles.accountDetailsContent}>
          <div className={styles.accountDetailsHeader}>
            <h1 className={styles.title}>Account Details</h1>
          </div>

          <div className={styles.formContainer}>
 {/* Email field */}
<div className={styles.field}>
  <label>Email</label>
  <div className={styles.inputGroup}>
    <input
      type="email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      className={styles.input}
    />
    <button 
      className={styles.updateButton}
      onClick={handleEmailUpdate}
      disabled={isLoading}
    >
      Update
    </button>
  </div>

              
              {/* Email visibility checkbox */}
              <div className={styles.checkboxContainer}>
                <input
                  type="checkbox"
                  id="hideEmail"
                  checked={emailHidden}
                  onChange={handleEmailHiddenChange}
                  disabled={membershipTier !== 'Elite'}
                  className={styles.checkbox}
                />
                <label htmlFor="hideEmail" className={styles.checkboxLabel}>
                  Hide email from users
                  {membershipTier !== 'Elite' && <span className={styles.eliteTag}>Elite</span>}
                </label>
              </div>
            </div>

            {/* Password reset */}
            <div className={styles.field}>
              <label>Password</label>
              <button 
                onClick={handleResetPassword}
                className={styles.resetButton}
              >
                Send Reset Link
              </button>
            </div>

            {/* Account Privacy */}
            <div className={styles.checkboxContainer}>
              <input
                type="checkbox"
                id="privateAccount"
                checked={isAccountPrivate}
                onChange={handleAccountVisibilityChange}
                disabled={membershipTier !== 'Elite'}
                className={styles.checkbox}
              />
              <label htmlFor="privateAccount" className={styles.checkboxLabel}>
                Make account private
                {membershipTier !== 'Elite' && <span className={styles.eliteTag}>Elite</span>}
              </label>
            </div>

            {/* Allow Managers (only show if not a manager) */}
            {userData?.userType !== 'Manager' && (
           
              <div className={styles.managersSection}>
        <div className={styles.checkboxContainer}>
          <input
            type="checkbox"
            id="allowManagers"
            checked={allowManagers}
            onChange={() => setAllowManagers(!allowManagers)}
            className={styles.checkbox}
          />
          <label htmlFor="allowManagers" className={styles.checkboxLabel}>
            Allowed Managers
            {membershipTier !== 'Elite' && <span className={styles.eliteTag}>Elite</span>}
          </label>
        </div>

        {allowManagers && membershipTier === 'Elite' && (
          <>
            <button 
              className={styles.searchManagerButton}
              onClick={() => setShowManagerSearchModal(true)}
            >
              <FontAwesomeIcon icon={faSearch} />
              Search Managers
            </button>

            {selectedManagers.length > 0 && (
              <div className={styles.selectedManagersGrid}>
                {selectedManagers.map(manager => (
                  <div key={manager.userId} className={styles.managerCard}>
                    <div className={styles.managerContent}>
                      <img 
                        src={manager.profilePictureUrl || manager.profilePictureUrl || defaultProfilePic}
                        alt={manager.fullName}
                        className={styles.profilePhoto}
                      />
                      <div className={styles.managerInfo}>
                        <div className={styles.nameContainer}>
                          <h3>{manager.fullName}</h3>
                          {manager.idVerificationStatus === 'Verified' && (
                            <img 
                              src={verifiedBadge} 
                              alt="Verified" 
                              className={styles.verifiedBadge}
                            />
                          )}
                        </div>
                        <p className={styles.username}>@{manager.userName}</p>
                        <p className={styles.subtype}>{manager.userSubType}</p>
                        <p className={styles.location}>
                          {[manager.city, manager.state, manager.country].filter(Boolean).join(', ')}
                        </p>
                      </div>
                      <button 
                        className={styles.removeButton}
                        onClick={() => removeSelectedManager(manager.userId)}
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>
                    
                  </div>
                ))}
              </div>
            )}
          </>
        )}
      </div>
      )}
 

            {/* Username Field */}
            <div className={styles.field}>
              <label>Username</label>
              <div className={styles.inputGroup}>
                <input
                  type="text"
                  value={userName}
                  onChange={(e) => setUserName(e.target.value)}
                  className={styles.input}
                />
                <button 
                  onClick={() => setShowUsernameDialog(true)}
                  className={styles.updateButton}
                >
                  Update
                </button>
              </div>
            </div>

            {/* User Type and Industry */}
            <div className={styles.field}>
              <label>User Type</label>
              <input
                type="text"
                value={userData?.userType || ''}
                readOnly
                className={`${styles.input} ${styles.readonly}`}
              />
            </div>

            <div className={styles.field}>
              <label>Industry</label>
              <input
                type="text"
                value={userData?.industry || ''}
                readOnly
                className={`${styles.input} ${styles.readonly}`}
              />
            </div>

                 {/* Sub Type Field */}
                 <div className={styles.field}>
              <label>Sub Type</label>
              <select
  value={userSubType}
  onChange={(e) => setUserSubType(e.target.value)}
  className={styles.select}
>
  <option value="">Select Sub Type</option>
  {subTypeOptions.map(option => (
    <option key={option.value} value={option.value}>
      {option.label}
    </option>
  ))}
</select>
            </div>

            {/* Contact and Location Fields */}
            <div className={styles.field}>
              <label>Phone Number</label>
              <input
  type="tel"
  value={phoneNumber}
  onChange={(e) => setPhoneNumber(e.target.value)}
  className={styles.input}

              />
            </div>

            <div className={styles.field}>
              <label>City</label>
              <input
                type="text"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                className={styles.input}
              />
            </div>

            <div className={styles.field}>
              <label>State</label>
              <input
                type="text"
                value={state}
                onChange={(e) => setState(e.target.value)}
                className={styles.input}
              />
            </div>

            <div className={styles.field}>
              <label>Country</label>
              <input
                type="text"
                value={country}
                onChange={(e) => setCountry(e.target.value)}
                className={styles.input}
              />
            </div>

             {/* Birthday field */}
             <div className={styles.field}>
  <label>Birthday</label>
  <div className={styles.datePickerContainer}>
    <input
      type="date"
      value={birthday ? birthday.toISOString().split('T')[0] : ''}
      onChange={(e) => {
        const date = e.target.value ? new Date(e.target.value) : null;
        setBirthday(date);
      }}
      className={styles.input}
    />
    <FontAwesomeIcon icon={faCalendar} className={styles.calendarIcon} />
  </div>
</div>

         <div className={styles.field}>
  <label>Gender</label>
  <select
    value={gender}
    onChange={(e) => setGender(e.target.value)}
    className={styles.select}
  >
    {genderOptions.map(option => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ))}
  </select>
</div>

             {/* More section */}
             <div className={styles.moreSection}>
          <button 
            className={styles.moreButton}
            onClick={() => setShowMore(!showMore)}
          >
            More
            <FontAwesomeIcon icon={showMore ? faChevronUp : faChevronDown} />
          </button>

          {showMore && (
            <button 
              onClick={() => {
                showCustomAlert({
                  title: 'Delete Account',
                  message: 'Are you sure you want to delete your account? This action cannot be undone. You have 60 days to log in and cancel this request.',
                  type: 'warning',
                  onConfirm: handleDeleteAccount,
                  showCancel: true
                });
              }}
              className={styles.deleteButton}
            >
              Request Account Deletion
            </button>
          )}
        </div>

        {/* Save button with loading state */}
        <button 
          onClick={handleUpdateDetails}
          className={styles.saveButton}
          disabled={isLoading}
        >
          {isLoading ? 'Updating...' : 'Save Details'}
        </button>
      </div>
      <RightMenu />

      

      {/* Show delete confirmation dialog when needed */}
      {showAlert && <AlertModal />}
          </div>
        </div>
  

        {/* Manager Search Modal */}
{showManagerSearchModal && (
  <div className={styles.modalOverlay}>
    <div className={styles.modal}>
      <div className={styles.modalHeader}>
        <h2>Search Managers</h2>
        <button 
          className={styles.closeButton}
          onClick={() => {
            setShowManagerSearchModal(false);
            setManagerSearchTerm('');
            setManagerSearchResults([]);
            setHasSearched(false);
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      </div>

      <div className={styles.searchBar}>
        <input
          type="text"
          placeholder="Search managers..."
          value={managerSearchTerm}
          onChange={(e) => setManagerSearchTerm(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleManagerSearch()}
        />
        <button 
          className={styles.searchButton}
          onClick={handleManagerSearch}
          disabled={!managerSearchTerm.trim()}
        >
          <FontAwesomeIcon icon={faSearch} />
          Search
        </button>
      </div>

      <div className={styles.searchResults}>
        {isLoading ? (
          <div className={styles.loading}>Loading...</div>
        ) : hasSearched ? (
          managerSearchResults.length > 0 ? (
            <div className={styles.resultsGrid}>
              {managerSearchResults.map(manager => (
                <div 
                  key={manager.userId} 
                  className={styles.resultCard}
                  onClick={() => handleManagerSelection(manager)}
                >
                  <div className={styles.resultCardContent}>
                    <img 
                      src={manager.profilePictureUrl || defaultProfilePic}
                      alt={manager.fullName}
                      className={styles.profilePhoto}
                    />
                    <div className={styles.resultInfo}>
                      <div className={styles.nameContainer}>
                        <h3>{manager.fullName}</h3>
                        {manager.idVerificationStatus === 'Verified' && (
                          <img 
                            src={verifiedBadge} 
                            alt="Verified" 
                            className={styles.verifiedBadge}
                          />
                        )}
                      </div>
                      <p className={styles.username}>@{manager.userName}</p>
                      <p className={styles.subtype}>{manager.userSubType}</p>
                      <p className={styles.location}>
                        {[manager.city, manager.state, manager.country].filter(Boolean).join(', ')}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className={styles.noResults}>
              No managers found for "{managerSearchTerm}"
            </div>
          )
        ) : (
          <div className={styles.searchPrompt}>
            Search for managers by name or username
          </div>
        )}
      </div>
    </div>
  </div>
)}

      {/* Custom Alert Dialog for Email Update */}
      {showEmailDialog && (
        <div className={styles.alertModalOverlay}>
          <div className={styles.alertModal}>
            <div className={styles.alertHeader}>
              <FontAwesomeIcon 
                icon={faExclamationTriangle} 
                className={styles.alertIcon} 
              />
              <h3 className={styles.alertTitle}>Update Email</h3>
            </div>
            <p className={styles.alertMessage}>
              Are you sure you want to update your email? This will update your email across all your tours.
            </p>
            <div className={styles.alertActions}>
              <button 
                className={`${styles.alertButton} ${styles.cancel}`}
                onClick={() => setShowEmailDialog(false)}
                disabled={isUpdating}
              >
                Cancel
              </button>
              <button 
                className={`${styles.alertButton} ${styles.confirm}`}
                onClick={handleEmailUpdate}
                disabled={isUpdating}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Custom Alert Dialog for Username Update */}
      {showUsernameDialog && (
        <div className={styles.alertModalOverlay}>
          <div className={styles.alertModal}>
            <div className={styles.alertHeader}>
              <FontAwesomeIcon 
                icon={faExclamationTriangle} 
                className={styles.alertIcon} 
              />
              <h3 className={styles.alertTitle}>Update Username</h3>
            </div>
            <p className={styles.alertMessage}>
              Are you sure you want to update your username? This will update your username across all your tours.
            </p>
            <div className={styles.alertActions}>
              <button 
                className={`${styles.alertButton} ${styles.cancel}`}
                onClick={() => setShowUsernameDialog(false)}
                disabled={isUpdating}
              >
                Cancel
              </button>
              <button 
                className={`${styles.alertButton} ${styles.confirm}`}
                onClick={handleUpdateUsername}
                disabled={isUpdating}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Show loading spinner when loading */}
      {isLoading && <LoadingSpinner />}
      
    </div>
  );
};

export default AccountDetails;