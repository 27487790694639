import React, { useRef, useState } from 'react';
import './ContactUs.module.css'; // Importing CSS file for styling
import logo from '../assets/Images/logo.png';
import emailjs from '@emailjs/browser';

function ContactUs() {
    const form = useRef();
    const [sent, setSent] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs
            .sendForm('service_lxc843q', 'template_qgku0cd', form.current, 'q-90hX1zJwba6RjEX') // Replace 'user_123456' with your user ID
            .then(
                () => {
                    console.log('SUCCESS!');
                    setSent(true);
                    setTimeout(() => {
                        setSent(false);
                        form.current.reset();
                    }, 3000); // Reset form after 3 seconds
                },
                (error) => {
                    console.log('FAILED...', error);
                }
            );
    };

    return (
        <div className="contact-page">
            <header className="top-bar">
                <img src={logo} alt="On Tour Logo" className="logo" />
                <button className="sign-in-btn">Sign In</button>
            </header>
            <div className="container">
                <h1 className="title">Contact Us</h1>
                <div className="content">
                    <p>If you have any questions or inquiries, feel free to contact us using the form below or through our email address.</p>
                    {sent && <div className="success-message">Message sent successfully!</div>}
                    <form ref={form} onSubmit={sendEmail}>
                        <div className="form-group">
                            <label htmlFor="name">Name:</label>
                            <input type="text" id="name" name="user_name" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="email">Email:</label>
                            <input type="email" id="email" name="user_email" />
                        </div>
                        <div className="form-group">
                            <label htmlFor="message">Message:</label>
                            <textarea id="message" name="message"></textarea>
                        </div>
                        <button type="submit" className="submit-btn">Submit</button>
                    </form>
                </div>
            </div>

            <footer className="footer">
                <p>If you have any queries, please contact us at <a href="mailto:support@ontour.co.in">support@ontour.co.in</a>.</p>
                <p>Office Address: Sri Ram Nagar, Phase-2, Shankar Nagar, Raipur, Chhattisgarh , India 492001</p>
                <ul>
                    <li><a href="https://ontour.co.in/help">Help</a></li>
                    <li><a href="https://ontour.co.in/contact-us">Contact Us</a></li>
                    <li><a href="https://ontour.co.in/terms-and-conditions">Terms and Conditions</a></li>
                    <li><a href="https://ontour.co.in/privacy-policy">Privacy</a></li>
                    <li><a href="https://ontour.co.in/refund-and-cancellation">Refund and Cancellation</a></li>
                    <li><a href="https://ontour.co.in/faq">FAQ</a></li>
                </ul>
            </footer>
        </div>
    );
}

export default ContactUs;
