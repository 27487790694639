import React, { useState, useContext, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { liteClient } from 'algoliasearch/lite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import TopBar from '../TopBar';
import LeftMenu from '../LeftMenu';
import RightMenu from '../RightMenu';
import UserContext from '../../context/UserContext';
import TourContext from '../../context/TourContext';
import styles from './Search.module.css';
import defaultProfilePhoto from '../../assets/Images/defaultUserProfilePicture.png';
import verifiedBadge from '../../assets/Images/profileverifiedtick.png';



// Initialize Algolia client
const client = liteClient('6DJNAZSX4P', '342932cbe26f7751ec87fa2b1ff32e4c');

const userSubTypeOptions = {
  Comedy: {
    Artist: [
      { label: "Comedy Writer", value: "Comedy Writer" },
      { label: "Improv Comedian", value: "Improv Comedian" },
      { label: "Sketch Artist", value: "Sketch Artist" },
      { label: "Stand-Up Comedian", value: "Stand-Up Comedian" },
    ],
    Crew: [
      { label: "Artist Manager", value: "Artist Manager" },
      { label: "Light Engineer", value: "Light Engineer" },
      { label: "Lighting Technician", value: "Lighting Technician" },
      { label: "Photographer", value: "Photographer" },
      { label: "Security Personnel", value: "Security Personnel" },
      { label: "Sound Engineer", value: "Sound Engineer" },
      { label: "Stage Manager", value: "Stage Manager" },
      { label: "Videographer", value: "Videographer" },
    ],
  },
  Music: {
    Artist: [
      { label: "Bass Guitarist", value: "Bass Guitarist" },
      { label: "Cellist", value: "Cellist" },
      { label: "DJ", value: "DJ" },
      { label: "Dhol Player", value: "Dhol Player" },
      { label: "Dholak Player", value: "Dholak Player" },
      { label: "Drummer", value: "Drummer" },
      { label: "Flutist", value: "Flutist" },
      { label: "Ghatam Player", value: "Ghatam Player" },
      { label: "Guitarist", value: "Guitarist" },
      { label: "Harmonium Player", value: "Harmonium Player" },
      { label: "Keyboardist", value: "Keyboardist" },
      { label: "Mridangam Player", value: "Mridangam Player" },
      { label: "Music Producer", value: "Music Producer" },
      { label: "Percussionist", value: "Percussionist" },
      { label: "Rapper", value: "Rapper" },
      { label: "Santoor Player", value: "Santoor Player" },
      { label: "Sarod Player", value: "Sarod Player" },
      { label: "Saxophonist", value: "Saxophonist" },
      { label: "Shehnai Player", value: "Shehnai Player" },
      { label: "Sitar Player", value: "Sitar Player" },
      { label: "Tabla Player", value: "Tabla Player" },
      { label: "Trumpeter", value: "Trumpeter" },
      { label: "Veena Player", value: "Veena Player" },
      { label: "Violinist", value: "Violinist" },
      { label: "Vocalist", value: "Vocalist" },
    ],
    Crew: [
      { label: "Artist Manager", value: "Artist Manager" },
      { label: "Backline Technician", value: "Backline Technician" },
      { label: "Dresser", value: "Dresser" },
      { label: "Drum Tech", value: "Drum Tech" },
      { label: "Guitar Tech", value: "Guitar Tech" },
      { label: "Keyboard Tech", value: "Keyboard Tech" },
      { label: "Light Engineer", value: "Light Engineer" },
      { label: "Lighting Technician", value: "Lighting Technician" },
      { label: "Makeup Artist", value: "Makeup Artist" },
      { label: "Merchandiser", value: "Merchandiser" },
      { label: "Monitor Engineer", value: "Monitor Engineer" },
      { label: "Photographer", value: "Photographer" },
      { label: "Rigger", value: "Rigger" },
      { label: "Road Manager", value: "Road Manager" },
      { label: "Security Personnel", value: "Security Personnel" },
      { label: "Sound Engineer", value: "Sound Engineer" },
      { label: "Stage Manager", value: "Stage Manager" },
      { label: "Stage Tech", value: "Stage Tech" },
      { label: "Tour Bus Driver", value: "Tour Bus Driver" },
      { label: "Videographer", value: "Videographer" },
    ],
    Vendor: [
      { label: "Backline Provider", value: "Backline Provider" },
      { label: "Light Vendor", value: "Light Vendor" },
      { label: "Sound Vendor", value: "Sound Vendor" },
      { label: "Stage and Production Vendor", value: "Stage and Production Vendor" },
    ],
  },
};



const Search = () => {
  const navigate = useNavigate();
  const { userData: currentUser } = useContext(UserContext);
  
  
  const [searchTerm, setSearchTerm] = useState('');
  const [activeTab, setActiveTab] = useState('Artist');
  const [searchResults, setSearchResults] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [filters, setFilters] = useState({});
  const [hasSearched, setHasSearched] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tempFilters, setTempFilters] = useState({});
  const [subTypeSuggestions, setSubTypeSuggestions] = useState([]);
  const [selectedSubTypes, setSelectedSubTypes] = useState([]);

  const isFreeTier = currentUser?.membershipTier === 'Free';
  const isRestrictedTier = currentUser?.membershipTier === 'Free' || currentUser?.membershipTier === 'Basic';

  const handleSearch = async () => {
    if ((searchTerm.trim() || Object.keys(filters).length > 0) && !isFreeTier) {
      setIsLoading(true);
      try {
        let algoliaFilters = [];
        algoliaFilters.push(`userType:"${activeTab}"`);
  
        if (filters.subType) {
          algoliaFilters.push(`userSubType:"${filters.subType}"`);
        }
        if (filters.city) {
          algoliaFilters.push(`city:"${filters.city}"`);
        }
        if (filters.state) {
          algoliaFilters.push(`state:"${filters.state}"`);
        }
  
        const filterString = algoliaFilters.join(' AND ');
        
        const { results } = await client.search([{
          indexName: 'users_index',
          query: searchTerm,
          filters: filterString,
          hitsPerPage: 10
        }]);

        setSearchResults(results[0].hits.map(hit => ({
          userId: hit.objectID,
          fullName: hit.fullName,
          userName: hit.userName,
          userSubType: hit.userSubType,
          profilePictureUrl: hit.profilePictureUrl,
          userType: hit.userType,
          idVerificationStatus: hit.idVerificationStatus,
          city: hit.city,
          state: hit.state,
          country: hit.country,
        })));
        setHasSearched(true);
      } catch (error) {
        console.error('Search error:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleTabChange = async (tab) => {
    setActiveTab(tab);
    if (hasSearched && (searchTerm.trim() || Object.keys(filters).length > 0)) {
      setIsLoading(true);
      try {
        let algoliaFilters = [];
        algoliaFilters.push(`userType:"${tab}"`);
  
        if (filters.subType) {
          algoliaFilters.push(`userSubType:"${filters.subType}"`);
        }
        if (filters.city) {
          algoliaFilters.push(`city:"${filters.city}"`);
        }
        if (filters.state) {
          algoliaFilters.push(`state:"${filters.state}"`);
        }
  
        const filterString = algoliaFilters.join(' AND ');
        
        const { results } = await client.search([{
          indexName: 'users_index',
          query: searchTerm,
          filters: filterString,
          hitsPerPage: 10
        }]);

        setSearchResults(results[0].hits.map(hit => ({
          userId: hit.objectID,
          fullName: hit.fullName,
          userName: hit.userName,
          userSubType: hit.userSubType,
          profilePictureUrl: hit.profilePictureUrl,
          userType: hit.userType,
          idVerificationStatus: hit.idVerificationStatus,
          city: hit.city,
          state: hit.state,
          country: hit.country,
        })));
      } catch (error) {
        console.error('Search error:', error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleUserSelect = (user) => {
    if (user.userId === currentUser?.userId) {
      navigate('/profile');
    } else {
      navigate(`/${user.userName}`);
    }
  };

  const handleUpgrade = () => {
    navigate('/membership');
  };

  const handleSubTypeInputChange = (text) => {
    setTempFilters(prev => ({ ...prev, subType: text }));
    if (text.length > 0) {
      const suggestions = Object.values(userSubTypeOptions)
        .flatMap(category => Object.values(category).flat())
        .filter(option => option.label.toLowerCase().includes(text.toLowerCase()));
      setSubTypeSuggestions(suggestions);
    } else {
      setSubTypeSuggestions([]);
    }
  };

  const handleSubTypeSelection = (subType) => {
    if (!selectedSubTypes.includes(subType)) {
      setSelectedSubTypes(prev => [...prev, subType]);
    }
    setTempFilters(prev => ({ ...prev, subType: '' }));
    setSubTypeSuggestions([]);
  };

  const removeSelectedSubType = (subType) => {
    setSelectedSubTypes(prev => prev.filter(type => type !== subType));
  };

  const applyFilters = () => {
    const newFilters = { ...tempFilters };
    if (selectedSubTypes.length > 0) {
      newFilters.subType = selectedSubTypes.join(',');
    }
    setFilters(newFilters);
    setIsModalOpen(false);
    handleSearch();
  };

  const removeFilter = (key, value) => {
    const newFilters = { ...filters };
    if (key === 'subType') {
      const subTypes = newFilters[key].split(',').filter(type => type !== value);
      if (subTypes.length === 0) {
        delete newFilters[key];
      } else {
        newFilters[key] = subTypes.join(',');
      }
    } else {
      delete newFilters[key];
    }
    setFilters(newFilters);
    handleSearch();
  };

  return (
    <div className={styles['main-layout']}>
      <TopBar />
      <LeftMenu />
      <div className={styles['search-container']}>
        <div className={styles['search-header']}>
          <div className={styles['search-bar']}>
            <FontAwesomeIcon 
              icon={faFilter} 
              className={`${styles['filter-icon']} ${isRestrictedTier ? styles['disabled'] : ''}`}
              onClick={() => !isRestrictedTier && setIsModalOpen(true)}
            />
            <input
              type="text"
              placeholder="Search users..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
            />
            {searchTerm && (
              <FontAwesomeIcon 
                icon={faTimes} 
                className={styles['clear-icon']}
                onClick={() => setSearchTerm('')}
              />
            )}
            <button 
              className={`${styles['search-button']} ${(!searchTerm.trim() && Object.keys(filters).length === 0) || isFreeTier ? styles['disabled'] : ''}`}
              onClick={handleSearch}
              disabled={(!searchTerm.trim() && Object.keys(filters).length === 0) || isFreeTier}
            >
              <FontAwesomeIcon icon={faSearch} />
              Search
            </button>
          </div>

          <div className={styles['filter-tags']}>
            {Object.entries(filters).map(([key, value]) => {
              if (key === 'subType') {
                return value.split(',').map((subType, index) => (
                  <div key={`${key}-${index}`} className={styles['filter-tag']}>
                    <span>{subType}</span>
                    <button onClick={() => removeFilter(key, subType)}>×</button>
                  </div>
                ));
              } else {
                return (
                  <div key={key} className={styles['filter-tag']}>
                    <span>{`${key}: ${value}`}</span>
                    <button onClick={() => removeFilter(key, value)}>×</button>
                  </div>
                );
              }
            })}
          </div>

          <div className={styles.tabs}>
            {['Artist', 'Crew', 'Manager', 'Vendor'].map((tab) => (
              <button
                key={tab}
                className={`${styles.tab} ${activeTab === tab ? styles.active : ''}`}
                onClick={() => handleTabChange(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>

        <div className={styles['search-results']}>
          {isFreeTier ? (
            <div className={styles['upgrade-prompt']}>
              <p>Please upgrade your membership to search users.</p>
              <button className={styles['upgrade-button']} onClick={handleUpgrade}>
                Upgrade
              </button>
            </div>
          ) : isLoading ? (
            <div className={styles.loading}>Loading...</div>
          ) : hasSearched ? (
            searchResults.length > 0 ? (
              <div className={styles['results-grid']}>
                {searchResults.map(user => (
                  <div 
                    key={user.userId} 
                    className={styles['user-card']}
                    onClick={() => handleUserSelect(user)}
                  >
                    <div className={styles['user-card-content']}>
                      <img 
                        src={user.profilePictureUrl || defaultProfilePhoto}
                        alt={user.fullName}
                        className={styles['profile-photo']}
                      />
                      <div className={styles['user-info']}>
                        <div className={styles['name-container']}>
                          <h3>{user.fullName}</h3>
                          {user.idVerificationStatus === 'Verified' && (
                            <img src={verifiedBadge} alt="Verified" className={styles['verified-badge']} />
                          )}
                        </div>
                        <p className={styles.username}>@{user.userName}</p>
                        <p className={styles.subtype}>{user.userSubType}</p>
                        {!isRestrictedTier && (
                          <p className={styles.location}>{[user.city, user.state, user.country].filter(Boolean).join(', ')}</p>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles['no-results']}>
                No users found for "{searchTerm}"
              </div>
            )
          ) : (
            <div className={styles['search-prompt']}>
              Please search by username or full name
            </div>
          )}
        </div>
      </div>
      <RightMenu />

      {/* Filter Modal */}
      {isModalOpen && (
        <div className={styles['modal-overlay']}>
          <div className={styles['modal-content']}>
            <h2>Filters</h2>
            
            <div className={styles['filter-section']}>
              <label>Sub Type</label>
              <div className={styles['selected-subtypes']}>
                {selectedSubTypes.map((subType) => (
                  <div key={subType} className={styles['subtype-tag']}>
                    <span>{subType}</span>
                    <button onClick={() => removeSelectedSubType(subType)}>×</button>
                  </div>
                ))}
              </div>
              <input
                type="text"
                placeholder="Enter Sub Type"
                value={tempFilters.subType || ''}
                onChange={(e) => handleSubTypeInputChange(e.target.value)}
                className={styles['filter-input']}
              />
              {subTypeSuggestions.length > 0 && (
                <div className={styles['suggestions-list']}>
                  {subTypeSuggestions.map((suggestion) => (
                    <div
                      key={suggestion.value}
                      className={styles['suggestion-item']}
                      onClick={() => handleSubTypeSelection(suggestion.label)}
                    >
                      {suggestion.label}
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className={styles['filter-section']}>
              <label>Location</label>
              <div className={styles['location-inputs']}>
                <input
                  type="text"
                  placeholder="City"
                  value={tempFilters.city || ''}
                  onChange={(e) => setTempFilters(prev => ({ ...prev, city: e.target.value }))}
                  className={styles['filter-input']}
                />
                <input
                  type="text"
                  placeholder="State"
                  value={tempFilters.state || ''}
                  onChange={(e) => setTempFilters(prev => ({ ...prev, state: e.target.value }))}
                  className={styles['filter-input']}
                />
              </div>
            </div>

            <div className={styles['filter-actions']}>
              <button 
                className={styles['cancel-button']}
                onClick={() => setIsModalOpen(false)}
              >
                Cancel
              </button>
              <button 
                className={styles['apply-button']}
                onClick={applyFilters}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Search;