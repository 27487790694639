import { getFirestoreInstance } from './firebaseHelper';
import { 
  collection, 
  addDoc, 
  query, 
  where, 
  getDocs, 
  Timestamp,
  orderBy,
  limit,
  startAfter,
  updateDoc,
  arrayUnion,
  doc,
  getDoc
} from 'firebase/firestore';

// Types of band notifications
export const BAND_NOTIFICATION_TYPES = {
  BAND_CREATED: 'BAND_CREATED',
  ADMIN_ADDED: 'ADMIN_ADDED',
  ADMIN_REMOVED: 'ADMIN_REMOVED',
  USER_ADDED: 'USER_ADDED',
  USER_REMOVED: 'USER_REMOVED',
  MEMBER_REQUEST: 'MEMBER_REQUEST',
  REQUEST_ACCEPTED: 'REQUEST_ACCEPTED',
  REQUEST_REJECTED: 'REQUEST_REJECTED'
};

export async function createBandNotification(data) {
  const db = getFirestoreInstance();
  try {
    // Create band notification
    const notificationRef = await addDoc(collection(db, 'bandNotifications'), {
      ...data,
      timestamp: Timestamp.now(),
      readBy: {}
    });

    // Create regular notification for each recipient
    if (data.recipientIds?.length > 0) {
      const notificationPromises = data.recipientIds.map(userId => 
        addDoc(collection(db, 'Notifications'), {
          userId,
          type: 'BAND_NOTIFICATION',
          title: data.title,
          body: data.message,
          data: {
            bandId: data.bandId,
            notificationId: notificationRef.id,
            type: data.type
          },
          timestamp: Timestamp.now(),
          read: false
        })
      );

      await Promise.all(notificationPromises);
    }

    return notificationRef.id;
  } catch (error) {
    console.error('Error creating band notification:', error);
    throw error;
  }
}

export async function getBandNotifications(bandId, pageSize = 8, lastDoc = null) {
  const db = getFirestoreInstance();
  try {
    let q = query(
      collection(db, 'bandNotifications'),
      where('bandId', '==', bandId),
      orderBy('timestamp', 'desc'),
      limit(pageSize)
    );

    if (lastDoc) {
      q = query(q, startAfter(lastDoc));
    }

    const querySnapshot = await getDocs(q);
    const notifications = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    const hasMore = notifications.length === pageSize;
    const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

    return {
      notifications,
      hasMore,
      lastDoc: lastVisible
    };
  } catch (error) {
    console.error('Error fetching band notifications:', error);
    throw error;
  }
}

export async function markBandNotificationAsRead(notificationId, userId) {
  const db = getFirestoreInstance();
  try {
    const notificationRef = doc(db, 'bandNotifications', notificationId);
    await updateDoc(notificationRef, {
      [`readBy.${userId}`]: Timestamp.now()
    });
  } catch (error) {
    console.error('Error marking band notification as read:', error);
    throw error;
  }
}

// Function to send band membership request
export async function sendBandMembershipRequest(bandId, userData) {
  const db = getFirestoreInstance();
  try {
    const bandRef = doc(db, 'bands', bandId);
    const bandDoc = await getDoc(bandRef);
    
    if (!bandDoc.exists()) {
      throw new Error('Band not found');
    }

    const bandData = bandDoc.data();

    // Add to memberRequests array in band document
    await updateDoc(bandRef, {
      memberRequests: arrayUnion({
        userId: userData.userId,
        userName: userData.userName,
        fullName: userData.fullName,
        profilePictureUrl: userData.profilePictureUrl,
        userType: userData.userType,
        timestamp: Timestamp.now()
      })
    });

    // Create notification for band admins
    await createBandNotification({
      bandId,
      type: BAND_NOTIFICATION_TYPES.MEMBER_REQUEST,
      title: 'New Member Request',
      message: `${userData.fullName} has requested to join ${bandData.bandFullName}`,
      recipientIds: bandData.admins.map(admin => admin.userId),
      requestedBy: {
        userId: userData.userId,
        userName: userData.userName,
        fullName: userData.fullName,
        profilePictureUrl: userData.profilePictureUrl
      }
    });

    return true;
  } catch (error) {
    console.error('Error sending band membership request:', error);
    throw error;
  }
}

// Function to handle member request response
export async function handleMembershipRequest(bandId, requestData, isAccepted, respondedBy) {
  const db = getFirestoreInstance();
  try {
    const bandRef = doc(db, 'bands', bandId);
    const bandDoc = await getDoc(bandRef);
    
    if (!bandDoc.exists()) {
      throw new Error('Band not found');
    }

    const bandData = bandDoc.data();

    // Remove from memberRequests array
    const updatedRequests = bandData.memberRequests.filter(
      req => req.userId !== requestData.userId
    );

    if (isAccepted) {
      // Add to members array if accepted
      await updateDoc(bandRef, {
        memberRequests: updatedRequests,
        members: arrayUnion({
          userId: requestData.userId,
          userName: requestData.userName,
          fullName: requestData.fullName,
          profilePictureUrl: requestData.profilePictureUrl,
          userType: requestData.userType,
          joinedAt: Timestamp.now()
        })
      });

      // Update user's document to include band association
      const userRef = doc(db, 'users', requestData.userId);
      await updateDoc(userRef, {
        associatedActs: arrayUnion({
          id: bandId,
          type: 'band',
          name: bandData.bandFullName,
          username: bandData.bandUserName,
          photoUrl: bandData.bandProfilePictureUrl,
          genre: bandData.bandGenres?.join(', ')
        })
      });
    } else {
      // Just remove request if rejected
      await updateDoc(bandRef, {
        memberRequests: updatedRequests
      });
    }

    // Create notification for the requesting user
    await createBandNotification({
      bandId,
      type: isAccepted ? BAND_NOTIFICATION_TYPES.REQUEST_ACCEPTED : BAND_NOTIFICATION_TYPES.REQUEST_REJECTED,
      title: isAccepted ? 'Member Request Accepted' : 'Member Request Rejected',
      message: `Your request to join ${bandData.bandFullName} has been ${isAccepted ? 'accepted' : 'rejected'} by ${respondedBy.fullName}`,
      recipientIds: [requestData.userId],
      respondedBy: {
        userId: respondedBy.userId,
        userName: respondedBy.userName,
        fullName: respondedBy.fullName,
        profilePictureUrl: respondedBy.profilePictureUrl
      }
    });

    return true;
  } catch (error) {
    console.error('Error handling membership request:', error);
    throw error;
  }
}