import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { signUp, checkUsernameUniqueness, uploadProfilePhoto } from '../utils/actions/authActions';
import { FiCamera, FiEye, FiEyeOff, FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import defaultUserProfilePicture from '../assets/Images/defaultUserProfilePicture.png';
import logo from '../assets/Images/logo.png';
import styles from './SignupPage.module.css';

const INDUSTRY_OPTIONS = [
  { label: "Music", value: "Music" },
  { label: "Comedy", value: "Comedy" }
];

const USER_TYPE_OPTIONS = {
  Music: [
    { label: "Artist", value: "Artist" },
    { label: "Crew", value: "Crew" },
    { label: "Manager", value: "Manager" },
    { label: "Vendor", value: "Vendor" },
    { label: "Staff", value: "Staff" }
  ],
  Comedy: [
    { label: "Artist", value: "Artist" },
    { label: "Crew", value: "Crew" },
    { label: "Manager", value: "Manager" },
    { label: "Staff", value: "Staff" }
  ]
};

const USER_SUBTYPE_OPTIONS = {
  Comedy: {
    Artist: [
      { label: "Comedy Writer", value: "Comedy Writer" },
      { label: "Improv Comedian", value: "Improv Comedian" },
      { label: "Sketch Artist", value: "Sketch Artist" },
      { label: "Stand-Up Comedian", value: "Stand-Up Comedian" },
    ],
    Crew: [
      { label: "Backline Technician", value: "Backline Technician" },
      { label: "Dresser", value: "Dresser" },
      { label: "Drum Tech", value: "Drum Tech" },
      { label: "Guitar Tech", value: "Guitar Tech" },
      { label: "Keyboard Tech", value: "Keyboard Tech" },
      { label: "Light Engineer", value: "Light Engineer" },
      { label: "Lighting Technician", value: "Lighting Technician" },
      { label: "Makeup Artist", value: "Makeup Artist" },
      { label: "Merchandiser", value: "Merchandiser" },
      { label: "Monitor Engineer", value: "Monitor Engineer" },
      { label: "Photographer", value: "Photographer" },
      { label: "Rigger", value: "Rigger" },
      { label: "Road Manager", value: "Road Manager" },
      { label: "Security Personnel", value: "Security Personnel" },
      { label: "Sound Engineer", value: "Sound Engineer" },
      { label: "Stage Manager", value: "Stage Manager" },
      { label: "Stage Tech", value: "Stage Tech" },
      { label: "Tour Bus Driver", value: "Tour Bus Driver" },
      { label: "Videographer", value: "Videographer" },
    ],
    Manager: [
      { label: "Tour Manager", value: "Tour Manager" },
      { label: "Artist Manager", value: "Artist Manager" },
    ],
    Vendor: [
      { label: "Backline Provider", value: "Backline Provider" },
      { label: "Light Vendor", value: "Light Vendor" },
      { label: "Sound Vendor", value: "Sound Vendor" },
      { label: "Stage and Production Vendor", value: "Stage and Production Vendor" },
    ],
  
    Staff: [
    // Backline Staff
    { label: "Backline Equipment Coordinator", value: "Backline Equipment Coordinator" },
    { label: "Instrument Maintenance Technician", value: "Instrument Maintenance Technician" },
    { label: "Backline Logistics Coordinator", value: "Backline Logistics Coordinator" },
    { label: "Equipment Setup Technician", value: "Equipment Setup Technician" },
  
    // Lighting Staff
    { label: "Lighting Setup Technician", value: "Lighting Setup Technician" },
    { label: "Lighting Board Operator", value: "Lighting Board Operator" },
    { label: "Lighting Rig Technician", value: "Lighting Rig Technician" },
    { label: "DMX Systems Specialist", value: "DMX Systems Specialist" },
    { label: "Moving Light Technician", value: "Moving Light Technician" },
  
    // Sound Staff
    { label: "PA System Technician", value: "PA System Technician" },
    { label: "Audio Setup Specialist", value: "Audio Setup Specialist" },
    { label: "Speaker Systems Technician", value: "Speaker Systems Technician" },
    { label: "Audio Equipment Coordinator", value: "Audio Equipment Coordinator" },
    { label: "FOH Systems Technician", value: "FOH Systems Technician" },
  
    // Stage and Production Staff
    { label: "Stage Construction Technician", value: "Stage Construction Technician" },
    { label: "Truss System Specialist", value: "Truss System Specialist" },
    { label: "Stage Equipment Coordinator", value: "Stage Equipment Coordinator" },
    { label: "Production Setup Coordinator", value: "Production Setup Coordinator" },
    { label: "Staging Logistics Specialist", value: "Staging Logistics Specialist" }
  ],
},
Music: {
  Artist: [
    { label: "Bass Guitarist", value: "Bass Guitarist" },
    { label: "Cellist", value: "Cellist" },
    { label: "DJ", value: "DJ" },
    { label: "Dhol Player", value: "Dhol Player" },
    { label: "Dholak Player", value: "Dholak Player" },
    { label: "Drummer", value: "Drummer" },
    { label: "Flutist", value: "Flutist" },
    { label: "Ghatam Player", value: "Ghatam Player" },
    { label: "Guitarist", value: "Guitarist" },
    { label: "Harmonium Player", value: "Harmonium Player" },
    { label: "Keyboardist", value: "Keyboardist" },
    { label: "Mridangam Player", value: "Mridangam Player" },
    { label: "Music Producer", value: "Music Producer" },
    { label: "Percussionist", value: "Percussionist" },
    { label: "Rapper", value: "Rapper" },
    { label: "Santoor Player", value: "Santoor Player" },
    { label: "Sarod Player", value: "Sarod Player" },
    { label: "Saxophonist", value: "Saxophonist" },
    { label: "Shehnai Player", value: "Shehnai Player" },
    { label: "Sitar Player", value: "Sitar Player" },
    { label: "Tabla Player", value: "Tabla Player" },
    { label: "Trumpeter", value: "Trumpeter" },
    { label: "Veena Player", value: "Veena Player" },
    { label: "Violinist", value: "Violinist" },
    { label: "Vocalist", value: "Vocalist" },
  ],
  Crew: [
    { label: "Backline Technician", value: "Backline Technician" },
    { label: "Dresser", value: "Dresser" },
    { label: "Drum Tech", value: "Drum Tech" },
    { label: "Guitar Tech", value: "Guitar Tech" },
    { label: "Keyboard Tech", value: "Keyboard Tech" },
    { label: "Light Engineer", value: "Light Engineer" },
    { label: "Lighting Technician", value: "Lighting Technician" },
    { label: "Makeup Artist", value: "Makeup Artist" },
    { label: "Merchandiser", value: "Merchandiser" },
    { label: "Monitor Engineer", value: "Monitor Engineer" },
    { label: "Photographer", value: "Photographer" },
    { label: "Rigger", value: "Rigger" },
    { label: "Road Manager", value: "Road Manager" },
    { label: "Security Personnel", value: "Security Personnel" },
    { label: "Sound Engineer", value: "Sound Engineer" },
    { label: "Stage Manager", value: "Stage Manager" },
    { label: "Stage Tech", value: "Stage Tech" },
    { label: "Tour Bus Driver", value: "Tour Bus Driver" },
    { label: "Videographer", value: "Videographer" },
  ],
  Manager: [
    { label: "Tour Manager", value: "Tour Manager" },
    { label: "Artist Manager", value: "Artist Manager" },
  ],
  Vendor: [
    { label: "Backline Provider", value: "Backline Provider" },
    { label: "Light Vendor", value: "Light Vendor" },
    { label: "Sound Vendor", value: "Sound Vendor" },
    { label: "Stage and Production Vendor", value: "Stage and Production Vendor" },
  ],

  Staff: [
  // Backline Staff
  { label: "Backline Equipment Coordinator", value: "Backline Equipment Coordinator" },
  { label: "Instrument Maintenance Technician", value: "Instrument Maintenance Technician" },
  { label: "Backline Logistics Coordinator", value: "Backline Logistics Coordinator" },
  { label: "Equipment Setup Technician", value: "Equipment Setup Technician" },

  // Lighting Staff
  { label: "Lighting Setup Technician", value: "Lighting Setup Technician" },
  { label: "Lighting Board Operator", value: "Lighting Board Operator" },
  { label: "Lighting Rig Technician", value: "Lighting Rig Technician" },
  { label: "DMX Systems Specialist", value: "DMX Systems Specialist" },
  { label: "Moving Light Technician", value: "Moving Light Technician" },

  // Sound Staff
  { label: "PA System Technician", value: "PA System Technician" },
  { label: "Audio Setup Specialist", value: "Audio Setup Specialist" },
  { label: "Speaker Systems Technician", value: "Speaker Systems Technician" },
  { label: "Audio Equipment Coordinator", value: "Audio Equipment Coordinator" },
  { label: "FOH Systems Technician", value: "FOH Systems Technician" },

  // Stage and Production Staff
  { label: "Stage Construction Technician", value: "Stage Construction Technician" },
  { label: "Truss System Specialist", value: "Truss System Specialist" },
  { label: "Stage Equipment Coordinator", value: "Stage Equipment Coordinator" },
  { label: "Production Setup Coordinator", value: "Production Setup Coordinator" },
  { label: "Staging Logistics Specialist", value: "Staging Logistics Specialist" }
],
},
};

const SignupPage = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    password: '',
    userName: '',
    userType: '',
    userSubType: '',
    city: '',
    state: '',
    country: '',
    birthday: '',
    industry: '',
    profilePhoto: null,
    gender: '',
    isInBand: false,
    selectedBands: []
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));

    // Reset dependent fields when industry or userType changes
    if (name === 'industry') {
      setFormData(prev => ({
        ...prev,
        userType: '',
        userSubType: ''
      }));
    } else if (name === 'userType') {
      setFormData(prev => ({
        ...prev,
        userSubType: ''
      }));
    }
  };

  const handleFileChange = (e) => {
    if (e.target.files[0]) {
      const file = e.target.files[0];
      if (file.size > 5 * 1024 * 1024) { // 5MB limit
        setError('Image size should be less than 5MB');
        return;
      }
      setFormData(prev => ({
        ...prev,
        profilePhoto: file
      }));
    }
  };

  const validateUsername = (username) => {
    const regex = /^[a-z0-9_]+$/;
    return regex.test(username);
  };

  const validateStep = async () => {
    try {
      switch (currentStep) {
        case 1:
          if (!formData.fullName || !formData.gender || !formData.birthday) {
            throw new Error('Please fill in all required fields');
          }
          if (!formData.city || !formData.state || !formData.country) {
            throw new Error('Please provide your location details');
          }
          break;
        case 2:
          if (!formData.userName) {
            throw new Error('Username is required');
          }
          if (!validateUsername(formData.userName)) {
            throw new Error('Username can only contain lowercase letters, numbers, and underscores');
          }
          const isUnique = await checkUsernameUniqueness(formData.userName);
          if (!isUnique) {
            throw new Error('Username is already taken');
          }
          if (!formData.industry || !formData.userType) {
            throw new Error('Please select industry and user type');
          }
          if (!formData.userSubType) {
            throw new Error('Please select your sub type');
          }
          break;
        case 3:
          if (!formData.email) {
            throw new Error('Email is required');
          }
          if (!formData.password) {
            throw new Error('Password is required');
          }
          if (formData.password.length < 6) {
            throw new Error('Password must be at least 6 characters long');
          }
          break;
        default:
          return true;
      }
      return true;
    } catch (error) {
      setError(error.message);
      return false;
    }
  };

  const handleNext = async () => {
    const isValid = await validateStep();
    if (isValid) {
      setCurrentStep(prev => prev + 1);
      setError('');
    }
  };

  const handleBack = () => {
    setCurrentStep(prev => prev - 1);
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError('');

    try {
      let profilePictureUrl = '';
      const result = await signUp({
        ...formData,
        profilePictureUrl
      });

      if (formData.profilePhoto) {
        profilePictureUrl = await uploadProfilePhoto(formData.profilePhoto, result.user.uid);
      }

      alert('Account created successfully! Please check your email for verification.');
      navigate('/login');
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 1:
        return (
          <>
            <div className={styles['profile-photo-section']}>
              <img
                src={formData.profilePhoto ? URL.createObjectURL(formData.profilePhoto) : defaultUserProfilePicture}
                alt="Profile"
                className={styles['profile-photo']}
              />
              <label className={styles['upload-button']}>
                <FiCamera /> Upload Photo
                <input
                  type="file"
                  hidden
                  accept="image/*"
                  onChange={handleFileChange}
                />
              </label>
            </div>

            <div className={styles['form-group']}>
              <label className={styles['form-label']}>Full Name</label>
              <input
                type="text"
                name="fullName"
                value={formData.fullName}
                onChange={handleInputChange}
                className={styles['form-input']}
                placeholder="Enter your full name"
              />
            </div>

            <div className={styles['form-group']}>
              <label className={styles['form-label']}>Gender</label>
              <select
                name="gender"
                value={formData.gender}
                onChange={handleInputChange}
                className={styles['select-input']}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
                <option value="other">Other</option>
              </select>
            </div>

            <div className={styles['form-group']}>
              <label className={styles['form-label']}>Birthday</label>
              <input
                type="date"
                name="birthday"
                value={formData.birthday}
                onChange={handleInputChange}
                className={styles['form-input']}
              />
            </div>

            <div className={styles['form-group']}>
              <label className={styles['form-label']}>City</label>
              <input
                type="text"
                name="city"
                value={formData.city}
                onChange={handleInputChange}
                className={styles['form-input']}
                placeholder="Enter your city"
              />
            </div>

            <div className={styles['form-group']}>
              <label className={styles['form-label']}>State</label>
              <input
                type="text"
                name="state"
                value={formData.state}
                onChange={handleInputChange}
                className={styles['form-input']}
                placeholder="Enter your state"
              />
            </div>

            <div className={styles['form-group']}>
              <label className={styles['form-label']}>Country</label>
              <input
                type="text"
                name="country"
                value={formData.country}
                onChange={handleInputChange}
                className={styles['form-input']}
                placeholder="Enter your country"
              />
            </div>
          </>
        );

      case 2:
        return (
          <>
            <div className={styles['form-group']}>
              <label className={styles['form-label']}>Username</label>
              <input
                type="text"
                name="userName"
                value={formData.userName}
                onChange={handleInputChange}
                className={styles['form-input']}
                placeholder="Choose a username"
              />
            </div>

            <div className={styles['form-group']}>
              <label className={styles['form-label']}>Industry</label>
              <select
                name="industry"
                value={formData.industry}
                onChange={handleInputChange}
                className={styles['select-input']}
              >
                <option value="">Select Industry</option>
                {INDUSTRY_OPTIONS.map(option => (
                  <option key={option.value} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            {formData.industry && (
              <div className={styles['form-group']}>
                <label className={styles['form-label']}>User Type</label>
                <select
                  name="userType"
                  value={formData.userType}
                  onChange={handleInputChange}
                  className={styles['select-input']}
                >
                  <option value="">Select User Type</option>
                  {USER_TYPE_OPTIONS[formData.industry]?.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {formData.industry && formData.userType && (
              <div className={styles['form-group']}>
                <label className={styles['form-label']}>Sub Type</label>
                <select
                  name="userSubType"
                  value={formData.userSubType}
                  onChange={handleInputChange}
                  className={styles['select-input']}
                >
                  <option value="">Select Sub Type</option>
                  {USER_SUBTYPE_OPTIONS[formData.industry]?.[formData.userType]?.map(option => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {formData.userType === 'Artist' && (
              <div className={styles['form-group']}>
                <label className={styles['form-label']}>Are you in any bands?</label>
                <div className={styles['radio-group']}>
                  <label className={styles['radio-button']}>
                    <input
                      type="radio"
                      name="isInBand"
                      checked={formData.isInBand === true}
                      onChange={() => setFormData(prev => ({ ...prev, isInBand: true }))}
                    />
                    Yes
                  </label>
                  <label className={styles['radio-button']}>
                    <input
                      type="radio"
                      name="isInBand"
                      checked={formData.isInBand === false}
                      onChange={() => setFormData(prev => ({ ...prev, isInBand: false }))}
                    />
                    No
                  </label>
                </div>
              </div>
            )}
          </>
        );

      case 3:
        return (
          <>
            <div className={styles['form-group']}>
              <label className={styles['form-label']}>Email</label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                className={styles['form-input']}
                placeholder="Enter your email"
              />
            </div>

            <div className={styles['form-group']}>
              <label className={styles['form-label']}>Password</label>
              <div className={styles['password-input-container']}>
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={formData.password}
                  onChange={handleInputChange}
                  className={styles['form-input']}
                  placeholder="Create a password"
                />
                <button
                  type="button"
                  className={styles['password-toggle']}
                  onClick={() => setShowPassword(!showPassword)}
                >
                  {showPassword ? <FiEyeOff /> : <FiEye />}
                </button>
              </div>
            </div>
          </>
        );

      default:
        return null;
    }
  };

  return (
    <div className={styles['signup-page']}>
      <div className={styles['signup-container']}>
        <div className={styles['logo-section']}>
          <img src={logo} alt="On Tour Logo" className={styles.logo} />
          <h1>Create Account</h1>
        </div>

        <div className={styles['step-indicator']}>
          {[1, 2, 3].map(step => (
            <div
              key={step}
              className={`${styles.step} ${currentStep === step ? styles.active : ''} 
                ${currentStep > step ? styles.completed : ''}`}
            >
              {step}
            </div>
          ))}
        </div>

        <div className={styles['form-container']}>
          {error && <div className={styles['error-message']}>{error}</div>}
          
          <form onSubmit={currentStep === 3 ? handleSubmit : e => e.preventDefault()}>
            {renderStep()}

            <div className={styles['navigation-buttons']}>
              {currentStep > 1 && (
                <button 
                  type="button" 
                  className={styles['back-button']}
                  onClick={handleBack}
                  disabled={isLoading}
                >
                  <FiArrowLeft /> Back
                </button>
              )}
              
              <button
                type={currentStep === 3 ? "submit" : "button"}
                className={styles['next-button']}
                onClick={currentStep === 3 ? undefined : handleNext}
                disabled={isLoading}
              >
                {currentStep === 3 ? (
                  isLoading ? 'Creating Account...' : 'Create Account'
                ) : (
                  <>
                    Next <FiArrowRight />
                  </>
                )}
              </button>
            </div>
          </form>
        </div>

        <div className={styles['login-link']}>
          Already have an account? <Link to="/login">Login</Link>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;