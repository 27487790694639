import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs, doc, getDoc, addDoc, updateDoc, arrayUnion, } from 'firebase/firestore';
import { getFirestoreInstance } from '../../utils/firebaseHelper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faMapMarkerAlt, 
  faCalendarCheck, 
  faRoute, 
  faEnvelope,
  faHome,
  faUser,
  faCog,
  faSignOutAlt,
  faWallet,
  faShare,
  faEdit,
  faVideo,
  faMusic,
  faImage,
  faTimes,
  faPlus,
  faLink,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { 
  faInstagram, 
  faTwitter, 
  faFacebook, 
  faYoutube ,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons';
import spotifylogo from '../../assets/Images/spotifyicon.png'
import applemusiclogo from '../../assets/Images/applemusicicon.png'
import { createPost, getUserPosts, validatePostUrl } from '../../utils/actions/PostActions';
import { getUserTags } from '../../utils/actions/PostActions';
import PostCard from '../common/PostCard';
import UserContext from '../../context/UserContext';
import TourContext from '../../context/TourContext';
import TopBar from '../TopBar';
import LeftMenu from '../LeftMenu';
import RightMenu from '../RightMenu';
import styles from './Profile.module.css';
import defaultProfilePhoto from '../../assets/Images/defaultUserProfilePicture.png';
import defaultCoverPhoto from '../../assets/Images/defaultUserCoverPicture.png';
import verifiedBadge from '../../assets/Images/profileverifiedtick.png'
import { deletePost, updatePost } from '../../utils/actions/PostActions';
import ShareModal from '../common/ShareModal';
import BrandWebsiteModal from '../common/BrandWebsiteModal';
import SearchComponent from '../common/SearchComponent';
import { ToursModal, ShowsModal, UsersModal } from './ProfileModals';
import { sendTourInvitationNotification } from '../../utils/NotificationService';

// Modal Component for Post Upload
const UploadModal = ({ isOpen, onClose, onUpload, existingTags, editingPost }) => {
  const [postType, setPostType] = useState(editingPost?.type || null);
  const [url, setUrl] = useState(editingPost?.url || '');
  const [caption, setCaption] = useState(editingPost?.caption || '');
  const [tags, setTags] = useState(editingPost?.tags.join(', ') || '');
  const [selectedExistingTags, setSelectedExistingTags] = useState(
    editingPost?.tags || []
  );

  useEffect(() => {
    if (editingPost) {
      setPostType(editingPost.type);
      setUrl(editingPost.url);
      setCaption(editingPost.caption);
      setTags(editingPost.tags.join(', '));
      setSelectedExistingTags(editingPost.tags);
    }
  }, [editingPost]);

  const handleTagClick = (tag) => {
    const normalizedTag = tag.toLowerCase().replace(/\s+/g, '');
    const updatedTags = selectedExistingTags.includes(normalizedTag)
      ? selectedExistingTags.filter(t => t !== normalizedTag)
      : [...selectedExistingTags, normalizedTag];
    setSelectedExistingTags(updatedTags);
    setTags(updatedTags.join(', '));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const normalizedTags = [...new Set([
      ...selectedExistingTags,
      ...tags.split(',')
        .map(tag => tag.trim().toLowerCase().replace(/\s+/g, ''))
        .filter(tag => tag)
    ])];
    
    const postData = {
      type: postType,
      url,
      caption,
      tags: normalizedTags,
    };
    
    await onUpload(postData);
    onClose();
  };

  const resetForm = () => {
    if (!editingPost) {
      setPostType(null);
      setUrl('');
      setCaption('');
      setTags('');
      setSelectedExistingTags([]);
    }
  };

  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  if (!isOpen) return null;

  return (
    <div className={styles['modal-overlay']}>
      <div className={styles['modal-content']}>
        <button className={styles['modal-close']} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        
        {!postType && !editingPost ? (
          <div className={styles['upload-options']}>
            <h2>Choose Upload Type</h2>
            <div className={styles['upload-buttons']}>
              <button onClick={() => setPostType('video')}>
                <FontAwesomeIcon icon={faVideo} />
                <span>Video</span>
              </button>
              <button onClick={() => setPostType('audio')}>
                <FontAwesomeIcon icon={faMusic} />
                <span>Audio</span>
              </button>
              <button onClick={() => setPostType('photo')}>
                <FontAwesomeIcon icon={faImage} />
                <span>Photo</span>
              </button>
            </div>
          </div>
        ) : (
          <form onSubmit={handleSubmit} className={styles['upload-form']}>
            <h2>{editingPost ? 'Edit Post' : `${postType.charAt(0).toUpperCase() + postType.slice(1)} Upload`}</h2>
            
            <div className={styles['form-group']}>
              <label>URL</label>
              <input 
                type="url" 
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                placeholder={`Enter ${postType} URL`}
                required
              />
              {postType === 'video' && (
                <small>Supported: Instagram, YouTube, Twitter, Facebook, LinkedIn</small>
              )}
              {postType === 'audio' && (
                <small>Supported: Spotify, SoundCloud, Apple Music</small>
              )}
              {postType === 'photo' && (
                <small>Supported: Instagram, Twitter, Facebook, LinkedIn</small>
              )}
            </div>

            <div className={styles['form-group']}>
              <label>Caption</label>
              <textarea
                value={caption}
                onChange={(e) => setCaption(e.target.value)}
                placeholder="Write a caption..."
              />
            </div>

            <div className={styles['form-group']}>
              <label>Tags</label>
              <input
                type="text"
                value={tags}
                onChange={(e) => setTags(e.target.value)}
                placeholder="Add tags separated by commas (no spaces allowed)"
              />
              <small className={styles['helper-text']}>
                Tags should be single words without spaces (e.g., newmusic, tourlife, behindthescenes)
              </small>
              <div className={styles['existing-tags']}>
                {existingTags.map((tag) => (
                  <button
                    type="button"
                    key={tag}
                    onClick={() => handleTagClick(tag)}
                    className={`${styles['tag-button']} ${
                      selectedExistingTags.includes(tag) ? styles['selected'] : ''
                    }`}
                  >
                    {tag}
                  </button>
                ))}
              </div>
            </div>

            <div className={styles['form-actions']}>
              <button 
                type="button" 
                onClick={() => editingPost ? onClose() : setPostType(null)}
                className={styles['secondary-button']}
              >
                {editingPost ? 'Cancel' : 'Back'}
              </button>
              <button 
                type="submit"
                className={styles['primary-button']}
              >
                {editingPost ? 'Update' : 'Upload'}
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};


const StatsContainer = ({ profileData, tours, currentUser, onToursClick, onShowsClick, onUsersClick, onAddPress }) => {
  const isAddOnTourAllowed = () => {
    if (currentUser.userType === 'Manager') {
      if (profileData.allowManagers === 'True' && profileData.allowedManagers) {
        return profileData.allowedManagers.some(manager => manager.userId === currentUser.userId);
      }
      return true;
    }
    return false;
  };

  return (
    <div className={styles['stats-grid']}>
      <div className={styles['stat-card']} onClick={onToursClick}>
        <div className={styles['stat-value']}>{profileData?.toursCount || 0}</div>
        <div className={styles['stat-label']}>Tours</div>
      </div>
      <div className={styles['stat-card']} onClick={onShowsClick}>
        <div className={styles['stat-value']}>{profileData?.showsCount || 0}</div>
        <div className={styles['stat-label']}>Shows</div>
      </div>
      {(profileData?.userType === 'Manager' || profileData?.userType === 'Artist Management Company') ? (
        <div className={styles['stat-card']} onClick={onUsersClick}>
          <div className={styles['stat-value']}>{profileData?.usersCount || 0}</div>
          <div className={styles['stat-label']}>Users</div>
        </div>
      ) : (
        <div 
          className={`${styles['add-user-button']} ${!isAddOnTourAllowed() ? styles.disabled : ''}`}
          onClick={() => isAddOnTourAllowed() && onAddPress()}
        >
          <img 
            src={require('../../assets/Images/AddOnTour.png')}
            alt="Add to Tour"
            className={`${styles['add-user-icon']} ${!isAddOnTourAllowed() ? styles.disabled : ''}`}
          />
        </div>
      )}
    </div>
  );
};


const checkIfUserOnTour = (tours, userData, date) => {
  const dateString = date.toISOString().split('T')[0];
  return tours.some(tour => {
    const userDetails = tour.artistDetails?.find(artist => artist.userId === userData.userId) ||
                       tour.crewDetails?.find(crew => crew.userId === userData.userId);
    
    return tour.tourDates.some(tourDate => {
      if (tourDate.rescheduleDate?.isRescheduled) {
        return new Date(tourDate.rescheduleDate.newDate).toISOString().split('T')[0] === dateString &&
               userDetails?.assignedDates?.some(assignment => assignment.date === tourDate.date);
      } else {
        return tourDate.date === dateString &&
               userDetails?.assignedDates?.some(assignment => assignment.date === dateString);
      }
    });
  });
};

const TourInvitationModal = ({ isOpen, onClose, userData, currentUser, profileData }) => {
  // Add TourContext at the component level
  const { tours, updateTourData } = useContext(TourContext);
  const [selectedTour, setSelectedTour] = useState(null);
  const [tourDateAssignments, setTourDateAssignments] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const db = getFirestoreInstance();

  // Function to check if a date has a pending invitation
  const checkPendingInvitation = (tour, dateString) => {
    if (!tour?.pendingInvitationsData || !Array.isArray(tour.pendingInvitationsData)) {
      return false;
    }
  
    return tour.pendingInvitationsData.some(invitation => 
      invitation.userId === profileData.userId && 
      invitation.status === 'pending' &&
      invitation.assignedDates.some(date => date.date === dateString)
    );
  };


  const [isLoading, setIsLoading] = useState(false);

  const handleTourSelect = async (tour) => {
    setSelectedTour(tour);
    setIsLoading(true);
    setTourDateAssignments({});
  
    try {
      const db = getFirestoreInstance();
      
      // Fetch tour invitations
      const pendingInvitationsData = [];
      if (tour.pendingInvitations && Array.isArray(tour.pendingInvitations)) {
        for (const invitationId of tour.pendingInvitations) {
          const invitationDoc = await getDoc(doc(db, 'TourInvitations', invitationId));
          if (invitationDoc.exists()) {
            pendingInvitationsData.push({
              id: invitationId,
              ...invitationDoc.data()
            });
          }
        }
      }
  
      // Add pendingInvitationsData to tour
      const updatedTour = {
        ...tour,
        pendingInvitationsData
      };
  
      const tourDatesRef = collection(db, 'Tours', tour.id, 'tourDates');
      const tourDatesSnapshot = await getDocs(tourDatesRef);
      
      const tourDates = tourDatesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
  
      const existingAssignments = {};
      const userDetails = tour.artistDetails?.find(artist => artist.userId === profileData.userId) || 
                         tour.crewDetails?.find(crew => crew.userId === profileData.userId);
      
      tourDates.forEach(date => {
        const isAssigned = userDetails?.assignedDates?.some(assignment => assignment.date === date.date);
        const isPending = checkPendingInvitation(updatedTour, date.date);
        const isOnTour = checkIfUserOnTour(tours, profileData, new Date(date.date));
  
        existingAssignments[date.date] = {
          isAssigned: isAssigned,
          isAlreadyAssigned: isAssigned,
          isPending: isPending,
          isOnTour: isOnTour,
          tourDateDocId: date.id
        };
      });
  
      setTourDateAssignments(existingAssignments);
      setSelectedTour({...updatedTour, tourDates});
    } catch (error) {
      console.error("Error fetching tour dates:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateSelection = (date, tourDateDocId) => {
    if (tourDateAssignments[date]?.isOnTour) return;
    
    setTourDateAssignments(prev => ({
      ...prev,
      [date]: {
        ...prev[date],
        isAssigned: !prev[date]?.isAssigned
      }
    }));
  };

  const renderDateItem = (date) => {
    const assignment = tourDateAssignments[date.date];
    const isPending = checkPendingInvitation(selectedTour, date.date);
    const isOnTour = assignment?.isOnTour;
    
    return (
      <div 
        key={date.id}
        className={`${styles.dateItem} 
          ${isOnTour ? styles.onTour : ''} 
          ${isPending ? styles.pending : ''} 
          ${assignment?.isAssigned ? styles.selected : ''}`}
        onClick={() => !isOnTour && !isPending && handleDateSelection(date.date, date.id)}
      >
        <div className={styles.dateInfo}>
          <span>{new Date(date.date).toLocaleDateString()}</span>
          <span className={styles.status}>
            {isOnTour ? 'On Tour' : 
             isPending ? 'Pending' :
             'Available'}
          </span>
        </div>
        {date.event?.venueDetails?.venueData && (
          <div className={styles.venueInfo}>
            <p className={styles.venueName}>{date.event.venueDetails.venueData.displayName}</p>
            <p className={styles.venueLocation}>{date.event.venueDetails.venueData.formattedAddress}</p>
          </div>
        )}
      </div>
    );
  };

  const handleSendInvitation = async () => {
    try {
      setIsUpdating(true);
  
      const selectedDates = Object.entries(tourDateAssignments)
        .filter(([date, details]) => details.isAssigned)
        .map(([date, details]) => ({
          date,
          tourDateDocId: details.tourDateDocId
        }));
  
      if (selectedDates.length === 0) {
        alert('Please select at least one date');
        return;
      }
  
      const invitationData = {
        tourId: selectedTour.id,
        tourName: selectedTour.tourName,
        actName: selectedTour.actName,
        userId: profileData.userId,
        userName: profileData.userName,
        name: profileData.fullName,
        email: profileData.email || '',
        profilePictureUrl: profileData.profilePictureUrl || '',
        userType: profileData.userType,
        userSubType: profileData.userSubType,
        role: profileData.userType,
        read: false,
        pendingTimestamp: new Date().toISOString(),
        timestamp: new Date().toISOString(),
        assignedDates: selectedDates,
        status: 'pending',
        createdBy: {
          userId: currentUser.userId,
          fullName: currentUser.fullName,
          userName: currentUser.userName,
          email: currentUser.email,
          profilePictureUrl: currentUser.profilePictureUrl,
          userType: currentUser.userType,
          userSubType: currentUser.userSubType
        }
      };
  
      const invitationRef = await addDoc(collection(db, 'TourInvitations'), invitationData);
    
      await updateDoc(doc(db, 'Tours', selectedTour.id), {
        pendingInvitations: arrayUnion(invitationRef.id)
      });
  
      // Update local state
      const updatedAssignments = { ...tourDateAssignments };
      selectedDates.forEach(({ date }) => {
        updatedAssignments[date] = {
          ...updatedAssignments[date],
          isPending: true,
          isAssigned: false,
        };
      });
      setTourDateAssignments(updatedAssignments);
  
      // Update tour data
      await updateTourData(selectedTour.id);
  
      // Send notification - don't wait for it or let it block the process
      sendTourInvitationNotification(profileData.userId, {
        ...invitationData,
        invitationId: invitationRef.id
      }).catch(console.error); // Just log any errors
  
      alert('Tour invitation sent successfully!');
      onClose();
    } catch (error) {
      console.error('Error sending invitation:', error);
      alert('Error sending invitation. Please try again.');
    } finally {
      setIsUpdating(false);
    }
  };

if (!isOpen) return null;
return (
  <div className={styles['modal-overlay']}>
    <div className={styles['modal-content']}>
      <button className={styles['modal-close']} onClick={onClose}>
        <FontAwesomeIcon icon={faTimes} />
      </button>

      {!selectedTour ? (
        <div className={styles['upload-options']}>
          <h2>Choose Tour</h2>
          <div className={styles['tour-list']}>
  {tours.map(tour => {
    const isPending = tour.pendingInvitations?.some(inv => 
      inv.userId === userData.userId && inv.status === 'pending'
    );
    const isAccepted = tour.artistDetails?.some(a => a.userId === userData.userId) ||
                      tour.crewDetails?.some(c => c.userId === userData.userId);

    return (
      <div 
        key={tour.id} 
        className={`${styles['tour-item']} ${isPending || isAccepted ? styles.disabled : ''}`}
        onClick={() => !isPending && !isAccepted && handleTourSelect(tour)}
      >
        <img 
          src={tour.tourCoverPhotoUrl || require('../../assets/Images/dafaulttourcoverphoto.png')}
          alt={tour.tourName}
          className={styles['tour-cover']}
        />
        <div className={styles['tour-item-content']}>
          <div>
            <h3>{tour.tourName}</h3>
            <p>{tour.actName}</p>
          </div>
          {(isPending || isAccepted) && (
            <div className={styles['tour-status']}>
              {isPending ? 'Request Pending' : 'Already Added'}
            </div>
          )}
        </div>
      </div>
    );
  })}
</div>
        </div>
      ) : (
        <div className={styles['tour-info']}>
          <h2>{selectedTour.tourName}</h2>
          <p className={styles['act-name']}>{selectedTour.actName}</p>
          
          <div className={styles['dates-list']}>
            {selectedTour.tourDates.map(renderDateItem)}
          </div>
          
          <div className={styles['form-actions']}>
            <button 
              className={styles['secondary-button']} 
              onClick={() => setSelectedTour(null)}
            >
              Back
            </button>
            <button 
              className={styles['primary-button']}
              onClick={handleSendInvitation}
              disabled={isUpdating || !Object.values(tourDateAssignments).some(a => a.isAssigned)}
            >
              {isUpdating ? 'Sending...' : 'Send Invitation'}
            </button>
          </div>
        </div>
      )}
    </div>
  </div>
);
      };


const Profile = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const { userData: currentUserData, getUserDataFromCache } = useContext(UserContext);
  const [profileData, setProfileData] = useState(null);
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const [selectedTour, setSelectedTour] = useState(null);
  const { tours, setTours } = useContext(TourContext);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [posts, setPosts] = useState([]);
  const [activeTab, setActiveTab] = useState('showcase');
  const [displayedPosts, setDisplayedPosts] = useState(4);
const [selectedTag, setSelectedTag] = useState('all');
const [userTags, setUserTags] = useState([]);
const [editingPost, setEditingPost] = useState(null);
const [showProfileShareModal, setShowProfileShareModal] = useState(false);
const [isPostsLoading, setIsPostsLoading] = useState(false);
const [isPageLoading, setIsPageLoading] = useState(true);

const [toursModalVisible, setToursModalVisible] = useState(false);
  const [showsModalVisible, setShowsModalVisible] = useState(false);
  const [usersModalVisible, setUsersModalVisible] = useState(false);

  const handleToursCountClick = () => setToursModalVisible(true);
  const handleShowsCountClick = () => setShowsModalVisible(true);
  const handleUsersCountClick = () => setUsersModalVisible(true);

const [showAddOnTourModal, setShowAddOnTourModal] = useState(false);
const [showBrandModal, setShowBrandModal] = useState(false);
const [selectedBrand, setSelectedBrand] = useState(null);  

useEffect(() => {
  const fetchPosts = async () => {
    if (!profileData?.userId) return;
    
    try {
      setIsPostsLoading(true);
      
      const postsData = await getUserPosts(
        profileData.userId, 
        displayedPosts,
        selectedTag === 'all' ? null : selectedTag
      );
      
      setPosts(postsData);
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setIsPostsLoading(false);
      setIsPageLoading(false); // Add this line to ensure page loading state is updated
    }
  };

  fetchPosts();
}, [profileData?.userId, displayedPosts, selectedTag]);

// Add this function before your Profile component
const isUserOnTour = (tours, userData) => {
  // Format today's date in YYYY-MM-DD
  const today = new Date();
  const todayString = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(2, '0')}-${String(today.getDate()).padStart(2, '0')}`;

  const formatToDateString = (dateStr) => {
    if (!dateStr) return '';
    const date = new Date(dateStr);
    return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
  };

  // Check each tour
  for (const tour of tours) {
    if (userData.userType === 'Manager') {
      // For managers, check all tour dates
      for (const tourDate of tour.tourDates) {
        if (tourDate.cancelled) continue;

        const originalDate = formatToDateString(tourDate.date);
        const hasReschedule = tourDate.rescheduleDate?.isRescheduled;
        const rescheduledDate = hasReschedule ? 
          formatToDateString(tourDate.rescheduleDate.newDate) : null;

        if ((originalDate === todayString && !hasReschedule) || 
            (hasReschedule && rescheduledDate === todayString)) {
          return true;
        }
      }
    } else {
      // For artists, crew, vendors, and staff
      let userDetails;
      switch (userData.userType) {
        case 'Artist':
          userDetails = tour.artistDetails;
          break;
        case 'Crew':
          userDetails = tour.crewDetails;
          break;
        case 'Vendor':
          userDetails = tour.vendorDetails;
          break;
        case 'Staff':
          userDetails = tour.staffDetails;
          break;
        default:
          continue;
      }
      
      const userAssignment = userDetails?.find(user => user.userId === userData.userId);
      
      if (userAssignment?.assignedDates) {
        for (const assignedDate of userAssignment.assignedDates) {
          const tourDate = tour.tourDates.find(td => td.id === assignedDate.tourDateDocId);
          if (!tourDate || tourDate.cancelled) continue;

          const originalDate = formatToDateString(tourDate.date);
          const hasReschedule = tourDate.rescheduleDate?.isRescheduled;
          const rescheduledDate = hasReschedule ? 
            formatToDateString(tourDate.rescheduleDate.newDate) : null;

          if ((originalDate === todayString && !hasReschedule) || 
              (hasReschedule && rescheduledDate === todayString)) {
            return true;
          }
        }
      }
    }
  }

  return false;
};


const handleUserSelect = (user) => {
  if (user.userId === currentUserData?.userId) {
    navigate(`/${user.userName}`);
  } else {
    navigate(`/${user.userName}`);
  }
};

const handleBrandClick = (brand) => {
  setSelectedBrand(brand);
  setShowBrandModal(true);
};


  const isMobileDevice = () => {
    return (
      typeof window !== 'undefined' && 
      (window.innerWidth <= 768 || 'ontouchstart' in window)
    );
  };
  
  const handleShareProfile = async () => {
    const shareData = {
      title: `${profileData?.fullName}'s Profile on OnTour`,
      text: profileData?.bio || 'Check out this profile on OnTour',
      url: window.location.href
    };
  
    // Check if it's mobile and has native sharing
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  
    if (isMobileDevice && navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error sharing:', error);
          handleClipboardCopy(window.location.href);
        }
      }
    } else {
      // On desktop, show the ShareModal
      setShowProfileShareModal(true);
    }
  };
  
  const handleClipboardCopy = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      // You can add a toast notification here
      alert('Profile URL copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy URL:', err);
    }
  };
  
  const handleTagSelect = (tag) => {
    const normalizedTag = tag === 'all' ? 'all' : tag.toLowerCase().replace(/\s+/g, '');
    setSelectedTag(normalizedTag);
    setDisplayedPosts(4);
  };


  const handleSearchResults = (searchResults) => {
    if (!searchResults || searchResults.length === 0) {
      // If no search results, show all posts
      if (profileData?.userId) {
        getUserPosts(profileData.userId, displayedPosts).then(setPosts);
      }
    } else {
      // Update with search results
      setPosts(searchResults);
    }
  };

  useEffect(() => {
    const fetchTags = async () => {
      if (profileData?.userId) {
        const tags = await getUserTags(profileData.userId);
        setUserTags(tags);
      }
    };
    fetchTags();
  }, [profileData?.userId]);

  const handleUpload = async (postData) => {
    try {
      if (!validatePostUrl(postData.url, postData.type)) {
        throw new Error('Invalid URL for selected platform');
      }
  
      let newPost;
      if (editingPost) {
        // Update existing post
        newPost = await updatePost(profileData.userId, editingPost.postId, {
          ...postData,
          userName: profileData.userName
        });
  
        // Update local posts state
        setPosts(prevPosts => 
          prevPosts.map(p => 
            p.postId === editingPost.postId 
              ? { ...p, ...newPost }
              : p
          )
        );
      } else {
        // Create new post with explicit timestamp
        const now = new Date();
        newPost = await createPost(profileData.userId, {
          ...postData,
          userName: profileData.userName,
          createdAt: {
            seconds: Math.floor(now.getTime() / 1000),
            nanoseconds: 0
          }
        });
  
        // Update local posts state with properly formatted date
        setPosts(prevPosts => [{
          ...newPost,
          createdAt: {
            seconds: Math.floor(now.getTime() / 1000),
            nanoseconds: 0
          }
        }, ...prevPosts].slice(0, displayedPosts));
      }
  
      setEditingPost(null);
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error handling post:', error);
      throw error;
    }
  };
  
  // Add cleanup when modal closes
  const handleModalClose = () => {
    setEditingPost(null);
    setIsModalOpen(false);
  };

  const handleEditPost = (post) => {
    setEditingPost(post);
    setIsModalOpen(true);
  };
  
  // Update the handleDeletePost function
  const handleDeletePost = async (postId) => {
    try {
      if (window.confirm('Are you sure you want to delete this post?')) {
        await deletePost(currentUserData.userId, postId);
        // Refresh posts after deletion
        const updatedPosts = posts.filter(p => p.postId !== postId);
        setPosts(updatedPosts);
      }
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };
  
  
  useEffect(() => {
    const fetchProfileData = async () => {
      setIsLoading(true);
      try {
        if (currentUserData?.userName === username) {
          setProfileData(currentUserData);
          setIsOwnProfile(true);
          return; // Tours are already managed by TourContext
        }
  
        const db = getFirestore();
        const userQuery = query(collection(db, 'users'), where('userName', '==', username));
        const userSnapshot = await getDocs(userQuery);
        
        if (!userSnapshot.empty) {
          const userId = userSnapshot.docs[0].id;
          const userData = await getUserDataFromCache(userId);
          setProfileData(userData);
          setIsOwnProfile(false);
  
          // Only fetch and set tours for other users' profiles
          if (userData.tourIds?.length) {
            const tourRefs = userData.tourIds.map(id => doc(db, 'Tours', id));
            const toursData = await Promise.all(
              tourRefs.map(async (tourRef) => {
                const tourDoc = await getDoc(tourRef);
                if (!tourDoc.exists()) return null;
  
                const tourData = tourDoc.data();
                const tourDatesSnapshot = await getDocs(collection(db, 'Tours', tourDoc.id, 'tourDates'));
                const tourDates = tourDatesSnapshot.docs.map(dateDoc => ({
                  id: dateDoc.id,
                  ...dateDoc.data(),
                }));
  
                return {
                  id: tourDoc.id,
                  ...tourData,
                  tourDates,
                  artistDetails: tourData.artistDetails || [],
                  crewDetails: tourData.crewDetails || [],
                  pendingInvitations: tourData.pendingInvitations || []
                };
              })
            );
  
            setTours(toursData.filter(Boolean));
          } else {
            setTours([]); // Clear tours if user has none
          }
        } else {
          setProfileData(null);  // Set to null to indicate user not found
        }
      } catch (error) {
        console.error('Error:', error);
        setProfileData(null);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchProfileData();
  }, [username, currentUserData, getUserDataFromCache, navigate, setTours]);



  const handleTourSelect = (tour) => {
    setSelectedTour(tour);
  };

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);




  const handleEditProfile = () => navigate('/edit-profile');
  
  const handleEmailClick = () => window.location.href = `mailto:${profileData.email}`;

  const BottomNav = () => (
    <div className={styles['bottom-nav']}>
      <button onClick={() => navigate('/dashboard')} className={styles['nav-item']}>
        <FontAwesomeIcon icon={faHome} className={styles['nav-icon']} />
        <span>Home</span>
      </button>
      <button onClick={() => navigate('/profile')} className={`${styles['nav-item']} ${styles.active}`}>
        <FontAwesomeIcon icon={faUser} className={styles['nav-icon']} />
        <span>Profile</span>
      </button>
      <button onClick={() => navigate('/payment-info')} className={styles['nav-item']}>
        <FontAwesomeIcon icon={faWallet} className={styles['nav-icon']} />
        <span>Payments</span>
      </button>
      <button onClick={() => navigate('/settings')} className={styles['nav-item']}>
        <FontAwesomeIcon icon={faCog} className={styles['nav-icon']} />
        <span>Settings</span>
      </button>
    </div>
  );



  const TourDates = ({ userData, tours }) => {
    const [filter, setFilter] = useState('all');
    const [displayDays, setDisplayDays] = useState(30);
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [showDatePicker, setShowDatePicker] = useState(false);
  
    // Updated date formatting to remove timezone influence
    const formatToDateString = (dateStr) => {
      if (!dateStr) return '';
      const date = new Date(dateStr);
      return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`;
    };
  
    // Updated date generation to use local dates
    const generateDates = () => {
      const datesList = [];
      const today = new Date();
      // Reset time to start of day in local time
      today.setHours(0, 0, 0, 0);
      
      for (let i = 0; i < displayDays; i++) {
        const date = new Date(today);
        date.setDate(today.getDate() + i);
        datesList.push(date);
      }
      return datesList;
    };
  
    const checkTourStatus = (date) => {
      // Convert input date to YYYY-MM-DD format in local time
      const dateString = formatToDateString(date);
  
      for (const tour of tours) {
        if (userData.userType === 'Manager') {
          // For managers, check all tour dates
          for (const tourDate of tour.tourDates) {
            if (tourDate.cancelled) continue;
  
            const originalDate = formatToDateString(tourDate.date);
            const hasReschedule = tourDate.rescheduleDate?.isRescheduled;
            const rescheduledDate = hasReschedule ? 
              formatToDateString(tourDate.rescheduleDate.newDate) : null;
  
            if ((originalDate === dateString && !hasReschedule) || 
                (hasReschedule && rescheduledDate === dateString)) {
              return {
                isOnTour: true,
                tourName: tour.tourName,
                actName: tour.actName,
                venue: tourDate.event?.venueDetails?.venueData,
                rescheduleInfo: hasReschedule && rescheduledDate === dateString ? {
                  isRescheduled: true,
                  originalDate: originalDate
                } : null
              };
            }
          }
        } else {
          // For artists and crew
          const userDetails = userData.userType === 'Artist' ? 
            tour.artistDetails : tour.crewDetails;
          
          const userAssignment = userDetails?.find(user => user.userId === userData.userId);
          
          if (userAssignment?.assignedDates) {
            for (const assignedDate of userAssignment.assignedDates) {
              const tourDate = tour.tourDates.find(td => td.id === assignedDate.tourDateDocId);
              if (!tourDate || tourDate.cancelled) continue;
  
              const originalDate = formatToDateString(tourDate.date);
              const hasReschedule = tourDate.rescheduleDate?.isRescheduled;
              const rescheduledDate = hasReschedule ? 
                formatToDateString(tourDate.rescheduleDate.newDate) : null;
  
              if ((originalDate === dateString && !hasReschedule) || 
                  (hasReschedule && rescheduledDate === dateString)) {
                return {
                  isOnTour: true,
                  tourName: tour.tourName,
                  actName: tour.actName,
                  venue: tourDate.event?.venueDetails?.venueData,
                  rescheduleInfo: hasReschedule && rescheduledDate === dateString ? {
                    isRescheduled: true,
                    originalDate: originalDate
                  } : null
                };
              }
            }
          }
        }
      }
  
      return null;
    };
  

  
    // Filter dates based on selected filter
    const getFilteredDates = () => {
      if (filter === 'byDate') {
        return [selectedDate];
      }
  
      const allDates = generateDates();
      
      switch (filter) {
        case 'onTour':
          return allDates.filter(date => checkTourStatus(date));
        case 'available':
          return allDates.filter(date => !checkTourStatus(date));
        default:
          return allDates;
      }
    };
  
    const renderDateCard = (date) => {
      const tourStatus = checkTourStatus(date);
      const isOnTour = !!tourStatus;
    
      return (
        <div key={date.toISOString()} className={styles['date-card']}>
          <div className={styles['date-header']}>
            <div className={styles.date}>
              {date.toLocaleDateString('en-GB', {
                weekday: 'short',
                day: '2-digit',
                month: 'short',
                year: 'numeric'
              })}
            </div>
            <div className={`${styles.status} ${isOnTour ? styles['on-tour'] : styles.available}`}>
              {isOnTour ? 'On Tour' : 'Available'}
            </div>
          </div>
    
          {isOnTour && tourStatus.venue && (
            <div className={styles['tour-info']}>
              <h4>{tourStatus.tourName}</h4>
              <p className={styles['act-name']}>{tourStatus.actName}</p>
              <div className={styles['venue-info']}>
                <p className={styles['venue-name']}>
                  {tourStatus.venue.displayName}
                </p>
                <p className={styles['venue-location']}>
                  {tourStatus.venue.formattedAddress}
                </p>
              </div>
              {tourStatus.rescheduleInfo && (
                <p className={styles['rescheduled-info']}>
                  Rescheduled from: {new Date(tourStatus.rescheduleInfo.originalDate).toLocaleDateString()}
                </p>
              )}
            </div>
          )}
        </div>
      );
    };
  
    return (
      <div className={styles['dates-container']}>
        <div className={styles['date-filters']}>
          <button 
            className={`${styles['filter-btn']} ${filter === 'all' ? styles.active : ''}`}
            onClick={() => setFilter('all')}
          >
            All
          </button>
          <button 
            className={`${styles['filter-btn']} ${filter === 'onTour' ? styles.active : ''}`}
            onClick={() => setFilter('onTour')}
          >
            On Tour
          </button>
          <button 
            className={`${styles['filter-btn']} ${filter === 'available' ? styles.active : ''}`}
            onClick={() => setFilter('available')}
          >
            Available
          </button>
          <button 
            className={`${styles['filter-btn']} ${filter === 'byDate' ? styles.active : ''}`}
            onClick={() => {
              setFilter('byDate');
              setShowDatePicker(true);
            }}
          >
            By Date
          </button>
        </div>
    
        {filter === 'byDate' && (
          <input
            type="date"
            value={selectedDate.toISOString().split('T')[0]}
            onChange={(e) => setSelectedDate(new Date(e.target.value))}
            className={styles['date-picker']}
          />
        )}
    
        <div className={styles['dates-list']}>
          {getFilteredDates().map(renderDateCard)}
        </div>
    
        {filter !== 'byDate' && displayDays < 365 && (
          <button 
            className={styles['load-more']}
            onClick={() => setDisplayDays(prev => prev + 30)}
          >
            Load More
          </button>
        )}
      </div>
    );
  };
  
 
  if (isLoading) {
    return (
      <div className={styles['loading-container']}>
        <div className={styles['loading-spinner']}></div>
      </div>
    );
  }

  if (!profileData) {
    return (
      <div className={styles['profile-container']}>
        <TopBar />
        <div className={styles['main-layout']}>
          {!isMobile && <LeftMenu selectedTour={selectedTour} />}
          {!isMobile && (
            <RightMenu
              selectedTour={selectedTour}
              tours={tours}
              onTourSelect={handleTourSelect}
            />
          )}
          <div className={styles['not-found-container']}>
            <div className={styles['not-found-content']}>
              <div className={styles['not-found-artwork']}>
                <FontAwesomeIcon 
                  icon={faUser} 
                  className={styles['not-found-icon']} 
                />
                <div className={styles['not-found-circle']}></div>
              </div>
              <h1>User Not Found</h1>
              <p>Sorry, this profile doesn't exist or may have been removed.</p>
              {currentUserData && (
                <button 
                  className={styles['return-button']}
                  onClick={() => navigate(`/${currentUserData.userName}`)}
                >
                  <FontAwesomeIcon icon={faUser} />
                  Go to Your Profile
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  // Finally check if ready to render full profile
  if (!tours) {
    return (
      <div className={styles['loading-container']}>
        <div className={styles['loading-spinner']}></div>
      </div>
    );
  }
  
  return (
    <>
    <div className={styles['profile-container']}>
      <TopBar />
      <div className={styles['main-layout']}>
        {!isMobile && <LeftMenu selectedTour={selectedTour} />}
        {!isMobile && (
          <RightMenu
            selectedTour={selectedTour}
            tours={tours}
            onTourSelect={handleTourSelect}
          />
        )}
        
        <div className={styles['profile-main']}>
          <div className={styles['profile-content']}>
            {/* Profile Card */}
            <div className={styles['profile-card']}>
              <div className={styles['cover-photo-container']}>
                <img 
                  src={profileData?.profileCoverPictureUrl || defaultCoverPhoto}
                  alt="Cover"
                  className={styles['cover-photo']}
                />
                <div className={styles['cover-overlay']}></div>
              </div>
              
              <div className={styles['profile-header']}>
                <div className={styles['profile-left']}>
                  <img
                    src={profileData?.profilePictureUrl || defaultProfilePhoto}
                    alt="Profile"
                    className={styles['profile-photo']}
                  />
                  
                  <div className={styles['profile-info']}>
                    <div className={styles['name-section']}>
                      <h1>{profileData?.fullName}</h1>
                      {profileData?.idVerificationStatus === 'Verified' && (
                        <img src={verifiedBadge} alt="Verified" className={styles['verified-badge']} />
                      )}
                    </div>
  
                    <div className={styles['user-type']}>
                      {profileData?.userType}
                      {profileData?.userSubType && ` · ${profileData.userSubType}`}
                    </div>
  
                    <div className={styles['info-items']}>
  <div className={styles['info-item']}>
    <FontAwesomeIcon icon={faMapMarkerAlt} />
    <span>{`${profileData?.city}, ${profileData?.state}, ${profileData?.country}`}</span>
  </div>
  <div className={`${styles['info-item']} ${isUserOnTour(tours, profileData) ? styles['on-tour'] : styles.available}`}>
    <FontAwesomeIcon icon={isUserOnTour(tours, profileData) ? faRoute : faCalendarCheck} />
    <span>{isUserOnTour(tours, profileData) ? 'On Tour' : 'Available'}</span>
  </div>
</div>

{/* Bio Section */}
<div className={styles.bioSection}>
  {profileData?.bio ? (
    <p className={styles.bio}>{profileData.bio}</p>
  ) : (
    isOwnProfile && (
      <button 
        onClick={() => navigate('/edit-profile')} 
        className={styles.addBioButton}
      >
        Add bio
      </button>
    )
  )}
</div>

{isOwnProfile && !profileData?.associatedActs?.length && (
  <button 
    onClick={() => navigate('/edit-profile')} 
    className={styles.addAssociatedActButton}
  >
    <FontAwesomeIcon icon={faPlus} />
    Add Associated Act
  </button>
)}

{/* Associated Acts section */}
{profileData?.associatedActs?.length > 0 && (
  <div className={styles.associatedActsSection}>
    <h3 className={styles.sectionTitle}>Associated Acts</h3>
    <div className={styles.associatedActsGrid}>
      {profileData.associatedActs.map(act => (
        <div 
          key={act.id} 
          className={styles.actCard}
          onClick={() => navigate(`/${act.username}`)}
        >
          <img 
            src={act.photoUrl || defaultProfilePhoto} 
            alt={act.name} 
            className={styles.actPhoto}
          />
          <div className={styles.actInfo}>
            <div className={styles.actNameContainer}>
              <h4>{act.name}</h4>
              {act.verified && (
                <img src={verifiedBadge} alt="Verified" className={styles.verifiedBadge} />
              )}
            </div>
            <p className={styles.actUsername}>@{act.username}</p>
            {act.subType && <p className={styles.actSubtype}>{act.subType}</p>}
            {act.genre && <p className={styles.actGenre}>{act.genre}</p>}
          </div>
        </div>
      ))}
      {isOwnProfile && (
        <button 
          className={styles.addMoreActsButton}
          onClick={() => navigate('/edit-profile')}
        >
          <FontAwesomeIcon icon={faPlus} />
          Add More
        </button>
      )}
    </div>
  </div>
)}

{/* Social Media Icons */}
<div className={styles.socialIcons}>
  {profileData?.socialLinks?.instagramUrl && profileData.socialLinks.instagramUrl.trim() !== "" && (
    <a 
      href={profileData.socialLinks.instagramUrl} 
      target="_blank" 
      rel="noopener noreferrer" 
      className={styles.socialIconLink}
      title={profileData.socialLinks.instagramUrl}
    >
      <FontAwesomeIcon icon={faInstagram} className={styles.socialIcon} />
    </a>
  )}
  {profileData?.socialLinks?.twitterUrl && profileData.socialLinks.twitterUrl.trim() !== "" && (
    <a 
      href={profileData.socialLinks.twitterUrl}
      target="_blank" 
      rel="noopener noreferrer" 
      className={styles.socialIconLink}
      title={profileData.socialLinks.twitterUrl}
    >
      <FontAwesomeIcon icon={faTwitter} className={styles.socialIcon} />
    </a>
  )}
  {profileData?.socialLinks?.linkedInUrl && profileData.socialLinks.linkedInUrl.trim() !== "" && (
    <a 
      href={profileData.socialLinks.linkedInUrl}
      target="_blank" 
      rel="noopener noreferrer" 
      className={styles.socialIconLink}
      title={profileData.socialLinks.linkedInUrl}
    >
      <FontAwesomeIcon icon={faLinkedin} className={styles.socialIcon} />
    </a>
  )}
  {profileData?.socialLinks?.facebookUrl && profileData.socialLinks.facebookUrl.trim() !== "" && (
    <a 
      href={profileData.socialLinks.facebookUrl}
      target="_blank" 
      rel="noopener noreferrer" 
      className={styles.socialIconLink}
      title={profileData.socialLinks.facebookUrl}
    >
      <FontAwesomeIcon icon={faFacebook} className={styles.socialIcon} />
    </a>
  )}
  {profileData?.socialLinks?.youtubeUrl && profileData.socialLinks.youtubeUrl.trim() !== "" && (
    <a 
      href={profileData.socialLinks.youtubeUrl}
      target="_blank" 
      rel="noopener noreferrer" 
      className={styles.socialIconLink}
      title={profileData.socialLinks.youtubeUrl}
    >
      <FontAwesomeIcon icon={faYoutube} className={styles.socialIcon} />
    </a>
  )}
  {profileData?.socialLinks?.spotifyUrl && profileData.socialLinks.spotifyUrl.trim() !== "" && (
    <a 
      href={profileData.socialLinks.spotifyUrl}
      target="_blank" 
      rel="noopener noreferrer" 
      className={styles.customsocialIcon}
      title={profileData.socialLinks.spotifyUrl}
    >
      <img src={spotifylogo}  alt="Spotify" className={styles.socialIcon} />
    </a>
  )}
  {profileData?.socialLinks?.appleMusicUrl && profileData.socialLinks.appleMusicUrl.trim() !== "" && (
    <a 
      href={profileData.socialLinks.appleMusicUrl}
      target="_blank" 
      rel="noopener noreferrer" 
      className={styles.customsocialIcon}
      title={profileData.socialLinks.appleMusicUrl}
    >
      <img src={applemusiclogo}  alt="Apple Music" className={styles.socialIcon} />
    </a>
  )}
  {isOwnProfile && (
    <button 
      onClick={() => navigate('/edit-profile')} 
      className={styles.addSocialButton}
      title="Add social links"
    >
      <FontAwesomeIcon icon={faPlus} />
    </button>
  )}
</div>

{profileData?.endorsedBrands && profileData.endorsedBrands.length > 0 && (
  <div className={styles.endorsedBrandsSection}>
    <h3 className={styles.endorsedBrandsTitle}>Endorsed by</h3>
    <div className={styles.endorsedBrands}>
      {profileData.endorsedBrands.map(brand => (
        <button 
          key={brand.brandName} 
          className={styles.brandBadge}
          onClick={() => handleBrandClick(brand)}
        >
          <img 
            src={brand.logo || defaultProfilePhoto}
            alt={brand.brandName}
            className={styles.brandBadgeLogo}
          />
          <span className={styles.brandBadgeName}>{brand.brandName}</span>
        </button>
      ))}
    </div>
  </div>
)}

                    
                    <div className={styles['profile-actions']}>
                      {isOwnProfile ? (
                        <button onClick={handleEditProfile} className={`${styles['action-button']} ${styles.edit}`}>
                          <FontAwesomeIcon icon={faEdit} />
                          Edit Profile
                        </button>
                      ) : (
                        <button onClick={handleEmailClick} className={`${styles['action-button']} ${styles.contact}`}>
                          <FontAwesomeIcon icon={faEnvelope} />
                          Contact
                        </button>
                      )}
                      <button onClick={handleShareProfile} className={`${styles['action-button']} ${styles.share}`}>
                        <FontAwesomeIcon icon={faShare} />
                        Share Profile
                      </button>
                    </div>
                  </div>
                </div>
  
                <div className={styles['profile-right']}>
                  <StatsContainer 
                    profileData={profileData}
                    tours={tours}
                    currentUser={currentUserData}
                    onToursClick={handleToursCountClick}
                    onShowsClick={handleShowsCountClick}
                    onUsersClick={handleUsersCountClick}
                    onAddPress={() => setShowAddOnTourModal(true)}
                  />
                </div>
              </div>
            </div>
  
            {/* Content Section */}
            <div className={styles['content-section']}>
              <div className={styles['content-tabs']}>
                <button 
                  className={`${styles.tab} ${activeTab === 'showcase' ? styles.active : ''}`}
                  onClick={() => setActiveTab('showcase')}
                >
                  Showcase
                </button>
                <button 
                  className={`${styles.tab} ${activeTab === 'dates' ? styles.active : ''}`}
                  onClick={() => setActiveTab('dates')}
                >
                  Tour Dates
                </button>
              </div>
  
              <div className={styles['tab-content']}>
                {activeTab === 'showcase' ? (
                  <div className={styles['showcase-section']}>
                  {isOwnProfile && (
  <button 
    className={styles['upload-button']}
    onClick={() => setIsModalOpen(true)}
  >
    <FontAwesomeIcon icon={faPlus} />
    Add to Showcase
  </button>
)}
                    
                    <div className={styles['search-section']}>
                      <SearchComponent 
                        onResultsUpdate={handleSearchResults} 
                        userId={profileData?.userId}
                      />
  
                      <div className={styles['tags-tabs']}>
                        <button
                          className={`${styles['tag-tab']} ${selectedTag === 'all' ? styles.active : ''}`}
                          onClick={() => handleTagSelect('all')}
                        >
                          All
                        </button>
                        {userTags.map((tag) => (
                          <button
                            key={tag}
                            className={`${styles['tag-tab']} ${selectedTag === tag.toLowerCase().replace(/\s+/g, '') ? styles.active : ''}`}
                            onClick={() => handleTagSelect(tag)}
                          >
                            {tag}
                          </button>
                        ))}
                      </div>
                    </div>
                    
                    <div className={styles['posts-grid']}>
                      {isPageLoading || isPostsLoading ? (
                        <div className={styles['loading-spinner-container']}>
                          <div className={styles['loading-spinner']}></div>
                        </div>
                      ) : posts.length > 0 ? (
                        posts.map(post => (
                          <PostCard
                            key={post.postId}
                            post={post}
                            isOwner={currentUserData?.userId === post.userId}
                            onEdit={handleEditPost}
                            onDelete={handleDeletePost}
                            isMobile={isMobileDevice()}
                          />
                        ))
                      ) : (
                        <div className={styles['no-results']}>
                          {selectedTag === 'all' 
                            ? 'No posts yet.' 
                            : `No posts found with tag #${selectedTag}`}
                        </div>
                      )}
                    </div>
                    
                    {posts.length >= displayedPosts && (
                      <button 
                        className={styles['load-more']}
                        onClick={() => setDisplayedPosts(prev => prev + 6)}
                      >
                        Load More
                      </button>
                    )}
                  </div>
                ) : (
                  <TourDates 
                    userData={profileData}
                    tours={tours}
                  />
                )}
              </div>
            </div>
          </div>
        </div>

        {showProfileShareModal && (
  <ShareModal
    isOpen={showProfileShareModal}
    onClose={() => setShowProfileShareModal(false)}
    postData={{
      type: 'profile', // This is key - set type to 'profile' to indicate it's a profile share
      caption: profileData?.bio || `${profileData?.fullName}'s profile on OnTour`,
      tags: [], // Profiles don't have tags
      postId: profileData?.userId
    }}
  />
)}

        <ToursModal 
          isOpen={toursModalVisible}
          onClose={() => setToursModalVisible(false)}
          tours={tours}
          userData={profileData}
        />

        <ShowsModal 
          isOpen={showsModalVisible}
          onClose={() => setShowsModalVisible(false)}
          tours={tours}
          userData={profileData}
        />

       

        {(profileData?.userType === 'Manager' || profileData?.userType === 'Artist Management Company') && (
          <UsersModal 
            isOpen={usersModalVisible}
            onClose={() => setUsersModalVisible(false)}
            tours={tours}
            currentUser={currentUserData}
            onUserSelect={handleUserSelect}
          />
        )}
        
        {isMobile && <BottomNav />}
      </div>

      {showBrandModal && selectedBrand && (
  <BrandWebsiteModal
    isOpen={showBrandModal}
    onClose={() => setShowBrandModal(false)}
    brand={selectedBrand}
  />
)}

      <UploadModal 
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onUpload={handleUpload}
        existingTags={userTags}
        editingPost={editingPost}
      />
    </div>
    <TourInvitationModal 
          isOpen={showAddOnTourModal}
          onClose={() => setShowAddOnTourModal(false)}
          userData={currentUserData}
          currentUser={currentUserData}
          profileData={profileData}
        />
          </>
  );
};

export default Profile;