import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getUserDataFromLocalStorage, removeUserDataFromLocalStorage } from '../../utils/firebaseHelper';
import { getFirestore, doc, getDoc, collection, query, where, getDocs, updateDoc, setDoc } from 'firebase/firestore';
import { getFirebaseApp } from '../../utils/firebaseHelper';
import './Dashboard.module.css';
import playstorelogo from '../../assets/Images/playstorelogo.png';
import appstorelogo from '../../assets/Images/appstorelogo.png';
import logo from '../../assets/Images/logo.png';
import UserContext from '../../context/UserContext';
import Layout from '../Layout';
import { getAuth } from 'firebase/auth';
import { arrayUnion } from 'firebase/firestore';
import axios from 'axios';



export default function Dashboard() {
  const { userData, setUserData } = useContext(UserContext);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!userData) {
      navigate('/login');
    }
  }, [userData, navigate]);

  if (!userData) {
    return <div>Loading...</div>;
  }

  const membershipTier = userData.membershipTier || 'Free';
  const membershipEndDate = userData.membershipEndDate;

  const tableData = [
    { feature: 'Create Unlimited Tours', free: '✓', pro: '✓', elite: '✓' },
    { feature: 'Artists per Tour', free: '1', pro: '5', elite: 'Unlimited' },
    { feature: 'Crew Members per Tour', free: '2', pro: '3', elite: 'Unlimited' },
    { feature: 'Account Verification', free: '✕', pro: '✓', elite: '✓' },
    { feature: 'Send Messages to Other Accounts', free: '✕', pro: '✓', elite: '✓' },
    { feature: 'See users Tour Dates', free: '✕', pro: '✓', elite: '✓' },
    { feature: 'Ideal For Managing', free: 'Solo Artist', pro: 'A Band', elite: 'Multiple Bands' },
    { feature: 'Price', free: 'Free', pro: '₹499 per month', elite: '₹999 per month' },
  ];

  const fetchPaymentDetails = async (paymentId) => {
    try {
      const response = await axios.get(`http://localhost:3000/api/payments/${paymentId}`);
      const paymentDetails = response.data;
      console.log('Payment Details:', paymentDetails);
      // Process the payment details as needed
      return paymentDetails;
    } catch (error) {
      console.error('Error fetching payment details:', error);
      throw error;
    }
  };

  const handleUpgrade = async (membershipTier) => {
    const db = getFirestore(getFirebaseApp());
    const auth = getAuth();
    const currentUser = auth.currentUser;

    // Check if the user is authenticated
    if (!currentUser) {
      console.error('User is not authenticated');
      return;
    }

    const usersCollectionRef = collection(db, 'users');
    const userQuery = query(usersCollectionRef, where('userId', '==', currentUser.uid));
    const userQuerySnapshot = await getDocs(userQuery);

    if (!userQuerySnapshot.empty) {
      const userDocSnapshot = userQuerySnapshot.docs[0];
      const userDocRef = userDocSnapshot.ref;
      const userData = userDocSnapshot.data();

      const extractCardNetwork = (cardId) => {
        const cardNetworkMap = {
          'card_': 'Unknown',
          'visa_': 'Visa',
          'master_': 'Mastercard',
          'amex_': 'American Express',
          'maestro_': 'Maestro',
          'rupay_': 'RuPay',
          'diners_': 'Diners Club',
          'jcb_': 'JCB',
          'discover_': 'Discover',
          'bajaj_': 'Bajaj Finserv',
          // Add more card network prefixes if needed
        };

        for (const prefix in cardNetworkMap) {
          if (cardId.startsWith(prefix)) {
            return cardNetworkMap[prefix];
          }
        }

        return 'Unknown';
      };

      // Fetch the payment details from the userData
      const { paymentInfo } = userData;

      const options = {
        key: 'rzp_test_X1matWmcxvSzhu',
        amount: membershipTier === 'Pro' ? 49900 : 99900, // Amount in paise
        currency: 'INR',
        name: 'On Tour',
        description: `Upgrade to ${membershipTier} Membership`,
        image: 'https://ontour.co.in/static/media/logo.004c25c8bff3fe32ff88.png',
        handler: async (response) => {
          console.log('Payment Response:', response);

          const paymentId = response.razorpay_payment_id;

          try {
            const paymentDetails = await fetchPaymentDetails(paymentId);

            // Extract the common fields from the payment details
            const { method, status, amount, currency, email, contact } = paymentDetails;

            const endDate = new Date();
            endDate.setMonth(endDate.getMonth() + 1);

            const startDate = new Date();

            // Initialize the payment-related fields
            let upiId = null;
            let cardLastFour = null;
            let cardNetwork = null;
            let bank = null;
            let ifsc = null;
            let accountNumber = null;

            // Set the fields based on the payment method
            if (method === 'upi') {
              upiId = paymentDetails.vpa;
            } else if (method === 'netbanking') {
              bank = paymentDetails.bank;
            } else if (method === 'card') {
              const cardId = paymentDetails.card_id;
              if (cardId) {
                cardLastFour = cardId.slice(-4);
                // You can extract the card network from the card ID if available
                cardNetwork = extractCardNetwork(cardId);
              }
            }

            const paymentInfo = {
              method,
              upiId,
              cardLastFour,
              cardNetwork,
              bank,
              ifsc,
              accountNumber,
            };

            const paymentData = {
              paymentId,
              method,
              membershipType: membershipTier,
              membershipStartDate: startDate.toISOString(),
              membershipEndDate: endDate.toISOString(),
              amount,
              status: paymentDetails.status,
              upiId,
              cardLastFour,
              cardNetwork,
              bank,
              ifsc,
              accountNumber,
            };

            const updateData = {
              membershipTier,
              membershipStartDate: startDate.toISOString(),
              membershipEndDate: endDate.toISOString(),
              paymentInfo,
              paymentHistory: arrayUnion(paymentData),
            };

            console.log('Update Data:', updateData);

            const db = getFirestore(getFirebaseApp());
            const auth = getAuth();
            const currentUser = auth.currentUser;

            // Check if the user is authenticated
            if (!currentUser) {
              console.error('User is not authenticated');
              return;
            }

            const usersCollectionRef = collection(db, 'users');
            const userQuery = query(usersCollectionRef, where('userId', '==', currentUser.uid));
            const userQuerySnapshot = await getDocs(userQuery);

            if (!userQuerySnapshot.empty) {
              const userDocSnapshot = userQuerySnapshot.docs[0];
              const userDocRef = userDocSnapshot.ref;

              await updateDoc(userDocRef, updateData);
              console.log('User document updated successfully');

              // Update the userData state with the updated membership details and payment info
              setUserData((prevUserData) => ({
                ...prevUserData,
                ...updateData,
              }));

              // Create a recurring payment
              const response = await axios.post('http://localhost:3000/api/create-recurring-payment', {
                userId: currentUser.uid,
                paymentMethod: paymentDetails.method,
                membershipTier,
              });

              if (response.data.success) {
                console.log('Recurring payment created successfully');
                // Update the user data or perform any necessary actions
              } else {
                console.error('Failed to create recurring payment');
              }
            } else {
              console.error('User document not found');
            }
          } catch (error) {
            console.error('Error updating user document:', error);
          }
        },
        prefill: {
          name: userData.fullName,
          email: userData.email,
          contact: userData.phoneNumber,
        },
        notes: {
          address: 'On Tour Membership Upgrade',
        },
        theme: {
          color: '#E2384D',
        },
        method: ['upi', 'card', 'netbanking'], // Specify the payment method here (e.g., 'netbanking', 'card', 'upi')
      };

      // Update the options based on the payment method
      if (paymentInfo) {
        if (paymentInfo.method === 'upi') {
          options.prefill.vpa = paymentInfo.upiId;
        } else if (paymentInfo.method === 'card') {
          if (paymentInfo.card && paymentInfo.card.number) {
            options.prefill.card = {
              number: paymentInfo.card.number,
              expiry: paymentInfo.card.expiry,
              cvv: paymentInfo.card.cvv,
            };
          }
        } else if (paymentInfo.method === 'netbanking') {
          options.prefill.bank = paymentInfo.bank;
          options.prefill.account_number = paymentInfo.accountNumber;
          options.prefill.ifsc_code = paymentInfo.ifscCode;
        }
      }

      const rzp = new window.Razorpay(options);
      rzp.open();
    } else {
      // User document not found, handle the case accordingly (e.g., create a new document)
      console.error('User document not found');
    }
  };
  const handleCancelMembership = async () => {
    try {
      const response = await axios.post('http://localhost:3000/api/cancel-membership', {
        userId: userData.userId,
      });
  
      if (response.data.success) {
        console.log('Membership canceled successfully');
        // Update the user data or perform any necessary actions
      } else {
        console.error('Failed to cancel membership');
      }
    } catch (error) {
      console.error('Error canceling membership:', error);
    }
  };

  return (
    <Layout>
      <div className="main-content">
        <div className="content">
          <h2>Membership Info</h2>
          <p>
            Your current membership tier is <span className="membership-tier">{membershipTier}</span>.
          </p>
          {membershipEndDate && (
            <p>
              Membership Ending Date:{' '}
              {new Date(membershipEndDate).toLocaleDateString()}
            </p>
          )}
          <table className="membership-table">
            <thead>
              <tr>
                <th>Features</th>
                <th>Free</th>
                <th>Pro</th>
                <th>Elite</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>{row.feature}</td>
                  <td>{row.free}</td>
                  <td>{row.pro}</td>
                  <td>{row.elite}</td>
                </tr>
              ))}
              <tr>
                <td></td>
                <td></td>
                <td>
                  {membershipTier !== 'Pro' && (
                    <button className="upgrade-button" onClick={() => handleUpgrade('Pro')}>
                      Upgrade to Pro
                    </button>
                  )}
                  {membershipTier === 'Pro' && (
                   
                    <button className="cancel-button" onClick={handleCancelMembership}> Cancel Membership </button>
                  )}
                </td>
                <td>
                  {membershipTier !== 'Elite' && (
                    <button className="upgrade-button" onClick={() => handleUpgrade('Elite')}>
                      Upgrade to Elite
                    </button>
                  )}
                  {membershipTier === 'Elite' && (
                    <button className="cancel-button" onClick={handleCancelMembership}> Cancel Membership </button>
                  )}
                </td>
              </tr>
            </tbody>
          </table>
          <div className="app-download">
            <p>Get On Tour App on Google Play and App Store</p>
            <div className="app-store-logos">
              <img src={playstorelogo} alt="Google Play" className="playstorelogo" />
              <img src={appstorelogo} alt="App Store" className="appstorelogo" />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}