import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faHome,
  faUser,
  faWallet,
  faCog,
  faMobile,
  faLaptop
} from '@fortawesome/free-solid-svg-icons';
import { faAppStore, faGooglePlay, faApple, faWindows } from '@fortawesome/free-brands-svg-icons';
import TopBar from '../TopBar';
import LeftMenu from '../LeftMenu';
import RightMenu from '../RightMenu';
import styles from './Downloads.module.css';

const Downloads = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const downloadOptions = [
    {
      title: 'Mobile Apps',
      description: 'Download our mobile apps for iOS and Android',
      platforms: [
        {
          name: 'iOS App Store',
          icon: faAppStore,
          link: '#',
          buttonText: 'Download for IOS',
          tag: 'iOS 12.0 or later'
        },
        {
          name: 'Google Play Store',
          icon: faGooglePlay,
          link: '#',
          buttonText: 'Download for Android',
          tag: 'Android 8.0 or later'
        }
      ]
    },
    {
      title: 'Desktop Apps',
      description: 'Download our desktop application for Windows and Mac',
      platforms: [
        {
          name: 'Windows',
          icon: faWindows,
          link: '#',
          buttonText: 'Download for Windows',
          tag: 'Windows 10 64-bit or later'
        },
        {
          name: 'macOS',
          icon: faApple,
          link: '#',
          buttonText: 'Download for Mac',
          tag: 'macOS 10.15 or later'
        }
      ]
    }
  ];

  const BottomNav = () => (
    <div className={styles['bottom-nav']}>
      <button onClick={() => navigate('/dashboard')} className={styles['nav-item']}>
        <FontAwesomeIcon icon={faHome} />
        <span>Home</span>
      </button>
      <button onClick={() => navigate('/profile')} className={styles['nav-item']}>
        <FontAwesomeIcon icon={faUser} />
        <span>Profile</span>
      </button>
      <button onClick={() => navigate('/membership')} className={styles['nav-item']}>
        <FontAwesomeIcon icon={faWallet} />
        <span>Membership</span>
      </button>
      <button onClick={() => navigate('/settings')} className={styles['nav-item']}>
        <FontAwesomeIcon icon={faCog} />
        <span>Settings</span>
      </button>
    </div>
  );

  return (
    <div className={styles.container}>
      <TopBar />
      <div className={styles['main-layout']}>
        {!isMobile && <LeftMenu />}
        <div className={styles['downloads-content']}>
          <div className={styles['downloads-header']}>
            <h1>Downloads</h1>
            <p className={styles['header-description']}>
              Download On Tour for your preferred platform
            </p>
          </div>

          <div className={styles['downloads-grid']}>
            {downloadOptions.map((section, index) => (
              <div key={index} className={styles['download-section']}>
                <h2 className={styles['section-title']}>
                  <FontAwesomeIcon 
                    icon={section.title === 'Mobile Apps' ? faMobile : faLaptop} 
                    className={styles['section-icon']}
                  />
                  {section.title}
                </h2>
                <p className={styles['section-description']}>{section.description}</p>
                
                <div className={styles['platforms-grid']}>
                  {section.platforms.map((platform, pIndex) => (
                    <div key={pIndex} className={styles['platform-card']}>
                      <div className={styles['platform-content']}>
                        <FontAwesomeIcon 
                          icon={platform.icon} 
                          className={styles['platform-icon']}
                        />
                        <h3 className={styles['platform-name']}>{platform.name}</h3>
                        <span className={styles['platform-tag']}>{platform.tag}</span>
                        
                        {platform.image ? (
                          <a 
                            href={platform.link} 
                            className={styles['store-button']}
                          >
                            <img 
                              src={platform.image} 
                              alt={`Download on ${platform.name}`}
                              className={styles['store-image']}
                            />
                          </a>
                        ) : (
                          <a 
                            href={platform.link} 
                            className={styles['download-button']}
                          >
                            <FontAwesomeIcon icon={faDownload} />
                            {platform.buttonText}
                          </a>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>

          <div className={styles['system-requirements']}>
            <h2>System Requirements</h2>
            <div className={styles['requirements-grid']}>
              <div className={styles['requirement-card']}>
                <h3>Mobile</h3>
                <ul>
                  <li>iOS 12.0 or later</li>
                  <li>Android 8.0 or later</li>
                  <li>500 MB free space</li>
                  <li>Active internet connection</li>
                </ul>
              </div>
              <div className={styles['requirement-card']}>
                <h3>Desktop</h3>
                <ul>
                  <li>Windows 10 64-bit or later</li>
                  <li>macOS 10.15 or later</li>
                  <li>4 GB RAM minimum</li>
                  <li>1 GB free space</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      {!isMobile && (
          <RightMenu
          />
        )}
        
      {isMobile && <BottomNav />}
    </div>
  );
};

export default Downloads;