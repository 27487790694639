import React, { useState, useContext, useEffect, useRef, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSearch,
  faEllipsisV,
  faPaperPlane,
  faSmile,
  faUser,
  faCrown,
  faArrowLeft,
  faCheckDouble
} from '@fortawesome/free-solid-svg-icons';
import EmojiPicker from 'emoji-picker-react';
import TourContext from '../../context/TourContext';
import UserContext from '../../context/UserContext';
import ChatContext from '../../context/ChatContext';
import TopBar from '../TopBar';
import LeftMenu from '../LeftMenu';
import styles from './Messages.module.css';

const MessagesLayout = () => {
  const [messageInput, setMessageInput] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const messagesEndRef = useRef(null);
  const messageInputRef = useRef(null);
  const emojiPickerRef = useRef(null);
  
  const { selectedTour, tours } = useContext(TourContext);
  const { userData } = useContext(UserContext);
  const { 
    messages, 
    activeChat, 
    loading, 
    sendMessage, 
    selectChat, 
    setActiveChat 
  } = useContext(ChatContext);

  // Handle window resize
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Auto scroll to bottom when new messages arrive
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [messages]);

  // Emoji picker outside click handler
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target)) {
        setShowEmojiPicker(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Get all unique tour members (previous implementation)
  const allTourMembers = React.useMemo(() => {
    if (!tours || !tours.length) return [];
    
    const uniqueUsers = new Map();
    
    tours.forEach(tour => {
      // Add artists
      tour.artistDetails?.forEach(artist => {
        if (!uniqueUsers.has(artist.userId)) {
          uniqueUsers.set(artist.userId, {
            ...artist,
            tourName: tour.tourName || 'Unnamed Tour'
          });
        }
      });
      
      // Add crew members
      tour.crewDetails?.forEach(crew => {
        if (!uniqueUsers.has(crew.userId)) {
          uniqueUsers.set(crew.userId, {
            ...crew,
            tourName: tour.tourName || 'Unnamed Tour'
          });
        }
      });
      
      // Add tour manager
      if (tour.createdBy && !uniqueUsers.has(tour.createdBy.userId)) {
        uniqueUsers.set(tour.createdBy.userId, {
          ...tour.createdBy,
          userType: 'Manager',
          tourName: tour.tourName || 'Unnamed Tour'
        });
      }
    });
    
    if (userData?.userId) {
      uniqueUsers.delete(userData.userId);
    }
    
    return Array.from(uniqueUsers.values());
  }, [tours, userData?.userId]);

  // Filter users based on search
  const filteredUsers = React.useMemo(() => {
    return allTourMembers.filter(member => 
      !searchQuery.trim() || 
      member.fullName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [allTourMembers, searchQuery]);

  // Get current chat user
  const currentChatUser = React.useMemo(() => {
    if (!activeChat) return null;
    return allTourMembers.find(member => 
      activeChat.includes(member.userId)
    );
  }, [activeChat, allTourMembers]);

  // Handle chat selection
  const handleChatSelect = useCallback((user) => {
    if (!user?.userId) return;
    console.log('[UI] Selecting chat with user:', user.fullName);
    selectChat(user);
  }, [selectChat]);

  // Handle mobile back button
  const handleMobileBack = useCallback(() => {
    setActiveChat(null);
  }, [setActiveChat]);

  // Handle sending message
  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (!messageInput.trim()) return;

    await sendMessage(messageInput);
    setMessageInput('');
    messageInputRef.current?.focus();
  };

  // Handle input height adjustment
  const adjustInputHeight = (e) => {
    const element = e.target;
    element.style.height = 'auto';
    element.style.height = `${Math.min(element.scrollHeight, 100)}px`;
  };

  // Emoji selection handler
  const handleEmojiSelect = (emojiObject) => {
    const emoji = emojiObject.emoji;
    setMessageInput(prev => prev + emoji);
    setShowEmojiPicker(false);
    messageInputRef.current?.focus();
  };

  // Render message status icon
  const renderMessageStatus = (message) => {
    if (message.senderId !== userData?.userId) return null;

    return (
      <div className={`${styles.messageStatus} ${message.read ? styles.read : styles.sent}`}>
        <FontAwesomeIcon icon={faCheckDouble} />
      </div>
    );
  };

  return (
    <div className={styles.pageContainer}>
      <TopBar />
      <div className={styles.contentLayout}>
        <LeftMenu />
        
        <div className={styles.messagesContainer}>
          {/* Contacts List */}
          <div className={`${styles.chatListPanel} ${activeChat && isMobile ? styles.hidden : ''}`}>
            <div className={styles.chatListHeader}>
              <h2>Messages</h2>
              <div className={styles.searchContainer}>
                <FontAwesomeIcon icon={faSearch} className={styles.searchIcon} />
                <input 
                  type="text"
                  placeholder="Search contacts"
                  className={styles.searchInput}
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                />
              </div>
            </div>

            <div className={styles.contactsSection}>
              {filteredUsers.map(user => (
                <div 
                  key={user.userId}
                  className={`${styles.userCard} ${activeChat?.includes(user.userId) ? styles.active : ''}`}
                  onClick={() => handleChatSelect(user)}
                >
                  <div className={styles.userAvatar}>
                    {user.profilePictureUrl ? (
                      <img src={user.profilePictureUrl} alt={user.fullName} />
                    ) : (
                      <FontAwesomeIcon icon={faUser} />
                    )}
                  </div>
                  <div className={styles.userInfo}>
                    <div className={styles.userName}>
                      {user.fullName}
                      {user.userType === 'Manager' && (
                        <FontAwesomeIcon icon={faCrown} className={styles.managerIcon} />
                      )}
                    </div>
                    <div className={styles.lastMessage}>{user.tourName}</div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Chat Area */}
          <div className={`${styles.chatArea} ${activeChat || !isMobile ? styles.active : ''}`}>
            {activeChat && currentChatUser ? (
              <>
                <div className={styles.chatHeader}>
                  {isMobile && (
                    <button 
                      className={styles.backButton}
                      onClick={handleMobileBack}
                    >
                      <FontAwesomeIcon icon={faArrowLeft} />
                    </button>
                  )}
                  <div className={styles.chatUserInfo}>
                    <div className={styles.userAvatar}>
                      {currentChatUser.profilePictureUrl ? (
                        <img 
                          src={currentChatUser.profilePictureUrl} 
                          alt={currentChatUser.fullName}
                          className={styles.chatUserAvatar}
                        />
                      ) : (
                        <FontAwesomeIcon icon={faUser} />
                      )}
                    </div>
                    <div>
                      <div className={styles.chatUserName}>
                        {currentChatUser.fullName}
                      </div>
                      <div className={styles.lastMessage}>
                        {currentChatUser.tourName}
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.messagesArea}>
                  {loading ? (
                    <div className={styles.loading}>Loading messages...</div>
                  ) : messages[activeChat]?.length > 0 ? (
                    messages[activeChat].map((message) => (
                      <div 
                        key={message.timestamp}
                        className={`${styles.messageItem} ${
                          message.senderId === userData?.userId ? styles.sent : ''
                        }`}
                      >
                        <div className={styles.messageContent}>
                          <div className={styles.messageText}>
                            {message.content}
                          </div>
                          <div className={styles.messageTime}>
                            {new Date(message.timestamp).toLocaleTimeString([], {
                              hour: '2-digit',
                              minute: '2-digit'
                            })}
                            {renderMessageStatus(message)}
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className={styles.emptyChatState}>
                      No messages yet. Start the conversation!
                    </div>
                  )}
                  <div ref={messagesEndRef} />
                </div>

                <div className={styles.messageInputContainer}>
                  <form 
                    onSubmit={handleSendMessage}
                    className={styles.messageInputWrapper}
                  >
                    {!isMobile && (
                      <div style={{ position: 'relative' }} ref={emojiPickerRef}>
                        <button 
                          type="button" 
                          className={styles.emojiButton}
                          onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                        >
                          <FontAwesomeIcon icon={faSmile} />
                        </button>
                        {showEmojiPicker && (
                          <div style={{ 
                            position: 'absolute', 
                            bottom: '100%', 
                            left: 0, 
                            zIndex: 1000,
                            transform: 'translateX(-50%)' 
                          }}>
                            <EmojiPicker 
                              onEmojiClick={handleEmojiSelect} 
                              height={400}
                              width={300}
                            />
                          </div>
                        )}
                      </div>
                    )}
                    <textarea
                      ref={messageInputRef}
                      value={messageInput}
                      onChange={(e) => {
                        setMessageInput(e.target.value);
                        adjustInputHeight(e);
                      }}
                      onKeyPress={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                          e.preventDefault();
                          handleSendMessage(e);
                        }
                      }}
                      placeholder="Type a message..."
                      className={styles.messageInput}
                      rows={1}
                    />
                    <button 
                      type="submit" 
                      className={styles.sendButton}
                      disabled={!messageInput.trim()}
                    >
                      <FontAwesomeIcon icon={faPaperPlane} />
                    </button>
                  </form>
                </div>
              </>
            ) : (
              <div className={styles.emptyChatState}>
                Select a contact to start messaging
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(MessagesLayout);