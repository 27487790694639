// BrandsImporter.js
import React, { useState } from 'react';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { firebaseInstances } from '../utils/firebaseHelper';
import styles from './BrandsImporter.module.css';
import { getFunctions, httpsCallable } from 'firebase/functions';

const BrandsImporter = () => {
  const [isUploading, setIsUploading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState('');
  const [statusClass, setStatusClass] = useState('');
  const [fileContent, setFileContent] = useState(null);
  const [progress, setProgress] = useState(0);
  
  const { firestore: db, storage } = firebaseInstances;
  const functions = getFunctions();

  const extractDomain = (url) => {
    try {
      if (!url) return null;
      const domain = new URL(url).hostname.replace('www.', '');
      return domain;
    } catch (error) {
      return null;
    }
  };

  // Updated fetchImageAsBlob to use backend proxy
  const fetchImageAsBlob = async (websiteUrl) => {
    try {
      if (!websiteUrl) return null;

      // Use Firebase Cloud Function to proxy favicon fetch
      const fetchFaviconFunction = httpsCallable(functions, 'fetchFavicon');
      
      try {
        const result = await fetchFaviconFunction({ url: websiteUrl });
        
        // result.data will be the image blob
        if (result.data) {
          // Convert base64 or blob to Blob object if needed
          const blob = result.data instanceof Blob 
            ? result.data 
            : await (await fetch(`data:image/png;base64,${result.data}`)).blob();
          
          return blob;
        }
      } catch (proxyError) {
        console.warn('Favicon proxy failed:', proxyError);
        return null;
      }
    } catch (error) {
      console.error('Error fetching favicon:', error);
      return null;
    }
  };

  // Function to upload image to Firebase Storage
  const uploadToStorage = async (imageBlob, brandName, domain) => {
    if (!imageBlob) return null;

    const safeFileName = `${brandName}-${domain}`.replace(/[^a-z0-9-]/gi, '_').toLowerCase();
    const storageRef = ref(storage, `brands/logos/${safeFileName}.png`);
    
    try {
      const snapshot = await uploadBytes(storageRef, imageBlob);
      const downloadUrl = await getDownloadURL(snapshot.ref);
      return downloadUrl;
    } catch (error) {
      console.error(`Error uploading logo for ${brandName}:`, error);
      return null;
    }
  };

  // Update the processAndUploadLogos function
  const processAndUploadLogos = async (brandsData) => {
    const processedBrands = [];
    let completed = 0;

    for (const brand of brandsData) {
      if (!brand.website) {
        processedBrands.push({ ...brand, logo: null });
        continue;
      }

      // Fetch favicon using the backend proxy
      let imageBlob = await fetchImageAsBlob(brand.website);

      // Upload to Firebase Storage and get URL
      const domain = extractDomain(brand.website);
      const safeFileName = brand.brandName.replace(/[^a-z0-9-]/gi, '_').toLowerCase();
      const logoUrl = await uploadToStorage(imageBlob, brand.brandName, safeFileName);
      
      processedBrands.push({
        ...brand,
        logo: logoUrl
      });

      // Update progress
      completed++;
      const percentComplete = Math.round((completed / brandsData.length) * 100);
      setProgress(percentComplete);
      setUploadStatus(`Processing brands: ${percentComplete}% (${completed}/${brandsData.length})`);
    }

    return processedBrands;
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const content = JSON.parse(e.target.result);
        setFileContent(content);
        setUploadStatus('File loaded successfully. Ready to import.');
        setStatusClass(styles.success);
      } catch (error) {
        setUploadStatus('Error parsing JSON file: ' + error.message);
        setStatusClass(styles.error);
      }
    };
    reader.readAsText(file);
  };

  const handleImport = async () => {
    if (!fileContent) {
      setUploadStatus('Please select a valid JSON file first.');
      setStatusClass(styles.error);
      return;
    }

    setIsUploading(true);
    setUploadStatus('Starting brand processing...');
    setStatusClass(styles.progress);
    setProgress(0);

    try {
      // Process and upload logos
      const brandsWithLogos = await processAndUploadLogos(fileContent);
      
      // Upload to Firestore
      const brandDetailsRef = doc(db, 'musicalBrands', 'brandsList');
      await setDoc(brandDetailsRef, { 
        brandsDetails: brandsWithLogos 
      }, { merge: true });
      
      setUploadStatus('Brands data with logos successfully imported!');
      setStatusClass(styles.success);
    } catch (error) {
      setUploadStatus('Error importing brands: ' + error.message);
      setStatusClass(styles.error);
      console.error('Import error:', error);
    } finally {
      setIsUploading(false);
      setProgress(0);
    }
  };

  return (
    <div className={styles.container}>
      <h1>Music Brands Importer</h1>
      <p className={styles.description}>
        Upload a JSON file containing music brands to import into the database.
        Logos will be automatically fetched and stored.
      </p>
      
      <div className={styles.uploadContainer}>
        <label className={styles.fileLabel}>
          <span>Select Brands JSON File</span>
          <input 
            type="file" 
            accept=".json" 
            onChange={handleFileChange}
            disabled={isUploading}
          />
        </label>
        
        <button 
          className={styles.importButton} 
          onClick={handleImport}
          disabled={isUploading || !fileContent}
        >
          {isUploading ? 'Processing...' : 'Import Brands'}
        </button>
      </div>
      
      {uploadStatus && (
        <div className={`${styles.status} ${statusClass}`}>
          {uploadStatus}
          {isUploading && progress > 0 && (
            <div className={styles.progressBar}>
              <div 
                className={styles.progressFill} 
                style={{ width: `${progress}%` }}
              />
            </div>
          )}
        </div>
      )}
      
      {fileContent && (
        <div className={styles.preview}>
          <h3>Preview:</h3>
          <p>{fileContent.length} brands found in file</p>
          <div className={styles.brandsPreview}>
            {fileContent.slice(0, 5).map((brand, index) => (
              <div key={index} className={styles.brandItem}>
                <strong>{brand.brandName}</strong>
                {brand.website && <span>: {brand.website}</span>}
              </div>
            ))}
            {fileContent.length > 5 && <p>...and {fileContent.length - 5} more</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default BrandsImporter;