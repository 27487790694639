import { getFirestore, collection, doc, setDoc, updateDoc, addDoc, getDoc, getDocs, arrayUnion, query, orderBy, getCountFromServer, startAfter, limit, where } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

// Membership Pricing Configuration
const MEMBERSHIP_PRICES = {
  Free: {
    basePrice: 0,
    features: [
      'Create 1 Tour',
      '10 Schedules Per Tour Date',
      '5 Tour Dates per Tour',
      'Upload Travel Tickets',
      'See Tour Date Availability'
    ]
  },
  Basic: {
    basePrice: 499,  // Total price inclusive of GST
    features: [
      'Create 10 Tours',
      'Unlimited Schedules',
      '10 Tour Dates per Tour',
      'Upload Travel Tickets',
      'See Tour Date Availability',
      'Record Expenses',
      'See Vendor Inventory',
      'Search Users'
    ]
  },
  Pro: {
    basePrice: 1499,  // Total price inclusive of GST
    features: [
      'Unlimited Tours',
      'Unlimited Schedules',
      '15 Tour Dates per Tour',
      'All Basic Features',
      'Account Verification',
      'Send Messages Outside Tour',
      'Search Filters'
    ]
  },
  Elite: {
    basePrice: 10,  // Total price inclusive of GST
    features: [
      'Unlimited Everything',
      'All Pro Features',
      'Download Expense Reports',
      'Past Tours & Shows Access',
      'Custom Schedule Visibility',
      'Set Hotel Visibility',
      'Set Tickets Visibility',
      'Hide Email from Users',
      'Private Account Option'
    ]
  }
};

// Helper function to calculate GST
const calculateInclusiveGST = (totalPrice, gstRate = 0.18) => {
  // Calculate original price and GST amount
  const basePrice = totalPrice / (1 + gstRate);
  const gstAmount = totalPrice - basePrice;
  
  return {
    basePrice: Math.round(basePrice * 100) / 100,  // Round to 2 decimal places
    gstRate: gstRate,
    gstAmount: Math.round(gstAmount * 100) / 100,
    totalPrice: totalPrice
  };
};

// Create a plan in Razorpay with GST included
export const createPlan = async (tierName, userData) => {
  try {

      // Get the current user's ID token
      const auth = getAuth();
      const currentUser = auth.currentUser;
      const idToken = await currentUser.getIdToken();

    const tierPricing = MEMBERSHIP_PRICES[tierName];
    if (!tierPricing) {
      throw new Error(`Invalid membership tier: ${tierName}`);
    }

    // Use inclusive GST calculation
    const { 
      basePrice, 
      gstRate, 
      gstAmount, 
      totalPrice 
    } = calculateInclusiveGST(tierPricing.basePrice);

    const plan = {
      period: 'monthly',
      interval: 1,
      item: {
        name: `${tierName} Plan (incl. ${gstRate * 100}% GST)`,
        amount: Math.round(totalPrice * 100), // Convert to paise
        currency: 'INR',
        description: `${tierName} Membership Plan\n` +
          `Base Price: ₹${basePrice.toFixed(2)}\n` +
          `GST (${gstRate * 100}%): ₹${gstAmount.toFixed(2)}\n` +
          `Total: ₹${totalPrice.toFixed(2)}\n\n` +
          `Features:\n${tierPricing.features.map(f => `- ${f}`).join('\n')}`
      }
    };


    const response = await fetch('https://asia-south1-ontourauth.cloudfunctions.net/createRazorpayPlan', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${idToken}`
      },
      body: JSON.stringify({ 
        plan,
        tierDetails: {
          name: tierName,
          basePrice,
          gstDetails: {
            rate: gstRate,
            amount: gstAmount,
            totalPrice
          },
          features: tierPricing.features,
          // Include user details for Razorpay dashboard tracking
          customerDetails: {
            userId: userData.userId,
            fullName: userData.fullName,
            email: userData.email,
            phoneNumber: userData.phoneNumber,
            userName: userData.userName,
            userType: userData.userType,
            userSubType: userData.userSubType,
            city: userData.city || 'Not Specified'
          }
        }
      })
    });

    const data = await response.json();
    if (!response.ok) {
      throw new Error(data.error?.details || 'Failed to create plan');
    }

    return {
      ...data,
      tierDetails: {
        name: tierName,
        basePrice,
        gstDetails: {
          rate: gstRate,
          amount: gstAmount,
          totalPrice
        },
        features: tierPricing.features,
        customerDetails: {
          userId: userData.userId,
          fullName: userData.fullName,
          email: userData.email,
          phoneNumber: userData.phoneNumber,
          userName: userData.userName,
          userType: userData.userType,
          userSubType: userData.userSubType,
          city: userData.city || 'Not Specified'
        }
      }
    };
  } catch (error) {
    console.error('Plan creation error:', error);
    throw error;
  }
};

// Create a subscription in Razorpay
export const createSubscription = async (planId, userData, selectedTier) => {
  try {
    const subscription = {
      plan_id: planId,
      total_count: 12,
      quantity: 1,
      notes: {
        // Include comprehensive user details
        userId: userData.userId,
        tier: selectedTier || userData.membershipTier || userData.selectedTier, // Ensure tier is explicitly set
        fullName: userData.fullName,
        email: userData.email,
        phoneNumber: userData.phoneNumber,
        userName: userData.userName,
        userType: userData.userType,
        userSubType: userData.userSubType,
        city: userData.city || 'Not Specified'
      }
    };

    console.log(`Creating subscription with tier: ${subscription.notes.tier}`);

    const response = await fetch('https://asia-south1-ontourauth.cloudfunctions.net/createRazorpaySubscription', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ subscription })
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.details || 'Failed to create subscription');
    }

    return response.json();
  } catch (error) {
    console.error('Error creating subscription:', error);
    throw error;
  }
};

// Cancel a subscription in Razorpay
export const cancelSubscription = async (subscriptionId) => {
  try {
    const response = await fetch(`https://asia-south1-ontourauth.cloudfunctions.net/cancelSubscription/${subscriptionId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.details || 'Failed to cancel subscription');
    }

    return response.json();
  } catch (error) {
    console.error('Error cancelling subscription:', error);
    throw error;
  }
};

// Save membership details to Firestore

// Updated saveMembershipDetails function with proper amount handling
export const saveMembershipDetails = async (userId, subscriptionData, paymentDetails) => {
  const db = getFirestore();
  const date = new Date();
  
  try {
    // Get the subscription details from Razorpay API to ensure we have complete data
    const subscription = await fetchSubscriptionDetails(subscriptionData.id);
    
    // Get payment details to ensure we have accurate amount information
    const paymentInfo = await fetchPaymentDetails(paymentDetails.razorpay_payment_id);
    
    // Get the membership tier from subscription notes
    const membershipTier = subscription?.notes?.tier || 'Unknown';
    
    // First try to get amount from payment details, as this is the actual charged amount
    let amountInRupees = paymentInfo.amount || 0;
    
    // Log raw payment info for debugging
    console.log(`Raw payment info from Razorpay: 
      Payment ID: ${paymentInfo.id}
      Raw amount: ${paymentInfo.amount}
      Currency: ${paymentInfo.currency}
      Status: ${paymentInfo.status}
      Method: ${paymentInfo.method}
    `);
    
    // If payment amount is not available, fall back to subscription plan
    if (!amountInRupees && subscription?.plan?.item?.amount) {
      // Subscription plan amount is likely in paise, so convert it
      amountInRupees = (subscription.plan.item.amount / 100) || 0;
      console.log(`No amount in payment info, using subscription plan amount (converted from paise): ${amountInRupees}`);
    }
    
    console.log(`Amount in rupees (directly from Razorpay): ${amountInRupees}`);
    
    // Calculate GST for the total price (inclusive calculation)
    const gstRateDecimal = 0.18; // 18% as decimal
    
    console.log(`Amount in rupees: ${amountInRupees}`);
    
    
    // Log amount processing with clear indicators
    console.log(`Payment processing:
      Amount (received): ${amountInRupees} INR
      GST rate: ${gstRateDecimal * 100}%
    `);
    
    // Handle all tiers consistently, using the Razorpay amount
    let totalPrice = amountInRupees;
    
    // For inclusive GST calculation:
    const basePrice = totalPrice / (1 + gstRateDecimal);
    const gstAmount = totalPrice - basePrice;
    
    // Round to 2 decimal places for consistent display
    const basePrice2Decimal = Math.round(basePrice * 100) / 100;
    const gstAmount2Decimal = Math.round(gstAmount * 100) / 100;
    const totalPrice2Decimal = Math.round(totalPrice * 100) / 100;
    
    console.log(`Calculated GST breakdown:
      Base Price: ₹${basePrice2Decimal}
      GST (18%): ₹${gstAmount2Decimal}
      Total: ₹${totalPrice2Decimal}`);

    // Use proper timestamps for subscription dates
    const startDate = subscription.current_start ? new Date(subscription.current_start * 1000) : new Date();
    const endDate = subscription.current_end ? new Date(subscription.current_end * 1000) : new Date(startDate.getTime() + 30 * 24 * 60 * 60 * 1000);

    // Create a unique ID for this membership record
    const recordId = `${paymentDetails.razorpay_payment_id}_${date.getTime()}`;
    const membershipRef = doc(db, 'users', userId, 'membership', recordId);
    
    // Also create a month-year reference for grouping
    const monthYear = `${date.toLocaleString('default', { month: 'long' })}_${date.getFullYear()}`;
    
    // Extract user details from subscription notes
    const userDetails = subscription?.notes || {};

    // Get current user data
    const userRef = doc(db, 'users', userId);
    const userSnapshot = await getDoc(userRef);
    const userData = userSnapshot.exists() ? userSnapshot.data() : {};
    
    // Store previous tier for history
    const previousTier = userData.membershipTier || 'Free';

    // Get payment method information
    let paymentMethod = null;
    try {
      paymentMethod = {
        method: paymentInfo.method || 'unknown',
        card: paymentInfo.card ? {
          last4: paymentInfo.card.last4,
          network: paymentInfo.card.network,
          type: paymentInfo.card.type
        } : null,
        wallet: paymentInfo.wallet,
        bank: paymentInfo.bank
      };
    } catch (error) {
      console.error('Error processing payment details:', error);
    }

    // Get auth token for getting invoice number
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const idToken = await currentUser.getIdToken();
    
    // Generate invoice number at transaction time
    let invoiceNumber = null;
    try {
      const invoiceResponse = await fetch('https://asia-south1-ontourauth.cloudfunctions.net/generateInvoiceNumber', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          transactionDate: startDate.toISOString() // Pass the transaction date to use for year calculation
        })
      });
      
      if (!invoiceResponse.ok) {
        throw new Error('Failed to generate invoice number');
      }
      
      const invoiceData = await invoiceResponse.json();
      invoiceNumber = invoiceData.formattedInvoiceNumber;
    } catch (error) {
      console.error('Error generating invoice number:', error);
      // Continue with membership update even if invoice number generation fails
    }

    const membershipData = {
      id: recordId, // Store the ID within the document as well
      subscriptionId: subscription.id,
      planId: subscription.plan_id,
      status: subscription.status,
      membershipTier: membershipTier,
      startDate: startDate,
      endDate: endDate,
      paymentId: paymentDetails.razorpay_payment_id,
      paymentStatus: 'completed',
      paymentMethod: paymentMethod,
      baseAmount: basePrice2Decimal,
      gstDetails: {
        rate: gstRateDecimal,
        amount: gstAmount2Decimal,
        totalAmount: totalPrice2Decimal
      },
      // Include the invoice number generated at transaction time
      invoiceNumber: invoiceNumber,
      invoiceGeneratedAt: invoiceNumber ? new Date() : null,
      // Include user details in membership record
      userDetails: {
        userId: userDetails.userId || userData.userId,
        fullName: userDetails.fullName || userData.fullName,
        email: userDetails.email || userData.email,
        phoneNumber: userDetails.phoneNumber || userData.phoneNumber,
        userName: userDetails.userName || userData.userName,
        userType: userDetails.userType || userData.userType,
        userSubType: userDetails.userSubType || userData.userSubType,
        city: userDetails.city || userData.city
      },
      monthYear: monthYear, // Store the month-year for easier querying
      createdAt: new Date(),
      updatedAt: new Date(),
      previousTier: previousTier
    };

    console.log(`Saving membership data with amount: ${membershipData.baseAmount}, GST: ${membershipData.gstDetails.amount}, total: ${membershipData.gstDetails.totalAmount}`);
    await setDoc(membershipRef, membershipData);

    // Add this record to the month's index
    const monthIndexRef = doc(db, 'users', userId, 'membershipByMonth', monthYear);
    await setDoc(monthIndexRef, {
      month: date.getMonth() + 1,
      year: date.getFullYear(),
      records: arrayUnion({
        recordId: recordId,
        subscriptionId: subscription.id,
        paymentId: paymentDetails.razorpay_payment_id,
        membershipTier: membershipTier,
        amount: totalPrice2Decimal,
        timestamp: date
      })
    }, { merge: true });

    // Update user document with new data
    const updateData = {
      membershipTier: membershipTier,
      subscriptionId: subscription.id,
      membershipStartDate: startDate,
      membershipEndDate: endDate,
      lastPaymentAmount: totalPrice2Decimal,
      lastPaymentGstDetails: {
        rate: gstRateDecimal,
        amount: gstAmount2Decimal,
        totalAmount: totalPrice2Decimal
      },
      // Include the invoice number in user document
      lastInvoiceNumber: invoiceNumber,
      lastInvoiceGeneratedAt: invoiceNumber ? new Date() : null,
      // Update last subscription details
      lastSubscriptionDetails: {
        subscriptionId: subscription.id,
        tier: membershipTier,
        startDate: startDate,
        endDate: endDate,
        amount: totalPrice2Decimal,
        paymentMethod: paymentMethod,
        invoiceNumber: invoiceNumber
      },
      previousMembershipTier: previousTier,
      subscriptionStatus: 'active'
    };

    // If user has an active trial, properly disable it
    if (userData.membershipTrial?.active) {
      updateData['membershipTrial'] = {
        active: false,
        disabled: true,
        startDate: userData.membershipTrial.startDate,
        endDate: userData.membershipTrial.endDate,
        tier: userData.membershipTrial.tier
      };
      
      // If this is their first paid subscription after trial, mark the trial as completed
      if (!userData.hasCompletedTrial) {
        updateData.hasCompletedTrial = true;
      }
    }

    console.log(`Updating user document for ${userId} with tier: ${membershipTier}`);
    await updateDoc(userRef, updateData);
    
    return {
      success: true,
      recordId: recordId,
      invoiceNumber: invoiceNumber,
      amount: totalPrice2Decimal,
      tier: membershipTier
    };

  } catch (error) {
    console.error('Error saving membership details:', error);
    throw error;
  }
};

// Helper function to fetch payment details - improved with more robust error handling
async function fetchPaymentDetails(paymentId) {
  if (!paymentId) {
    console.error('No payment ID provided to fetchPaymentDetails');
    return { amount: 0, method: 'unknown' };
  }

  try {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const idToken = await currentUser.getIdToken();
    
    const response = await fetch(`https://asia-south1-ontourauth.cloudfunctions.net/getPaymentDetails/${paymentId}`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      }
    });
    
    if (!response.ok) {
      console.error(`Failed to fetch payment details: ${response.status} ${response.statusText}`);
      throw new Error('Failed to fetch payment details');
    }
    
    const paymentData = await response.json();
    console.log(`Retrieved payment data: amount=${paymentData.amount}, method=${paymentData.method}`);
    return paymentData;
  } catch (error) {
    console.error('Error in fetchPaymentDetails:', error);
    throw error;
  }
}



// Helper function to fetch subscription details
async function fetchSubscriptionDetails(subscriptionId) {
  const auth = getAuth();
  const currentUser = auth.currentUser;
  const idToken = await currentUser.getIdToken();
  
  const response = await fetch(`https://asia-south1-ontourauth.cloudfunctions.net/getSubscription/${subscriptionId}`, {
    method: 'GET',
    headers: {
      'Authorization': `Bearer ${idToken}`,
      'Content-Type': 'application/json'
    }
  });
  
  if (!response.ok) {
    throw new Error('Failed to fetch subscription details');
  }
  
  return response.json();
}

// Handle subscription cancellation in Firestore
// Updated handleSubscriptionCancellation function to work with new ID format
export const handleSubscriptionCancellation = async (userId, subscriptionId) => {
  const db = getFirestore();
  const userRef = doc(db, 'users', userId);
  
  try {
    // Get the current user's ID token
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const idToken = await currentUser.getIdToken();

    const userData = await getDoc(userRef);
    if (!userData.exists()) throw new Error('User not found');

    const currentData = userData.data();
    
    // Normalize the membership end date for proper comparison
    let membershipEndDate;
    if (currentData.membershipEndDate) {
      // Handle Firestore Timestamp
      if (typeof currentData.membershipEndDate.toDate === 'function') {
        membershipEndDate = currentData.membershipEndDate.toDate();
      } 
      // Handle Date object
      else if (currentData.membershipEndDate instanceof Date) {
        membershipEndDate = currentData.membershipEndDate;
      } 
      // Handle string or timestamp number
      else {
        membershipEndDate = new Date(currentData.membershipEndDate);
      }
    } else {
      membershipEndDate = new Date(); // Default to now if no end date
    }
    
    const now = new Date();
    
    // Properly format both dates to compare just the dates (not time)
    const endDateStr = membershipEndDate.toISOString().split('T')[0];
    const nowStr = now.toISOString().split('T')[0];
    
    // Log both dates for debugging
    console.log(`Comparing dates - End date: ${endDateStr}, Current date: ${nowStr}`);
    console.log(`Original end date: ${membershipEndDate}, Now: ${now}`);
    
    // Update user document - maintain current tier until end date
    // Important: We're comparing the dates directly, not the formatted strings
    const shouldKeepTier = membershipEndDate > now;
    console.log(`Should keep current tier? ${shouldKeepTier}`);
    
    await updateDoc(userRef, {
      // Keep the current membershipTier if we're still within the billing period
      membershipTier: shouldKeepTier ? currentData.membershipTier : 'Free',
      subscriptionStatus: 'cancelled',
      subscriptionCancelledAt: new Date()
    });

    // Find the membership document by subscription ID rather than month
    const membershipQuery = query(
      collection(db, 'users', userId, 'membership'),
      where('subscriptionId', '==', subscriptionId),
      orderBy('createdAt', 'desc'),
      limit(1)
    );
    
    const membershipSnapshot = await getDocs(membershipQuery);
    
    if (!membershipSnapshot.empty) {
      // Update the found document with cancellation information
      const membershipDoc = membershipSnapshot.docs[0];
      await updateDoc(membershipDoc.ref, {
        status: 'cancelled',
        cancelledAt: new Date(),
        updatedAt: new Date(),
        // Explicitly set current tier until the end date
        membershipTier: shouldKeepTier ? currentData.membershipTier : 'Free'
      });
      
      console.log(`Updated membership record ${membershipDoc.id} with cancellation status`);
    } else {
      console.warn(`No membership record found for subscription ${subscriptionId}`);
      
      // Alternatively, create a cancellation record
      const date = new Date();
      const recordId = `cancelled_${subscriptionId}_${date.getTime()}`;
      const cancelRef = doc(db, 'users', userId, 'membership', recordId);
      
      await setDoc(cancelRef, {
        subscriptionId: subscriptionId,
        status: 'cancelled',
        cancelledAt: date,
        createdAt: date,
        updatedAt: date,
        membershipTier: shouldKeepTier ? currentData.membershipTier : 'Free', 
        previousTier: currentData.membershipTier,
        startDate: currentData.membershipStartDate || null,
        endDate: currentData.membershipEndDate || null
      });
      
      console.log(`Created cancellation record ${recordId} for subscription ${subscriptionId}`);
    }

    // Also update the month index if it exists
    if (membershipEndDate) {
      const monthYear = membershipEndDate.toLocaleString('default', { month: 'long', year: 'numeric' });
      const monthRef = doc(db, 'users', userId, 'membershipByMonth', monthYear);
      const monthDoc = await getDoc(monthRef);
      
      if (monthDoc.exists()) {
        try {
          // Update the month index to mark the subscription as cancelled
          await updateDoc(monthRef, {
            lastCancellationDate: new Date(),
            [`records.${subscriptionId}.status`]: 'cancelled'
          });
        } catch (monthError) {
          // If the update fails due to the path not existing, log and continue
          console.warn(`Could not update month index: ${monthError.message}`);
        }
      }
    }

    return {
      success: true,
      keepCurrentTier: shouldKeepTier,
      currentTier: shouldKeepTier ? currentData.membershipTier : 'Free',
      endDate: membershipEndDate
    };

  } catch (error) {
    console.error('Error handling subscription cancellation:', error);
    throw error;
  }
};

// Fetch membership history
export const fetchMembershipHistory = async (userId, options = {}) => {
  const db = getFirestore();
  const membershipRef = collection(db, 'users', userId, 'membership');
  
  try {
    const {
      limit: recordLimit = 5,       // Default to 5 records per page
      startAfterDoc = null,         // Cursor for pagination
      orderByField = 'createdAt',   // Default sort field
      orderDirection = 'desc'       // Default sort direction
    } = options;

    // Build the query
    let queryConstraints = [orderBy(orderByField, orderDirection)];
    
    // Add limit
    if (recordLimit > 0) {
      queryConstraints.push(limit(recordLimit));
    }
    
    // Add startAfter for "load more" functionality
    if (startAfterDoc) {
      queryConstraints.push(startAfter(startAfterDoc));
    }
    
    const queryRef = query(membershipRef, ...queryConstraints);
    const querySnapshot = await getDocs(queryRef);
    
    // Get the last document for next batch
    const lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];
    
    const membershipData = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));
    
    return {
      memberships: membershipData,
      lastDoc: lastDoc || null,
      hasMore: querySnapshot.docs.length === recordLimit
    };
  } catch (error) {
    console.error('Error fetching membership history:', error);
    throw error;
  }
};

// Helper function to get membership history by month (paginated)
export const fetchMembershipHistoryByMonth = async (userId, options = {}) => {
  const db = getFirestore();
  const indexRef = collection(db, 'users', userId, 'membershipByMonth');
  
  try {
    const {
      limit: recordLimit = 5,       // Default months per batch 
      startAfterDoc = null,         // Month cursor for load more
      includeRecords = true         // Whether to include the actual records
    } = options;

    // Build the query
    let queryConstraints = [
      orderBy('year', 'desc'),
      orderBy('month', 'desc')
    ];
    
    // Add limit
    if (recordLimit > 0) {
      queryConstraints.push(limit(recordLimit));
    }
    
    // Add startAfter for "load more" functionality
    if (startAfterDoc) {
      queryConstraints.push(startAfter(startAfterDoc));
    }
    
    const queryRef = query(indexRef, ...queryConstraints);
    const indexSnapshot = await getDocs(queryRef);
    
    // Get the last document for next batch
    const lastDoc = indexSnapshot.docs[indexSnapshot.docs.length - 1];
    
    // For each month, get the detailed records if requested
    const monthlyHistory = [];
    
    for (const monthDoc of indexSnapshot.docs) {
      const monthData = monthDoc.data();
      
      if (includeRecords && monthData.records && monthData.records.length > 0) {
        // Get full record details for each record ID
        const recordPromises = monthData.records.map(async (record) => {
          const recordDoc = await getDoc(
            doc(db, 'users', userId, 'membership', record.recordId)
          );
          
          if (recordDoc.exists()) {
            return {
              id: recordDoc.id,
              ...recordDoc.data()
            };
          }
          
          // If detailed record not found, return the summary
          return record;
        });
        
        const records = await Promise.all(recordPromises);
        
        monthlyHistory.push({
          monthYear: monthDoc.id,
          month: monthData.month,
          year: monthData.year,
          records: records
        });
      } else {
        // Just return the month summary without fetching records
        monthlyHistory.push({
          monthYear: monthDoc.id,
          month: monthData.month,
          year: monthData.year,
          recordCount: monthData.records?.length || 0
        });
      }
    }
    
    return {
      months: monthlyHistory,
      lastDoc: lastDoc || null,
      hasMore: indexSnapshot.docs.length === recordLimit
    };
  } catch (error) {
    console.error('Error fetching membership history by month:', error);
    throw error;
  }
};


// Check and update membership status
export const checkAndUpdateMembershipStatus = async (userId) => {
   const db = getFirestore();
  const userRef = doc(db, 'users', userId);
  
  try {
    // Get the current user's ID token
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const idToken = await currentUser.getIdToken();

    const userData = await getDoc(userRef);
    if (!userData.exists()) return;

    const data = userData.data();
    const membershipEndDate = new Date(data.membershipEndDate);
    const now = new Date();

    // If membership has expired and subscription was cancelled
    if (membershipEndDate < now && data.subscriptionStatus === 'cancelled') {
      await updateDoc(userRef, {
        membershipTier: 'Free',
        subscriptionId: null,
        membershipEndDate: null,
        membershipStartDate: null
      });
    }
  } catch (error) {
    console.error('Error checking membership status:', error);
  }
};