import React, { useState, useEffect } from 'react';
import { Dialog } from '../ui/dialog';
import { Card, CardContent } from '../ui/card';
import { Button } from '../ui/button';
import { Input } from '../ui/input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { doc, setDoc } from 'firebase/firestore';
import { firebaseInstances } from '../../utils/firebaseHelper';
import './LocationSearchModal.module.css' ;

const { firestore: db } = firebaseInstances;

const LocationSearchModal = ({ isOpen, onClose, onSelect }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [sessionToken, setSessionToken] = useState('');
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    generateSessionToken();
  }, []);

  const generateSessionToken = () => {
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    setSessionToken(uuid);
  };

  const fetchSuggestions = async (query) => {
    try {
      const response = await fetch('https://places.googleapis.com/v1/places:autocomplete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Goog-Api-Key': 'AIzaSyBB7qZR3v2RlLctMh3MsApdVGoDhzLpXJM',
        },
        body: JSON.stringify({
          input: query,
          sessionToken: sessionToken,
        }),
      });

      const data = await response.json();
      setSuggestions(data.suggestions || []);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleSearch = () => {
    if (searchQuery.length > 0) {
      setIsSearching(true);
      fetchSuggestions(searchQuery);
    }
  };

  const fetchPhotoUrl = async (placeId, photoName) => {
    const apiKey = 'AIzaSyBB7qZR3v2RlLctMh3MsApdVGoDhzLpXJM';
    
    try {
      const requestUrl = `https://places.googleapis.com/v1/${photoName}/media?maxWidthPx=400&maxHeightPx=400&key=${apiKey}`;
      const response = await fetch(requestUrl, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const contentType = response.headers.get('Content-Type');
        if (contentType.includes('application/json')) {
          const data = await response.json();
          return data.photoUri || '';
        } else {
          return response.url;
        }
      }
      return '';
    } catch (error) {
      console.error('Error fetching photo:', error);
      return '';
    }
  };

  const handleSelectPlace = async (placeId) => {
    try {
      const response = await fetch(`https://places.googleapis.com/v1/places/${placeId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Goog-Api-Key': 'AIzaSyBB7qZR3v2RlLctMh3MsApdVGoDhzLpXJM',
          'X-Goog-FieldMask': '*',
        },
      });

      const data = await response.json();

      const photoName = data.photos?.[0]?.name;
      const placeCoverPhotoUrl = photoName ? await fetchPhotoUrl(data.id, photoName) : '';

      const placeDetails = {
        id: data.id || '',
        name: data.name || '',
        displayName: data.displayName?.text || '',
        formattedAddress: data.formattedAddress || '',
        location: data.location || null,
        placeCoverPhotoUrl,
        // Add other fields as needed
      };

      setSelectedPlace(placeDetails);
      setSearchQuery('');
      setIsSearching(false);
      setSuggestions([]);
    } catch (error) {
      console.error('Error fetching place details:', error);
    }
  };

  const handleConfirm = async () => {
    if (selectedPlace) {
      try {
        const placeRef = doc(db, 'Places', selectedPlace.id);
        await setDoc(placeRef, selectedPlace);
        onSelect(selectedPlace);
        onClose();
      } catch (error) {
        console.error('Error saving place:', error);
      }
    }
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <div className="p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-bold">Search Location</h2>
          <Button variant="outline" onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </Button>
        </div>

        <div className="flex gap-2 mb-4">
          <Input
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            placeholder="Search for a location"
            className="flex-1"
          />
          <Button onClick={handleSearch}>
            <FontAwesomeIcon icon={faSearch} />
          </Button>
        </div>

        {isSearching && suggestions.length > 0 && (
          <Card className="mb-4">
            <CardContent>
              {suggestions.map((suggestion) => (
                <button
                  key={suggestion.placePrediction.placeId}
                  className="w-full text-left py-2 px-3 hover:bg-gray-50 rounded"
                  onClick={() => handleSelectPlace(suggestion.placePrediction.placeId)}
                >
                  {suggestion.placePrediction.text.text}
                </button>
              ))}
            </CardContent>
          </Card>
        )}

        {selectedPlace && (
          <div className="mb-4">
            <h3 className="font-semibold mb-2">Selected Location</h3>
            <Card>
              <CardContent className="flex items-center gap-4">
                {selectedPlace.placeCoverPhotoUrl && (
                  <img
                    src={selectedPlace.placeCoverPhotoUrl}
                    alt={selectedPlace.displayName}
                    className="w-20 h-20 rounded-full object-cover"
                  />
                )}
                <div>
                  <p className="font-semibold">{selectedPlace.displayName}</p>
                  <p className="text-gray-600 text-sm">{selectedPlace.formattedAddress}</p>
                </div>
              </CardContent>
            </Card>

            <div className="mt-4 h-48 rounded-lg overflow-hidden">
            <iframe
  title={`Map view of ${selectedPlace.displayName}`}
  width="100%"
  height="100%"
  frameBorder="0"
  style={{ border: 0 }}
  referrerPolicy="no-referrer-when-downgrade"
  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDX3zmGo2kfdaD1jmTOzjoCP4D2rfZ3c3A&q=${encodeURIComponent(selectedPlace.formattedAddress)}&zoom=15`}
  allowFullScreen
/>
            </div>
          </div>
        )}

        <div className="flex gap-2 justify-end">
          <Button variant="outline" onClick={onClose}>Cancel</Button>
          <Button onClick={handleConfirm} disabled={!selectedPlace}>Select</Button>
        </div>
      </div>
    </Dialog>
  );
};

export default LocationSearchModal;