import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './FileViewerModal.module.css'; // Rename your CSS file

const FileViewerModal = ({ isOpen, onClose, file }) => {
  const [isLoading, setIsLoading] = useState(true);

  if (!isOpen) return null;

  const renderContent = () => {
    const fileType = file.fileType || '';
    
    if (fileType.startsWith('image/')) {
      return (
        <div className={styles.imageContainer}>
          {isLoading && <LoadingSpinner type="image" />}
          <img
            src={file.fileUrl}
            alt={file.fileName}
            className={styles.imageFrame}
            onLoad={() => setIsLoading(false)}
          />
        </div>
      );
    } else if (fileType === 'application/pdf') {
      return (
        <div className={styles.pdfContainer}>
          {isLoading && <LoadingSpinner type="PDF" />}
          <iframe
            src={file.fileUrl}
            title="PDF Viewer"
            className={styles.pdfFrame}
            onLoad={() => setIsLoading(false)}
          />
        </div>
      );
    } else {
      // For other file types, show a preview card with download option
      return (
        <div className={styles.previewContainer}>
          <div className={styles.previewCard}>
            <h3 className={styles.fileName}>{file.fileName}</h3>
            <p className={styles.fileType}>{getFileTypeDisplay(file.fileType)}</p>
            <p className={styles.fileSize}>
              {formatFileSize(file.size)}
            </p>
            <a 
              href={file.fileUrl} 
              download={file.fileName}
              className={styles.downloadButton}
              target="_blank"
              rel="noopener noreferrer"
            >
              Download File
            </a>
          </div>
        </div>
      );
    }
  };

  const LoadingSpinner = ({ type }) => (
    <div className={styles.loaderContainer}>
      <div className={styles.spinner}></div>
      <p className={styles.loadingText}>Loading {type}...</p>
    </div>
  );

  const getFileTypeDisplay = (fileType) => {
    const typeMap = {
      'application/pdf': 'PDF Document',
      'application/msword': 'Word Document',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word Document',
      'application/vnd.ms-excel': 'Excel Spreadsheet',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel Spreadsheet',
      'text/plain': 'Text Document',
      'application/zip': 'ZIP Archive',
      'application/x-zip-compressed': 'ZIP Archive'
    };
    return typeMap[fileType] || fileType;
  };

  const formatFileSize = (bytes) => {
    if (!bytes) return '0 Bytes';
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
  };

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <button className={styles.closeButton} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        {renderContent()}
      </div>
    </div>
  );
};

export default FileViewerModal;