import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestoreInstance, firebaseInstances } from '../../utils/firebaseHelper';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { UserContext } from '../../context/UserContext';
import TopBar from '../TopBar';
import LeftMenu from '../LeftMenu';
import RightMenu from '../RightMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faUpload, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import defaultVerificationPending from '../../assets/Images/profileverificationpending.png';
import verifiedTick from '../../assets/Images/profileverifiedtick.png';
import styles from './ProfileVerification.module.css';

const ProfileVerification = () => {
  const [selectedDocument, setSelectedDocument] = useState('');
  const [frontImage, setFrontImage] = useState(null);
  const [backImage, setBackImage] = useState(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [verificationSubmitted, setVerificationSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [existingVerification, setExistingVerification] = useState(null);
  const { userData, setUserData } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Check membership tier
    if (userData?.membershipTier !== 'Pro' && userData?.membershipTier !== 'Elite') {
      navigate('/membership');
      return;
    }

    // Check verification status and fetch existing data
    const checkVerificationStatus = async () => {
      if (userData?.idVerificationStatus === 'Verified') {
        setExistingVerification({
          status: 'Verified',
          message: 'Your profile has been verified successfully!'
        });
      } else if (userData?.idVerificationStatus === 'Pending') {
        setVerificationSubmitted(true);
        if (userData?.profileVerificationUrl) {
          setExistingVerification({
            status: 'Pending',
            frontPhotoUrl: userData.profileVerificationUrl.frontPhotoUrl,
            backPhotoUrl: userData.profileVerificationUrl.backPhotoUrl,
            documentType: userData.profileVerificationUrl.documentType
          });
        }
      }
    };

    checkVerificationStatus();
  }, [userData, navigate]);

  const documentOptions = [
    { label: "Driving License", value: "Driving License" },
    { label: "Aadhar Card", value: "Aadhar Card" },
    { label: "PAN Card", value: "PAN Card" }
  ];

  const handleImageUpload = (side) => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = 'image/*';
    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (file) {
        try {
          if (!file.type.startsWith('image/')) {
            throw new Error('Please upload an image file');
          }
          if (file.size > 5 * 1024 * 1024) {
            throw new Error('Image size should be less than 5MB');
          }

          const reader = new FileReader();
          reader.onload = () => {
            if (side === 'front') {
              setFrontImage({ file, preview: reader.result });
            } else {
              setBackImage({ file, preview: reader.result });
            }
          };
          reader.readAsDataURL(file);
        } catch (error) {
          alert(error.message);
        }
      }
    };
    input.click();
  };

  const handleVerificationSubmit = async () => {
    if (!selectedDocument || !frontImage || !backImage) {
      alert('Please select a document type and upload both front and back images.');
      return;
    }

    setIsLoading(true);
    try {
      const storage = firebaseInstances.storage;
      const userId = userData.userId;

      // Upload front image
      const frontImageRef = ref(storage, `users/${userId}/verification/${selectedDocument}_front.jpg`);
      await uploadBytes(frontImageRef, frontImage.file);
      const frontPhotoUrl = await getDownloadURL(frontImageRef);

      // Upload back image
      const backImageRef = ref(storage, `users/${userId}/verification/${selectedDocument}_back.jpg`);
      await uploadBytes(backImageRef, backImage.file);
      const backPhotoUrl = await getDownloadURL(backImageRef);

      // Update user document
      const db = getFirestoreInstance();
      const userRef = doc(db, 'users', userId);
      const updatedData = {
        idVerificationStatus: 'Pending',
        profileVerificationUrl: {
          frontPhotoUrl,
          backPhotoUrl,
          documentType: selectedDocument
        },
      };

      await updateDoc(userRef, updatedData);
      setUserData(prev => ({ ...prev, ...updatedData }));
      setVerificationSubmitted(true);
    } catch (error) {
      console.error('Error submitting verification:', error);
      alert('Failed to submit verification. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderVerificationStatus = () => {
    if (existingVerification?.status === 'Verified') {
      return (
        <div className={styles.verifiedContainer}>
          <div className={styles.verifiedHeader}>
            <img src={verifiedTick} alt="Verified" className={styles.verifiedImage} />
            <h2 className={styles.verifiedTitle}>Profile Verified</h2>
          </div>
          <p className={styles.verifiedMessage}>{existingVerification.message}</p>
          <button
            onClick={() => navigate(`/${userData.userName}`)}
            className={styles.goToProfileButton}
          >
            Go to Profile
          </button>
        </div>
      );
    }

    if (verificationSubmitted) {
      return (
        <div className={styles.successContainer}>
          <p className={styles.successMessage}>
            Verification documents submitted successfully! Your verification status is now pending.
          </p>
          {existingVerification && (
            <div className={styles.submittedDocuments}>
            
              
            </div>
          )}
          <img
            src={defaultVerificationPending}
            alt="Verification Pending"
            className={styles.pendingImage}
          />
          <button
            onClick={() => navigate(`/${userData.userName}`)}
            className={styles.goToProfileButton}
          >
            Go to Profile
          </button>

          <p className={styles.documentType}>
                Document Type: {existingVerification.documentType}
              </p>
              
          <div className={styles.documentPreview}>
                <img
                  src={existingVerification.frontPhotoUrl}
                  alt="Front"
                  className={styles.previewImage}
                />
                <img
                  src={existingVerification.backPhotoUrl}
                  alt="Back"
                  className={styles.previewImage}
                />
              </div>
              
        </div>
      );
    }

    return (
      <div className={styles.formContainer}>
        <div className={styles.descriptionContainer}>
          <p className={styles.description}>
            To verify your account, please submit any one of the documents listed below.
          </p>
          <p className={styles.verificationBenefit}>
            Once your account is verified, a
            <img 
              src={verifiedTick} 
              alt="Verified" 
              className={styles.inlineVerifiedTick}
            />
            will appear beside your name, indicating that you're an authentic user.
          </p>
        </div>

        <div className={styles.documentSelect}>
          <div
            className={styles.selectButton}
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <span>{selectedDocument || 'Select a document'}</span>
            <FontAwesomeIcon icon={faChevronDown} />
          </div>
          
          {isDropdownOpen && (
            <div className={styles.dropdownMenu}>
              {documentOptions.map((option) => (
                <div
                  key={option.value}
                  className={styles.dropdownItem}
                  onClick={() => {
                    setSelectedDocument(option.value);
                    setIsDropdownOpen(false);
                  }}
                >
                  {option.label}
                </div>
              ))}
            </div>
          )}
        </div>

        <div className={styles.uploadContainer}>
          {/* Front Image Upload */}
          <div
            className={styles.uploadBox}
            onClick={() => handleImageUpload('front')}
          >
            {frontImage ? (
              <div className={styles.imagePreviewContainer}>
                <img
                  src={frontImage.preview}
                  alt="Front"
                  className={styles.imagePreview}
                />
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className={styles.checkIcon}
                />
              </div>
            ) : (
              <div>
                <FontAwesomeIcon icon={faUpload} className={styles.uploadIcon} />
                <p className={styles.uploadText}>Upload Front Image</p>
              </div>
            )}
          </div>

          {/* Back Image Upload */}
          <div
            className={styles.uploadBox}
            onClick={() => handleImageUpload('back')}
          >
            {backImage ? (
              <div className={styles.imagePreviewContainer}>
                <img
                  src={backImage.preview}
                  alt="Back"
                  className={styles.imagePreview}
                />
                <FontAwesomeIcon
                  icon={faCheckCircle}
                  className={styles.checkIcon}
                />
              </div>
            ) : (
              <div>
                <FontAwesomeIcon icon={faUpload} className={styles.uploadIcon} />
                <p className={styles.uploadText}>Upload Back Image</p>
              </div>
            )}
          </div>
        </div>

        <button
          onClick={handleVerificationSubmit}
          disabled={isLoading}
          className={styles.submitButton}
        >
          {isLoading ? (
            <div className={styles.loadingSpinner} />
          ) : (
            'Submit Verification'
          )}
        </button>
      </div>
    );
  };

  return (
    <div className={styles.mainLayout}>
      <TopBar />
      <LeftMenu />
      <main className={styles.verificationMain}>
        <div className={styles.verificationContent}>
          <h1 className={styles.title}>Profile Verification</h1>
          {renderVerificationStatus()}
        </div>
      </main>
      <RightMenu />
    </div>
  );
};

export default ProfileVerification;