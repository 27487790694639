import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faMapMarkerAlt, 
  faCalendarCheck, 
  faRoute, 
  faEnvelope,
  faHome,
  faUser,
  faCog,
  faSignOutAlt,
  faWallet,
  faShare,
  faEdit
} from '@fortawesome/free-solid-svg-icons';
import UserContext from '../../context/UserContext';
import TopBar from '../TopBar';
import LeftMenu from '../LeftMenu';
import './Profile.css';

const formatDateRange = (startDate, endDate) => {
  if (!startDate || !endDate) return '';
  const start = new Date(startDate);
  const end = new Date(endDate);
  return `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`;
};

const formatDate = (date) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString();
};

const Profile = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const { userData: currentUserData } = useContext(UserContext);
  const [profileData, setProfileData] = useState(null);
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const [activeTab, setActiveTab] = useState('tours');
  const [tours, setTours] = useState([]);
  const [dates, setDates] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [displayDays, setDisplayDays] = useState(30);
  const [filter, setFilter] = useState('all');

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        const db = getFirestore();
        const userQuery = query(collection(db, 'users'), where('userName', '==', username));
        const userSnapshot = await getDocs(userQuery);
        
        if (!userSnapshot.empty) {
          const userData = userSnapshot.docs[0].data();
          setProfileData(userData);
          setIsOwnProfile(currentUserData?.userId === userData.userId);
          
          if (userData.tourIds?.length > 0) {
            const toursQuery = query(collection(db, 'Tours'), where('id', 'in', userData.tourIds));
            const toursSnapshot = await getDocs(toursQuery);
            
            const toursData = toursSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            
            setTours(toursData);
            
            const allDates = toursData.flatMap(tour => {
              return tour.tourDates.map(date => ({
                ...date,
                tourName: tour.tourName,
                actName: tour.actName
              }));
            });
            
            setDates(allDates);
          }
        } else {
          navigate('/404');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserData();
  }, [username, currentUserData, navigate]);

  const handleEditProfile = () => navigate('/edit-profile');
  const handleShareProfile = () => navigator.clipboard.writeText(window.location.href);
  const handleEmailClick = () => window.location.href = `mailto:${profileData.email}`;

  const BottomNav = () => (
    <div className="bottom-nav">
      <button onClick={() => navigate('/dashboard')} className="nav-item">
        <FontAwesomeIcon icon={faHome} className="nav-icon" />
        <span>Home</span>
      </button>
      <button onClick={() => navigate('/profile')} className="nav-item active">
        <FontAwesomeIcon icon={faUser} className="nav-icon" />
        <span>Profile</span>
      </button>
      <button onClick={() => navigate('/payment-info')} className="nav-item">
        <FontAwesomeIcon icon={faWallet} className="nav-icon" />
        <span>Payments</span>
      </button>
      <button onClick={() => navigate('/settings')} className="nav-item">
        <FontAwesomeIcon icon={faCog} className="nav-icon" />
        <span>Settings</span>
      </button>
    </div>
  );

  const TourCard = ({ tour }) => (
    <div className="tour-card">
      <img 
        src={tour.tourCoverPhotoUrl || '/default-tour-cover.png'} 
        alt={tour.tourName} 
        className="tour-cover"
      />
      <div className="tour-info">
        <h3>{tour.tourName}</h3>
        <p className="act-name">{tour.actName}</p>
        <div className="tour-dates">
          {formatDateRange(tour.tourDates[0]?.date, tour.tourDates[tour.tourDates.length - 1]?.date)}
        </div>
        <div className="shows-count">
          {tour.tourDates.length} {tour.tourDates.length === 1 ? 'show' : 'shows'}
        </div>
      </div>
    </div>
  );

  const TourDates = () => {
    const filteredDates = dates.filter(date => {
      const today = new Date();
      const tourDate = new Date(date.date);
      
      switch(filter) {
        case 'upcoming':
          return tourDate >= today;
        case 'past':
          return tourDate < today;
        default:
          return true;
      }
    }).slice(0, displayDays);

    return (
      <div className="dates-container">
        <div className="date-filters">
          <button 
            className={`filter-btn ${filter === 'all' ? 'active' : ''}`}
            onClick={() => setFilter('all')}
          >
            All
          </button>
          <button 
            className={`filter-btn ${filter === 'upcoming' ? 'active' : ''}`}
            onClick={() => setFilter('upcoming')}
          >
            Upcoming
          </button>
          <button 
            className={`filter-btn ${filter === 'past' ? 'active' : ''}`}
            onClick={() => setFilter('past')}
          >
            Past
          </button>
        </div>

        <div className="dates-list">
          {filteredDates.map((date, index) => (
            <div key={index} className="date-card">
              <div className="date-header">
                <div className="date">{formatDate(date.date)}</div>
                <div className={`status ${date.status.toLowerCase()}`}>
                  {date.status}
                </div>
              </div>
              <div className="venue-info">
                <h4>{date.venue?.name}</h4>
                <p>{date.venue?.location}</p>
              </div>
            </div>
          ))}
        </div>

        {dates.length > displayDays && (
          <button 
            className="load-more"
            onClick={() => setDisplayDays(prev => prev + 30)}
          >
            Load More
          </button>
        )}
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <div className="profile-container">
      <TopBar />
      <div className="main-layout">
        {!isMobile && <LeftMenu />}
        <div className="profile-main">
          <div className="cover-photo-container">
            <img 
              src={profileData?.coverPhotoUrl || '/default-cover.jpg'}
              alt="Cover"
              className="cover-photo"
            />
            <div className="cover-overlay"></div>
          </div>

          <div className="profile-content">
            <div className="profile-card">
              <div className="profile-header">
                <img
                  src={profileData?.profilePictureUrl || '/default-profile.jpg'}
                  alt="Profile"
                  className="profile-photo"
                />

                <div className="profile-info">
                  <div className="name-section">
                    <h1>{profileData?.fullName}</h1>
                    {profileData?.idVerificationStatus === 'Verified' && (
                      <img src="/verified-badge.png" alt="Verified" className="verified-badge" />
                    )}
                  </div>

                  <div className="user-type">
                    {profileData?.userType}
                    {profileData?.userSubType && ` · ${profileData.userSubType}`}
                  </div>

                  <div className="info-items">
                    <div className="info-item">
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                      <span>{`${profileData?.city}, ${profileData?.state}, ${profileData?.country}`}</span>
                    </div>
                    <div className={`info-item ${profileData?.isOnTour ? 'on-tour' : 'available'}`}>
                      <FontAwesomeIcon icon={profileData?.isOnTour ? faRoute : faCalendarCheck} />
                      <span>{profileData?.isOnTour ? 'On Tour' : 'Available'}</span>
                    </div>
                  </div>

                  {profileData?.bio && <p className="bio">{profileData.bio}</p>}

                  <div className="profile-actions">
                    {isOwnProfile ? (
                      <button onClick={handleEditProfile} className="action-button edit">
                        <FontAwesomeIcon icon={faEdit} />
                        Edit Profile
                      </button>
                    ) : (
                      <button onClick={handleEmailClick} className="action-button contact">
                        <FontAwesomeIcon icon={faEnvelope} />
                        Contact
                      </button>
                    )}
                    <button onClick={handleShareProfile} className="action-button share">
                      <FontAwesomeIcon icon={faShare} />
                      Share Profile
                    </button>
                  </div>
                </div>
              </div>

              <div className="stats-grid">
                <div className="stat-card">
                  <div className="stat-value">{profileData?.toursCount || 0}</div>
                  <div className="stat-label">Tours</div>
                </div>
                <div className="stat-card">
                  <div className="stat-value">{profileData?.showsCount || 0}</div>
                  <div className="stat-label">Shows</div>
                </div>
                {(profileData?.userType === 'Manager' || profileData?.userType === 'Artist Management Company') && (
                  <div className="stat-card">
                    <div className="stat-value">{profileData?.usersCount || 0}</div>
                    <div className="stat-label">Users</div>
                  </div>
                )}
              </div>

              <div className="content-tabs">
                <button 
                  className={`tab ${activeTab === 'tours' ? 'active' : ''}`}
                  onClick={() => setActiveTab('tours')}
                >
                  Tours
                </button>
                <button 
                  className={`tab ${activeTab === 'dates' ? 'active' : ''}`}
                  onClick={() => setActiveTab('dates')}
                >
                  Tour Dates
                </button>
              </div>

              <div className="tab-content">
                {activeTab === 'tours' ? (
                  <div className="tour-grid">
                    {tours.map(tour => (
                      <TourCard key={tour.id} tour={tour} />
                    ))}
                  </div>
                ) : (
                  <TourDates />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isMobile && <BottomNav />}
    </div>
  );
};

export default Profile;