// PostPage.js
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, doc, getDoc, collection, query, where, getDocs, limit } from 'firebase/firestore';
import UserContext from '../../context/UserContext';
import PostCard  from '../common/PostCard'; 
import TopBar from '../TopBar';
import './PostCard.css';

const PostPage = () => {
  const { postId } = useParams();
  const navigate = useNavigate();
  const { userData: currentUserData } = useContext(UserContext);
  const [post, setPost] = useState(null);
  const [userPosts, setUserPosts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [postOwner, setPostOwner] = useState(null);

  useEffect(() => {
    const fetchPostAndRelatedContent = async () => {
      try {
        const db = getFirestore();
        
        // First, find the post in the central posts collection
        const postsQuery = query(collection(db, 'posts'), where('postId', '==', postId));
        const postSnapshot = await getDocs(postsQuery);
        
        if (postSnapshot.empty) {
          navigate('/404');
          return;
        }

        const postData = postSnapshot.docs[0].data();
        setPost(postData);

        // Fetch post owner's data
        const usersQuery = query(collection(db, 'users'), where('userId', '==', postData.userId));
        const userSnapshot = await getDocs(usersQuery);
        
        if (!userSnapshot.empty) {
          const userData = userSnapshot.docs[0].data();
          setPostOwner(userData);

          // Fetch more posts from the same user
          const userPostsQuery = query(
            collection(db, `users/${userData.userId}/posts`),
            where('postId', '!=', postId),
            limit(4)
          );
          const userPostsSnapshot = await getDocs(userPostsQuery);
          const userPostsData = userPostsSnapshot.docs.map(doc => doc.data());
          setUserPosts(userPostsData);
        }

      } catch (error) {
        console.error('Error fetching post:', error);
        navigate('/404');
      } finally {
        setIsLoading(false);
      }
    };

    fetchPostAndRelatedContent();
  }, [postId, navigate]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
      </div>
    );
  }

  return (
    <div className="post-page-container">
      <TopBar />
      <div className="post-page-content">
        <div className="main-post-container">
          <PostCard 
            post={post} 
            isOwner={currentUserData?.userId === post?.userId}
          />
        </div>
        
        {postOwner && (
          <div className="post-owner-info">
            <img 
              src={postOwner.profilePhotoUrl} 
              alt={postOwner.fullName} 
              className="owner-avatar"
            />
            <div className="owner-details">
              <h2>{postOwner.fullName}</h2>
              <p>@{postOwner.userName}</p>
            </div>
          </div>
        )}

        {userPosts.length > 0 && (
          <div className="more-posts-section">
            <h3>More from {postOwner.fullName}</h3>
            <div className="more-posts-grid">
              {userPosts.map(post => (
                <PostCard 
                  key={post.postId} 
                  post={post}
                  isOwner={currentUserData?.userId === post.userId}
                />
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PostPage;