import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTimes, 
  faUpload, 
  faCrown,
  faFilePdf,
  faFileImage,
  faFileWord,
  faFileExcel,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirestoreInstance } from '../../utils/firebaseHelper';
import { UserContext } from '../../context/UserContext';
import { TourContext } from '../../context/TourContext';
import styles from './AttachmentDetails.module.css';

const AttachmentDetails = ({ isOpen, onClose, tourId, dateId, onAttachmentAdded }) => {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const { selectedTour, getSelectedTourDate } = useContext(TourContext);
  
  const [selectedFile, setSelectedFile] = useState(null);
  const [description, setDescription] = useState('');
  const [visibilityType, setVisibilityType] = useState('all');
  const [customVisibility, setCustomVisibility] = useState([]);
  const [showCustomSelection, setShowCustomSelection] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({});
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5MB
  const ALLOWED_FILE_TYPES = {
    'application/pdf': { icon: faFilePdf, label: 'PDF' },
    'image/jpeg': { icon: faFileImage, label: 'Image' },
    'image/png': { icon: faFileImage, label: 'Image' },
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': { icon: faFileWord, label: 'Word' },
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': { icon: faFileExcel, label: 'Excel' }
  };

  const handleFileSelect = (event) => {
    const file = event.target.files[0];
    if (!file) return;

    // Check file type
    if (!ALLOWED_FILE_TYPES[file.type]) {
      setAlertConfig({
        title: 'Invalid File Type',
        message: 'Please upload only PDF, Image (JPG/PNG), Word, or Excel files.',
        type: 'error'
      });
      setShowAlert(true);
      event.target.value = ''; // Clear input
      return;
    }

    // Check file size
    if (file.size > MAX_FILE_SIZE) {
      setAlertConfig({
        title: 'File Too Large',
        message: `The selected file ${file.name} is ${(file.size / (1024 * 1024)).toFixed(2)}MB. Maximum allowed size is 5MB.`,
        type: 'error'
      });
      setShowAlert(true);
      event.target.value = ''; // Clear input
      return;
    }

    setSelectedFile(file);
  };

  const getTourMembers = () => {
    if (!selectedTour) return [];
    
    const members = [];
    
    // Add manager
    if (selectedTour.createdBy) {
      members.push({
        userId: selectedTour.createdBy.userId,
        fullName: selectedTour.createdBy.fullName,
        userType: 'Manager'
      });
    }

    // Add artists
    selectedTour.artistDetails?.forEach(artist => {
      members.push({
        userId: artist.userId,
        fullName: artist.fullName,
        userType: 'Artist'
      });
    });

    // Add crew
    selectedTour.crewDetails?.forEach(crew => {
      members.push({
        userId: crew.userId,
        fullName: crew.fullName,
        userType: 'Crew'
      });
    });

    return members;
  };

  const getMembersByType = (type) => {
    const members = getTourMembers();
    switch (type) {
      case 'artist':
        return members.filter(m => m.userType === 'Artist').map(m => m.userId);
      case 'crew':
        return members.filter(m => m.userType === 'Crew').map(m => m.userId);
      case 'me':
        return [userData.userId];
      case 'custom':
        return customVisibility.map(m => m.userId);
      default:
        return []; // empty array means visible to all
    }
  };

  const handleUpgrade = () => {
    navigate('/membership');
  };

  const getFileIcon = (fileType) => {
    return ALLOWED_FILE_TYPES[fileType]?.icon || faFilePdf;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedFile) return;

    setIsLoading(true);
    try {
      // Upload file to Firebase Storage
      const storage = getStorage();
      const fileRef = ref(storage, `Tours/${tourId}/tourDates/${dateId}/attachments/${selectedFile.name}`);
      await uploadBytes(fileRef, selectedFile);
      const fileUrl = await getDownloadURL(fileRef);

      // Get visibility members based on selected type
      const visibilityMembers = getMembersByType(visibilityType);

      // Create attachment object
      const newAttachment = {
        id: Date.now().toString(),
        fileName: selectedFile.name,
        fileType: selectedFile.type,
        fileUrl,
        description,
        visibility: userData?.membershipTier === 'Elite' ? visibilityMembers : [], // Empty array means visible to all
        visibilityType: userData?.membershipTier === 'Elite' ? visibilityType : 'all',
        uploadedBy: userData.userName,
        uploaderId: userData.userId,
        uploadDate: new Date().toISOString(),
        size: selectedFile.size
      };

      // Update Firestore
      const db = getFirestoreInstance();
      const tourDateRef = doc(db, 'Tours', tourId, 'tourDates', dateId);
      await updateDoc(tourDateRef, {
        'schedule.attachments': arrayUnion(newAttachment)
      });

      onAttachmentAdded(newAttachment);
      onClose();
    } catch (error) {
      console.error('Error uploading attachment:', error);
      setAlertConfig({
        title: 'Error',
        message: 'Failed to upload attachment. Please try again.',
        type: 'error'
      });
      setShowAlert(true);
    } finally {
      setIsLoading(false);
    }
  };

  const UpgradeModal = () => (
    <div className={styles.upgradeModalOverlay}>
      <div className={styles.upgradeModal}>
        <h3 className={styles.upgradeModalTitle}>Upgrade to Elite</h3>
        <p className={styles.upgradeModalMessage}>
          Upgrade to 'Elite' membership to set custom visibility for your attachments
        </p>
        <div className={styles.upgradeModalActions}>
          <button
            type="button"
            onClick={() => setShowUpgradeModal(false)}
            className={styles.cancelButton}
          >
            Close
          </button>
          <button
            type="button"
            onClick={handleUpgrade}
            className={styles.submitButton}
          >
            Upgrade
          </button>
        </div>
      </div>
    </div>
  );

  const AlertModal = ({ config, onClose }) => (
    <div className={styles.alertModalOverlay}>
      <div className={styles.alertModal}>
        <div className={styles.alertHeader}>
          <FontAwesomeIcon 
            icon={faExclamationTriangle} 
            className={`${styles.alertIcon} ${styles[config.type]}`}
          />
          <h3 className={styles.alertTitle}>{config.title}</h3>
        </div>
        <p className={styles.alertMessage}>{config.message}</p>
        <div className={styles.alertActions}>
          <button 
            className={`${styles.alertButton} ${styles[config.type]}`}
            onClick={onClose}
          >
            OK
          </button>
        </div>
      </div>
    </div>
  );

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <button className={styles.closeButton} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2 className={styles.modalTitle}>Add Attachment</h2>

        <form onSubmit={handleSubmit} className={styles.form}>
          <div className={styles.uploadSection}>
            {!selectedFile ? (
              <label className={styles.uploadArea}>
                <input
                  type="file"
                  onChange={handleFileSelect}
                  accept=".pdf,.docx,.xlsx,.jpg,.jpeg,.png"
                  className={styles.fileInput}
                />
                <FontAwesomeIcon icon={faUpload} className={styles.uploadIcon} />
                <div className={styles.uploadText}>
                  <p>Drop your file here or click to browse</p>
                  <span className={styles.uploadInfo}>
                    Maximum file size: 5MB<br />
                    Supported formats: PDF, Word, Excel, Images
                  </span>
                </div>
              </label>
            ) : (
              <div className={styles.selectedFileInfo}>
                <FontAwesomeIcon 
                  icon={getFileIcon(selectedFile.type)} 
                  className={styles.fileTypeIcon} 
                />
                <div className={styles.fileDetails}>
                  <p className={styles.fileName}>{selectedFile.name}</p>
                  <p className={styles.fileSize}>
                    {(selectedFile.size / (1024 * 1024)).toFixed(2)} MB
                  </p>
                </div>
                <button 
                  type="button"
                  className={styles.removeFileButton}
                  onClick={() => setSelectedFile(null)}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </button>
              </div>
            )}
          </div>

          {selectedFile && (
            <div className={styles.formGroup}>
              <label htmlFor="description">Description</label>
              <textarea
                id="description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Add a description..."
                className={styles.textarea}
              />
            </div>
          )}

          <div className={styles.formGroup}>
            <div className={styles.visibilityHeader}>
              <label>Visibility</label>
              {userData?.membershipTier !== 'Elite' && (
                <button 
                  type="button"
                  className={styles.upgradeButton}
                  onClick={() => setShowUpgradeModal(true)}
                >
                  <FontAwesomeIcon icon={faCrown} />
                  Upgrade
                </button>
              )}
            </div>

            {userData?.membershipTier === 'Elite' ? (
              <div className={styles.visibilityControl}>
                <div className={styles.visibilityOptions}>
                  <label className={styles.visibilityOption}>
                    <input
                      type="radio"
                      name="visibility"
                      value="all"
                      checked={visibilityType === 'all'}
                      onChange={(e) => {
                        setVisibilityType(e.target.value);
                        setShowCustomSelection(false);
                      }}
                    />
                    <span>All Members</span>
                  </label>
                  <label className={styles.visibilityOption}>
                    <input
                      type="radio"
                      name="visibility"
                      value="artist"
                      checked={visibilityType === 'artist'}
                      onChange={(e) => {
                        setVisibilityType(e.target.value);
                        setShowCustomSelection(false);
                      }}
                    />
                    <span>Artists Only</span>
                  </label>
                  <label className={styles.visibilityOption}>
                    <input
                      type="radio"
                      name="visibility"
                      value="crew"
                      checked={visibilityType === 'crew'}
                      onChange={(e) => {
                        setVisibilityType(e.target.value);
                        setShowCustomSelection(false);
                      }}
                    />
                    <span>Crew Only</span>
                  </label>
                  <label className={styles.visibilityOption}>
                    <input
                      type="radio"
                      name="visibility"
                      value="me"
                      checked={visibilityType === 'me'}
                      onChange={(e) => {
                        setVisibilityType(e.target.value);
                        setShowCustomSelection(false);
                      }}
                    />
                    <span>Only Me</span>
                  </label>
                  <label className={styles.visibilityOption}>
                    <input
                      type="radio"
                      name="visibility"
                      value="custom"
                      checked={visibilityType === 'custom'}
                      onChange={(e) => {
                        setVisibilityType(e.target.value);
                        setShowCustomSelection(true);
                      }}
                    />
                    <span>Custom</span>
                  </label>
                </div>

                {showCustomSelection && (
                  <div className={styles.customVisibilitySelector}>
                    <p className={styles.customVisibilityTitle}>Select specific members:</p>
                    {getTourMembers().map(member => {
                      const isManager = member.userType === 'Manager';
                      return (
                        <div 
                          key={member.userId} 
                          className={`${styles.customVisibilityOption} ${isManager ? styles.manager : ''}`}
                        >
                          <input
                            type="checkbox"
                            id={`custom-${member.userId}`}
                            checked={isManager || customVisibility.some(v => v.userId === member.userId)}
                            onChange={(e) => {
                              if (!isManager) {
                                if (e.target.checked) {
                                  setCustomVisibility([...customVisibility, member]);
                                } else {
                                  setCustomVisibility(customVisibility.filter(v => v.userId !== member.userId));
                                }
                              }
                            }}
                            disabled={isManager}
                          />
                          <label htmlFor={`custom-${member.userId}`}>
                            {member.fullName} ({member.userType})
                          </label>
                        </div>
                      );
                    })}
                  </div>
                )}
              </div>
            ) : (
              <div className={styles.visibilityInfo}>
                <span>Visible to all members</span>
                <span className={styles.eliteTag}>Elite feature</span>
              </div>
            )}
          </div>

          <div className={styles.formActions}>
            <button
              type="button"
              onClick={onClose}
              className={styles.cancelButton}
              disabled={isLoading}
            >
              Cancel
            </button>
            {selectedFile && (
              <button 
                type="submit" 
                className={styles.submitButton} 
                disabled={isLoading}
              >
                {isLoading ? 'Uploading...' : 'Upload'}
              </button>
            )}
          </div>
        </form>

        {showUpgradeModal && <UpgradeModal />}

        {showAlert && (
          <AlertModal 
            config={alertConfig}
            onClose={() => setShowAlert(false)}
          />
        )}

        {isLoading && (
          <div className={styles.loadingOverlay}>
            <div className={styles.spinner} />
            <p className={styles.loadingText}>Uploading file...</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default AttachmentDetails;