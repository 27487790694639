import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUsers, faEdit, faTrash, faPlus, faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import TourContext from '../../context/TourContext';
import UserContext from '../../context/UserContext';
import { doc, updateDoc, getDoc, } from 'firebase/firestore';
import { getFirestoreInstance } from '../../utils/firebaseHelper';
import TopBar from '../TopBar';
import LeftMenu from '../LeftMenu';
import RightMenu from '../RightMenu';
import styles from './Crew.module.css';
import defaultProfilePhoto from '../../assets/Images/defaultUserProfilePicture.png';
import verifiedBadge from '../../assets/Images/profileverifiedtick.png';
import { useNavigate } from 'react-router-dom';

const DeleteConfirmationModal = ({ show, onConfirm, onCancel, memberName }) => {
  if (!show) return null;

  return (
    <div className={styles.modalOverlay} onClick={onCancel}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <h3 className={styles.modalTitle}>Remove Member</h3>
        <p className={styles.modalText}>
          Are you sure you want to remove {memberName} from this date?
        </p>
        <div className={styles.modalActions}>
          <button className={styles.modalCancelButton} onClick={onCancel}>
            Cancel
          </button>
          <button className={styles.modalConfirmButton} onClick={onConfirm}>
            Remove
          </button>
        </div>
      </div>
    </div>
  );
};

const Crew = () => {
  const { selectedTour, selectedDate, setSelectedDate, updateTourData } = useContext(TourContext);
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const [crewMembers, setCrewMembers] = useState([]);
  const [artists, setArtists] = useState([]);
  const [manager, setManager] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingMember, setEditingMember] = useState(null);
  const [dateScrollPosition, setDateScrollPosition] = useState(0);
  const [pendingArtists, setPendingArtists] = useState([]);
  const [pendingCrew, setPendingCrew] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [memberToDelete, setMemberToDelete] = useState(null);
  

  const handleMemberClick = (userName) => {
    navigate(`/${userName}`);
  };
  
  const handleEditMember = (member) => {
    setEditingMember(member);
    setIsModalOpen(true);
  };

  const handleDeleteMember = (userId) => {
    const member = [...(selectedTour.artistDetails || []), ...(selectedTour.crewDetails || [])]
      .find(m => m.userId === userId);
      
    if (member) {
      setMemberToDelete(member);
      setShowDeleteModal(true);
    }
  };

  const confirmDelete = async () => {
    if (!memberToDelete || !selectedDate) return;
  
    const isArtist = selectedTour.artistDetails?.some(a => a.userId === memberToDelete.userId);
    const isCrew = selectedTour.crewDetails?.some(c => c.userId === memberToDelete.userId);
  
    try {
      const db = getFirestoreInstance();
      const tourRef = doc(db, 'Tours', selectedTour.id);
      
      // Create deep copies of the current details
      const updatedArtistDetails = [...(selectedTour.artistDetails || [])];
      const updatedCrewDetails = [...(selectedTour.crewDetails || [])];
  
      // Update the arrays based on member type
      if (isArtist) {
        const artistIndex = updatedArtistDetails.findIndex(a => a.userId === memberToDelete.userId);
        if (artistIndex !== -1) {
          const artist = updatedArtistDetails[artistIndex];
          const updatedDates = artist.assignedDates.filter(
            ad => ad.tourDateDocId !== selectedDate
          );
          
          if (updatedDates.length > 0) {
            updatedArtistDetails[artistIndex] = {
              ...artist,
              assignedDates: updatedDates
            };
          } else {
            updatedArtistDetails.splice(artistIndex, 1);
          }
        }
      }
  
      if (isCrew) {
        const crewIndex = updatedCrewDetails.findIndex(c => c.userId === memberToDelete.userId);
        if (crewIndex !== -1) {
          const crew = updatedCrewDetails[crewIndex];
          const updatedDates = crew.assignedDates.filter(
            ad => ad.tourDateDocId !== selectedDate
          );
          
          if (updatedDates.length > 0) {
            updatedCrewDetails[crewIndex] = {
              ...crew,
              assignedDates: updatedDates
            };
          } else {
            updatedCrewDetails.splice(crewIndex, 1);
          }
        }
      }
  
      // Update Firestore
      await updateDoc(tourRef, {
        artistDetails: updatedArtistDetails,
        crewDetails: updatedCrewDetails
      });
  
      // After successful Firestore update, update local state
      setArtists(prevArtists => 
        prevArtists.filter(artist => {
          if (artist.userId === memberToDelete.userId) {
            return artist.assignedDates.some(ad => ad.tourDateDocId !== selectedDate);
          }
          return true;
        })
      );
  
      setCrewMembers(prevCrew => 
        prevCrew.filter(crew => {
          if (crew.userId === memberToDelete.userId) {
            return crew.assignedDates.some(ad => ad.tourDateDocId !== selectedDate);
          }
          return true;
        })
      );
  
      // Use the updateTourData from context to refresh the tour data
      await updateTourData(selectedTour.id);
  
    } catch (error) {
      console.error('Error removing member:', error);
    } finally {
      setShowDeleteModal(false);
      setMemberToDelete(null);
    }
  };

  // Format date for display
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    });
  };

  // Handle date scroll
  const handleScroll = (direction) => {
    const scrollContainer = document.getElementById('dateScroller');
    if (scrollContainer) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      scrollContainer.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  // Handle date selection
  const handleDateSelect = (date) => {
    setSelectedDate(date.id);
  };

  // Filter members by selected date
  useEffect(() => {
    if (selectedTour && selectedDate) {
      const tourDate = selectedTour.tourDates?.find(date => date.id === selectedDate);
      const actualDate = tourDate?.date;
    
      // Set manager
      if (selectedTour.createdBy) {
        setManager({
          ...selectedTour.createdBy,
          userType: 'Manager'
        });
      }
    
      // Filter active artists for selected date
      const filteredArtists = selectedTour.artistDetails?.filter(artist => 
        artist.assignedDates?.some(ad => ad.date === actualDate)
      ) || [];
    
      // Filter active crew for selected date
      const filteredCrew = selectedTour.crewDetails?.filter(crew => 
        crew.assignedDates?.some(ad => ad.date === actualDate)
      ) || [];
    
      // Filter pending invitations for this specific date
      const pendingInvitationsForDate = selectedTour.pendingInvitationsData?.filter(inv => 
        inv.assignedDates?.some(ad => ad.date === actualDate)
      ) || [];
    
      setPendingArtists(pendingInvitationsForDate.filter(inv => inv.role === 'Artist'));
      setPendingCrew(pendingInvitationsForDate.filter(inv => inv.role === 'Crew'));
      
      setArtists(filteredArtists);
      setCrewMembers(filteredCrew);
    }
  }, [selectedTour, selectedDate]);

  const MemberSection = ({ title, members, pendingMembers }) => {
    if (!members?.length && !pendingMembers?.length) {
      return null;
    }
  
    return (
      <div className={styles['member-section']}>
        <h2 className={styles['section-title']}>{title}</h2>
        <div className={styles['members-list']}>
          {/* Regular Members */}
          {members?.map((member) => (
          <div 
            key={member.userId} 
            className={styles['member-card']}
            onClick={() => navigate(`/${member.userName}`)}
            style={{ cursor: 'pointer' }}
          >
              <div className={styles['member-header']}>
                <img
                  src={member.profilePictureUrl || defaultProfilePhoto}
                  alt={member.fullName}
                  className={styles['member-photo']}
                />
                <div className={styles['member-info']}>
                  <div className={styles['name-container']}>
                    <h3>{member.fullName}</h3>
                    {member.idVerificationStatus === 'Verified' && (
                      <img src={verifiedBadge} alt="Verified" className={styles['verified-badge']} />
                    )}
                  </div>
                  <p className={styles['member-username']}>@{member.userName}</p>
                  <p className={styles['member-type']}>{member.userSubType}</p>
                </div>
                {member.userType !== 'Manager' && (
                  <div 
                    className={styles['member-actions']}
                    onClick={(e) => e.stopPropagation()} 
                  >
                    <button 
                      className={styles['action-button']}
                      onClick={() => handleDeleteMember(member.userId)}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                )}
              </div>
            </div>
          ))}
  
          {/* Pending Members */}
          {pendingMembers?.map((member) => (
          <div 
            key={`pending-${member.userId}`}
            className={`${styles['member-card']} ${styles['pending-member']}`}
            onClick={() => navigate(`/${member.userName}`)}
            style={{ cursor: 'pointer' }}
          >
              <div className={styles['member-header']}>
                <img
                  src={member.profilePictureUrl || defaultProfilePhoto}
                  alt={member.fullName}
                  className={styles['member-photo']}
                />
                <div className={styles['member-info']}>
                  <div className={styles['name-container']}>
                    <h3>{member.fullName}</h3>
                    {member.idVerificationStatus === 'Verified' && (
                      <img src={verifiedBadge} alt="Verified" className={styles['verified-badge']} />
                    )}
                  </div>
                  <p className={styles['member-username']}>@{member.userName}</p>
                  <p className={styles['member-type']}>{member.userSubType}</p>
                  <span className={styles['pending-badge']}>Pending Invitation</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className={styles['main-layout']}>
      <TopBar />
      <LeftMenu />
      <div className={styles['crew-main']}>
        <div className={styles['crew-content']}>
          {/* Date Selector */}
          <div className={styles['date-selector']}>
            <button 
              className={styles['scroll-button']} 
              onClick={() => handleScroll('left')}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            
            <div className={styles['dates-container']} id="dateScroller">
  {selectedTour?.tourDates?.map((date) => {
    const isRescheduled = date.rescheduleDate?.isRescheduled;
    const rescheduledDate = isRescheduled ? new Date(date.rescheduleDate.newDate) : null;
    const isCancelled = date.cancelled;
    
    // Display rescheduled date if available, otherwise show original date
    const displayDate = isRescheduled ? rescheduledDate : new Date(date.date);

    return (
      <button
        key={date.id}
        className={`${styles['date-button']} 
          ${selectedDate === date.id ? styles['selected'] : ''}
          ${isCancelled ? styles['cancelled'] : ''}
          ${isRescheduled ? styles['rescheduled'] : ''}`}
        onClick={() => handleDateSelect(date)}
      >
        <div className={styles['date-content']}>
          <span className={styles['date-day']}>
            {formatDate(displayDate)}
          </span>
          {date.event?.venueDetails?.venueData && (
            <span className={styles['date-venue']}>
              {date.event.venueDetails.venueData.displayName}
            </span>
          )}
        </div>
        <div className={styles['status-badges']}>
          {isCancelled && (
            <span className={styles['cancelled-badge']}>Cancelled</span>
          )}
          {isRescheduled && (
            <span className={styles['rescheduled-badge']}>Rescheduled</span>
          )}
        </div>
        {/* {isRescheduled && (
          <span className={styles['original-date']}>
            Original: {formatDate(new Date(date.date))}
          </span>
        )} */}
      </button>
    );
  })}
</div>            
            <button 
              className={styles['scroll-button']} 
              onClick={() => handleScroll('right')}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>

        

          {/* Manager Section */}
          {manager && <MemberSection title="Manager" members={[manager]} pendingMembers={[]} />}

{/* Artists Section */}
{(artists.length > 0 || pendingArtists.length > 0) ? (
  <MemberSection 
  title="Artists" 
  members={artists} 
  pendingMembers={pendingArtists}
/>
) : (
  <div className={styles['empty-state']}>
    <FontAwesomeIcon icon={faUsers} className={styles['empty-icon']} />
    <p className={styles['empty-text']}>No Artist added for this date</p>
  </div>
)}

{/* Crew Section */}
{(crewMembers.length > 0 || pendingCrew.length > 0) ? (
  <MemberSection 
    title="Crew" 
    members={crewMembers} 
    pendingMembers={pendingCrew}
  />
) : (
  <div className={styles['empty-state']}>
    <FontAwesomeIcon icon={faUsers} className={styles['empty-icon']} />
    <p className={styles['empty-text']}>No crew members added for this date</p>
  </div>
)}
        </div>
      </div>

      {/* Delete Confirmation Modal */}
<DeleteConfirmationModal
  show={showDeleteModal}
  onConfirm={confirmDelete}
  onCancel={() => {
    setShowDeleteModal(false);
    setMemberToDelete(null);
  }}
  memberName={memberToDelete?.fullName}
/>

      <RightMenu />
      
    </div>
  );
};

export default Crew;