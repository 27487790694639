import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import BandModal from './BandModal';
import SearchBandsModal from './SearchBandsModal';
import styles from './AssociatedActsSection.module.css';

const AssociatedActsSection = ({ 
  associatedActs, 
  onAssociatedActAdd, 
  onAssociatedActRemove 
}) => {
  const [showBandModal, setShowBandModal] = useState(false);
  const [showSearchModal, setShowSearchModal] = useState(false);

  const handleSearchSelection = (selected) => {
    onAssociatedActAdd(selected);
    setShowSearchModal(false);
  };

  const handleRemoveAct = (actId) => {
    onAssociatedActRemove(actId);
  };

  return (
    <div className={styles.section}>
      <h3>Associated Acts</h3>
      
      <div className={styles.buttonContainer}>
        <button 
          className={styles.actionButton}
          onClick={() => setShowBandModal(true)}
        >
          <FontAwesomeIcon icon={faPlus} />
          Create Band Page
        </button>
        <button 
          className={styles.actionButton}
          onClick={() => setShowSearchModal(true)}
        >
          <FontAwesomeIcon icon={faPlus} />
          Search Acts
        </button>
      </div>

      {associatedActs?.length > 0 && (
        <div className={styles.actsList}>
          {associatedActs.map(act => (
            <div key={act.id} className={styles.actCard}>
              <img 
                src={act.photoUrl} 
                alt={act.name} 
                className={styles.actPhoto}
              />
              <div className={styles.actInfo}>
                <h4>{act.name}</h4>
                <p>@{act.username}</p>
                {act.subType && <p className={styles.subtype}>{act.subType}</p>}
                {act.genre && <p className={styles.genre}>{act.genre}</p>}
              </div>
              <button 
                className={styles.removeButton}
                onClick={() => handleRemoveAct(act.id)}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>
          ))}
        </div>
      )}

      {showBandModal && (
        <BandModal 
          isOpen={showBandModal}
          onClose={() => setShowBandModal(false)}
        />
      )}

      {showSearchModal && (
        <SearchBandsModal
          isOpen={showSearchModal}
          onClose={() => setShowSearchModal(false)}
          onSelect={handleSearchSelection}
        />
      )}
    </div>
  );
};

export default AssociatedActsSection;