import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs, doc, setDoc, updateDoc, arrayUnion, serverTimestamp, addDoc,   getDoc,  deleteDoc, } from 'firebase/firestore';
import { getStorage, ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import UserContext from '../../context/UserContext';
import TourContext from '../../context/TourContext';
import TopBar from '../TopBar';
import LeftMenu from '../LeftMenu';
import RightMenu from '../RightMenu';
import { Calendar } from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faTrash, faCalendar, faMapMarkerAlt, faUsers, faCheck, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './CreateTour.module.css';
import defaultAvatar from '../../assets/Images/defaultUserProfilePicture.png';
import verifiedBadge from '../../assets/Images/profileverifiedtick.png';
import CreateTourMapSearch from './CreateTourMapSearch'
import { liteClient } from 'algoliasearch/lite';
import { sendTourInvitationNotification, sendTourRemovalNotification } from '../../utils/NotificationService';
import { incrementUserStats, decrementUserStats } from '../../utils/actions/userActions';
import defaultVenueImage from '../../assets/Images/defaulthotelpicture.png'

const STEPS = {
  TOUR_DETAILS: 1,
  SELECT_DATES: 2,
  VENUE_DETAILS: 3,
  ARTIST_SELECTION: 4,
  CREW_SELECTION: 5
};

const CreateTour = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const tourData = location.state?.tourData;
  const isEditing = !!tourData;
  const { userData, setUserData } = useContext(UserContext);
  const { fetchToursData, setSelectedTour, setTours, tours, } = useContext(TourContext);
  const [currentStep, setCurrentStep] = useState(STEPS.TOUR_DETAILS);
  const [loading, setLoading] = useState(false);
  
  // Tour details states
  const [tourCoverPhotoUrl, setTourCoverPhotoUrl] = useState('');
  const [tourName, setTourName] = useState('');
  const [actName, setActName] = useState('');
  const [actType, setActType] = useState('');
  const [tourCoverPhoto, setTourCoverPhoto] = useState(null);
  const [coverPhotoPreview, setCoverPhotoPreview] = useState(null);
  const [selectedDates, setSelectedDates] = useState([]);
  
  // Venue states
  const [venueResults, setVenueResults] = useState({});
  const [venueDetails, setVenueDetails] = useState({});
  const [searchingVenue, setSearchingVenue] = useState(false);
  const [selectedDateIndex, setSelectedDateIndex] = useState(0);
  const [venueSearchQuery, setVenueSearchQuery] = useState('');
  const [showMapSearch, setShowMapSearch] = useState(false);


  // Artist states
  const [selectedArtists, setSelectedArtists] = useState([]);
  const [artistResults, setArtistResults] = useState([]);
  const [searchingArtists, setSearchingArtists] = useState(false);
  const [artistDateAssignments, setArtistDateAssignments] = useState({});
  const [searchTermArtist, setSearchTermArtist] = useState('');
  const [artistSearchQuery, setArtistSearchQuery] = useState('');
  const [showArtistModal, setShowArtistModal] = useState(false);

  // Crew states
  const [selectedCrew, setSelectedCrew] = useState([]);
  const [crewResults, setCrewResults] = useState([]);
  const [searchingCrew, setSearchingCrew] = useState(false);
  const [crewDateAssignments, setCrewDateAssignments] = useState({});
  const [crewSearchQuery, setCrewSearchQuery] = useState('');

const [searchTermCrew, setSearchTermCrew] = useState('');
const [showCrewModal, setShowCrewModal] = useState(false);

const [pendingInvitations, setPendingInvitations] = useState([]);
const db = getFirestore();

const [showVenueWarningModal, setShowVenueWarningModal] = useState(false);
const [isSearchButtonPressed, setIsSearchButtonPressed] = useState(false);

// Add state for tracking existing assignments
const [existingArtistAssignments, setExistingArtistAssignments] = useState({});
const [existingCrewAssignments, setExistingCrewAssignments] = useState({});
const [loadingMessage, setLoadingMessage] = useState('');

const [confirmedDatesByUser, setConfirmedDatesByUser] = useState({});
const [pendingDatesByUser, setPendingDatesByUser] = useState({});
const [removedConfirmedDates, setRemovedConfirmedDates] = useState({});
const [unselectedConfirmedDates, setUnselectedConfirmedDates] = useState({});

// Initialize Algolia (put this with other initialization code)


const client = liteClient("6DJNAZSX4P", "342932cbe26f7751ec87fa2b1ff32e4c");


useEffect(() => {
  document.title = 'Create Tour | On Tour';
  
  // Optional: Reset title when component unmounts
  return () => {
    document.title = 'On Tour';
  };
}, []); // Empty dependency array means this runs once when component mounts


  // Check tour limit on component mount
  useEffect(() => {
    if (!checkTourLimit()) {
      alert('You have reached the maximum number of tours for your membership tier. Please upgrade to create more tours.');
      navigate('/crew');
    }
  }, [userData, navigate]);

  const getPageTitle = () => isEditing ? 'Edit Tour' : 'Create Tour';
const getSubmitButtonText = () => {
  if (loading) return loadingMessage || 'Processing...';
  return isEditing ? 'Update Tour' : 'Create Tour';
};

  // Check tour creation limits based on membership tier
  const checkTourLimit = () => {
    if (!userData?.membershipTier) return false;
    
    const currentTourCount = userData.tourIds?.length || 0;
    switch (userData.membershipTier) {
      case 'Free':
        return currentTourCount < 1;
      case 'Basic':
        return currentTourCount < 10;
      case 'Pro':
        return currentTourCount < 15;
      case 'Elite':
        return true;
      default:
        return false;
    }
  };

  // File handling functions
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setTourCoverPhoto(file);
      const previewUrl = URL.createObjectURL(file);
      setCoverPhotoPreview(previewUrl);
    }
  };

  // Cleanup preview URL on unmount
  useEffect(() => {
    return () => {
      if (coverPhotoPreview) {
        URL.revokeObjectURL(coverPhotoPreview);
      }
    };
  }, [coverPhotoPreview]);



   // Update useEffect for handling date changes
   useEffect(() => {
    // Initialize venue results and details for new dates
    setVenueResults(prev => {
      const newResults = {};
      selectedDates.forEach(date => {
        const dateKey = date.getTime().toString();
        newResults[dateKey] = prev[dateKey] || [];
      });
      return newResults;
    });
  
    // Keep venue details only for selected dates
    setVenueDetails(prev => {
      const newVenueDetails = {};
      selectedDates.forEach(date => {
        const dateKey = date.getTime().toString();
        if (prev[dateKey]) {
          newVenueDetails[dateKey] = prev[dateKey];
        }
      });
      return newVenueDetails;
    });
  }, [selectedDates]);

   // Initialize form with existing data if editing
   useEffect(() => {
    if (tourData) {
      setTourName(tourData.tourName);
      setActName(tourData.actName);
      setActType(tourData.actType);
      setTourCoverPhotoUrl(tourData.tourCoverPhotoUrl);
      
      // Load selected dates
      const dates = tourData.tourDates.map(td => new Date(td.date));
      setSelectedDates(dates);
  
      // Initialize venue details
      const venueDetailsObj = {};
      tourData.tourDates.forEach(date => {
        // Create a Date object to get timestamp
        const dateObj = new Date(date.date);
        const dateKey = dateObj.getTime().toString();
        
        if (date.event?.venueDetails?.venueData) {
          venueDetailsObj[dateKey] = {
            ...date.event.venueDetails.venueData
          };
        }
      });
      setVenueDetails(venueDetailsObj);
  
      // Load artists and their assignments
      if (tourData.artistDetails) {
        setSelectedArtists(tourData.artistDetails);
        const artistAssignments = {};
        tourData.artistDetails.forEach(artist => {
          artistAssignments[artist.userId] = artist.assignedDates || [];
        });
        setArtistDateAssignments(artistAssignments);
        setExistingArtistAssignments(artistAssignments);
      }
  
      // Load crew and their assignments
      if (tourData.crewDetails) {
        setSelectedCrew(tourData.crewDetails);
        const crewAssignments = {};
        tourData.crewDetails.forEach(crew => {
          crewAssignments[crew.userId] = crew.assignedDates || [];
        });
        setCrewDateAssignments(crewAssignments);
        setExistingCrewAssignments(crewAssignments);
      }
    }
  }, [tourData]);

  useEffect(() => {
    if (tourData) {
      // Initialize confirmed dates
      const confirmedDates = {};
      if (tourData.artistDetails) {
        tourData.artistDetails.forEach(artist => {
          confirmedDates[artist.userId] = artist.assignedDates.map(d => new Date(d.date).toISOString());
        });
      }
      if (tourData.crewDetails) {
        tourData.crewDetails.forEach(crew => {
          confirmedDates[crew.userId] = crew.assignedDates.map(d => new Date(d.date).toISOString());
        });
      }
      setConfirmedDatesByUser(confirmedDates);
  
      // Initialize pending dates
      const pendingDates = {};
      if (tourData.pendingInvitationsData) {
        tourData.pendingInvitationsData.forEach(inv => {
          pendingDates[inv.userId] = inv.assignedDates.map(d => new Date(d.date).toISOString());
        });
      }
      setPendingDatesByUser(pendingDates);
    }
  }, [tourData]);

  // Date handling functions
  const getDateLimit = () => {
    switch (userData?.membershipTier) {
      case 'Free': return 5;
      case 'Basic': return 10;
      case 'Pro': return 15;
      case 'Elite': return Infinity;
      default: return 5;
    }
  };

  const handleDateClick = (date) => {
    const dateLimit = getDateLimit();
    
    console.log('Calendar clicked date:', date);
    
    // Create a new date in local timezone at start of day
    const normalizeDate = (d) => {
      // Extract year, month, day from the local date
      const year = d.getFullYear();
      const month = d.getMonth();
      const day = d.getDate();
      // Create new date with these components
      return new Date(year, month, day, 0, 0, 0, 0);
    };
    
    const clickedDate = normalizeDate(date);
    console.log('Normalized clicked date:', clickedDate);
    
    // Compare dates using their local components instead of timestamps
    const compareDates = (d1, d2) => {
      return d1.getFullYear() === d2.getFullYear() &&
             d1.getMonth() === d2.getMonth() &&
             d1.getDate() === d2.getDate();
    };
    
    setSelectedDates(prev => {
      // Check if date exists using local date comparison
      const dateExists = prev.some(d => compareDates(normalizeDate(d), clickedDate));
      
      console.log('Current selected dates:', prev.map(d => ({
        date: d,
        year: d.getFullYear(),
        month: d.getMonth() + 1,
        day: d.getDate()
      })));
      
      if (dateExists) {
        // Remove date using local date comparison
        return prev.filter(d => !compareDates(normalizeDate(d), clickedDate));
      } else {
        if (prev.length >= dateLimit) {
          alert(`Your ${userData.membershipTier} membership allows up to ${dateLimit} dates.`);
          return prev;
        }
        return [...prev, clickedDate];
      }
    });
  };

  
  // Add this component in CreateTour.js before the main CreateTour component
const VenueWarningModal = ({ show, onConfirm, onCancel }) => {
  if (!show) return null;

  return (
    <div className={styles.modalOverlay} onClick={onCancel}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <h3 className={styles.modalTitle}>Missing Venues</h3>
        <p className={styles.modalText}>
          You have not added venues for all tour dates. You can add them later if you wish.
        </p>
        <p className={styles.modalSubText}>
          Would you like to continue?
        </p>
        <div className={styles.modalActions}>
          <button className={styles.modalCancelButton} onClick={onCancel}>
            Go Back
          </button>
          <button className={styles.modalConfirmButton} onClick={onConfirm}>
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

// Add this function to check if all dates have venues
const allDatesHaveVenues = () => {
  return selectedDates.every(date => 
    venueDetails[date.getTime().toString()]
  );
};

  // Update the handleVenueSearch function
  const handleVenueSearch = async () => {
    if (!venueSearchQuery.trim()) return;
    
    const currentDate = selectedDates[selectedDateIndex];
    if (!currentDate) return;
    
    // Use timestamp as unique key
    const dateKey = currentDate.getTime().toString();
    
    try {
      setSearchingVenue(true);
      setIsSearchButtonPressed(true); // Set this when search begins
  
      const { results } = await client.search([{
        indexName: 'places_index',
        query: venueSearchQuery,
        hitsPerPage: 5
      }]);
  
      setVenueResults(prev => ({
        ...prev,
        [dateKey]: results[0].hits || []
      }));
    } catch (error) {
      console.error('Error searching venues:', error);
      setVenueResults(prev => ({
        ...prev,
        [dateKey]: []
      }));
    } finally {
      setSearchingVenue(false);
    }
  };
  
  // Update handleVenueSelect to use timestamp
 const handleVenueSelect = (venue, date) => {
  if (!date) return;
  
  const dateKey = date.getTime().toString();
  
  setVenueDetails(prev => ({
    ...prev,
    [dateKey]: {
      ...venue,
      id: venue.id || venue.placeId,
      displayName: venue.displayName || venue.name,
      formattedAddress: venue.formattedAddress || venue.address,
      dateTimestamp: dateKey
    }
  }));

  setVenueResults(prev => ({
    ...prev,
    [dateKey]: []
  }));
  
  setVenueSearchQuery(''); // Clear search query
  setIsSearchButtonPressed(false); // Reset search state
};

const handleVenueSearchKeyDown = (e) => {
  if (e.key === 'Enter') {
    handleVenueSearch();
  }
};

const handleArtistSearchKeyDown = (e) => {
  if (e.key === 'Enter') {
    handleArtistSearch();
  }
};

const handleCrewSearchKeyDown = (e) => {
  if (e.key === 'Enter') {
    handleCrewSearch();
  }
};

 // Update the venue details display in step 3
 const renderVenueDetails = () => {
  const currentDate = selectedDates[selectedDateIndex];
  if (!currentDate) return null;

  const dateKey = currentDate.getTime().toString();
  const venueForDate = venueDetails[dateKey];

  return venueForDate ? (
    <div className={styles.selectedVenue}>
      <div className={styles.selectedVenueContainer}>
        <img 
          src={venueForDate.placeCoverPhotoUrl || defaultVenueImage}
          alt={venueForDate.displayName}
          className={styles.selectedVenueImage}
        />
        <div className={styles.venueInfo}>
          <h4 className={styles.venueName}>{venueForDate.displayName}</h4>
          <p className={styles.venueAddress}>{venueForDate.formattedAddress}</p>
        </div>
      </div>
      
      <div className={styles.mapContainer}>
        <iframe
          title="Location Map"
          width="100%"
          height="100%"
          frameBorder="0"
          style={{ border: 0 }}
          referrerPolicy="no-referrer-when-downgrade"
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDX3zmGo2kfdaD1jmTOzjoCP4D2rfZ3c3A&q=${encodeURIComponent(venueForDate.formattedAddress)}&zoom=20&maptype=satellite&language=en`}
          allowFullScreen
        />
      </div>
    </div>
  ) : null;
};
  
  // Update venue display in date selector
  const renderDateSelector = () => {
    return (
      <div className={styles.dateSelectorContainer}>
        <div className={styles.datesScrollContainer}>
          {selectedDates.map((date, index) => {
            const dateKey = date.getTime().toString();
            const hasVenue = Boolean(venueDetails[dateKey]);
            
            return (
              <div 
                key={dateKey}
                className={`${styles.dateBox} ${selectedDateIndex === index ? styles.active : ''}`}
                onClick={() => setSelectedDateIndex(index)}
              >
                <div className={styles.dateBoxDay}>
                  {date.toLocaleDateString('en-US', {
                    weekday: 'short',
                    month: 'short',
                    day: 'numeric'
                  })}
                </div>
                <div className={styles.dateBoxVenue}>
                  {hasVenue ? venueDetails[dateKey].displayName : 'No venue selected'}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  
  // Add this function to render venue search results
  const renderVenueResults = () => {
    const currentDate = selectedDates[selectedDateIndex];
    if (!currentDate) return null;
    
    const dateKey = currentDate.getTime().toString();
    const currentResults = venueResults[dateKey] || [];
  
    if (searchingVenue) {
      return (
        <div className={styles.searchingMessage}>
          Searching...
        </div>
      );
    }
  
    if (currentResults.length > 0) {
      return (
        <div className={styles.venueResults}>
        {currentResults.map((venue, index) => (
          <div
            key={`venue-result-${venue.id}-${index}`}
            className={styles.venueItem}
            onClick={() => handleVenueSelect(venue, currentDate)}
          >
            <img 
              src={venue.placeCoverPhotoUrl || defaultVenueImage}
              alt={venue.displayName}
              className={styles.venueResultImage}
            />
            <div className={styles.venueInfoContainer}>
              <h4 className={styles.venueName}>{venue.displayName}</h4>
              <p className={styles.venueAddress}>{venue.formattedAddress}</p>
            </div>
          </div>
        ))}
      </div>
      );
    }
  
    // Only show no results message after search
    if (venueSearchQuery && isSearchButtonPressed) {
      return (
        <div className={styles.noVenueResults}>
          <p className={styles.noResultsText}>Couldn't find venue in our database</p>
          <button 
            className={styles.searchMapButton}
            onClick={() => setShowMapSearch(true)}
          >
            Search on Map
          </button>
        </div>
      );
    }
  
    return null;
  };

  const updateVenueForDate = (date, venueData) => {
    if (!date) return;
    
    const dateStr = date.toISOString().split('T')[0];
    
    // Only update venue details for the specific date
    setVenueDetails(prev => ({
      ...prev,
      [dateStr]: venueData
    }));
  
    setVenueResults(prev => ({
      ...prev,
      [dateStr]: []
    }));
  };


  // Add this helper function in your component
const isUserInvited = (userId) => {
    // Check if the user is in selectedArtists or selectedCrew
    const isSelected = selectedArtists.some(artist => artist.userId === userId) ||
                       selectedCrew.some(crew => crew.userId === userId);
    
    // Check if the user has a pending invitation
    const hasPendingInvitation = pendingInvitations.some(inv => inv.userId === userId);
    
    return isSelected || hasPendingInvitation;
  };

  // Artist handling functions
 // Update handleArtistSearch function
const handleArtistSearch = async () => {
    if (!artistSearchQuery.trim()) return;
    
    try {
      setSearchingArtists(true);
      const { results } = await client.search([{
        indexName: 'users_index',
        query: artistSearchQuery,
        filters: 'userType:Artist', // This was missing
        hitsPerPage: 10
      }]);
  
      const formattedArtists = results[0].hits.map(hit => ({
        id: hit.objectID,
        fullName: hit.fullName,
        email: hit.email,
        userName: hit.userName,
        userSubType: hit.userSubType,
        profilePictureUrl: hit.profilePictureUrl,
        userType: hit.userType,
        idVerificationStatus: hit.idVerificationStatus,
        isPending: pendingInvitations.some(inv => inv.userId === hit.objectID && inv.role === 'Artist'),
        isAssigned: selectedArtists.some(artist => artist.userId === hit.objectID)
      }));
      
      setArtistResults(formattedArtists);
    } catch (error) {
      console.error('Error searching artists:', error);
      setArtistResults([]);
    } finally {
      setSearchingArtists(false);
    }
  };
  

  // Update handleCrewSearch function
  const handleCrewSearch = async () => {
    if (!crewSearchQuery.trim()) return;
    
    try {
      setSearchingCrew(true);
      const { results } = await client.search([{
        indexName: 'users_index',
        query: crewSearchQuery,
        filters: 'userType:Crew', // This was missing
        hitsPerPage: 10
      }]);
  
      const formattedCrew = results[0].hits.map(hit => ({
        id: hit.objectID,
        fullName: hit.fullName,
        email: hit.email,
        userName: hit.userName,
        userSubType: hit.userSubType,
        profilePictureUrl: hit.profilePictureUrl,
        userType: hit.userType,
        idVerificationStatus: hit.idVerificationStatus,
        isPending: pendingInvitations.some(inv => inv.userId === hit.objectID && inv.role === 'Crew'),
        isAssigned: selectedCrew.some(crew => crew.userId === hit.objectID)
      }));
      
      setCrewResults(formattedCrew);
    } catch (error) {
      console.error('Error searching crew:', error);
      setCrewResults([]);
    } finally {
      setSearchingCrew(false);
    }
  };

  const handleAddArtist = (artist) => {
    if (!isUserInvited(artist.id)) {
      setSelectedArtists(prevSelectedArtists => [...prevSelectedArtists, {
        userId: artist.id, // This should match what we use in date assignments
        name: artist.fullName,
        profilePictureUrl: artist.profilePictureUrl,
        userSubType: artist.userSubType,
        email: artist.email,
        userName: artist.userName,
        idVerificationStatus: artist.idVerificationStatus,
      }]);
      
      // Initialize empty date assignments for this artist
      setArtistDateAssignments(prevAssignments => ({
        ...prevAssignments,
        [artist.id]: [] // Use the same ID here
      }));
    }
    setArtistSearchQuery('');
    setArtistResults([]);
  };
  
  const handleAddCrew = (crew) => {
    if (!isUserInvited(crew.id)) {
      setSelectedCrew(prevSelectedCrew => [...prevSelectedCrew, {
        userId: crew.id, // This should match what we use in date assignments
        name: crew.fullName,
        profilePictureUrl: crew.profilePictureUrl,
        userSubType: crew.userSubType,
        email: crew.email,
        userName: crew.userName,
        idVerificationStatus: crew.idVerificationStatus,
      }]);
      
      // Initialize empty date assignments for this crew member
      setCrewDateAssignments(prevAssignments => ({
        ...prevAssignments,
        [crew.id]: [] // Use the same ID here
      }));
    }
    setCrewSearchQuery('');
    setCrewResults([]);
  };
  
  const handleRemoveArtist = (userId) => {
    setSelectedArtists(prev => prev.filter(artist => artist.userId !== userId));
    setArtistDateAssignments(prev => {
      const newAssignments = { ...prev };
      delete newAssignments[userId];
      return newAssignments;
    });
  };
  
  const handleRemoveCrew = (userId) => {
    setSelectedCrew(prev => prev.filter(crew => crew.userId !== userId));
    setCrewDateAssignments(prev => {
      const newAssignments = { ...prev };
      delete newAssignments[userId];
      return newAssignments;
    });
  };
  
  


  const handleSelectAllDates = (userId, isArtist = true) => {
    const allDates = selectedDates.map(date => date.toISOString());
    const confirmedDates = confirmedDatesByUser[userId] || [];
    const pendingDates = pendingDatesByUser[userId] || [];
    
    // Filter out pending dates as they can't be modified
    const availableDates = allDates.filter(date => !pendingDates.includes(date));
    
    if (isArtist) {
      setArtistDateAssignments(prev => {
        const currentAssignments = prev[userId] || [];
        const shouldSelectAll = currentAssignments.length !== availableDates.length;
        
        if (shouldSelectAll) {
          // Add all available dates
          return { ...prev, [userId]: availableDates };
        } else {
          // Remove all except pending dates
          return { ...prev, [userId]: [] };
        }
      });
    } else {
      // Same logic for crew
      setCrewDateAssignments(prev => {
        const currentAssignments = prev[userId] || [];
        const shouldSelectAll = currentAssignments.length !== availableDates.length;
        
        if (shouldSelectAll) {
          return { ...prev, [userId]: availableDates };
        } else {
          return { ...prev, [userId]: [] };
        }
      });
    }
  };


  
  // Modified handler for date selection
  const handleDateSelection = (userId, date, isArtist = true) => {
    const dateString = date.toISOString();
    const userDates = isArtist ? artistDateAssignments : crewDateAssignments;
    const setUserDates = isArtist ? setArtistDateAssignments : setCrewDateAssignments;
    const confirmedDates = confirmedDatesByUser[userId] || [];
    const pendingDates = pendingDatesByUser[userId] || [];
    const userUnselectedConfirmed = unselectedConfirmedDates[userId] || [];
  
    // Don't allow changes to pending dates
    if (pendingDates.includes(dateString)) {
      return;
    }
  
    // Handle confirmed dates
    if (confirmedDates.includes(dateString)) {
      const wasUnselected = unselectedConfirmedDates[userId]?.includes(dateString);
      
      if (!wasUnselected) {
        // First time unselecting a confirmed date
        setUnselectedConfirmedDates(prev => ({
          ...prev,
          [userId]: [...(prev[userId] || []), dateString]
        }));
        setRemovedConfirmedDates(prev => ({
          ...prev,
          [userId]: [...(prev[userId] || []), dateString]
        }));
      } else {
        // Reselecting a previously unselected confirmed date
        setUnselectedConfirmedDates(prev => ({
          ...prev,
          [userId]: prev[userId].filter(d => d !== dateString)
        }));
        setRemovedConfirmedDates(prev => ({
          ...prev,
          [userId]: prev[userId].filter(d => d !== dateString)
        }));
      }
    } else {
      // Handle regular date selection
      setUserDates(prev => {
        const currentDates = prev[userId] || [];
        return {
          ...prev,
          [userId]: currentDates.includes(dateString)
            ? currentDates.filter(d => d !== dateString)
            : [...currentDates, dateString]
        };
      });
    }
  };

  const renderMemberCard = (member, isArtist = true) => {
  const userId = member.userId;
  const confirmedDates = confirmedDatesByUser[userId] || [];
  const pendingDates = pendingDatesByUser[userId] || [];
  const selectedDatesForUser = isArtist 
    ? artistDateAssignments[userId] || []
    : crewDateAssignments[userId] || [];
  const userUnselectedConfirmed = unselectedConfirmedDates[userId] || [];

  return (
    <div key={userId} className={styles.selectedMemberCard}>
      <img
        src={member.profilePictureUrl || defaultAvatar}
        alt={member.fullName}
        className={styles.memberPhoto}
      />
      <div className={styles.memberInfo}>
        <div className={styles.memberNameContainer}>
          <span className={styles.memberName}>{member.fullName}</span>
          {member.idVerificationStatus === 'Verified' && (
            <img src={verifiedBadge} alt="Verified" className={styles.verifiedBadge} />
          )}
        </div>
        <span className={styles.userName}>@{member.userName}</span>
        <span className={styles.userSubType}>{member.userSubType}</span>
      </div>
      
      <div className={styles.dateSelectionContainer}>
        <button 
          className={styles.selectAllButton}
          onClick={() => handleSelectAllDates(userId, isArtist)}
        >
          Select All Available
        </button>
        <div className={styles.datesScrollWrapper}>
          <div className={styles.datesScrollContainer}>
            {selectedDates.map(date => {
              const dateString = date.toISOString();
              const isConfirmed = confirmedDates.includes(dateString);
              const isPending = pendingDates.includes(dateString);
              const isSelected = selectedDatesForUser.includes(dateString);
              const wasUnselected = userUnselectedConfirmed.includes(dateString);
              
              let dateClassName = styles.dateCard;
// Show confirmed styling if it's confirmed and not unselected
if (isConfirmed && !userUnselectedConfirmed.includes(dateString)) {
  dateClassName += ' ' + styles.confirmedDate;
}
if (isPending) dateClassName += ' ' + styles.pendingDate;
if (isSelected && !isConfirmed) dateClassName += ' ' + styles.selected;

              return (
                <div
                  key={dateString}
                  className={dateClassName}
                  onClick={() => !isPending && handleDateSelection(userId, date, isArtist)}
                >
                  <div className={styles.dateCardDay}>
                    {date.toLocaleDateString('en-GB', { weekday: 'short' })}
                  </div>
                  <div className={styles.dateCardDate}>
                    {date.toLocaleDateString('en-GB', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric'
                    })}
                  </div>
                  {isConfirmed && (!wasUnselected || isSelected) && (
                    <div className={styles.dateStatus}>Confirmed</div>
                  )}
                  {isPending && <div className={styles.dateStatus}>Pending</div>}
                </div>
              );
            })}
          </div>
        </div>
      </div>

      {!confirmedDates.length && !pendingDates.length && (
        <button
          onClick={() => isArtist ? handleRemoveArtist(userId) : handleRemoveCrew(userId)}
          className={styles.removeMemberButton}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}
    </div>
  );
};
  
const handleSubmit = async () => {
  try {
    setLoading(true);
    setLoadingMessage('Updating tour details...');

    // Log selected dates
    console.log('Selected dates before tour operation:');
    selectedDates.forEach((date, index) => {
      console.log(`Date ${index + 1}:`, {
        original: date,
        iso: date.toISOString(),
        local: date.toLocaleString(),
        timezoneOffset: date.getTimezoneOffset()
      });
    });

    const db = getFirestore();
    const storage = getStorage();

    // Determine if we're editing or creating
    const isEditing = !!location.state?.tourData;
    let tourId = isEditing ? location.state.tourData.id : doc(collection(db, 'Tours')).id;
    let tourRef = doc(db, 'Tours', tourId);

    // Handle tour cover photo
    let tourCoverPhotoUrl = '';
    if (tourCoverPhoto) {
      const storageRef = ref(storage, `Tours/${tourId}/tourCoverPhoto/cover.jpg`);
      const uploadTask = uploadBytesResumable(storageRef, tourCoverPhoto);
      const snapshot = await uploadTask;
      tourCoverPhotoUrl = await getDownloadURL(snapshot.ref);
    }

    // Keep track of pending invitations
    const pendingInvitations = [];

    // If editing, get existing data
    let existingTourData = null;
    if (isEditing) {
      const tourSnapshot = await getDoc(tourRef);
      if (tourSnapshot.exists()) {
        existingTourData = tourSnapshot.data();
      }
    }

    setLoadingMessage('Processing artist assignments...');

    // Process artists
    for (const artist of selectedArtists) {
      const userId = artist.userId;
      const selectedDates = artistDateAssignments[userId] || [];
      const confirmedDates = confirmedDatesByUser[userId] || [];
      const pendingDates = pendingDatesByUser[userId] || [];
      const unselectedConfirmedDates = removedConfirmedDates[userId] || [];

      // Handle removed confirmed dates
      if (unselectedConfirmedDates.length > 0) {
        // Create notification for removed dates
        const notification = {
          userId,
          tourId,
          tourName,
          dates: unselectedConfirmedDates,
          type: 'REMOVED_FROM_DATES',
          message: `You have been removed from ${unselectedConfirmedDates.length} date(s) in the tour "${tourName}"`
        };
        await sendTourRemovalNotification(userId, notification);

        // Update existing artist details to remove these dates
        if (isEditing && existingTourData?.artistDetails) {
          const artistIndex = existingTourData.artistDetails.findIndex(a => a.userId === userId);
          if (artistIndex !== -1) {
            existingTourData.artistDetails[artistIndex].assignedDates = 
              existingTourData.artistDetails[artistIndex].assignedDates.filter(
                d => !unselectedConfirmedDates.includes(d.date)
              );
          }
        }
      }

      // Filter out dates that are already confirmed or pending
      const newDates = selectedDates.filter(date => 
        !confirmedDates.includes(date) && 
        !pendingDates.includes(date)
      );

      if (newDates.length > 0) {
        // Create invitation only for new dates
        const mappedDates = newDates.map(dateStr => ({
          date: dateStr,
          tourDateDocId: `date${selectedDates.findIndex(d => d.toISOString() === dateStr) + 1}`
        }));

        const invitationData = {
          tourId,
          tourName,
          actName,
          userId: artist.userId,
          userName: artist.userName,
          fullName: artist.fullName,
          email: artist.email,
          profilePictureUrl: artist.profilePictureUrl,
          userType: 'Artist',
          userSubType: artist.userSubType,
          role: 'Artist',
          read: false,
          pendingTimestamp: new Date().toISOString(),
          timestamp: new Date().toISOString(),
          assignedDates: mappedDates,
          status: 'pending',
          createdBy: {
            userId: userData.userId,
            fullName: userData.fullName,
            userName: userData.userName,
            email: userData.email,
            profilePictureUrl: userData.profilePictureUrl,
            userType: userData.userType,
            userSubType: userData.userSubType
          }
        };

        const invitationRef = await addDoc(collection(db, 'TourInvitations'), invitationData);
        pendingInvitations.push(invitationRef.id);

        await sendTourInvitationNotification(artist.userId, {
          ...invitationData,
          invitationId: invitationRef.id
        });
      }
    }

    setLoadingMessage('Processing crew assignments...');

    // Process crew members
    for (const crew of selectedCrew) {
      const userId = crew.userId;
      const selectedDates = crewDateAssignments[userId] || [];
      const confirmedDates = confirmedDatesByUser[userId] || [];
      const pendingDates = pendingDatesByUser[userId] || [];
      const unselectedConfirmedDates = removedConfirmedDates[userId] || [];

      // Handle removed confirmed dates
      if (unselectedConfirmedDates.length > 0) {
        const notification = {
          userId,
          tourId,
          tourName,
          dates: unselectedConfirmedDates,
          type: 'REMOVED_FROM_DATES',
          message: `You have been removed from ${unselectedConfirmedDates.length} date(s) in the tour "${tourName}"`
        };
        await sendTourRemovalNotification(userId, notification);

        // Update existing crew details to remove these dates
        if (isEditing && existingTourData?.crewDetails) {
          const crewIndex = existingTourData.crewDetails.findIndex(c => c.userId === userId);
          if (crewIndex !== -1) {
            existingTourData.crewDetails[crewIndex].assignedDates = 
              existingTourData.crewDetails[crewIndex].assignedDates.filter(
                d => !unselectedConfirmedDates.includes(d.date)
              );
          }
        }
      }

      // Filter out dates that are already confirmed or pending
      const newDates = selectedDates.filter(date => 
        !confirmedDates.includes(date) && 
        !pendingDates.includes(date)
      );

      if (newDates.length > 0) {
        // Create invitation only for new dates
        const mappedDates = newDates.map(dateStr => ({
          date: dateStr,
          tourDateDocId: `date${selectedDates.findIndex(d => d.toISOString() === dateStr) + 1}`
        }));

        const invitationData = {
          tourId,
          tourName,
          actName,
          userId: crew.userId,
          userName: crew.userName,
          fullName: crew.fullName,
          email: crew.email,
          profilePictureUrl: crew.profilePictureUrl,
          userType: 'Crew',
          userSubType: crew.userSubType,
          role: 'Crew',
          read: false,
          pendingTimestamp: new Date().toISOString(),
          timestamp: new Date().toISOString(),
          assignedDates: mappedDates,
          status: 'pending',
          createdBy: {
            userId: userData.userId,
            fullName: userData.fullName,
            userName: userData.userName,
            email: userData.email,
            profilePictureUrl: userData.profilePictureUrl,
            userType: userData.userType,
            userSubType: userData.userSubType
          }
        };

        const invitationRef = await addDoc(collection(db, 'TourInvitations'), invitationData);
        pendingInvitations.push(invitationRef.id);

        await sendTourInvitationNotification(crew.userId, {
          ...invitationData,
          invitationId: invitationRef.id
        });
      }
    }

    setLoadingMessage('Updating tour data...');
    
    // Prepare tour document data
    const tourData = {
      tourName,
      actName,
      actType,
      ...(tourCoverPhotoUrl && { tourCoverPhotoUrl }),
      lastUpdated: serverTimestamp()
    };

    // Add creation data only for new tours
    if (!isEditing) {
      tourData.createdBy = {
        userId: userData.userId,
        fullName: userData.fullName,
        profilePictureUrl: userData.profilePictureUrl,
        email: userData.email,
        userName: userData.userName,
        userType: userData.userType,
        userSubType: userData.userSubType
      };
      tourData.artistDetails = [];
      tourData.crewDetails = [];
      tourData.createdAt = serverTimestamp();
    }

    // If editing, merge with existing pending invitations
    if (isEditing && existingTourData?.pendingInvitations) {
      tourData.pendingInvitations = [
        ...existingTourData.pendingInvitations,
        ...pendingInvitations
      ];
    } else {
      tourData.pendingInvitations = pendingInvitations;
    }

    // Handle tour dates
    // First, delete any removed dates if editing
    if (isEditing) {
      const tourDatesRef = collection(db, 'Tours', tourId, 'tourDates');
      const existingDatesSnapshot = await getDocs(tourDatesRef);
      const existingDates = existingDatesSnapshot.docs.map(doc => doc.data().date);
      
      // Convert selected dates to same format as stored dates
      const formattedSelectedDates = selectedDates.map(date => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      });

      // Delete dates that are no longer selected
      for (const doc of existingDatesSnapshot.docs) {
        const storedDate = doc.data().date;
        if (!formattedSelectedDates.includes(storedDate)) {
          await deleteDoc(doc.ref);
        }
      }
    }

    setLoadingMessage('Creating tour dates...');

    // Create or update tour dates
    for (let i = 0; i < selectedDates.length; i++) {
      const dateId = `date${i + 1}`;
      const dateRef = doc(collection(db, 'Tours', tourId, 'tourDates'), dateId);
      const date = selectedDates[i];
      
      // Format date as YYYY-MM-DD using local components
      const formatLocalDate = (d) => {
        const year = d.getFullYear();
        const month = String(d.getMonth() + 1).padStart(2, '0');
        const day = String(d.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
    
      const dateKey = date.getTime().toString();
      const venueDetailForDate = venueDetails[dateKey];
    
      await setDoc(dateRef, {
        date: formatLocalDate(date),
        tourDateDocId: dateId,
        event: {
          venueDetails: {
            venueData: venueDetailForDate ? {
              id: venueDetailForDate.id || '',
              name: venueDetailForDate.name || '',
              displayName: venueDetailForDate.displayName || '',
              formattedAddress: venueDetailForDate.formattedAddress || '',
              placeCoverPhotoUrl: venueDetailForDate.placeCoverPhotoUrl || '',
              shortFormattedAddress: venueDetailForDate.shortFormattedAddress || '',
              adrFormatAddress: venueDetailForDate.adrFormatAddress || '',
              addressComponents: venueDetailForDate.addressComponents || '',
              location: venueDetailForDate.location || '',
              plusCode: venueDetailForDate.plusCode || '',
              types: venueDetailForDate.types || [],
              viewport: venueDetailForDate.viewport || null,
              accessibilityOptions: venueDetailForDate.accessibilityOptions || {},
              businessStatus: venueDetailForDate.businessStatus || '',
              googleMapsUri: venueDetailForDate.googleMapsUri || '',
              iconBackgroundColor: venueDetailForDate.iconBackgroundColor || '',
              iconMaskBaseUri: venueDetailForDate.iconMaskBaseUri || '',
              primaryType: venueDetailForDate.primaryType || '',
              primaryTypeDisplayName: venueDetailForDate.primaryTypeDisplayName || '',
              subDestinations: venueDetailForDate.subDestinations || '',
              utcOffsetMinutes: venueDetailForDate.utcOffsetMinutes || '',
              currentOpeningHours: venueDetailForDate.currentOpeningHours || '',
              currentSecondaryOpeningHours: venueDetailForDate.currentSecondaryOpeningHours || '',
              internationalPhoneNumber: venueDetailForDate.internationalPhoneNumber || '',
              nationalPhoneNumber: venueDetailForDate.nationalPhoneNumber || '',
              priceLevel: venueDetailForDate.priceLevel || '',
              rating: venueDetailForDate.rating || '',
              regularOpeningHours: venueDetailForDate.regularOpeningHours || '',
              regularSecondaryOpeningHours: venueDetailForDate.regularSecondaryOpeningHours || '',
              userRatingCount: venueDetailForDate.userRatingCount || '',
              websiteUri: venueDetailForDate.websiteUri || '',
            } : {}
          }
        }
      });
    }

    // Create/Update the main tour document
    if (isEditing) {
      await updateDoc(tourRef, tourData);
    } else {
      await setDoc(tourRef, tourData);

      // Update user's tourIds only for new tours
      const userRef = doc(db, 'users', userData.userId);
      await updateDoc(userRef, {
        tourIds: arrayUnion(tourId)
      });

      // Update user's stats only for new tours
      await incrementUserStats(userData.userId, {
        tourCount: 1,
        showCount: selectedDates.length
      });

      // Update the user context with new counts
      setUserData(prevUserData => ({
        ...prevUserData,
        toursCount: (prevUserData.toursCount || 0) + 1,
        showsCount: (prevUserData.showsCount || 0) + selectedDates.length
      }));
    }

    // Fetch the updated tour data
    const updatedTourDatesSnapshot = await getDocs(collection(db, 'Tours', tourId, 'tourDates'));
    const updatedTourDates = updatedTourDatesSnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data()
    }));

    // Create the complete updated tour object
    const updatedTour = {
      id: tourId,
      ...tourData,
      tourDates: updatedTourDates,
      // If editing, maintain existing artist/crew details but update with removed dates
      ...(isEditing && existingTourData ? {
        artistDetails: (existingTourData.artistDetails || []).map(artist => {
          const unselectedDates = removedConfirmedDates[artist.userId] || [];
          return {
            ...artist,
            assignedDates: artist.assignedDates.filter(
              date => !unselectedDates.includes(date.date)
            )
          };
        }),
        crewDetails: (existingTourData.crewDetails || []).map(crew => {
          const unselectedDates = removedConfirmedDates[crew.userId] || [];
          return {
            ...crew,
            assignedDates: crew.assignedDates.filter(
              date => !unselectedDates.includes(date.date)
            )
          };
        })
      } : {
        artistDetails: [],
        crewDetails: []
      })
    };

    // Update tours array
    if (isEditing) {
      setTours(prevTours => 
        prevTours.map(tour => 
          tour.id === tourId ? updatedTour : tour
        )
      );
    } else {
      setTours(prevTours => [...prevTours, updatedTour]);
    }

    // Update selected tour
    setSelectedTour(updatedTour);

    setLoadingMessage('Almost done...');

    // Add timeout before navigation
    await new Promise(resolve => setTimeout(resolve, 1000));

    // Get the first tour date from updatedTourDates
    const firstTourDate = updatedTourDates[0];
    
    if (firstTourDate) {
      // Add timeout before navigation
      await new Promise(resolve => setTimeout(resolve, 1500));
      navigate(`/${tourId}/${firstTourDate.id}`);
    }

  } catch (error) {
    console.error('Error in tour operation:', error);
    alert(`Failed to ${isEditing ? 'update' : 'create'} tour. Please try again.`);
  } finally {
    setLoading(false);
    setLoadingMessage('');
  }
};

    
  const LoadingOverlay = () => (
  <div className={styles.loadingOverlay}>
    <div className={styles.loadingContent}>
      <div className={styles.loadingSpinner} />
      <p className={styles.loadingMessage}>{loadingMessage || 'Processing...'}</p>
    </div>
  </div>
);

// Helper function to compare sets (used for date comparison)
const setsAreEqual = (set1, set2) => {
  if (set1.size !== set2.size) return false;
  for (const item of set1) {
    if (!set2.has(item)) return false;
  }
  return true;
};


  // Form validation
  const isStep1Valid = tourName && actName && actType;
  const isStep2Valid = selectedDates.length > 0;



  // Helper function to get the current step status
  const getStepStatus = (step) => {
    if (step < currentStep) return 'completed';
    if (step === currentStep) return 'active';
    return 'pending';
  };

  const renderStepContent = () => {
    switch (currentStep) {
      case STEPS.TOUR_DETAILS:
        return (
          <div className={styles.formSection}>
            <h2 className={styles.sectionTitle}>Tour Details</h2>
            
            <div className={styles.coverPhotoContainer} onClick={() => document.getElementById('coverPhotoInput').click()}>
              {coverPhotoPreview ? (
                <>
                  <img src={coverPhotoPreview} alt="Tour cover" className={styles.coverPhotoPreview} />
                  <div className={styles.uploadOverlay}>
                    <FontAwesomeIcon icon={faImage} size="2x" />
                    <span>Change Cover Photo</span>
                  </div>
                </>
              ) : (
                <div className={styles.uploadOverlay} style={{ opacity: 1 }}>
                  <FontAwesomeIcon icon={faImage} size="2x" />
                  <span>Upload Cover Photo</span>
                </div>
              )}
              <input
                id="coverPhotoInput"
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                className={styles.hiddenInput}
                style={{ display: 'none' }}
              />
            </div>
  
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Tour Name</label>
              <input
                type="text"
                value={tourName}
                onChange={(e) => setTourName(e.target.value)}
                className={styles.input}
                placeholder="Enter tour name"
              />
            </div>
  
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Act Name</label>
              <input
                type="text"
                value={actName}
                onChange={(e) => setActName(e.target.value)}
                className={styles.input}
                placeholder="Enter act name"
              />
            </div>
  
            <div className={styles.inputGroup}>
              <label className={styles.inputLabel}>Act Type</label>
              <select
                value={actType}
                onChange={(e) => setActType(e.target.value)}
                className={styles.select}
              >
                <option value="">Select act type</option>
                <option value="Band">Band</option>
                <option value="Solo">Solo</option>
              </select>
            </div>
          </div>
        );
  
      case STEPS.SELECT_DATES:
        return (
          <div className={styles.calendarContainer}>
            <div className={styles.membershipInfo}>
              {userData.membershipTier === 'Elite' ? (
                <p>Elite membership: No date limit</p>
              ) : (
                <p>{`${userData.membershipTier} membership: ${selectedDates.length}/${getDateLimit()} dates selected`}</p>
              )}
            </div>
  
            <div className={styles.calendarWrapper}>
            <Calendar
  onChange={handleDateClick}
  value={selectedDates}
  selectRange={false}
  tileClassName={({ date }) => {
    // Compare dates using local components
    return selectedDates.some(d => 
      d.getFullYear() === date.getFullYear() &&
      d.getMonth() === date.getMonth() &&
      d.getDate() === date.getDate()
    ) ? styles.selectedDate : null
  }}
  minDate={new Date()}
/>
            </div>
  
            {selectedDates.length > 0 && (
  <div className={styles.selectedDates}>
    <h3 className={styles.selectedDatesTitle}>Selected Dates:</h3>
    {selectedDates
      .sort((a, b) => a - b) // Sort dates chronologically
      .map((date) => (
        <div 
          key={`selected-${date.getTime()}`} // Use timestamp as unique key
          className={styles.dateItem}
        >
          <span className={styles.dateText}>
            {date.toLocaleDateString('en-US', {
              weekday: 'short',
              year: 'numeric',
              month: 'short',
              day: 'numeric'
            })}
          </span>
          <button
            onClick={() => handleDateClick(date)}
            className={styles.removeDate}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ))}
  </div>
)}
          </div>
        );
  
        case STEPS.VENUE_DETAILS:
  return (
    <div className={styles.formSection}>
      <h2 className={styles.sectionTitle}>Venue Details</h2>
      
      {renderDateSelector()}
      
      {selectedDates[selectedDateIndex] && (
        <div className={styles.venueSection}>
          <h3 className={styles.venueDateTitle}>
            {selectedDates[selectedDateIndex].toLocaleDateString('en-US', {
              weekday: 'long',
              year: 'numeric',
              month: 'long',
              day: 'numeric'
            })}
          </h3>

          <div className={styles.venueSearchContainer}>
            <div className={styles.searchInputContainer}>
            <input
  type="text"
  placeholder="Search venue"
  className={styles.venueSearchInput}
  value={venueSearchQuery}
  onChange={(e) => setVenueSearchQuery(e.target.value)}
  onKeyDown={handleVenueSearchKeyDown}
/>
              <button 
                className={styles.searchButton}
                onClick={handleVenueSearch}
                disabled={!venueSearchQuery.trim()}
              >
                <FontAwesomeIcon icon={faSearch} />
                Search
              </button>
            </div>

            {/* Venue results */}
            {renderVenueResults()}
          </div>

          {/* Selected venue display */}
          {renderVenueDetails()}
        </div>
      )}

      {/* Map Search Modal */}
      <CreateTourMapSearch
        show={showMapSearch}
        onHide={() => setShowMapSearch(false)}
        onSelect={(venue) => {
          handleVenueSelect(venue, selectedDates[selectedDateIndex]);
          setShowMapSearch(false);
        }}
        db={db}
      />
    </div>
  );
          
  
// Inside the renderStepContent function:

case STEPS.ARTIST_SELECTION:
  return (
    <div className={styles.formSection}>
      <h2 className={styles.sectionTitle}>Add Artists</h2>
      
      {/* Search Section */}
      <div className={styles.searchSection}>
        <div className={styles.searchBar}>
          <input
            type="text"
            placeholder="Search artists by name..."
            value={artistSearchQuery}
            onChange={(e) => setArtistSearchQuery(e.target.value)}
            onKeyDown={handleArtistSearchKeyDown}
            className={styles.searchInput}
          />
          <button
            className={styles.searchButton}
            onClick={handleArtistSearch}
            disabled={!artistSearchQuery.trim() || searchingArtists}
          >
            <FontAwesomeIcon icon={faSearch} />
            {searchingArtists ? 'Searching...' : 'Search'}
          </button>
        </div>
        
        {/* Search Results */}
        {searchingArtists ? (
          <div className={styles.noResults}>Searching...</div>
        ) : artistResults.length > 0 ? (
          <div className={styles.searchResults}>
            {artistResults.map((artist) => {
              const isAssigned = selectedArtists.some(a => a.userId === artist.id) ||
                               tourData?.artistDetails?.some(a => a.userId === artist.id) ||
                               tourData?.pendingInvitationsData?.some(inv => inv.userId === artist.id);
              
              return (
                <div
                  key={artist.id}
                  className={`${styles.searchResult} ${isAssigned && styles.disabledSearchResult}`}
                  onClick={() => !isAssigned && handleAddArtist(artist)}
                >
                  <img
                    src={artist.profilePictureUrl || defaultAvatar}
                    alt={artist.fullName}
                    className={styles.profilePhoto}
                  />
                  <div className={styles.userInfo}>
                    <div className={styles.nameContainer}>
                      <span className={styles.fullName}>{artist.fullName}</span>
                      {artist.idVerificationStatus === 'Verified' && (
                        <img src={verifiedBadge} alt="Verified" className={styles.verifiedBadge} />
                      )}
                    </div>
                    <span className={styles.userName}>@{artist.userName}</span>
                    <span className={styles.userSubType}>{artist.userSubType}</span>
                  </div>
                  {isAssigned && (
                    <span className={styles.alreadyAssignedText}>Already Added</span>
                  )}
                </div>
              );
            })}
          </div>
        ) : artistSearchQuery && !searchingArtists ? (
          <div className={styles.noResults}>No artists found</div>
        ) : null}
      </div>

      {/* Artists List */}
      <div className={styles.selectedMembersSection}>
        {[...selectedArtists, ...(tourData?.artistDetails || []), 
          ...(tourData?.pendingInvitationsData?.filter(inv => inv.role === 'Artist') || [])]
          .reduce((unique, artist) => {
            const exists = unique.find(a => a.userId === artist.userId);
            if (!exists) {
              unique.push(artist);
            }
            return unique;
          }, [])
          .map(artist => renderMemberCard(artist, true))}
      </div>
    </div>
  );

case STEPS.CREW_SELECTION:
  return (
    <div className={styles.formSection}>
      <h2 className={styles.sectionTitle}>Add Crew Members</h2>
      
      {/* Search Section */}
      <div className={styles.searchSection}>
        <div className={styles.searchBar}>
          <input
            type="text"
            placeholder="Search crew members by name..."
            value={crewSearchQuery}
            onChange={(e) => setCrewSearchQuery(e.target.value)}
            onKeyDown={handleCrewSearchKeyDown}
            className={styles.searchInput}
          />
          <button
            className={styles.searchButton}
            onClick={handleCrewSearch}
            disabled={!crewSearchQuery.trim() || searchingCrew}
          >
            <FontAwesomeIcon icon={faSearch} />
            {searchingCrew ? 'Searching...' : 'Search'}
          </button>
        </div>
        
        {/* Search Results */}
        {searchingCrew ? (
          <div className={styles.noResults}>
            Searching...
          </div>
        ) : crewResults.length > 0 ? (
          <div className={styles.searchResults}>
            {crewResults.map((crew) => (
              <div
                key={crew.id}
                className={`${styles.searchResult} ${(crew.isPending || crew.isAssigned) && styles.disabledSearchResult}`}
                onClick={() => !crew.isPending && !crew.isAssigned && handleAddCrew(crew)}
              >
                <img
                  src={crew.profilePictureUrl || defaultAvatar}
                  alt={crew.fullName}
                  className={styles.profilePhoto}
                />
                <div className={styles.userInfo}>
                  <div className={styles.nameContainer}>
                    <span className={styles.fullName}>{crew.fullName}</span>
                    {crew.idVerificationStatus === 'Verified' && (
                      <img src={verifiedBadge} alt="Verified" className={styles.verifiedBadge} />
                    )}
                  </div>
                  <span className={styles.userName}>@{crew.userName}</span>
                  <span className={styles.userSubType}>{crew.userSubType}</span>
                </div>
                {(crew.isPending || crew.isAssigned) && (
                  <span className={styles.alreadyAssignedText}>
                    {crew.isPending ? "Pending Invitation" : "Already Assigned"}
                  </span>
                )}
              </div>
            ))}
          </div>
        ) : crewSearchQuery && !searchingCrew ? (
          <div className={styles.noResults}>
            No crew members found
          </div>
        ) : null}
      </div>

      {/* Members Sections */}
      <div className={styles.selectedMembersSection}>
        {[...selectedCrew, ...(tourData?.crewDetails || []), 
          ...(tourData?.pendingInvitationsData?.filter(inv => inv.role === 'Crew') || [])]
          .reduce((unique, crew) => {
            const exists = unique.find(c => c.userId === crew.userId);
            if (!exists) {
              unique.push(crew);
            }
            return unique;
          }, [])
          .map(crew => renderMemberCard(crew, false))}
      </div>
    </div>
  );
  
  default:
  return null;
  }
  };

  
  return (
    <div className={styles.pageContainer}>
      <TopBar />
      <div className={styles.mainLayout}>
        <LeftMenu />
        <RightMenu />
        
        <div className={styles.content}>
          <div className={styles.container}>
            <h1 className={styles.title}>{getPageTitle()}</h1>
            
            <div className={styles.stepsContainer}>
              {Object.values(STEPS).map((step) => (
                <div key={step} className={styles.stepWrapper}>
                  <div className={`${styles.step} ${styles[getStepStatus(step)]}`}>
                    <FontAwesomeIcon 
                      icon={
                        step === STEPS.TOUR_DETAILS ? faImage :
                        step === STEPS.SELECT_DATES ? faCalendar :
                        step === STEPS.VENUE_DETAILS ? faMapMarkerAlt :
                        step === STEPS.ARTIST_SELECTION || step === STEPS.CREW_SELECTION ? faUsers :
                        faCheck
                      }
                    />
                  </div>
                  <div className={styles.stepLabel}>
                    {step === STEPS.TOUR_DETAILS ? 'Tour Details' :
                     step === STEPS.SELECT_DATES ? 'Select Dates' :
                     step === STEPS.VENUE_DETAILS ? 'Venue Details' :
                     step === STEPS.ARTIST_SELECTION ? 'Artists' :
                     'Crew'}
                  </div>
                </div>
              ))}
            </div>

            {renderStepContent()}

            <div className={styles.navigation}>
            {currentStep > STEPS.TOUR_DETAILS && (
              <button
                onClick={() => setCurrentStep(prev => prev - 1)}
                className={`${styles.navButton} ${styles.prevButton}`}
                disabled={loading}
              >
                Previous
              </button>
            )}
            
            {currentStep < STEPS.CREW_SELECTION ? (
              <button
                onClick={() => {
                  if (currentStep === STEPS.VENUE_DETAILS && !allDatesHaveVenues()) {
                    setShowVenueWarningModal(true);
                  } else {
                    setCurrentStep(prev => prev + 1);
                  }
                }}
                className={`${styles.navButton} ${styles.nextButton}`}
                disabled={
                  (currentStep === STEPS.TOUR_DETAILS && !isStep1Valid) ||
                  (currentStep === STEPS.SELECT_DATES && !isStep2Valid) ||
                  loading
                }
              >
                Next
              </button>
            ) : (
              <button
                onClick={handleSubmit}
                className={`${styles.navButton} ${styles.nextButton}`}
                disabled={loading}
              >
                {getSubmitButtonText()}
              </button>
            )}
          </div>


{/* Add the modal */}
<VenueWarningModal
  show={showVenueWarningModal}
  onConfirm={() => {
    setShowVenueWarningModal(false);
    setCurrentStep(prev => prev + 1);
  }}
  onCancel={() => setShowVenueWarningModal(false)}
/>

            
          {loading && <LoadingOverlay />}
        </div>
      </div>
    </div>
  </div>
);
};

export default CreateTour;