import React, { useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCalendarCheck,
  faCalendarWeek,
  faHotel,
  faMap,
  faSearch,
  faUsers,
  faTicketAlt,
  faCrown,
  faDownload,
  faMoneyBill,
  faGear,
} from '@fortawesome/free-solid-svg-icons';
import styles from './LeftMenu.module.css';
import UserContext from '../context/UserContext';
import TourContext from '../context/TourContext';

const LeftMenu = () => {
  const { userData } = useContext(UserContext);
  const { selectedTour, selectedDate, setCurrentSection } = useContext(TourContext);
  const navigate = useNavigate();
  const location = useLocation();

  const dateDependentSections = ['schedule', 'event', 'hotel', 'route'];

  const handleNavigate = (item) => {
    if (item.requiresDate && (!selectedTour || !selectedDate)) {
      alert('Please select a tour and date first');
      return;
    }
  
    if (item.section) {
      setCurrentSection(item.section);
    }
  
    // Special handling for schedule route
    if (item.path === '/schedule' && selectedTour && selectedDate) {
      navigate(`/schedule/${selectedTour.id}/${selectedDate}`);
      return;
    }
  
    // Only update URL if we're not already there
    if (location.pathname !== item.path) {
      navigate(item.path);
    }
  };

  const menuItems = [
   
    {
      label: 'Search',
      icon: faSearch,
      path: '/search',
      showWhen: () => true
    },
    {
      label: 'Crew',
      icon: faUsers,
      path: '/crew',
      showWhen: () => true
    },
    {
      label: 'Tickets',
      icon: faTicketAlt,
      path: '/tickets',
      showWhen: () => true
    },
    {
      label: 'Expense',
      icon: faMoneyBill,
      path: '/expense',
      showWhen: () => userData?.membershipTier !== 'Free'
    },
    {
      label: 'Membership',
      icon: faCrown,
      path: '/membership',
      showWhen: () => true
    },
    {
      label: 'Downloads',
      icon: faDownload,
      path: '/downloads',
      showWhen: () => true
    },
    {
      label: 'Settings',
      icon: faGear,
      path: '/settings',
      showWhen: () => true
    }
  ];



  return (
    <div className={styles['left-menu']}>
      {userData?.userSubType && (
        <div className={styles['subuser-type']}>
          {userData.userSubType}
        </div>
      )}

      <ul>
        {menuItems.map((item) => 
          item.showWhen() && (
            <li key={item.label}>
              <div 
                className={`${styles['menu-item']} ${
                  location.pathname === item.path ? styles.selected : ''
                } ${
                  item.requiresDate && (!selectedTour || !selectedDate) ? styles.disabled : ''
                }`}
                onClick={() => handleNavigate(item)}
              >
                <FontAwesomeIcon icon={item.icon} className={styles['menu-item-icon']} />
                <span>{item.label}</span>
              </div>
            </li>
          )
        )}
      </ul>
    </div>
  );
};

export default LeftMenu;