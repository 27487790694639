import React, { useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { signIn } from '../utils/actions/authActions';
import { Link } from 'react-router-dom';
import styles from './LoginPage.module.css';
import UserContext from '../context/UserContext';
import logo from '../assets/Images/logo.png';

const LoginPage = () => {
  const { userData, setUserData } = useContext(UserContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  // Get the redirect path from location state, if it exists
  const from = location.state?.from || `/${userData?.userName || ''}`;

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);
  
    try {
      const result = await signIn(email, password);
      if (result?.userData) {
        // Redirect to the original path the user was trying to access,
        // or to their profile if there wasn't a specific path
        navigate(from.startsWith('/:username') ? `/${result.userData.userName}` : from);
      }
    } catch (error) {
      console.error(error);
      setError('Invalid email or password. Please try again.');
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <div className={styles['login-page']}>
      <div className={styles['login-container']}>
        <div className={styles['login-left']}>
          <div className={styles['logo-section']}>
            <img src={logo} alt="On Tour Logo" className={styles.logo} />
            <h1 className={styles['brand-title']}>On Tour</h1>
          </div>
          <h2 className={styles['welcome-text']}>Welcome Back!</h2>
          <p className={styles['login-description']}>
            Log in to access your tour management dashboard. Keep track of your schedules,
            manage your team, and stay organized with On Tour's comprehensive tools.
          </p>
        </div>

        <div className={styles['login-right']}>
          <div className={styles['login-form-container']}>
            <h2>Sign In</h2>
            {error && <div className={styles.error}>{error}</div>}
            <form onSubmit={handleLogin}>
              <div className={styles['form-group']}>
                <label htmlFor="email">Email Address</label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter your email"
                  required
                />
              </div>
              <div className={styles['form-group']}>
                <label htmlFor="password">Password</label>
                <input
                  id="password"
                  type="password"
                  value={password}
                  onChange={handlePasswordChange}
                  placeholder="Enter your password"
                  required
                />
              </div>
              <div className={styles['forgot-password']}>
                <Link to="/forgot-password">Forgot Password?</Link>
              </div>
              <button type="submit" disabled={isLoading}>
                {isLoading ? 'Signing in...' : 'Sign In'}
              </button>
            </form>
            <div className={styles['signup-link']}>
              New to On Tour?
              <Link to="/signup">Create an account</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;