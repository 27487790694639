export const Switch = ({ checked, onCheckedChange }) => {
    return (
      <button
        role="switch"
        aria-checked={checked}
        onClick={() => onCheckedChange(!checked)}
        className={`relative inline-flex items-center w-10 h-5 rounded-full transition-colors ${
          checked ? 'bg-red-600' : 'bg-gray-200'
        }`}
      >
        <span
          className={`inline-block w-4 h-4 transform rounded-full bg-white transition-transform ${
            checked ? 'translate-x-5' : 'translate-x-1'
          }`}
        />
      </button>
    );
  };