import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheckCircle, 
  faTimesCircle,
  faCalendarAlt,
  faTasks,
  faUser,
  faCheck,
  faSpinner,
  faInfo
} from '@fortawesome/free-solid-svg-icons';
import { getNotifications } from '../../utils/NotificationService';
import UserContext from '../../context/UserContext';
import TourContext from '../../context/TourContext';
import moment from 'moment';
import styles from './Notifications.module.css';

const Notifications = ({ onClose }) => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [lastDoc, setLastDoc] = useState(null);
  const { userData } = useContext(UserContext);
  const { setSelectedTour, updateTourDate } = useContext(TourContext);
  const navigate = useNavigate();

  useEffect(() => {
    loadInitialNotifications();
  }, [userData.userId]);

  const loadInitialNotifications = async () => {
    if (!userData?.userId) return;
    
    try {
      setLoading(true);
      const result = await getNotifications(userData.userId);
      setNotifications(result.notifications);
      setHasMore(result.hasMore);
      setLastDoc(result.lastDoc);
    } catch (error) {
      console.error('Error loading notifications:', error);
    } finally {
      setLoading(false);
    }
  };

  const loadMoreNotifications = async () => {
    if (!userData?.userId || !lastDoc || loadingMore) return;
    
    try {
      setLoadingMore(true);
      const result = await getNotifications(userData.userId, 8, lastDoc);
      setNotifications(prev => [...prev, ...result.notifications]);
      setHasMore(result.hasMore);
      setLastDoc(result.lastDoc);
    } catch (error) {
      console.error('Error loading more notifications:', error);
    } finally {
      setLoadingMore(false);
    }
  };

  const handleNotificationClick = async (notification) => {
    switch (notification.type) {
      case 'task':
        if (notification.data?.tourId && notification.data?.tourDateId) {
          navigate(`/${notification.data.tourId}/${notification.data.tourDateId}/tasks`);
          onClose();
        }
        break;
      case 'TOUR_INVITATION':
        if (notification.tourId) {
          navigate(`/tour/${notification.tourId}`);
          onClose();
        }
        break;
      case 'DATE_ADDED':
      case 'DATE_REMOVED':
        if (notification.data?.tourId) {
          navigate(`/${notification.data.tourId}`);
          onClose();
        }
        break;
      default:
        console.log('Unknown notification type:', notification.type);
    }
  };

  const formatTimeAgo = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return moment(date).fromNow();
  };

  const getNotificationIcon = (type) => {
    switch (type) {
      case 'task':
        return faTasks;
      case 'TOUR_INVITATION':
        return faUser;
      case 'DATE_ADDED':
      case 'DATE_REMOVED':
        return faCalendarAlt;
      default:
        return faInfo;
    }
  };

  if (loading) {
    return (
      <div className={styles.panel}>
        <div className={styles.loadingSpinner} />
      </div>
    );
  }

  return (
    <div className={styles.panel}>
      <div className={styles.header}>
        <h2>Notifications</h2>
      </div>

      <div className={styles.notificationsList}>
        {notifications.length === 0 ? (
          <div className={styles.emptyState}>
            <p>No notifications yet</p>
          </div>
        ) : (
          <>
            {notifications.map(notification => (
              <div
                key={notification.id}
                className={styles.notificationItem}
                onClick={() => handleNotificationClick(notification)}
              >
                <div className={styles.notificationIcon}>
                  <FontAwesomeIcon icon={getNotificationIcon(notification.type)} />
                </div>
                <div className={styles.notificationContent}>
                  <div className={styles.notificationHeader}>
                    <h3>{notification.title}</h3>
                    <span className={styles.timestamp}>
                      {formatTimeAgo(notification.timestamp)}
                    </span>
                  </div>
                  <p>{notification.body}</p>
                  {notification.data?.tourName && (
                    <span className={styles.tourName}>
                      {notification.data.tourName}
                    </span>
                  )}
                </div>
              </div>
            ))}
            
            {hasMore && (
              <button
                className={styles.loadMoreButton}
                onClick={loadMoreNotifications}
                disabled={loadingMore}
              >
                {loadingMore ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  'Show More'
                )}
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default Notifications;