// SearchComponent.js
import React, { useState, useEffect, useCallback } from 'react';
import { getFirestore, collection, query, where, getDocs, limit } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import './SearchComponent.css';

const SearchComponent = ({ onResultsUpdate, userId }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [noResultsMessage, setNoResultsMessage] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);

  // Create a debounced version of the search function for suggestions
  const debouncedSearch = useCallback(
    _.debounce(async (searchTerm) => {
      if (!searchTerm.trim() || !userId) {
        setSuggestions([]);
        return;
      }

      const db = getFirestore();
      try {
        const postsQuery = query(
          collection(db, `users/${userId}/posts`),
          where('tags', 'array-contains', searchTerm.toLowerCase().trim()),
          limit(5)
        );

        const snapshot = await getDocs(postsQuery);
        const uniqueTags = new Set();
        
        // Collect all tags that match the search term
        snapshot.docs.forEach(doc => {
          const tags = doc.data().tags || [];
          tags.forEach(tag => {
            if (tag.toLowerCase().includes(searchTerm.toLowerCase())) {
              uniqueTags.add(tag);
            }
          });
        });

        setSuggestions(Array.from(uniqueTags));
      } catch (error) {
        console.error('Error fetching suggestions:', error);
        setSuggestions([]);
      }
    }, 500), // 500ms delay
    [userId]
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel();
    };
  }, [debouncedSearch]);

  const performSearch = async () => {
    if (!searchQuery.trim() || !userId) {
      onResultsUpdate([]);
      setNoResultsMessage('');
      return;
    }

    setIsLoading(true);
    setNoResultsMessage('');
    const db = getFirestore();
    
    try {
      const postsQuery = query(
        collection(db, `users/${userId}/posts`),
        where('tags', 'array-contains', searchQuery.toLowerCase().trim()),
        limit(10)
      );

      const snapshot = await getDocs(postsQuery);
      const posts = snapshot.docs.map(doc => ({
        postId: doc.id,
        ...doc.data()
      }));
      
      if (posts.length === 0) {
        setNoResultsMessage(`No results found for tag "#${searchQuery.trim()}"`);
      }
      
      onResultsUpdate(posts);
    } catch (error) {
      console.error('Error searching posts:', error);
      onResultsUpdate([]);
      setNoResultsMessage('An error occurred while searching');
    } finally {
      setIsLoading(false);
      setShowSuggestions(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    setSearchQuery(value);
    setNoResultsMessage('');
    
    if (value.trim()) {
      setShowSuggestions(true);
      debouncedSearch(value);
    } else {
      setShowSuggestions(false);
      setSuggestions([]);
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setSearchQuery(suggestion);
    setShowSuggestions(false);
    // Trigger search with the selected suggestion
    performSearch();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await performSearch();
  };

  const clearSearch = () => {
    setSearchQuery('');
    setSuggestions([]);
    setShowSuggestions(false);
    setNoResultsMessage('');
    onResultsUpdate([]);
  };

  return (
    <div className="search-component">
      <form onSubmit={handleSubmit} className="search-container">
        <div className="search-input-wrapper">
          {!searchQuery && (
            <FontAwesomeIcon 
              icon={faSearch} 
              className="search-icon"
            />
          )}
          <input
            type="text"
            className="search-input"
            placeholder="Search posts by tag..."
            value={searchQuery}
            onChange={handleInputChange}
            onFocus={() => searchQuery.trim() && setSuggestions(true)}
          />
          {searchQuery && (
            <button
              type="button"
              onClick={clearSearch}
              className="clear-button"
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </div>
        <button
          type="submit"
          disabled={isLoading}
          className="search-button"
        >
          {isLoading ? (
            <div className="spinner" />
          ) : (
            'Search'
          )}
        </button>
      </form>

      {/* Suggestions dropdown */}
      {showSuggestions && suggestions.length > 0 && (
        <div className="suggestions-dropdown">
          {suggestions.map((suggestion, index) => (
            <div
              key={index}
              className="suggestion-item"
              onClick={() => handleSuggestionClick(suggestion)}
            >
              #{suggestion}
            </div>
          ))}
        </div>
      )}

      {/* No results message */}
      {noResultsMessage && (
        <div className="no-results-message">
          {noResultsMessage}
        </div>
      )}
    </div>
  );
};

export default SearchComponent;