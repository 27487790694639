import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPlus, 
  faEdit, 
  faTrash, 
  faInfoCircle,
  faUser,
  faChevronLeft,
  faTimes
} from '@fortawesome/free-solid-svg-icons';
import { doc, updateDoc, addDoc, collection, deleteDoc, getDoc, Timestamp } from 'firebase/firestore';
import { getFirestoreInstance } from '../../utils/firebaseHelper';
import TourContext from '../../context/TourContext';
import UserContext from '../../context/UserContext';
import TopBar from '../TopBar';
import LeftMenu from '../LeftMenu';
import RightMenu from '../RightMenu';
import styles from './Tasks.module.css';

const TaskModal = ({ isOpen, onClose, task, onSave, tourMembers, isProcessing }) => {
  const [title, setTitle] = useState(task?.title || '');
  const [details, setDetails] = useState(task?.details || '');
  const [assignedToUserId, setAssignedToUserId] = useState(task?.assignedToUserId || '');
  const [completed, setCompleted] = useState(task?.completed || false);

  useEffect(() => {
    if (task) {
      setTitle(task.title);
      setDetails(task.details);
      setAssignedToUserId(task.assignedToUserId);
      setCompleted(task.completed);
    } else {
      setTitle('');
      setDetails('');
      setAssignedToUserId('');
      setCompleted(false);
    }
  }, [task]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave({
      title,
      details,
      assignedToUserId,
      completed
    });
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2>{task ? 'Edit Task' : 'Create New Task'}</h2>
          <button className={styles.closeButton} onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <form onSubmit={handleSubmit} className={styles.modalForm}>
          <div className={styles.formGroup}>
            <label htmlFor="title">Title</label>
            <input
              id="title"
              type="text"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              className={styles.input}
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="details">Details</label>
            <textarea
              id="details"
              value={details}
              onChange={(e) => setDetails(e.target.value)}
              className={styles.textarea}
              rows={4}
            />
          </div>

          <div className={styles.formGroup}>
            <label htmlFor="assignedTo">Assign To</label>
            <select
              id="assignedTo"
              value={assignedToUserId}
              onChange={(e) => setAssignedToUserId(e.target.value)}
              required
              className={styles.select}
            >
              <option value="">Select User</option>
              {tourMembers.map((member) => (
                <option key={member.userId} value={member.userId}>
                  {member.fullName} ({member.userType})
                </option>
              ))}
            </select>
          </div>

          <div className={styles.formGroup}>
            <label className={styles.checkboxLabel}>
              <input
                type="checkbox"
                checked={completed}
                onChange={(e) => setCompleted(e.target.checked)}
                className={styles.checkbox}
              />
              Completed
            </label>
          </div>

          <div className={styles.modalActions}>
            <button
              type="button"
              onClick={onClose}
              className={styles.cancelButton}
              disabled={isProcessing}
            >
              Cancel
            </button>
            <button
              type="submit"
              className={styles.submitButton}
              disabled={isProcessing}
            >
              {isProcessing ? 'Processing...' : (task ? 'Update Task' : 'Create Task')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

const Tasks = () => {
  const navigate = useNavigate();
  const { tourId, dateId } = useParams();
  const { selectedTour, getSelectedTourDate, updateTourDate } = useContext(TourContext);
  const { userData } = useContext(UserContext);
  
  const [tasks, setTasks] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingTask, setEditingTask] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const tourDate = getSelectedTourDate();
    if (tourDate) {
      setTasks(tourDate.schedule?.tasks || []);
    }
  }, [getSelectedTourDate]);

  const getTourMembers = () => {
    if (!selectedTour) return [];
    
    const members = [];
    
    // Add manager
    if (selectedTour.createdBy) {
      members.push({
        userId: selectedTour.createdBy.userId,
        fullName: selectedTour.createdBy.fullName,
        userType: 'Manager'
      });
    }

    // Add artists
    selectedTour.artistDetails?.forEach(artist => {
      members.push({
        userId: artist.userId,
        fullName: artist.fullName,
        userType: 'Artist'
      });
    });

    // Add crew
    selectedTour.crewDetails?.forEach(crew => {
      members.push({
        userId: crew.userId,
        fullName: crew.fullName,
        userType: 'Crew'
      });
    });

    return members;
  };

  const handleCreateTask = () => {
    setEditingTask(null);
    setIsModalOpen(true);
  };

  const handleEditTask = (task) => {
    setEditingTask(task);
    setIsModalOpen(true);
  };

  const handleBack = () => {
    navigate(`/${selectedTour.id}/${dateId}`);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', {
      weekday: 'long',
      month: 'long',
      day: 'numeric',
      year: 'numeric'
    });
  };

  const handleSaveTask = async (taskData) => {
    setIsProcessing(true);
    try {
      const db = getFirestoreInstance();
      const tourRef = doc(db, 'Tours', tourId);
      const tourDateRef = doc(tourRef, 'tourDates', dateId);
      const timestamp = Timestamp.now();

      const taskId = editingTask ? editingTask.id : Date.now().toString();
      const newTask = {
        id: taskId,
        ...taskData,
        assignedByUserId: userData.userId,
        notificationIds: [],
      };

      let updatedTasks;
      if (editingTask) {
        updatedTasks = tasks.map(task => 
          task.id === editingTask.id ? { ...task, ...newTask } : task
        );
      } else {
        updatedTasks = [...tasks, newTask];

        // Create notification for new task
        const notificationRef = collection(db, 'Notifications');
        const tourDate = getSelectedTourDate();
        const notificationDoc = await addDoc(notificationRef, {
          userId: taskData.assignedToUserId,
          tourId,
          tourDateId: dateId,
          taskId,
          title: 'New Task Assigned',
          body: `You have been assigned a task: ${taskData.title} for tour date: ${formatDate(tourDate.date)}`,
          type: 'task',
          read: false,
          timestamp: new Date(),
        });

        newTask.notificationIds = [notificationDoc.id];
        updatedTasks[updatedTasks.length - 1] = newTask;
      }

      await updateDoc(tourDateRef, {
        'schedule.tasks': updatedTasks,
        dateLastUpdated: timestamp
      });

      await updateTourDate(tourId, dateId);
      setTasks(updatedTasks);
      setIsModalOpen(false);

    } catch (error) {
      console.error('Error saving task:', error);
      alert('Failed to save task. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleDeleteTask = async (taskToDelete) => {
    if (!window.confirm('Are you sure you want to delete this task?')) return;

    setIsProcessing(true);
    try {
      const db = getFirestoreInstance();
      const tourRef = doc(db, 'Tours', tourId);
      const tourDateRef = doc(tourRef, 'tourDates', dateId);

      const updatedTasks = tasks.filter(task => task.id !== taskToDelete.id);

      // Delete associated notifications
      if (taskToDelete.notificationIds?.length) {
        for (const notificationId of taskToDelete.notificationIds) {
          const notificationRef = doc(db, 'Notifications', notificationId);
          await deleteDoc(notificationRef);
        }
      }

      await updateDoc(tourDateRef, {
        'schedule.tasks': updatedTasks,
        dateLastUpdated: Timestamp.now()
      });

      await updateTourDate(tourId, dateId);
      setTasks(updatedTasks);

    } catch (error) {
      console.error('Error deleting task:', error);
      alert('Failed to delete task. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const handleToggleComplete = async (taskToToggle) => {
    setIsProcessing(true);
    try {
      const db = getFirestoreInstance();
      const tourRef = doc(db, 'Tours', tourId);
      const tourDateRef = doc(tourRef, 'tourDates', dateId);

      const updatedTasks = tasks.map(task =>
        task.id === taskToToggle.id ? { ...task, completed: !task.completed } : task
      );

      await updateDoc(tourDateRef, {
        'schedule.tasks': updatedTasks,
        dateLastUpdated: Timestamp.now()
      });

      await updateTourDate(tourId, dateId);
      setTasks(updatedTasks);

    } catch (error) {
      console.error('Error updating task status:', error);
      alert('Failed to update task status. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

  const getUserName = (userId) => {
    const member = getTourMembers().find(member => member.userId === userId);
    return member ? member.fullName : 'Unknown User';
  };

  if (!selectedTour || !dateId) {
    return (
      <div className={styles.layout}>
        <TopBar />
        <main className={styles.main}>
          <div className={styles.emptyState}>
            <FontAwesomeIcon icon={faInfoCircle} size="2x" />
            <p>Please select a tour and date to view tasks</p>
          </div>
        </main>
      </div>
    );
  }

  return (
    <div className={styles.layout}>
      <TopBar />
      <div className={styles.mainLayout}>
        {!isMobile && <LeftMenu selectedTour={selectedTour} />}
        <main className={styles.content}>
          <div className={styles.header}>
            <button className={styles.backButton} onClick={handleBack}>
              <FontAwesomeIcon icon={faChevronLeft} />
              Back to Schedule
            </button>
            <h1>{selectedTour.tourName}</h1>
            <h2>{formatDate(getSelectedTourDate()?.date)}</h2>
          </div>

          <div className={styles.taskSection}>
            <button className={styles.createButton} onClick={handleCreateTask}>
              <FontAwesomeIcon icon={faPlus} />
              <span>Create Task</span>
            </button>

            <div className={styles.taskList}>
              {tasks.length === 0 ? (
                <div className={styles.emptyState}>
                  <p>No tasks available</p>
                </div>
              ) : (
                tasks.map(task => (
                  <div key={task.id} className={styles.taskCard}>
                    <div className={styles.taskHeader}>
                      <h3>{task.title}</h3>
                      {task.assignedByUserId === userData.userId && (
                        <div className={styles.taskActions}>
                          <button
                            onClick={() => handleEditTask(task)}
                            className={styles.actionButton}
                          >
                            <FontAwesomeIcon icon={faEdit} />
                          </button>
                          <button
                            onClick={() => handleDeleteTask(task)}
                            className={`${styles.actionButton} ${styles.deleteButton}`}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </div>
                      )}
                    </div>

                    <p className={styles.taskDetails}>{task.details}</p>

                    <div className={styles.taskMeta}>
                      <div className={styles.assignedInfo}>
                        <span className={styles.metaLabel}>
                          <FontAwesomeIcon icon={faUser} /> Assigned To:
                        </span>
                        <span>{getUserName(task.assignedToUserId)}</span>
                      </div>
                      <div className={styles.assignedInfo}>
                        <span className={styles.metaLabel}>Assigned By:</span>
                        <span>{getUserName(task.assignedByUserId)}</span>
                      </div>
                    </div>

                    {task.assignedByUserId === userData.userId && (
                      <div className={styles.completedSection}>
                        <label className={styles.checkboxLabel}>
                          <input
                            type="checkbox"
                            checked={task.completed}
                            onChange={() => handleToggleComplete(task)}
                            className={styles.checkbox}
                          />
                          Completed
                        </label>
                      </div>
                    )}
                  </div>
                ))
              )}
            </div>
          </div>

          {isModalOpen && (
            <TaskModal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              task={editingTask}
              onSave={handleSaveTask}
              tourMembers={getTourMembers()}
              isProcessing={isProcessing}
            />
          )}

          {isProcessing && (
            <div className={styles.loadingOverlay}>
              <div className={styles.loadingSpinner}></div>
              <p className={styles.loadingText}>Processing...</p>
            </div>
          )}
        </main>
        {!isMobile && (
          <RightMenu
            selectedTour={selectedTour}
            tours={[]}
            onTourSelect={() => {}}
          />
        )}
      </div>
    </div>
  );
};

export default Tasks;