import React, { useState, useEffect } from 'react';
import { useNavigate, useParams, Link } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faMapMarkerAlt, 
  faCalendarCheck, 
  faRoute, 
  faEnvelope,
  faUser,
  faShare,
  faSignInAlt,
  faUserPlus,
  faLock,
  faComment
} from '@fortawesome/free-solid-svg-icons';
import { 
  faInstagram, 
  faTwitter, 
  faFacebook, 
  faYoutube,
  faLinkedin
} from '@fortawesome/free-brands-svg-icons';
import TopBar from './TopBar';
import styles from './PublicProfile.module.css'; // Import our new CSS module
import defaultProfilePhoto from '../assets/Images/defaultUserProfilePicture.png';
import defaultCoverPhoto from '../assets/Images/defaultUserCoverPicture.png';
import verifiedBadge from '../assets/Images/profileverifiedtick.png';
import spotifylogo from '../assets/Images/spotifyicon.png';
import applemusiclogo from '../assets/Images/applemusicicon.png';
import ShareModal from './common/ShareModal';
import { getUserPosts } from '../utils/actions/PostActions';
import PostCard from './common/PostCard';

// Modified stats container for public profile
const StatsContainer = ({ profileData, onToursClick, onShowsClick, onShareClick }) => {
  return (
    <div className={styles['stats-grid']}>
      <div className={styles['stat-card']} onClick={onToursClick}>
        <div className={styles['stat-value']}>{profileData?.toursCount || 0}</div>
        <div className={styles['stat-label']}>Tours</div>
      </div>
      <div className={styles['stat-card']} onClick={onShowsClick}>
        <div className={styles['stat-value']}>{profileData?.showsCount || 0}</div>
        <div className={styles['stat-label']}>Shows</div>
      </div>
      {/* Share icon instead of users count */}
      <div className={styles['stat-card']} onClick={onShareClick}>
        <div className={styles['share-icon-container']}>
          <FontAwesomeIcon icon={faShare} className={styles['share-icon']} />
        </div>
        <div className={styles['stat-label']}>Share</div>
      </div>
    </div>
  );
};

// Login prompt component
const LoginPrompt = () => {
  const navigate = useNavigate();
  
  return (
    <div className={styles['login-prompt']}>
      <div className={styles['lock-icon']}>
        <FontAwesomeIcon icon={faLock} />
      </div>
      <h3>Sign in to see more</h3>
      <p>Log in to view more details, interact with content, and connect with other professionals.</p>
      <div className={styles['login-actions']}>
        <button 
          className={styles['login-button']}
          onClick={() => navigate('/login')}
        >
          <FontAwesomeIcon icon={faSignInAlt} />
          <span>Log In</span>
        </button>
        <button 
          className={styles['signup-button']}
          onClick={() => navigate('/signup')}
        >
          <FontAwesomeIcon icon={faUserPlus} />
          <span>Sign Up</span>
        </button>
      </div>
    </div>
  );
};

// Account is private component
const PrivateAccountMessage = () => {
  return (
    <div className={styles['private-account']}>
      <div className={styles['lock-icon']}>
        <FontAwesomeIcon icon={faLock} />
      </div>
      <h3>This account is private</h3>
      <p>Only members added on tour can see it.</p>
    </div>
  );
};

const PublicProfile = ({ profileData: initialProfileData }) => {
  const { username } = useParams();
  const navigate = useNavigate();
  const [profileData, setProfileData] = useState(initialProfileData);
  const [isLoading, setIsLoading] = useState(!initialProfileData);
  const [activeTab, setActiveTab] = useState('showcase');
  const [showProfileShareModal, setShowProfileShareModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [posts, setPosts] = useState([]);
  const [isPostsLoading, setIsPostsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  // Function to check if the device is mobile
  const isMobileDevice = () => {
    return (
      typeof window !== 'undefined' && 
      (window.innerWidth <= 768 || 'ontouchstart' in window)
    );
  };
  
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch profile data only if not provided via props
  useEffect(() => {
    if (initialProfileData) {
      setProfileData(initialProfileData);
      setIsLoading(false);
      
      // Load posts since we already have profile data
      if (initialProfileData.userId) {
        fetchPosts(initialProfileData.userId);
      }
      return;
    }
    
    const fetchProfileData = async () => {
      setIsLoading(true);
      try {
        const db = getFirestore();
        const userQuery = query(collection(db, 'users'), where('userName', '==', username));
        const userSnapshot = await getDocs(userQuery);
        
        if (!userSnapshot.empty) {
          const userDoc = userSnapshot.docs[0];
          const userId = userDoc.id;
          const userData = {
            userId,
            ...userDoc.data()
          };
          setProfileData(userData);
          
          // Load initial posts
          fetchPosts(userId);
        } else {
          setProfileData(null);  // Set to null to indicate user not found
        }
      } catch (error) {
        console.error('Error fetching profile:', error);
        setProfileData(null);
      } finally {
        setIsLoading(false);
      }
    };
    
    if (username) {
      fetchProfileData();
    }
  }, [username, initialProfileData]);

  // Fetch posts
  const fetchPosts = async (userId) => {
    if (!userId) return;
    
    try {
      setIsPostsLoading(true);
      
      // Fixed number of posts (4) since we removed load more functionality
      const postsData = await getUserPosts(userId, 4);
      
      setPosts(postsData);
    } catch (error) {
      console.error('Error fetching posts:', error);
    } finally {
      setIsPostsLoading(false);
    }
  };

  // Handle profile sharing
  const handleShareProfile = async () => {
    const shareData = {
      title: `${profileData?.fullName}'s Profile on OnTour`,
      text: profileData?.bio || 'Check out this profile on OnTour',
      url: window.location.href
    };
  
    // Check if it's mobile and has native sharing
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  
    if (isMobileDevice && navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error sharing:', error);
          handleClipboardCopy(window.location.href);
        }
      }
    } else {
      // On desktop, show the ShareModal
      setShowProfileShareModal(true);
    }
  };
  
  const handleClipboardCopy = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      alert('Profile URL copied to clipboard!');
    } catch (err) {
      console.error('Failed to copy URL:', err);
    }
  };

  // Show login prompt modal for stats clicks
  const handleStatsClick = () => {
    setShowLoginModal(true);
  };

  // If loading
  if (isLoading) {
    return (
      <div className={styles['loading-container']}>
        <div className={styles['loading-spinner']}></div>
      </div>
    );
  }

  // If user not found, use the updated styling from Profile.js
  if (!profileData) {
    return (
      <div className={styles['profile-container']}>
        <TopBar />
        <div className={styles['main-layout']}>
          <div className={styles['not-found-container']}>
            <div className={styles['not-found-content']}>
              <div className={styles['not-found-artwork']}>
                <FontAwesomeIcon 
                  icon={faUser} 
                  className={styles['not-found-icon']} 
                />
                <div className={styles['not-found-circle']}></div>
              </div>
              <h1>User Not Found</h1>
              <p>Sorry, this profile doesn't exist or may have been removed.</p>
              <Link 
                to="/login"
                className={styles['return-button']}
              >
                <FontAwesomeIcon icon={faSignInAlt} />
                Log In
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Get current availability status
  const isUserOnTour = profileData?.isCurrentlyOnTour || false;
  
  return (
    <div className={styles['profile-container']}>
      <TopBar />
      <div className={styles['main-layout']}>
        <div className={styles['profile-main']}>
          <div className={styles['profile-content']}>
            {/* Profile Card */}
            <div className={styles['profile-card']}>
              <div className={styles['cover-photo-container']}>
                <img 
                  src={profileData?.profileCoverPictureUrl || defaultCoverPhoto}
                  alt="Cover"
                  className={styles['cover-photo']}
                />
                <div className={styles['cover-overlay']}></div>
              </div>
              
              <div className={styles['profile-header']}>
                <div className={styles['profile-left']}>
                  <img
                    src={profileData?.profilePictureUrl || defaultProfilePhoto}
                    alt="Profile"
                    className={styles['profile-photo']}
                  />
                  
                  <div className={styles['profile-info']}>
                    <div className={styles['name-section']}>
                      <h1>{profileData?.fullName}</h1>
                      {profileData?.idVerificationStatus === 'Verified' && (
                        <img src={verifiedBadge} alt="Verified" className={styles['verified-badge']} />
                      )}
                    </div>
  
                    <div className={styles['user-type']}>
                      {profileData?.userType}
                      {profileData?.userSubType && ` · ${profileData.userSubType}`}
                    </div>
  
                    <div className={styles['info-items']}>
                      <div className={styles['info-item']}>
                        <FontAwesomeIcon icon={faMapMarkerAlt} />
                        <span>{`${profileData?.city || ''}, ${profileData?.state || ''}, ${profileData?.country || ''}`}</span>
                      </div>
                      <div className={`${styles['info-item']} ${isUserOnTour ? styles['on-tour'] : styles.available}`}>
                        <FontAwesomeIcon icon={isUserOnTour ? faRoute : faCalendarCheck} />
                        <span>{isUserOnTour ? 'On Tour' : 'Available'}</span>
                      </div>
                    </div>

                    {/* Bio Section */}
                    <div className={styles.bioSection}>
                      {profileData?.bio && (
                        <p className={styles.bio}>{profileData.bio}</p>
                      )}
                    </div>

                    {/* Social Media Icons */}
                    <div className={styles.socialIcons}>
                      {profileData?.socialLinks?.instagramUrl && profileData.socialLinks.instagramUrl.trim() !== "" && (
                        <a 
                          href={profileData.socialLinks.instagramUrl} 
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className={styles.socialIconLink}
                          title={profileData.socialLinks.instagramUrl}
                        >
                          <FontAwesomeIcon icon={faInstagram} className={styles.socialIcon} />
                        </a>
                      )}
                      {profileData?.socialLinks?.twitterUrl && profileData.socialLinks.twitterUrl.trim() !== "" && (
                        <a 
                          href={profileData.socialLinks.twitterUrl}
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className={styles.socialIconLink}
                          title={profileData.socialLinks.twitterUrl}
                        >
                          <FontAwesomeIcon icon={faTwitter} className={styles.socialIcon} />
                        </a>
                      )}
                      {profileData?.socialLinks?.linkedInUrl && profileData.socialLinks.linkedInUrl.trim() !== "" && (
                        <a 
                          href={profileData.socialLinks.linkedInUrl}
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className={styles.socialIconLink}
                          title={profileData.socialLinks.linkedInUrl}
                        >
                          <FontAwesomeIcon icon={faLinkedin} className={styles.socialIcon} />
                        </a>
                      )}
                      {profileData?.socialLinks?.facebookUrl && profileData.socialLinks.facebookUrl.trim() !== "" && (
                        <a 
                          href={profileData.socialLinks.facebookUrl}
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className={styles.socialIconLink}
                          title={profileData.socialLinks.facebookUrl}
                        >
                          <FontAwesomeIcon icon={faFacebook} className={styles.socialIcon} />
                        </a>
                      )}
                      {profileData?.socialLinks?.youtubeUrl && profileData.socialLinks.youtubeUrl.trim() !== "" && (
                        <a 
                          href={profileData.socialLinks.youtubeUrl}
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className={styles.socialIconLink}
                          title={profileData.socialLinks.youtubeUrl}
                        >
                          <FontAwesomeIcon icon={faYoutube} className={styles.socialIcon} />
                        </a>
                      )}
                      {profileData?.socialLinks?.spotifyUrl && profileData.socialLinks.spotifyUrl.trim() !== "" && (
                        <a 
                          href={profileData.socialLinks.spotifyUrl}
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className={styles.customsocialIcon}
                          title={profileData.socialLinks.spotifyUrl}
                        >
                          <img src={spotifylogo} alt="Spotify" className={styles.socialIcon} />
                        </a>
                      )}
                      {profileData?.socialLinks?.appleMusicUrl && profileData.socialLinks.appleMusicUrl.trim() !== "" && (
                        <a 
                          href={profileData.socialLinks.appleMusicUrl}
                          target="_blank" 
                          rel="noopener noreferrer" 
                          className={styles.customsocialIcon}
                          title={profileData.socialLinks.appleMusicUrl}
                        >
                          <img src={applemusiclogo} alt="Apple Music" className={styles.socialIcon} />
                        </a>
                      )}
                    </div>
                    
                    <div className={styles['profile-actions']}>
                      <Link 
                        to="/login" 
                        className={`${styles['action-button']} ${styles.contact}`}
                      >
                        <FontAwesomeIcon icon={faEnvelope} />
                        Contact
                      </Link>
                      <Link 
                        to="/login" 
                        className={`${styles['action-button']} ${styles.message}`}
                      >
                        <FontAwesomeIcon icon={faComment} />
                        Message
                      </Link>
                    </div>
                  </div>
                </div>
  
                <div className={styles['profile-right']}>
                  <StatsContainer 
                    profileData={profileData}
                    onToursClick={handleStatsClick}
                    onShowsClick={handleStatsClick}
                    onShareClick={handleShareProfile}
                  />
                </div>
              </div>
            </div>
  
            {/* Content Section */}
            <div className={styles['content-section']}>
              <div className={styles['content-tabs']}>
                <button 
                  className={`${styles.tab} ${activeTab === 'showcase' ? styles.active : ''}`}
                  onClick={() => setActiveTab('showcase')}
                >
                  Showcase
                </button>
                <button 
                  className={`${styles.tab} ${activeTab === 'dates' ? styles.active : ''}`}
                  onClick={() => setActiveTab('dates')}
                >
                  Tour Dates
                </button>
              </div>
  
              <div className={styles['tab-content']}>
                {activeTab === 'showcase' ? (
                  <div className={styles['showcase-section']}>
                    {/* Check if account is private */}
                    {profileData?.accountVisibility === 'Private' ? (
                      <PrivateAccountMessage />
                    ) : (
                      <>
                        <div className={styles['posts-grid']}>
                          {isPostsLoading ? (
                            <div className={styles['loading-spinner-container']}>
                              <div className={styles['loading-spinner']}></div>
                            </div>
                          ) : posts.length > 0 ? (
                            posts.map(post => (
                              <PostCard
                                key={post.postId}
                                post={post}
                                isOwner={false}
                                isMobile={isMobileDevice()}
                                isPublic={true}
                              />
                            ))
                          ) : (
                            <div className={styles['no-results']}>
                              No posts yet.
                            </div>
                          )}
                        </div>

                        {/* Login prompt for more showcase content */}
                        <div className={styles['login-prompt-container']}>
                          <LoginPrompt />
                        </div>
                      </>
                    )}
                  </div>
                ) : (
                  // Tour Dates Tab
                  <div className={styles['dates-container']}>
                    <div className={styles['login-prompt-container']}>
                      <LoginPrompt />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Share Modal */}
        {showProfileShareModal && (
  <ShareModal
    isOpen={showProfileShareModal}
    onClose={() => setShowProfileShareModal(false)}
    postData={{
      type: 'profile', // This is key - set type to 'profile' to indicate it's a profile share
      caption: profileData?.bio || `${profileData?.fullName}'s profile on OnTour`,
      tags: [], // Profiles don't have tags
      postId: profileData?.userId
    }}
  />
)}

        {/* Login Prompt Modal for Stats */}
        {showLoginModal && (
          <div className={styles['modal-overlay']} onClick={() => setShowLoginModal(false)}>
            <div className={styles['modal-content']} onClick={e => e.stopPropagation()}>
              <button className={styles['modal-close']} onClick={() => setShowLoginModal(false)}>
                <FontAwesomeIcon icon={faSignInAlt} />
              </button>
              <h2>Please Sign In</h2>
              <p>You need to be logged in to view {profileData.fullName}'s tours, shows, and more details.</p>
              <div className={styles['form-actions']}>
                <button 
                  className={styles['secondary-button']} 
                  onClick={() => setShowLoginModal(false)}
                >
                  Cancel
                </button>
                <button 
                  className={styles['primary-button']}
                  onClick={() => navigate('/login')}
                >
                  Log In
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PublicProfile;