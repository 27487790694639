import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../components/ui/dialog';
import Label from '../../components/ui/label';
import { Button } from '../../components/ui/button';
import { Input } from '../../components/ui/input';
import { Textarea } from '../../components/ui/textarea';
import { Switch } from '../../components/ui/switch';
import { Card, CardContent } from '../../components/ui/card';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import LocationSearchModal from './LocationSearchModal';
import './AddScheduleModal.module.css';

const AddScheduleModal = ({ isOpen, onClose, onSave, selectedTime }) => {
  const [title, setTitle] = useState('');
  const [notes, setNotes] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [visibility, setVisibility] = useState('all');
  const [fromLocation, setFromLocation] = useState(null);
  const [toLocation, setToLocation] = useState(null);
  const [showLocationSearch, setShowLocationSearch] = useState(false);
  const [isFromLocation, setIsFromLocation] = useState(true);

  useEffect(() => {
    if (selectedTime) {
      setStartTime(selectedTime);
      // Set end time to 1 hour after start time
      const [hours] = selectedTime.split(':');
      const nextHour = (parseInt(hours) + 1) % 24;
      setEndTime(`${nextHour.toString().padStart(2, '0')}:00`);
    }
  }, [selectedTime]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const scheduleData = {
      title,
      notes,
      startTime,
      endTime,
      isConfirmed,
      visibility,
      fromLocation,
      toLocation,
    };
    onSave(scheduleData);
    onClose();
  };

  const handleLocationSelect = (location) => {
    if (isFromLocation) {
      setFromLocation(location);
    } else {
      setToLocation(location);
    }
    setShowLocationSearch(false);
  };

  return (
    <>
      <Dialog open={isOpen} onOpenChange={onClose}>
        <DialogContent className="sm:max-w-[600px]">
          <DialogHeader>
            <DialogTitle>Add Schedule</DialogTitle>
          </DialogHeader>

          <form onSubmit={handleSubmit} className="space-y-6">
            <div className="space-y-4">
              <div>
                <Label htmlFor="title">Title</Label>
                <Input
                  id="title"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                  placeholder="Enter schedule title"
                />
              </div>

              <div>
                <Label htmlFor="notes">Notes</Label>
                <Textarea
                  id="notes"
                  value={notes}
                  onChange={(e) => setNotes(e.target.value)}
                  placeholder="Add any notes..."
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <Label htmlFor="startTime">Start Time</Label>
                  <Input
                    id="startTime"
                    type="time"
                    value={startTime}
                    onChange={(e) => setStartTime(e.target.value)}
                  />
                </div>

                <div>
                  <Label htmlFor="endTime">End Time</Label>
                  <Input
                    id="endTime"
                    type="time"
                    value={endTime}
                    onChange={(e) => setEndTime(e.target.value)}
                  />
                </div>
              </div>

              <Card>
                <CardContent className="p-4 space-y-4">
                  <h3 className="font-semibold">Location Details</h3>

                  <div>
                    <Label>From Location</Label>
                    <Button
                      type="button"
                      variant="outline"
                      className="w-full justify-start text-left"
                      onClick={() => {
                        setIsFromLocation(true);
                        setShowLocationSearch(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faSearch} className="mr-2" />
                      {fromLocation?.displayName || 'Search location'}
                    </Button>
                  </div>

                  <div>
                    <Label>To Location</Label>
                    <Button
                      type="button"
                      variant="outline"
                      className="w-full justify-start text-left"
                      onClick={() => {
                        setIsFromLocation(false);
                        setShowLocationSearch(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faSearch} className="mr-2" />
                      {toLocation?.displayName || 'Search location'}
                    </Button>
                  </div>
                </CardContent>
              </Card>

              <div className="flex items-center justify-between">
                <Label htmlFor="confirmed">Confirmed</Label>
                <Switch
                  id="confirmed"
                  checked={isConfirmed}
                  onCheckedChange={setIsConfirmed}
                />
              </div>

              <div>
                <Label htmlFor="visibility">Visibility</Label>
                <select
                  id="visibility"
                  value={visibility}
                  onChange={(e) => setVisibility(e.target.value)}
                  className="w-full p-2 rounded-md border border-input bg-background"
                >
                  <option value="all">All</option>
                  <option value="artist">Artists Only</option>
                  <option value="crew">Crew Only</option>
                  <option value="me">Only Me</option>
                </select>
              </div>
            </div>

            <div className="flex justify-end gap-2">
              <Button type="button" variant="outline" onClick={onClose}>
                Cancel
              </Button>
              <Button type="submit">Save</Button>
            </div>
          </form>
        </DialogContent>
      </Dialog>

      <LocationSearchModal
        isOpen={showLocationSearch}
        onClose={() => setShowLocationSearch(false)}
        onSelect={handleLocationSelect}
      />
    </>
  );
};

export default AddScheduleModal;