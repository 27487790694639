export const Input = ({ value, onChange, type = 'text', className = '', ...props }) => {
    return (
      <input
        type={type}
        value={value}
        onChange={onChange}
        className={`w-full border border-gray-300 rounded-md px-3 py-2 focus:outline-none focus:ring-2 focus:ring-red-600 ${className}`}
        {...props}
      />
    );
  };
  