import React, { useState, useContext, useEffect } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import TourContext from '../../context/TourContext';
import UserContext from '../../context/UserContext';
import { getFirestoreInstance } from '../../utils/firebaseHelper';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faMapMarkerAlt, 
  faCar, 
  faPhone, 
  faUser,
  faUserGroup,
  faMusic,
  faFileAlt,
  faEdit,
  faChevronRight,
  faUsers,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import styles from './Event.module.css';
import defaultVenueImage from '../../assets/Images/defaulthotelpicture.png';
import defaultUserImage from '../../assets/Images/defaultUserProfilePicture.png';
import olaLogo from '../../assets/Images/OLAlogo.png';
import uberLogo from '../../assets/Images/Uberlogo.png';


const Event = () => {
  const [isVendorModalVisible, setIsVendorModalVisible] = useState(false);
  const [vendors, setVendors] = useState([]);
  const [isRideModalVisible, setIsRideModalVisible] = useState(false);
  const [isLocationLoading, setIsLocationLoading] = useState(false);
  const { selectedTour, getSelectedTourDate, updateTourDate } = useContext(TourContext);
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();
  const [isOrganizerModalVisible, setIsOrganizerModalVisible] = useState(false);
  const [organizerName, setOrganizerName] = useState('');
  const [isSaving, setIsSaving] = useState(false);

  const tourDate = getSelectedTourDate();
  const event = tourDate?.event || {};
  const venueData = event?.venueDetails?.venueData || {};

  const handleSaveOrganizerName = async () => {
    if (!selectedTour?.id || !tourDate?.id) return;
    setIsSaving(true);
    try {
      const db = getFirestoreInstance();
      const tourRef = doc(db, 'Tours', selectedTour.id);
      const tourDateRef = doc(tourRef, 'tourDates', tourDate.id);

      await updateDoc(tourDateRef, {
        'event.organizer.organizerName': organizerName
      });

      await updateTourDate(selectedTour.id, tourDate.id);
      setIsOrganizerModalVisible(false);
    } catch (error) {
      console.error('Error saving organizer name:', error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleViewMap = () => {
    if (venueData.googleMapsUri) {
      window.open(venueData.googleMapsUri, '_blank');
    }
  };

  const handleGetARide = () => {
    setIsRideModalVisible(true);
  };

  const handleOpenCabApp = async (cabService) => {
    if (!venueData || !venueData.location) {
      alert('Venue location data is not available');
      return;
    }

    if (!venueData.location.latitude || !venueData.location.longitude) {
      alert('Invalid venue location data');
      return;
    }

    setIsLocationLoading(true);
    try {
      // Get current location
      const position = await getCurrentPosition();
      if (!position) {
        setIsLocationLoading(false);
        return;
      }

      const { latitude, longitude } = position.coords;
      const venue = venueData;
      const encodedAddress = encodeURIComponent(`${venue.displayName}, ${venue.formattedAddress}`);

      let url;
      if (cabService === 'ola') {
        url = `https://book.olacabs.com/?pickup_name=Current%20Location&pickup_lat=${latitude}&pickup_lng=${longitude}&drop_lat=${venue.location.latitude}&drop_lng=${venue.location.longitude}&drop_name=${encodedAddress}`;
      } else if (cabService === 'uber') {
        url = `uber://?action=setPickup&pickup[latitude]=${latitude}&pickup[longitude]=${longitude}&dropoff[latitude]=${venue.location.latitude}&dropoff[longitude]=${venue.location.longitude}&dropoff[formatted_address]=${encodedAddress}`;
      }

      // Try to open app first
      const canOpenApp = await checkIfUrlCanBeOpened(url);
      
      if (canOpenApp) {
        window.location.href = url;
      } else {
        // If app can't be opened, open website
        if (cabService === 'uber') {
          window.open('https://m.uber.com/looking', '_blank');
        } else {
          window.open('https://book.olacabs.com', '_blank');
        }
      }
    } catch (error) {
      console.error('Error getting location:', error);
      alert('Unable to get your current location. Please check your device settings and try again.');
    } finally {
      setIsLocationLoading(false);
      setIsRideModalVisible(false);
    }
  };

  const getCurrentPosition = () => {
    return new Promise((resolve, reject) => {
      if (!navigator.geolocation) {
        alert('Geolocation is not supported by your browser');
        reject('Geolocation not supported');
        return;
      }

      navigator.geolocation.getCurrentPosition(
        (position) => resolve(position),
        (error) => {
          alert('Unable to get your location. Please check your browser settings.');
          reject(error);
        },
        { enableHighAccuracy: true, timeout: 15000, maximumAge: 0 }
      );
    });
  };

  const checkIfUrlCanBeOpened = async (url) => {
    if (navigator.userAgent.match(/iPhone|iPad|iPod/i)) {
      // iOS devices - can't really check, just return true and let system handle it
      return true;
    }
    
    try {
      const response = await fetch(url);
      return response.ok;
    } catch {
      return false;
    }
  };

  const handleCall = () => {
    if (venueData.nationalPhoneNumber) {
      window.location.href = `tel:${venueData.nationalPhoneNumber}`;
    }
  };

  const handleNavigateToGuestList = () => {
    navigate(`/guestlist/${selectedTour?.id}/${tourDate?.id}`);
  };

  const handleNavigateToSetlist = () => {
    navigate(`/setlist/${selectedTour?.id}/${tourDate?.id}`);
  };

  // Add useEffect to load vendors
  useEffect(() => {
    if (tourDate?.event?.vendors) {
      setVendors(tourDate.event.vendors);
    }
  }, [tourDate?.event?.vendors]);

  const handleAddVendor = () => {
    setIsVendorModalVisible(true);
  };

  const handleVendorClick = (vendor) => {
    // Navigate to vendor profile or handle vendor click
    navigate(`/vendor/${vendor.userId}`);
  };

  const handleNavigateToTechRider = () => {
    navigate(`/techrider/${selectedTour?.id}/${tourDate?.id}`);
  };

  return (
    <div className={styles['event-container']}>
      {/* Venue Card */}
      {venueData.displayName && (
        <div className={styles['event-venue-card']}>
          <div className={styles['event-venue-header']}>
            <img
              src={venueData.placeCoverPhotoUrl || defaultVenueImage}
              alt={venueData.displayName}
              className={styles['event-venue-image']}
            />
            <div className={styles['event-venue-info']}>
              <h3 className={styles['event-venue-name']}>{venueData.displayName}</h3>
              <p className={styles['event-venue-address']}>{venueData.formattedAddress}</p>
            </div>
          </div>
          
          <div className={styles['event-actions']}>
            <button className={styles['event-action-button']} onClick={handleViewMap}>
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <span>View Map</span>
            </button>
            <button className={styles['event-action-button']} onClick={handleGetARide}>
              <FontAwesomeIcon icon={faCar} />
              <span>Get a Ride</span>
            </button>
            <button className={styles['event-action-button']} onClick={handleCall}>
              <FontAwesomeIcon icon={faPhone} />
              <span>Call</span>
            </button>
          </div>
        </div>
      )}
  
      {/* Organizer Section */}
      <button 
        className={styles['event-organizer-section']}
        onClick={() => setIsOrganizerModalVisible(true)}
      >
        <div className={styles['event-organizer-info']}>
          <FontAwesomeIcon icon={faUser} className={styles['event-organizer-icon']} />
          <span className={styles['event-organizer-name']}>
            Organizer: {event?.organizer?.organizerName || 'Add Organizer Name'}
          </span>
        </div>
        <FontAwesomeIcon icon={event?.organizer?.organizerName ? faEdit : faChevronRight} />
      </button>
  
      {/* Navigation Buttons */}
      <button className={styles['event-nav-button']} onClick={handleNavigateToGuestList}>
        <div className={styles['event-nav-button-content']}>
          <FontAwesomeIcon icon={faUserGroup} className={styles['event-nav-icon']} />
          <span>Guest List</span>
        </div>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
  
      <button className={styles['event-nav-button']} onClick={handleNavigateToSetlist}>
        <div className={styles['event-nav-button-content']}>
          <FontAwesomeIcon icon={faMusic} className={styles['event-nav-icon']} />
          <span>Setlist</span>
        </div>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
  
      <button className={styles['event-nav-button']} onClick={handleNavigateToTechRider}>
        <div className={styles['event-nav-button-content']}>
          <FontAwesomeIcon icon={faFileAlt} className={styles['event-nav-icon']} />
          <span>Tech Rider</span>
        </div>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>
  
      {/* Vendors Section */}
      <div className={styles['event-vendor-section']}>
        <button 
          className={styles['event-vendor-header']}
          onClick={handleAddVendor}
        >
          <div className={styles['event-vendor-header-content']}>
            <FontAwesomeIcon icon={faUsers} className={styles['event-nav-icon']} />
            <span>Vendors</span>
            {vendors.length > 0 && (
              <span className={styles['event-vendor-count']}>({vendors.length})</span>
            )}
          </div>
          <FontAwesomeIcon icon={faPlus} />
        </button>
  
        {vendors.length > 0 && (
          <div className={styles['event-vendor-list']}>
            {vendors.map((vendor, index) => (
              <div
                key={vendor.userId || index}
                className={styles['event-vendor-item']}
                onClick={() => handleVendorClick(vendor)}
              >
                <img
                  src={vendor.profilePictureUrl || defaultUserImage}
                  alt={vendor.fullName}
                  className={styles['event-vendor-image']}
                />
                <div className={styles['event-vendor-info']}>
                  <div className={styles['event-vendor-name']}>{vendor.fullName}</div>
                  <div className={styles['event-vendor-subtype']}>{vendor.userName}</div>
                  <div className={styles['event-vendor-subtype']}>{vendor.userSubType}</div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
  
      {/* Modals */}
      {isOrganizerModalVisible && (
        <div className={styles['event-modal-overlay']}>
          <div className={styles['event-modal-content']}>
            <div className={styles['event-modal-header']}>
              <h3 className={styles['event-modal-title']}>Enter Organizer Name</h3>
            </div>
            <input
              type="text"
              className={styles['event-modal-input']}
              value={organizerName}
              onChange={(e) => setOrganizerName(e.target.value)}
              placeholder="Organizer name"
            />
            <div className={styles['event-modal-actions']}>
              <button 
                className={styles['event-action-button']}
                onClick={() => setIsOrganizerModalVisible(false)}
              >
                Cancel
              </button>
              <button
                className={styles['event-action-button']}
                onClick={handleSaveOrganizerName}
                disabled={isSaving}
                style={{ backgroundColor: '#E2384D', color: 'white' }}
              >
                {isSaving ? 'Saving...' : 'Save'}
              </button>
            </div>
          </div>
        </div>
      )}
      
      {/* Ride Modal */}
      {isRideModalVisible && (
        <div className={styles['ride-modal-overlay']} onClick={() => setIsRideModalVisible(false)}>
          <div className={styles['ride-modal-content']} onClick={e => e.stopPropagation()}>
            <div className={styles['ride-modal-header']}>
              <h3 className={styles['ride-modal-title']}>Select Cab Service</h3>
            </div>
            <button 
              className={styles['ride-option-button']}
              onClick={() => handleOpenCabApp('ola')}
            >
              <img src={olaLogo} alt="OLA" className={styles['ride-option-logo']} />
              <span className={styles['ride-option-text']}>OLA</span>
            </button>
            <button 
              className={styles['ride-option-button']}
              onClick={() => handleOpenCabApp('uber')}
            >
              <img src={uberLogo} alt="UBER" className={styles['ride-option-logo']} />
              <span className={styles['ride-option-text']}>UBER</span>
            </button>
          </div>
        </div>
      )}
  
      {/* Loading Overlay */}
      {isLocationLoading && (
        <div className={styles['loading-overlay']}>
          <div className={styles['loading-spinner']}></div>
          <div className={styles['loading-text']}>Getting current location...</div>
        </div>
      )}
    </div>
  );
};

export default Event;