// ChatContext.js
import React, { createContext, useState, useContext, useEffect, useCallback } from 'react';
import { 
  collection, 
  query, 
  orderBy, 
  onSnapshot,
  doc, 
  getDoc,
  getDocs,
  setDoc,
  increment,
  serverTimestamp,
  limit,
  where
} from 'firebase/firestore';
import { getFirestoreInstance } from '../utils/firebaseHelper';
import UserContext from './UserContext';
import { nanoid } from 'nanoid';

export const ChatContext = createContext();

const MAX_MESSAGES_PER_DOC = 100; // Number of messages per document
const MAX_MESSAGE_LENGTH = 1000; // Maximum characters per message
const MESSAGES_BATCH_SIZE = 20;  // How many messages to load at first

export const ChatProvider = ({ children }) => {
  const [messages, setMessages] = useState({});
  const [activeChat, setActiveChat] = useState(null);
  const [loading, setLoading] = useState(false);
  
  const { userData } = useContext(UserContext);
  const db = getFirestoreInstance();

  // Create message batch document ID
  const createMessageBatchId = (timestamp) => {
    return `messages_${timestamp}_${nanoid(9)}`;
  };
  // Setup message listener for active chat
  useEffect(() => {
    if (!activeChat || !userData?.userId) return;

    console.log('[CHAT] Setting up message listener for:', activeChat);
    setLoading(true);

    // Query the latest message batch document
    const q = query(
      collection(db, 'Chats', activeChat, 'messageBatches'),
      orderBy('created', 'desc'),
      limit(1)
    );

    const unsubscribe = onSnapshot(q, async (snapshot) => {
      if (snapshot.empty) {
        setMessages(prev => ({
          ...prev,
          [activeChat]: []
        }));
        setLoading(false);
        return;
      }

      const batchDoc = snapshot.docs[0];
      const batchData = batchDoc.data();
      
      // Get messages from the batch
      const messageList = batchData.messages || [];
      
      setMessages(prev => ({
        ...prev,
        [activeChat]: messageList
      }));
      setLoading(false);
    });

    // Mark messages as read when chat is opened
    if (activeChat) {
      markMessagesAsRead(activeChat);
    }

    return () => {
      console.log('[CHAT] Cleaning up message listener');
      unsubscribe();
    };
  }, [activeChat, userData?.userId, db]);

  // Get or create a chat
  const getOrCreateChat = useCallback(async (otherUserId) => {
    if (!userData?.userId || !otherUserId) return null;

    const chatId = [userData.userId, otherUserId].sort().join('_');
    
    try {
      const chatRef = doc(db, 'Chats', chatId);
      const chatDoc = await getDoc(chatRef);

      if (!chatDoc.exists()) {
        // Create new chat with all required fields
        await setDoc(chatRef, {
          participants: [userData.userId, otherUserId].sort(),
          created: serverTimestamp(),
          lastUpdated: serverTimestamp(),
          lastMessage: '',
          unreadCount: {
            [userData.userId]: 0,
            [otherUserId]: 0
          }
        });

        // Create initial message batch document
        const batchId = createMessageBatchId(Date.now());
        await setDoc(doc(db, 'Chats', chatId, 'messageBatches', batchId), {
          created: serverTimestamp(),
          messages: [],
          messageCount: 0
        });
      }

      return chatId;
    } catch (error) {
      console.error('Error getting/creating chat:', error);
      return null;
    }
  }, [userData?.userId, db]);

  // Mark messages as read
  const markMessagesAsRead = useCallback(async (chatId) => {
    if (!userData?.userId || !chatId) return;

    try {
      await setDoc(doc(db, 'Chats', chatId), {
        [`unreadCount.${userData.userId}`]: 0
      }, { merge: true });
    } catch (error) {
      console.error('Error marking messages as read:', error);
    }
  }, [userData?.userId, db]);
  
  
 // Memoized setActiveChat handler
 const handleSetActiveChat = useCallback((chatId) => {
  console.log('[CHAT] Setting active chat:', chatId);
  setActiveChat(chatId);
}, []);

// Select chat function with memoized handler
const selectChat = useCallback(async (otherUser) => {
  if (!otherUser?.userId || !userData?.userId) {
    console.log('[CHAT] Invalid user data for chat selection');
    return;
  }

  try {
    console.log('[CHAT] Selecting chat with user:', otherUser.userId);
    const chatId = await getOrCreateChat(otherUser.userId);
    
    if (chatId) {
      console.log('[CHAT] Setting active chat:', chatId);
      handleSetActiveChat(chatId);  // Use memoized handler
    }
  } catch (error) {
    console.error('[CHAT] Error selecting chat:', error);
  }
}, [userData?.userId, getOrCreateChat, handleSetActiveChat]);  // Include handleSetActiveChat in deps



  // Send a message
  const sendMessage = useCallback(async (content) => {
    if (!activeChat || !userData?.userId || !content.trim()) return;
  
    try {
      // Get chat document to find other participant
      const chatDoc = await getDoc(doc(db, 'Chats', activeChat));
      const chatData = chatDoc.data();
      const otherUserId = chatData.participants.find(id => id !== userData.userId);
  
      // Get the latest message batch
      const batchQuery = query(
        collection(db, 'Chats', activeChat, 'messageBatches'),
        orderBy('created', 'desc'),
        limit(1)
      );
      
      const batchSnapshot = await getDocs(batchQuery);  // Use getDocs here
      let currentBatch = batchSnapshot.docs[0];
      let batchData = currentBatch?.data() || {};
      let batchId = currentBatch?.id;
  
      // Create new message
      const newMessage = {
        content: content.trim(),
        senderId: userData.userId,
        timestamp: Date.now(),
        read: false
      };
  
      // Check if we need a new batch
      if (!currentBatch || (batchData.messageCount || 0) >= MAX_MESSAGES_PER_DOC) {
        // Create new batch
        batchId = createMessageBatchId(Date.now());
        batchData = {
          created: serverTimestamp(),
          messages: [newMessage],
          messageCount: 1
        };
      } else {
        // Add to existing batch
        batchData.messages = [...(batchData.messages || []), newMessage];
        batchData.messageCount = (batchData.messageCount || 0) + 1;
      }
  
      // Update message batch
      await setDoc(doc(db, 'Chats', activeChat, 'messageBatches', batchId), batchData);
      
      // Update chat document
      await setDoc(doc(db, 'Chats', activeChat), {
        lastMessage: content.trim(),
        lastUpdated: serverTimestamp(),
        [`unreadCount.${otherUserId}`]: increment(1)
      }, { merge: true });
  
    } catch (error) {
      console.error('Error sending message:', error);
    }
  }, [activeChat, userData, db]);

  // Load more messages
  const loadMoreMessages = useCallback(async (beforeTimestamp) => {
    if (!activeChat) return;

    try {
      setLoading(true);
      
      const q = query(
        collection(db, 'Chats', activeChat, 'messageBatches'),
        orderBy('created', 'desc'),
        where('created', '<', beforeTimestamp),
        limit(1)
      );

      const snapshot = await q.get();
      if (!snapshot.empty) {
        const batchData = snapshot.docs[0].data();
        const oldMessages = batchData.messages || [];
        
        setMessages(prev => ({
          ...prev,
          [activeChat]: [...prev[activeChat], ...oldMessages]
        }));
      }
    } catch (error) {
      console.error('Error loading more messages:', error);
    } finally {
      setLoading(false);
    }
  }, [activeChat, db]);

  // Value object with memoization for stability
  const value = React.useMemo(() => ({
    messages,
    activeChat,
    setActiveChat: handleSetActiveChat,
    selectChat,  
    loading,
    sendMessage,
    loadMoreMessages
  }), [
    messages, 
    activeChat, 
    handleSetActiveChat,
    selectChat,  
    loading, 
    sendMessage,
    loadMoreMessages
  ]);

  return (
    <ChatContext.Provider value={value}>
      {children}
    </ChatContext.Provider>
  );
};

export default ChatContext;