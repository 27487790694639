import { getFirestore, doc, setDoc, collection, query, where, getDocs, updateDoc } from 'firebase/firestore';
import { getAuth, createUserWithEmailAndPassword, signInWithEmailAndPassword, sendPasswordResetEmail, updateProfile, updateEmail, sendEmailVerification } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { initializeApp } from 'firebase/app';
import { firebaseConfig } from '../firebaseHelper';

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

export const sendEmailOtp = async (email) => {
  try {
    const currentUser = auth.currentUser;
    if (currentUser) {
      await sendEmailVerification(currentUser);
      return true;
    }
    throw new Error('No authenticated user found');
  } catch (error) {
    console.error('Error sending email verification:', error);
    throw error;
  }
};

export const uploadProfilePhoto = async (file, userId) => {
  try {
    const filename = `profile_${Date.now()}_${file.name}`;
    const storageRef = ref(storage, `users/${userId}/profileMedia/${filename}`);
    
    await uploadBytes(storageRef, file);
    const downloadURL = await getDownloadURL(storageRef);
    
    return downloadURL;
  } catch (error) {
    console.error('Error uploading profile photo:', error);
    throw error;
  }
};

export const getUserData = async (userId) => {
  try {
    const userDoc = await getDocs(query(collection(db, 'users'), where('userId', '==', userId)));
    if (!userDoc.empty) {
      return userDoc.docs[0].data();
    }
    throw new Error('User not found');
  } catch (error) {
    console.error('Error getting user data:', error);
    throw error;
  }
};

export const signUp = async (formData) => {
  try {
    const result = await createUserWithEmailAndPassword(auth, formData.email, formData.password);
    const { uid } = result.user;
    
    // Update user profile with display name
    await updateProfile(result.user, { 
      displayName: formData.fullName 
    });

    // Send verification email
    await sendEmailVerification(result.user);

    // Create user data object
    const signUpDate = new Date().toISOString();
    const userData = {
      uid,
      userId: uid,
      fullName: formData.fullName,
      email: formData.email,
      userType: formData.userType,
      userSubType: formData.userSubType || '',
      userName: formData.userName,
      signUpDate,
      profileCoverPictureUrl: '',
      profilePictureUrl: formData.profilePictureUrl || '',
      toursCount: 0,
      showsCount: 0,
      postsCount: 0,
      phoneNumber: '',
      bio: '',
      birthday: formData.birthday || null,
      address: '',
      city: formData.city || '',
      state: formData.state || '',
      country: formData.country || '',
      profileStatus: '',
      idVerificationStatus: 'Not Verified',
      paymentInfo: '',
      socialLinks: '',
      membershipTier: 'Free',
      profileUrl: `https://ontour.co.in/${formData.userName}`,
      industry: formData.industry || '',
      currency: 'INR (₹)',
      emailVerified: 'No',
      gender: formData.gender || '',
      accountDeletionRequestedAt: null,
      isLoggedIn: true
    };

    // Add specific fields based on user type
    if (formData.userType === 'Manager' || formData.userType === 'Artist Management Company') {
      userData.usersCount = 0;
    } else if (formData.userType === 'Artist') {
      userData.fansCount = 0;
      if (formData.isInBand) {
        userData.bands = formData.bands || [];
      }
    }

    // Save user data to Firestore
    const userRef = doc(db, 'users', uid);
    await setDoc(userRef, userData);

    return { user: result.user, userData };
  } catch (error) {
    console.error('Error during sign-up:', error);
    throw error;
  }
};

export const getUserDataWithCache = async (userId, cache) => {
  const cacheEntry = cache?.get(userId);
  
  if (cacheEntry) {
    return cacheEntry.data;
  }

  const userData = await getUserData(userId);
  cache?.set(userId, { data: userData });
  return userData;
};

export const signIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(auth, email, password);
    const { uid } = userCredential.user;
    const token = await userCredential.user.getIdToken();

    console.log("🔥 Firebase Token:", token); //

    
    // Get user data
    const userDoc = await getDocs(query(collection(db, 'users'), where('userId', '==', uid)));
    if (userDoc.empty) throw new Error('User data not found');
    
    const userData = userDoc.docs[0].data();
    const profileUrl = `https://ontour.co.in/${userData.userName}`;

    // Update login status
    await updateDoc(doc(db, 'users', uid), {
      isLoggedIn: true,
      lastLoginTime: new Date().toISOString(),
      accountDeletionRequestedAt: null,
      profileUrl
    });

    // Return complete user data to be cached in context
    return {
      user: userCredential.user,
      userData: { ...userData, profileUrl },
      token,
      timestamp: Date.now() // For cache invalidation
    };
  } catch (error) {
    console.error('Error during sign-in:', error);
    throw error;
  }
};


export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return true;
  } catch (error) {
    console.error('Error in resetPassword:', error);
    throw error;
  }
};

export const updateUserEmail = async (newEmail) => {
  try {
    const user = auth.currentUser;
    if (!user) throw new Error('No authenticated user found');

    await updateEmail(user, newEmail);
    const userRef = doc(db, 'users', user.uid);
    await updateDoc(userRef, { email: newEmail });
    return true;
  } catch (error) {
    console.error('Error updating email:', error);
    throw error;
  }
};

export const checkUsernameUniqueness = async (username) => {
  try {
    const usersRef = collection(db, 'users');
    const querySnapshot = await getDocs(query(usersRef, where('userName', '==', username)));
    return querySnapshot.empty;
  } catch (error) {
    console.error('Error checking username:', error);
    throw error;
  }
};