import React, { useState, useContext, useEffect, useMemo, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMapMarker,
  faExternalLinkAlt,
  faChevronLeft,
  faChevronRight,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import TourContext from '../../context/TourContext';
import UserContext from '../../context/UserContext';
import moment from 'moment';
import styles from './Route.module.css';

function Route() {
  const { getSelectedTourDate } = useContext(TourContext);
  const { userData } = useContext(UserContext);
  const [currentPage, setCurrentPage] = useState(0);
  const [groupedSchedules, setGroupedSchedules] = useState([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [containerHeight, setContainerHeight] = useState('20%');
  const markersContainerRef = useRef(null);
  const dragStartY = useRef(0);
  const startHeight = useRef(0);

  const tourDate = getSelectedTourDate();

  const getStateFromAddress = (address) => {
    const parts = address.split(',');
    const stateWithPinCode = parts[parts.length - 2]?.trim() || '';
    const stateName = stateWithPinCode.replace(/\d+/g, '').trim();
    return { stateWithPinCode, stateName };
  };

  const groupSchedulesByState = (schedules) => {
    let currentState = '';
    return schedules.reduce((acc, schedule) => {
      const { stateWithPinCode, stateName } = getStateFromAddress(schedule.fromLocation.formattedAddress);
      if (stateName !== currentState) {
        currentState = stateName;
        acc.push([]);
      }
      acc[acc.length - 1].push({ ...schedule, stateWithPinCode });
      return acc;
    }, []);
  };

  useEffect(() => {
    if (tourDate?.schedule?.scheduleList) {
      const filtered = tourDate.schedule.scheduleList
        .filter(schedule => 
          schedule.fromLocation && 
          schedule.fromLocation.displayName && 
          schedule.fromLocation.formattedAddress &&
          schedule.toLocation && 
          schedule.toLocation.displayName && 
          schedule.toLocation.formattedAddress &&
          (userData.userType === 'Manager' ? schedule.isTravelling : true)
        )
        .sort((a, b) => {
          if (a.startTimeDayAfter !== b.startTimeDayAfter) {
            return a.startTimeDayAfter ? 1 : -1;
          }
          const timeA = moment(a.startTime).format('HH:mm');
          const timeB = moment(b.startTime).format('HH:mm');
          return timeA.localeCompare(timeB);
        });
      
      const grouped = groupSchedulesByState(filtered);
      setGroupedSchedules(grouped);
    }
  }, [tourDate, userData.userType]);

  const generateWaypointsString = useMemo(() => {
    return (schedules) => {
      return schedules.map((schedule, index) => {
        if (index === 0) {
          return encodeURIComponent(`${schedule.toLocation.displayName}, ${schedule.toLocation.formattedAddress}`);
        } else if (index === schedules.length - 1) {
          return encodeURIComponent(`${schedule.fromLocation.displayName}, ${schedule.fromLocation.formattedAddress}`);
        } else {
          return [
            encodeURIComponent(`${schedule.fromLocation.displayName}, ${schedule.fromLocation.formattedAddress}`),
            encodeURIComponent(`${schedule.toLocation.displayName}, ${schedule.toLocation.formattedAddress}`)
          ];
        }
      }).flat().join('|');
    };
  }, []);

  const mapUrl = useMemo(() => {
    if (groupedSchedules.length === 0 || currentPage >= groupedSchedules.length) return '';
    
    const currentSchedules = groupedSchedules[currentPage];
    return `https://www.google.com/maps/embed/v1/directions?key=YOUR_GOOGLE_MAPS_API_KEY
      &origin=${encodeURIComponent(`${currentSchedules[0].fromLocation.displayName}, ${currentSchedules[0].fromLocation.formattedAddress}`)}
      &destination=${encodeURIComponent(`${currentSchedules[currentSchedules.length - 1].toLocation.displayName}, ${currentSchedules[currentSchedules.length - 1].toLocation.formattedAddress}`)}
      &waypoints=${generateWaypointsString(currentSchedules)}
      &mode=driving`;
  }, [groupedSchedules, currentPage, generateWaypointsString]);

  const handleOpenInGoogleMaps = () => {
    if (groupedSchedules.length === 0 || currentPage >= groupedSchedules.length) return;
    window.open(mapUrl.replace('/embed/v1/', '/'), '_blank');
  };

  const handleViewLocation = (location) => {
    if (!location?.displayName || !location?.formattedAddress) return;
    const searchQuery = encodeURIComponent(`${location.displayName}, ${location.formattedAddress}`);
    window.open(`https://www.google.com/maps/search/?api=1&query=${searchQuery}`, '_blank');
  };

  // New drag handling functions
  const handleDragStart = (e) => {
    dragStartY.current = e.clientY || e.touches?.[0]?.clientY;
    startHeight.current = markersContainerRef.current.offsetHeight;
    document.addEventListener('mousemove', handleDrag);
    document.addEventListener('mouseup', handleDragEnd);
    document.addEventListener('touchmove', handleDrag);
    document.addEventListener('touchend', handleDragEnd);
  };

  const handleDrag = (e) => {
    const clientY = e.clientY || e.touches?.[0]?.clientY;
    if (!clientY) return;

    const deltaY = dragStartY.current - clientY;
    const newHeight = startHeight.current + deltaY;
    const windowHeight = window.innerHeight;
    const minHeight = windowHeight * 0.2;
    const maxHeight = windowHeight * 0.8;

    if (newHeight >= minHeight && newHeight <= maxHeight) {
      const heightPercentage = (newHeight / windowHeight) * 100;
      setContainerHeight(`${heightPercentage}%`);
      setIsExpanded(heightPercentage > 30);
    }
  };

  const handleDragEnd = () => {
    document.removeEventListener('mousemove', handleDrag);
    document.removeEventListener('mouseup', handleDragEnd);
    document.removeEventListener('touchmove', handleDrag);
    document.removeEventListener('touchend', handleDragEnd);
  };

  const DashedLine = () => (
    <div className={styles['dashed-line-container']}>
      {[...Array(20)].map((_, i) => (
        <div key={i} className={styles.dash} />
      ))}
    </div>
  );

  const LocationBlock = ({ location, letter, time, timeLabel }) => (
    <div className={styles['location-block']}>
      <div className={styles['marker-line']}></div>
      <div className={styles.marker}>
        <span className={styles['marker-letter']}>{letter}</span>
      </div>
      <div className={styles['location-info']}>
        <h4 className={styles['location-name']}>{location.displayName}</h4>
        <p className={styles['location-address']}>{location.formattedAddress}</p>
        <p className={styles['location-time']}>
          {timeLabel} - {time}
        </p>
      </div>
      <button 
        className={styles['map-button']}
        onClick={() => handleViewLocation(location)}
      >
        <FontAwesomeIcon icon={faMapMarker} />
      </button>
    </div>
  );

  
  if (!tourDate?.schedule?.scheduleList || tourDate.schedule.scheduleList.length === 0) {
    return (
      <div className={styles['empty-state']}>
        <FontAwesomeIcon icon={faMapMarker} />
        <p>No schedules found for this date</p>
      </div>
    );
  }
  
  if (groupedSchedules.length === 0) {
    return (
      <div className={styles['empty-state']}>
        <FontAwesomeIcon icon={faMapMarker} />
        <p>No schedules added with location yet</p>
      </div>
    );
  }
  
  return (
    <div className={styles['route-container']}>
      <div className={styles['map-section']}>
        <button 
          className={styles['external-map-button']}
          onClick={handleOpenInGoogleMaps}
        >
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </button>
        <iframe
          title="Route Map"
          className={styles['google-map']}
          src={mapUrl}
          allowFullScreen
        />
      </div>
  
      <div className={styles['locations-section']}>
        <div className={styles['navigation']}>
          <button
            className={`${styles['nav-button']} ${currentPage === 0 ? styles['disabled'] : ''}`}
            onClick={() => setCurrentPage(prev => Math.max(0, prev - 1))}
            disabled={currentPage === 0}
          >
            <FontAwesomeIcon icon={faChevronLeft} />
            <span>Back</span>
          </button>
  
          <button
            className={`${styles['nav-button']} ${currentPage === groupedSchedules.length - 1 ? styles['disabled'] : ''}`}
            onClick={() => setCurrentPage(prev => Math.min(groupedSchedules.length - 1, prev + 1))}
            disabled={currentPage === groupedSchedules.length - 1}
          >
            <span>Next</span>
            <FontAwesomeIcon icon={faChevronRight} />
          </button>
        </div>
  
        <div className={styles['schedule-list']}>
          {groupedSchedules[currentPage]?.map((schedule, index) => {
            const letter = String.fromCharCode(65 + index * 2);
            const nextLetter = String.fromCharCode(65 + index * 2 + 1);
            const startTime = moment(schedule.startTime).format('h:mm A');
            const endTime = moment(schedule.endTime).format('h:mm A');
            const startTimeText = schedule.startTimeDayAfter ? `${startTime} (tomorrow)` : startTime;
            const endTimeText = schedule.endTimeDayAfter ? `${endTime} (tomorrow)` : endTime;
  
            return (
              <div key={index} className={styles['schedule-marker']}>
                <div className={styles['schedule-title']}>
                  <h3>{schedule.title}</h3>
                  <button className={styles['info-button']}>
                    <FontAwesomeIcon icon={faInfoCircle} />
                  </button>
                </div>
  
                <LocationBlock
                  location={schedule.fromLocation}
                  letter={letter}
                  time={startTimeText}
                  timeLabel="Start By"
                />
  
                <LocationBlock
                  location={schedule.toLocation}
                  letter={nextLetter}
                  time={endTimeText}
                  timeLabel="Reach By"
                />
              </div>
            );
          })}
        </div>
      </div>
      <DashedLine />
    </div>
  );
}

export default Route;