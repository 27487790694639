import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';


export const firebaseConfig = {
  apiKey: "AIzaSyBBq5pz4AE4Q9aJDM6Wq5BU1vYZu972WeQ",
  authDomain: "ontourauth.firebaseapp.com",
  projectId: "ontourauth",
  storageBucket: "ontourauth.appspot.com",
  messagingSenderId: "661112341423",
  databaseURL: "https://ontourauth-default-rtdb.asia-southeast1.firebasedatabase.app",
  appId: "1:661112341423:web:8027ceaf2facb02212255a",
  measurementId: "G-MZL0T5JCS8"
};

let firebaseAppInstance;

export const getFirebaseApp = () => {
  if (!firebaseAppInstance) {
    firebaseAppInstance = initializeApp(firebaseConfig);
  }
  return firebaseAppInstance;
};

export const firebaseInstances = {
  auth: getAuth(getFirebaseApp()),
  firestore: getFirestore(getFirebaseApp()),
  storage: getStorage(getFirebaseApp()),
};

export const getFirestoreInstance = () => firebaseInstances.firestore;

export const saveUserDataToLocalStorage = (userData) => {
  localStorage.setItem('userData', JSON.stringify(userData));
};

export const getUserDataFromLocalStorage = () => {
  const userData = localStorage.getItem('userData');
  return userData ? JSON.parse(userData) : null;
};

export const removeUserDataFromLocalStorage = () => {
  localStorage.removeItem('userData');
};