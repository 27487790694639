import React from 'react';
import './label.module.css';

const Label = ({ children, htmlFor, className = '' }) => {
  return (
    <label 
      htmlFor={htmlFor}
      className={`ui-label ${className}`}
    >
      {children}
    </label>
  );
};

export default Label;