import React, { useEffect, useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getFirestore, doc, updateDoc, } from 'firebase/firestore';
import { 
  faCheck, 
  faTimes, 
  faInfoCircle,
  faWallet,
  faUser,
  faHome,
  faCog,
  faSignOutAlt,
  faExclamationTriangle
} from '@fortawesome/free-solid-svg-icons';
import TopBar from '../TopBar';
import LeftMenu from '../LeftMenu';
import RightMenu from '../RightMenu';
import UserContext from '../../context/UserContext';
import TourContext from '../../context/TourContext';
import styles from './Membership.module.css';
import { 
  createPlan, 
  createSubscription, 
  saveMembershipDetails, 
  cancelSubscription, 
  handleSubscriptionCancellation,
  checkAndUpdateMembershipStatus
} from '../../utils/membershipUtils';
import MembershipHistory from './MembershipHistory';
import SuccessPopup from '../common/SuccessPopup';

const Membership = () => {
  const { userData, setUserData } = useContext(UserContext);
  const { tours } = useContext(TourContext);
  const [selectedTier, setSelectedTier] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('plans');
  const [error, setError] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showSuccessPopup, setShowSuccessPopup] = useState(false);
  const [successTier, setSuccessTier] = useState(null);
  const [isDowngrade, setIsDowngrade] = useState(false);
  const [showCancelPopup, setShowCancelPopup] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (userData?.userId) {
      checkAndUpdateMembershipStatus(userData.userId);
    }
  }, [userData?.userId]);

  const membershipPlans = [
    {
      name: 'Free',
      description: 'Perfect for trying out',
      price: 'Free',
      features: [
        { text: 'Create 1 Tour', included: true },
        { text: '10 Schedules Per Tour Date', included: true },
        { text: '5 Tour Dates per Tour', included: true },
        { text: 'Upload Travel Tickets', included: true },
        { text: 'See Tour Date Availability', included: true },
        { text: 'Record Expenses', included: false },
        { text: 'See Vendor Inventory', included: false },
        { text: 'Search Users', included: false },
        { text: 'Account Verification', included: false },
      ]
    },
    {
      name: 'Basic',
      description: 'For stepping up your game',
      price: '₹499',
      features: [
        { text: 'Create 10 Tours', included: true },
        { text: 'Unlimited Schedules', included: true },
        { text: '10 Tour Dates per Tour', included: true },
        { text: 'Upload Travel Tickets', included: true },
        { text: 'See Tour Date Availability', included: true },
        { text: 'Record Expenses', included: true },
        { text: 'See Vendor Inventory', included: true },
        { text: 'Search Users', included: true },
        { text: 'Account Verification', included: false },
      ]
    },
    {
      name: 'Pro',
      description: 'For professional artists',
      price: '₹1,499',
      features: [
        { text: 'Unlimited Tours', included: true },
        { text: 'Unlimited Schedules', included: true },
        { text: '15 Tour Dates per Tour', included: true },
        { text: 'All Basic Features', included: true },
        { text: 'Account Verification', included: true },
        { text: 'Send Messages Outside Tour', included: true },
        { text: 'Search Filters', included: true },
        { text: 'Custom Schedule Visibility', included: false },
        { text: 'Download Expense Reports', included: false },
      ]
    },
    {
      name: 'Elite',
      description: 'For industry professionals',
      price: '₹10',
      hasTrial: true,
      trialDays: 14,
      features: [
        { text: 'Unlimited Everything', included: true },
        { text: 'All Pro Features', included: true },
        { text: 'Download Expense Reports', included: true },
        { text: 'Past Tours & Shows Access', included: true },
        { text: 'Custom Schedule Visibility', included: true },
        { text: 'Set Hotel Visibility', included: true },
        { text: 'Set Tickets Visibility', included: true },
        { text: 'Hide Email from Users', included: true },
        { text: 'Private Account Option', included: true },
      ]
    }
  
  ];

 
  // Helper to get tier-specific benefits for success popup
const getUniqueBenefits = (tierName) => {
  // Special case for trial
  if (tierName === 'Elite Trial') {
    return [
      'Unlimited Everything',
      'All Pro Features',
      'Download Expense Reports',
      'Past Tours & Shows Access', 
      'Custom Schedule Visibility',
      'Set Hotel Visibility',
      'Set Tickets Visibility',
      'Hide Email from Users',
      'Private Account Option',
      '14-Day Free Trial'
    ];
  }
  
  const plan = membershipPlans.find(p => p.name === tierName);
  if (!plan) return [];
  
  // Get previous tier (before upgrade)
  const previousTier = userData?.previousMembershipTier || userData?.membershipTier || 'Free';
  const previousPlan = membershipPlans.find(p => p.name === previousTier);
  
  // If upgrading from a lower tier, show only the new features gained
  if (previousTier !== tierName) {
    const newFeatures = [];
    
    plan.features.forEach(feature => {
      if (feature.included) {
        // Check if this feature was not included in the previous plan
        const isNewFeature = !previousPlan?.features.some(
          f => f.text === feature.text && f.included
        );
        
        if (isNewFeature) {
          newFeatures.push(feature.text);
        }
      }
    });
    
    if (newFeatures.length > 0) {
      return newFeatures;
    }
  }
  
  // If not upgrading or no differential benefits found, return all features
  return plan.features
    .filter(f => f.included)
    .map(f => f.text);
};


const formatMembershipDate = (dateValue) => {
  if (!dateValue) return 'N/A';
  
  try {
    // Handle Firestore Timestamp objects
    if (typeof dateValue.toDate === 'function') {
      return dateValue.toDate().toLocaleDateString();
    }
    
    // Handle string dates
    if (typeof dateValue === 'string') {
      const date = new Date(dateValue);
      if (!isNaN(date.getTime())) {
        return date.toLocaleDateString();
      }
    }
    
    // Handle Date objects and timestamps
    if (dateValue instanceof Date || typeof dateValue === 'number') {
      return new Date(dateValue).toLocaleDateString();
    }
    
    return 'Invalid Date Format';
  } catch (error) {
    console.error('Error formatting date:', error, dateValue);
    return 'Date Error';
  }
};

  const startTrial = async () => {
    if (!userData) {
      navigate('/login');
      return;
    }
  
    try {
      setIsLoading(true);
      setError(null);
      
      // Calculate trial end date (14 days from now)
      const startDate = new Date();
      const endDate = new Date();
      endDate.setDate(endDate.getDate() + 14);
      
      const db = getFirestore();
      const userRef = doc(db, 'users', userData.userId);
      
      // Update user document with trial info
      await updateDoc(userRef, {
        membershipTier: 'Elite',
        membershipTrial: {
          active: true,
          disabled: false,
          startDate,
          endDate,
          tier: 'Elite'
        },
        membershipStartDate: startDate,
        membershipEndDate: endDate
      });
      
      // Schedule trial end check
      await fetch('https://asia-south1-ontourauth.cloudfunctions.net/scheduleTriggerTrialEnd', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          userId: userData.userId,
          tier: 'Elite',
          endDate: endDate.toISOString()
        })
      });
      
      // Update user data in context
      setUserData(prev => ({
        ...prev,
        membershipTier: 'Elite',
        membershipTrial: {
          active: true,
          disabled: false,
          startDate,
          endDate,
          tier: 'Elite'
        },
        membershipStartDate: startDate,
        membershipEndDate: endDate
      }));
      
      // Show success popup
      setSuccessTier('Elite Trial');
      setShowSuccessPopup(true);
      
    } catch (error) {
      console.error('Error starting trial:', error);
      setError('Error starting trial. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpgrade = async (tier) => {
    if (!userData) {
      navigate('/login');
      return;
    }
  
    const currentTierIndex = membershipPlans.findIndex(p => p.name === userData.membershipTier);
    const newTierIndex = membershipPlans.findIndex(p => p.name === tier);
    const isDowngrade = newTierIndex < currentTierIndex;
    
    // Special case for Free tier
    if (tier === 'Free') {
      if (window.confirm('Are you sure you want to switch to the Free plan? Your current subscription will be cancelled, but you will retain access until the end of your billing cycle.')) {
        await handleCancelMembership();
      }
      return;
    }
  
    // Confirm downgrade with warning
    if (isDowngrade) {
      const shouldContinue = window.confirm(
        `Are you sure you want to downgrade to ${tier}? Your current subscription will be cancelled and you will be charged for the new tier immediately, but you'll retain access to your current plan until the end of your billing cycle.`
      );
      if (!shouldContinue) return;
    }
  
    try {
      setIsLoading(true);
      setError(null);
      const tierDetails = membershipPlans.find(plan => plan.name === tier);
      if (!tierDetails) throw new Error('Invalid membership tier');
  
      // Check if user is already on a plan and trying to change tiers
      const isChangingTier = userData.subscriptionId && userData.membershipTier !== tier;
      
      if (isChangingTier) {
        // Check if the subscription is already cancelled or still active
        const isSubscriptionActive = userData.subscriptionStatus !== 'cancelled';
        
        if (isSubscriptionActive) {
          try {
            // Only try to cancel if the subscription is active
            await cancelSubscription(userData.subscriptionId);
            await handleSubscriptionCancellation(userData.userId, userData.subscriptionId);
          } catch (error) {
            // If cancellation fails but it's because the subscription is already cancelled,
            // we can still proceed with creating a new subscription
            if (!error.message.includes("not cancellable in cancelled status")) {
              throw error; // Re-throw if it's a different error
            }
            console.log('Subscription already cancelled, proceeding with new subscription');
          }
        } else {
          console.log('Subscription already cancelled, skipping cancellation step');
        }
        
        console.log(`Changing from ${userData.membershipTier} to ${tier}`);
      }
      
      // Create plan in Razorpay
      const plan = await createPlan(tier, userData);
      console.log('Created plan:', plan);
  
      // Create subscription
      const subscription = await createSubscription(plan.id, userData, tier);
      console.log('Created subscription:', subscription);
  
      // Initialize Razorpay payment
      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        subscription_id: subscription.id,
        name: 'On Tour',
        description: isDowngrade ? `Downgrade to ${tier} Membership` : `Upgrade to ${tier} Membership`,
        image: 'https://ontour.co.in/static/media/logo.004c25c8bff3fe32ff88.png',
        prefill: {
          name: userData.fullName,
          email: userData.email,
          contact: userData.phoneNumber
        },
        theme: {
          color: '#E2384D'
        },
        handler: async function(response) {
          try {
            setIsLoading(true);
            
            // Save membership details to database
            await saveMembershipDetails(userData.userId, subscription, response);
            
            // Direct Firestore update for additional reliability
            const db = getFirestore();
            await updateDoc(doc(db, 'users', userData.userId), {
              membershipTier: tier,
              subscriptionStatus: 'active',
              lastUpdated: new Date()
            });
            console.log(`Direct Firestore update completed, setting tier to: ${tier}`);
            
            // Calculate the correct dates based on subscription data
            const startDate = subscription.current_start ? 
                 new Date(subscription.current_start * 1000) : 
                 new Date();
                 
// Use Razorpay's end date if available, otherwise calculate based on same day next month
const endDate = subscription.current_end ? 
               new Date(subscription.current_end * 1000) : 
               getNextMonthSameDay(startDate);
            
            if (userData?.membershipTrial?.active) {
              const db = getFirestore();
              await updateDoc(doc(db, 'users', userData.userId), {
                'membershipTrial.disabled': true
              });
            }
            
            const previousTier = userData.membershipTier;
            
            // Update local state with the correct dates
            setUserData(prev => ({
              ...prev,
              membershipTier: tier,
              subscriptionId: subscription.id,
              membershipStartDate: startDate,
              membershipEndDate: endDate,
              previousMembershipTier: previousTier,
              subscriptionStatus: 'active',
              membershipTrial: prev.membershipTrial ? {
                ...prev.membershipTrial,
                disabled: true
              } : null
            }));
      
            // Show success popup with appropriate message
            setSuccessTier(tier);
            setIsDowngrade(isDowngrade);
            setShowSuccessPopup(true);
          } catch (error) {
            console.error('Error processing payment:', error);
            setError('Error processing payment. Please check your membership status or contact support.');
            alert('Error processing payment. We\'ll check your status shortly.');
          } finally {
            setIsLoading(false);
          }
        },
        modal: {
          ondismiss: function() {
            setIsLoading(false);
          }
        }
      };
  
      const rzp = new window.Razorpay(options);
      rzp.open();
  
    } catch (error) {
      console.error('Error changing membership tier:', error);
      setError('Error changing membership tier. Please try again later.');
      alert('Error changing membership. Please try again.');
      setIsLoading(false);
    }
  };


  // New cancel membership popup functions
  const openCancelMembershipPopup = () => {
    if (!userData?.subscriptionId) {
      alert('No active subscription found');
      return;
    }
    setShowCancelPopup(true);
  };

  const handleCancelMembership = async () => {
    setIsLoading(true);
    try {
      if (!userData?.subscriptionId) {
        return;
      }
  
      // Cancel subscription in Razorpay
      await cancelSubscription(userData.subscriptionId);
      
      // Handle cancellation in Firestore and get the result
      const cancellationResult = await handleSubscriptionCancellation(
        userData.userId, 
        userData.subscriptionId
      );
      
      // Only use the result from the backend to ensure consistency
      if (cancellationResult && cancellationResult.success) {
        setUserData(prev => ({
          ...prev,
          subscriptionStatus: 'cancelled',
          subscriptionCancelledAt: new Date(),
          // Use the tier determined by the backend for consistency
          membershipTier: cancellationResult.currentTier
        }));
        
        // Close popup and give feedback
        setShowCancelPopup(false);
        setError({
          type: 'success',
          message: cancellationResult.keepCurrentTier ? 
            'Your membership has been cancelled. You will have access until the end of your current billing cycle.' :
            'Your membership has been cancelled and reverted to Free tier.'
        });
      } else {
        throw new Error('Cancellation process did not return a valid result');
      }
    } catch (error) {
      console.error('Error:', error);
      setError({
        type: 'error',
        message: 'Error cancelling membership. Please try again.'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const BottomNav = () => (
    <div className={styles['bottom-nav']}>
      <button onClick={() => navigate('/dashboard')} className={styles['nav-item']}>
        <FontAwesomeIcon icon={faHome} />
        <span>Home</span>
      </button>
      <button onClick={() => navigate('/profile')} className={styles['nav-item']}>
        <FontAwesomeIcon icon={faUser} />
        <span>Profile</span>
      </button>
      <button className={`${styles['nav-item']} ${styles.active}`}>
        <FontAwesomeIcon icon={faWallet} />
        <span>Membership</span>
      </button>
      <button onClick={() => navigate('/settings')} className={styles['nav-item']}>
        <FontAwesomeIcon icon={faCog} />
        <span>Settings</span>
      </button>
    </div>
  );

  function getNextMonthSameDay(date) {
    const nextMonth = new Date(date);
    nextMonth.setMonth(nextMonth.getMonth() + 1);
    
    // Handle edge case for months with fewer days
    // If the original day doesn't exist in the next month (e.g., Jan 31 -> Feb 28/29)
    // the date will automatically roll over to the next month
    // So we need to check and adjust if that happened
    if (nextMonth.getDate() !== date.getDate()) {
      // Set to last day of previous month
      nextMonth.setDate(0);
    }
    
    return nextMonth;
  }

  // Cancel membership popup component
  const CancelMembershipPopup = () => {
    if (!showCancelPopup) return null;
    
    return (
      <div className={styles['cancel-popup-overlay']}>
        <div className={styles['cancel-popup-content']}>
          <div className={styles['cancel-popup-header']}>
            <FontAwesomeIcon icon={faExclamationTriangle} className={styles['cancel-icon']} />
            <h3 className={styles['cancel-title']}>Cancel Membership?</h3>
          </div>
          
          <div className={styles['cancel-popup-body']}>
            <p>Are you sure you want to cancel your membership? You will still have access until the end of your current billing cycle.</p>
            
            {userData?.membershipEndDate && (
              <p className={styles['access-until']}>
                Your access will continue until: <span className={styles['date-highlight']}>
                {formatMembershipDate(userData.membershipEndDate)}
                </span>
              </p>
            )}
          </div>
          
          <div className={styles['cancel-popup-footer']}>
            <button 
              onClick={() => setShowCancelPopup(false)}
              className={styles['cancel-popup-button-secondary']}
              disabled={isLoading}
            >
              Keep Membership
            </button>
            <button 
              onClick={handleCancelMembership}
              className={styles['cancel-popup-button-primary']}
              disabled={isLoading}
            >
              {isLoading ? 'Processing...' : 'Yes, Cancel'}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <TopBar />
      <div className={styles['main-layout']}>
        {!isMobile && <LeftMenu />}
        <div className={styles['membership-content']}>
        <div className={styles['membership-header']}>
  <h1>Membership</h1>
  {userData?.membershipTier && (
    <div className={styles['current-plan-alert']}>
      <div className={styles['alert-content']}>
        Your current plan: <span className={styles.highlight}>
          {userData.membershipTier}
          {userData?.membershipTrial?.active && <span className={styles['trial-badge']}>(Trial)</span>}
        </span>
        {userData.membershipEndDate && (
          <span className={styles['end-date']}>
            {userData.subscriptionStatus === 'cancelled' ? 
              `Ends on: ${formatMembershipDate(userData.membershipEndDate)}` : 
              `Next Invoice: ${formatMembershipDate(userData.membershipEndDate)}`}
          </span>
        )}
      </div>
    </div>
  )}
</div>

          <div className={styles['tab-container']}>
            <div className={styles.tabs}>
              <div 
                className={`${styles.tab} ${activeTab === 'plans' ? styles.active : ''}`}
                onClick={() => setActiveTab('plans')}
              >
                Membership Plans
              </div>
              <div 
                className={`${styles.tab} ${activeTab === 'history' ? styles.active : ''}`}
                onClick={() => setActiveTab('history')}
              >
                Payment History
              </div>
            </div>
          </div>

          {isLoading && (
            <div className={styles['loading-overlay']}>
              <div className={styles['loading-spinner']} />
            </div>
          )}

          {error && (
            <div className={styles[error.type === 'success' ? 'success-message' : 'error-message']}>
              {error.message || error}
            </div>
          )}

          {activeTab === 'plans' ? (
            <div className={styles['plans-grid']}>
            {membershipPlans.map((plan) => (
  <div key={plan.name} 
    className={`${styles['plan-card']} ${userData?.membershipTier === plan.name ? styles.current : ''}`}
  >
    <div className={styles['plan-header']}>
      <h2 className={styles['plan-name']}>{plan.name}</h2>
      <p className={styles['plan-description']}>{plan.description}</p>
      <div className={styles['plan-price']}>
        <span className={styles.amount}>{plan.price}</span>
        {plan.name !== 'Free' && <span className={styles.period}>/month</span>}
      </div>
    </div>
    <div className={styles['plan-content']}>
      <ul className={styles['feature-list']}>
        {plan.features.map((feature, index) => (
          <li key={index} className={styles['feature-item']}>
            <FontAwesomeIcon
              icon={feature.included ? faCheck : faTimes}
              className={feature.included ? styles.included : styles.excluded}
            />
            <span>{feature.text}</span>
          </li>
        ))}
      </ul>
      
      {/* Logic for showing appropriate buttons */}
      {(() => {
        // Current tier - show cancel button if paid subscription
        if (userData?.membershipTier === plan.name) {
          // Don't show cancel for Free tier
          if (plan.name === 'Free') {
            return null;
          }
          
          // If on trial, show upgrade button instead of cancel
          if (userData?.membershipTrial?.active && plan.name === 'Elite') {
            return (
              <button 
                onClick={() => handleUpgrade(plan.name)}
                className={`${styles['upgrade-button']} ${isLoading ? styles['button-loading'] : ''}`}
                disabled={isLoading}
              >
                {isLoading ? 'Processing...' : 'Subscribe to Elite'}
              </button>
            );
          }
          
          // Show cancel button for paid subscription
          return (
            <button 
              onClick={openCancelMembershipPopup}
              className={`${styles['cancel-button']} ${isLoading ? styles['button-loading'] : ''}`}
              disabled={isLoading}
            >
              {isLoading ? 'Processing...' : 'Cancel Membership'}
            </button>
          );
        }
        
        // Show appropriate upgrade/downgrade buttons for other plans
        // For Elite, also show trial option if eligible
        if (plan.name === 'Elite' && !userData?.membershipTrial?.active && !userData?.hasCompletedTrial) {
          return (
            <>
              <button 
                onClick={startTrial}
                className={`${styles['trial-button']} ${isLoading ? styles['button-loading'] : ''}`}
                disabled={isLoading}
              >
                {isLoading ? 'Processing...' : 'Start 14-Day Free Trial'}
              </button>
              <button 
                onClick={() => handleUpgrade(plan.name)}
                className={`${styles['upgrade-button']} ${isLoading ? styles['button-loading'] : ''}`}
                disabled={isLoading}
              >
                {isLoading ? 'Processing...' : 'Subscribe to Elite'}
              </button>
            </>
          );
        }
        
        // For Free plan, show "Switch to Free" instead of "Upgrade to Free"
        if (plan.name === 'Free') {
          return (
            <button 
              onClick={() => handleUpgrade(plan.name)}
              className={`${styles['downgrade-button']} ${isLoading ? styles['button-loading'] : ''}`}
              disabled={isLoading}
            >
              {isLoading ? 'Processing...' : 'Switch to Free'}
            </button>
          );
        }
        
        // For other paid tiers, determine if it's an upgrade or downgrade
        const currentTierIndex = membershipPlans.findIndex(p => p.name === userData?.membershipTier);
        const thisPlanIndex = membershipPlans.findIndex(p => p.name === plan.name);
        const isUpgrade = thisPlanIndex > currentTierIndex;
        
        return (
          <button 
            onClick={() => handleUpgrade(plan.name)}
            className={`${isUpgrade ? styles['upgrade-button'] : styles['downgrade-button']} ${isLoading ? styles['button-loading'] : ''}`}
            disabled={isLoading}
          >
            {isLoading ? 'Processing...' : 
              (isUpgrade ? `Upgrade to ${plan.name}` : `Downgrade to ${plan.name}`)
            }
          </button>
        );
      })()}
    </div>
  </div>
))}
            </div>
          ) : (
            <MembershipHistory userId={userData?.userId} />
          )}
        </div>
      </div>
      
      {isMobile && <BottomNav />}

      {/* Success Popup */}
      <SuccessPopup 
        isOpen={showSuccessPopup}
        onClose={() => setShowSuccessPopup(false)}
        tierName={successTier}
        benefits={getUniqueBenefits(successTier)}
        isUpgrade={!isDowngrade && userData?.previousMembershipTier && userData?.membershipTier !== userData.previousMembershipTier}
        isDowngrade={isDowngrade}
      />

      {/* Cancel Membership Popup */}
      <CancelMembershipPopup />
    </div>
  );
};

export default Membership;