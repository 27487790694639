import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTicketAlt, 
  faPlus, 
  faEdit, 
  faTrash, 
  faLink,
  faChevronLeft,
  faChevronRight ,
  faInfoCircle,
  faExclamationTriangle 
} from '@fortawesome/free-solid-svg-icons';
import TourContext from '../../context/TourContext';
import UserContext from '../../context/UserContext';
import TopBar from '../TopBar';
import LeftMenu from '../LeftMenu';
import RightMenu from '../RightMenu';
import TicketModal from './TicketModal';
import styles from './Tickets.module.css';
import defaultProfilePic from '../../assets/Images/defaultUserProfilePicture.png';
import FileViewerModal from '../common/FileViewerModal'; 
import { doc, updateDoc, getDoc, getFirestore } from 'firebase/firestore';

const Tickets = () => {
  const { selectedTour, selectedDate, setSelectedDate, setSelectedTour, updateTourData } = useContext(TourContext);
  const { userData } = useContext(UserContext);
  const [tickets, setTickets] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [editingTicket, setEditingTicket] = useState(null);
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
const [isDeleting, setIsDeleting] = useState(false);

  // Format date for display
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    });
  };

  const [deleteConfirmation, setDeleteConfirmation] = useState({
    isOpen: false,
    ticketId: null,
    date: null
  });


  // Handle date scroll
  const handleScroll = (direction) => {
    const scrollContainer = document.getElementById('dateScroller');
    if (scrollContainer) {
      const scrollAmount = direction === 'left' ? -200 : 200;
      scrollContainer.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  // Handle date selection
  const handleDateSelect = (date) => {
    setSelectedDate(date.id);
  };

  // Update tickets when selected date changes
  useEffect(() => {
    if (selectedTour && selectedDate) {
      // Find the actual date from tourDates using dateId
      const tourDate = selectedTour.tourDates?.find(date => date.id === selectedDate);
      const actualDate = tourDate?.date;
  
      console.log('Checking for tickets with date:', actualDate);
      console.log('Tour ticket details:', selectedTour.ticketDetails);
  
      // Find tickets for the selected date
      const dateTickets = selectedTour.ticketDetails?.find(
        detail => {
          const detailDate = new Date(detail.date).toISOString().split('T')[0];
          const compareDate = new Date(actualDate).toISOString().split('T')[0];
          return detailDate === compareDate;
        }
      );
  
      if (dateTickets?.tickets) {
        setTickets(dateTickets.tickets);
      } else {
        setTickets([]);
      }
    }
  }, [selectedTour, selectedDate]);

  const handleAddTicket = () => {
    setEditingTicket(null);
    setIsModalOpen(true);
  };

  const handleEditTicket = (ticket) => {
    setEditingTicket(ticket);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setEditingTicket(null);
  };

const handleDeleteTicket = (ticket, date) => {
    setDeleteConfirmation({
      isOpen: true,
      ticketId: ticket.id,
      date: date
    });
  };

  const confirmDelete = async () => {
    try {
      setIsDeleting(true); // Start loading
      const db = getFirestore();
      const tourRef = doc(db, 'Tours', selectedTour.id);
      const tourDoc = await getDoc(tourRef);
      const tourData = tourDoc.data();
  
      let ticketDetails = tourData.ticketDetails || [];
      const dateIndex = ticketDetails.findIndex(item => {
        const itemDate = new Date(item.date).toISOString().split('T')[0];
        const compareDate = new Date(deleteConfirmation.date).toISOString().split('T')[0];
        return itemDate === compareDate;
      });
  
      if (dateIndex !== -1) {
        ticketDetails[dateIndex].tickets = ticketDetails[dateIndex].tickets.filter(
          ticket => ticket.id !== deleteConfirmation.ticketId
        );
  
        if (ticketDetails[dateIndex].tickets.length === 0) {
          ticketDetails = ticketDetails.filter((_, index) => index !== dateIndex);
        }
  
        await updateDoc(tourRef, { ticketDetails });
        await updateTourData(selectedTour.id);
      }
    } catch (error) {
      console.error('Error deleting ticket:', error);
      alert('Failed to delete ticket. Please try again.');
    } finally {
      setIsDeleting(false); // Stop loading
      setDeleteConfirmation({
        isOpen: false,
        ticketId: null,
        date: null
      });
    }
  };

  // Add this function inside the Tickets component
const getTourMemberById = (userId) => {
  if (!selectedTour) return null;

  // Check manager
  if (selectedTour.createdBy?.userId === userId) {
    return {
      fullName: selectedTour.createdBy.fullName,
      profilePictureUrl: selectedTour.createdBy.profilePictureUrl,
      userType: 'Manager'
    };
  }

  // Check artists
  const artist = selectedTour.artistDetails?.find(a => a.userId === userId);
  if (artist) {
    return {
      fullName: artist.fullName,
      profilePictureUrl: artist.profilePictureUrl,
      userType: 'Artist'
    };
  }

  // Check crew
  const crew = selectedTour.crewDetails?.find(c => c.userId === userId);
  if (crew) {
    return {
      fullName: crew.fullName,
      profilePictureUrl: crew.profilePictureUrl,
      userType: 'Crew'
    };
  }

  return null;
};

   // Group tickets by passenger name

  
  // Update the groupTicketsByPassenger function to handle the new structure
  const groupTicketsByPassenger = (tickets) => {
    const grouped = {};
    tickets.forEach((ticket) => {
      if (ticket.passengers && ticket.passengers.length > 0) {
        const passengerNames = ticket.passengers.map(p => p.fullName).join(', ');
        if (!grouped[passengerNames]) {
          grouped[passengerNames] = [];
        }
        grouped[passengerNames].push(ticket);
      }
    });
    return grouped;
  };

  // Get airline icon
  const getAirlineIcon = (airline, travelMode) => {
    const icons = {
      IndiGo: require('../../assets/Images/indigologo.png'),
      'American Airlines': require('../../assets/Images/americanairlineslogo.png'), 
      'Alaska Airlines': require('../../assets/Images/alaskaairlineslogo.png'), 
      'Air Canada': require('../../assets/Images/aircanadalogo.png'),
      'Air China': require('../../assets/Images/airchinalogo.png'),  
      'Air India': require('../../assets/Images/airindialogo.png'), 
      'Air India Express': require('../../assets/Images/airindiaexpresslogo.png'), 
      'Air Astana': require('../../assets/Images/airastanalogo.png'), 
      'Austrian Airlines': require('../../assets/Images/austrianairlineslogo.png'), 
  Vistara: require('../../assets/Images/vistaralogo.png'), 
  'Akasa Air': require('../../assets/Images/akasaairlogo.png'), 
  'Air France': require('../../assets/Images/airfrancelogo.png'), 
  'Cathay Pacific': require('../../assets/Images/cathaypacificlogo.png'), 
  'British Airways': require('../../assets/Images/britishairwayslogo.png'), 
  'Brussels Airlines': require('../../assets/Images/brusselsairlineslogo.png'), 
  'Delta Airlines': require('../../assets/Images/deltaairlineslogo.png'), 
  Emirates: require('../../assets/Images/emirateslogo.png'), 
  Ethithad: require('../../assets/Images/etihadlogo.png'), 
  'Ethiopian Airlines': require('../../assets/Images/ethiopianairlineslogo.png'), 
  Finnair: require('../../assets/Images/finnairlogo.png'),  
  flynas: require('../../assets/Images/flynaslogo.png'), 
  'ITA Airways': require('../../assets/Images/itaairwayslogo.png'), 
  'KLM Airlines': require('../../assets/Images/klmairlineslogo.png'), 
  'Korean Air': require('../../assets/Images/koreanairlogo.png'), 
  Lufthansa: require('../../assets/Images/lufthansalogo.png'), 
  'Qantas': require('../../assets/Images/qantaslogo.png'), 
  'Qatar Airways': require('../../assets/Images/qatarairwayslogo.png'), 
  'Royal Air Maroc': require('../../assets/Images/royalairmaroclogo.png'), 
  'Salam Air': require('../../assets/Images/salamairlogo.png'), 
  'Saudi Arabian': require('../../assets/Images/airindialogo.png'),
  'Singapore Airlines': require('../../assets/Images/singaporeairlineslogo.png'), 
  'SriLankan Airlines': require('../../assets/Images/srilankanairlineslogo.png'), 
  SpiceJet: require('../../assets/Images/spicejetlogo.png'), 
  'Swiss Airlines': require('../../assets/Images/swissairlineslogo.png'), 
  'Turkish Airlines': require('../../assets/Images/turkishairlineslogo.png'), 
  'United Airlines': require('../../assets/Images/unitedairlineslogo.png'), 
  'Virgin Atlantic': require('../../assets/Images/virginatlanticlogo.png'), 

      Flight: require('../../assets/Images/flightlogo.png'),
      Bus: require('../../assets/Images/bus.png'),
      Train: require('../../assets/Images/train.png'),
    };
    return icons[airline] || icons[travelMode];
  };

  const renderTicketCards = () => {
    const groupedTickets = groupTicketsByPassenger(tickets);
  
    if (tickets.length === 0) {
      return (
        <div className={styles['empty-state']}>
          <FontAwesomeIcon icon={faTicketAlt} className={styles['empty-icon']} />
          <p className={styles['empty-text']}>No tickets for this date</p>
        </div>
      );
    }
  
    return Object.entries(groupedTickets).map(([passengerNames, passengerTickets]) => (
      <div key={passengerNames} className={styles['passenger-section']}>
        <div className={styles['passenger-name']}>
          <div className={styles['passenger-list']}>
            {passengerTickets[0].passengers.map((passenger, index) => (
              <div key={passenger.userId} className={styles['passenger-item']}>
                <img 
                  src={getTourMemberById(passenger.userId)?.profilePictureUrl || defaultProfilePic}
                  alt={passenger.fullName}
                  className={styles['passenger-avatar']}
                />
                <span>{passenger.fullName}</span>
              </div>
            ))}
          </div>
        </div>
        <div className={styles['passenger-tickets']}>
          {passengerTickets.map((ticket) => (
            <div key={ticket.id} className={styles['ticket-card']}>
              {/* Rest of your ticket card render code remains the same */}
              <div className={styles['ticket-header']}>
                <div className={styles['airline-info']}>
                  <img 
                    src={getAirlineIcon(ticket.airlines, ticket.travelMode)} 
                    alt={ticket.airlines || ticket.travelMode}
                    className={styles['airline-logo']}
                  />
                  <div>
                    <span className={styles['airline-name']}>{ticket.airlines || ticket.travelMode}</span>
                    {(ticket.flightNumber || ticket.trainNumber || ticket.busNumber) && (
                      <span className={styles['ticket-number']}>
                        {ticket.flightNumber || ticket.trainNumber || ticket.busNumber}
                      </span>
                    )}
                  </div>
                </div>
                {ticket.pnrNumber && (
                  <div className={styles['pnr-number']}>PNR: {ticket.pnrNumber}</div>
                )}
              </div>

              <div className={styles['journey-details']}>
              <div className={styles['location-time']}>
  <div className={styles['time']}>
    {new Date(ticket.departureTime.seconds * 1000).toLocaleTimeString([], { 
      hour: '2-digit', 
      minute: '2-digit' 
    })}
  </div>
  <div className={styles['date']}>
    {new Date(ticket.departureTime.seconds * 1000).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    })}
  </div>
  <div className={styles['location']}>{ticket.origin}</div>
</div>

                <div className={styles['journey-duration']}>
                  <div className={styles['duration-line']}>
                    <span className={styles['dot']}></span>
                    <span className={styles['line']}></span>
                    <span className={styles['dot']}></span>
                  </div>
                  <div className={styles['duration-text']}>
                    {calculateDuration(ticket.departureTime, ticket.arrivalTime)}
                  </div>
                </div>

                <div className={styles['location-time']}>
  <div className={styles['time']}>
    {new Date(ticket.arrivalTime.seconds * 1000).toLocaleTimeString([], { 
      hour: '2-digit', 
      minute: '2-digit' 
    })}
  </div>
  <div className={styles['date']}>
    {new Date(ticket.arrivalTime.seconds * 1000).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    })}
  </div>
  <div className={styles['location']}>{ticket.destination}</div>
</div>
              </div>

              {ticket.notes && (
                <div className={styles['ticket-notes']}>
                  <FontAwesomeIcon icon={faInfoCircle} />
                  <span>{ticket.notes}</span>
                </div>
              )}

 {/* Add visibility section */}
 <div className={styles['visibility-section']}>
        <span className={styles['visibility-label']}>Visible to:</span>
        {ticket.visibility && ticket.visibility.length > 0 ? (
          <div className={styles['visibility-users']}>
            {ticket.visibility.map(userId => {
              const member = getTourMemberById(userId);
              return member ? (
                <div key={userId} className={styles['visibility-user']}>
                  <img 
                    src={member.profilePictureUrl || defaultProfilePic}
                    alt={member.fullName}
                    className={styles['visibility-user-pic']}
                  />
                  <span>{member.fullName}</span>
                </div>
              ) : null;
            })}
          </div>
        ) : (
          <span className={styles['visibility-all']}>All tour members</span>
        )}
      </div>


              <div className={styles['ticket-actions']}>
              {ticket.ticketPdfUrl && (
  <button 
    className={styles['action-button']}
    onClick={() => {
      setSelectedFile(ticket.ticketPdfUrl);
      setIsFileModalOpen(true);
    }}
  >
    View Ticket
  </button>
)}

                <div className={styles['action-icons']}>
                  <button 
                    className={styles['icon-button']}
                    onClick={() => handleEditTicket(ticket)}
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button 
  className={styles['icon-button']}
  onClick={() => {
    const tourDate = selectedTour.tourDates.find(date => date.id === selectedDate);
    const actualDate = tourDate?.date;
    handleDeleteTicket(ticket, actualDate);
  }}
>
  <FontAwesomeIcon icon={faTrash} />
</button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    ));
  };

  // Helper function to calculate duration
  const calculateDuration = (departure, arrival) => {
    const diff = (arrival.seconds - departure.seconds) * 1000;
    const hours = Math.floor(diff / (1000 * 60 * 60));
    const minutes = Math.floor((diff % (1000 * 60 * 60)) / (1000 * 60));
    return `${hours}h ${minutes}m`;
  };

  return (
    <div className={styles['main-layout']}>
      <TopBar />
      <LeftMenu />
      <div className={styles['ticket-main']}>
        <div className={styles['ticket-content']}>
          {/* Date Selector */}
          <div className={styles['date-selector']}>
            <button 
              className={styles['scroll-button']} 
              onClick={() => handleScroll('left')}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
            </button>
            
            <div className={styles['dates-container']} id="dateScroller">
              {selectedTour?.tourDates?.map((date) => {
                const isRescheduled = date.rescheduleDate?.isRescheduled;
                const rescheduledDate = isRescheduled ? new Date(date.rescheduleDate.newDate) : null;
                const isCancelled = date.cancelled;
                const displayDate = isRescheduled ? rescheduledDate : new Date(date.date);

                return (
                  <button
                    key={date.id}
                    className={`${styles['date-button']} 
                      ${selectedDate === date.id ? styles['selected'] : ''}
                      ${isCancelled ? styles['cancelled'] : ''}
                      ${isRescheduled ? styles['rescheduled'] : ''}`}
                    onClick={() => handleDateSelect(date)}
                  >
                    <div className={styles['date-content']}>
                      <span className={styles['date-day']}>
                        {formatDate(displayDate)}
                      </span>
                      {date.event?.venueDetails?.venueData && (
                        <span className={styles['date-venue']}>
                          {date.event.venueDetails.venueData.displayName}
                        </span>
                      )}
                    </div>
                    <div className={styles['status-badges']}>
                      {isCancelled && (
                        <span className={styles['cancelled-badge']}>Cancelled</span>
                      )}
                      {isRescheduled && (
                        <span className={styles['rescheduled-badge']}>Rescheduled</span>
                      )}
                    </div>
                      {/* {isRescheduled && (
          <span className={styles['original-date']}>
            Original: {formatDate(new Date(date.date))}
          </span>
        )} */}
                  </button>
                );
              })}
            </div>            
            <button 
              className={styles['scroll-button']} 
              onClick={() => handleScroll('right')}
            >
              <FontAwesomeIcon icon={faChevronRight} />
            </button>
          </div>

         
          {selectedDate && (
        <TicketModal 
          editingTicket={editingTicket}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        />
      )}

      <button 
        className={styles['add-ticket-button']}
        onClick={() => setIsModalOpen(true)}
      >
        <FontAwesomeIcon icon={faPlus} />
        Add Tickets
      </button>

  <div className={styles['tickets-container']}>
    {renderTicketCards()}
  </div>
</div>
      </div>
      <RightMenu />
      {isFileModalOpen && selectedFile && (
  <FileViewerModal 
    isOpen={isFileModalOpen}
    onClose={() => {
      setIsFileModalOpen(false);
      setSelectedFile(null);
    }}
    file={{
      fileUrl: selectedFile,
      fileName: 'Ticket',
      fileType: 'application/pdf',
      size: 0 // Since we don't have the size information for existing tickets
    }}
  />
)}

     {/* Delete Confirmation Modal */}
     {deleteConfirmation.isOpen && (
  <div className={styles['alert-modal-overlay']}>
    <div className={styles['alert-modal']}>
      {isDeleting && (
        <div className={styles['loading-overlay']}>
          <div className={styles['loading-spinner']} />
        </div>
      )}
      <div className={styles['alert-header']}>
        <FontAwesomeIcon 
          icon={faExclamationTriangle} 
          className={styles['alert-icon']} 
        />
        <h3 className={styles['alert-title']}>Delete Ticket</h3>
      </div>
      <p className={styles['alert-message']}>
        Are you sure you want to delete this ticket? This action cannot be undone.
      </p>
      <div className={styles['alert-actions']}>
        <button 
          className={`${styles['alert-button']} ${styles['cancel']}`}
          onClick={() => setDeleteConfirmation({
            isOpen: false,
            ticketId: null,
            date: null
          })}
          disabled={isDeleting}
        >
          Cancel
        </button>
        <button 
          className={`${styles['alert-button']} ${styles['delete']}`}
          onClick={confirmDelete}
          disabled={isDeleting}
        >
          Delete
        </button>
      </div>
    </div>
  </div>
)}

    </div>
  );
};

export default Tickets;