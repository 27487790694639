// userActions.js
import { 
    getFirestore, 
    collection, 
    query, 
    where, 
    getDocs,
    doc,
    updateDoc,
    arrayUnion,
    arrayRemove,
    increment 
  } from 'firebase/firestore';
  import { getAuth } from 'firebase/auth';
  import { firebaseInstances } from '../firebaseHelper';
  
  const { firestore: db } = firebaseInstances;
  
  export const getUserData = async (userId) => {
      try {
          const usersCollection = collection(db, 'users');
          const q = query(usersCollection, where("userId", "==", userId));
  
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
              const userData = querySnapshot.docs[0].data();
              userData.docId = querySnapshot.docs[0].id;
              return userData;
          } else {
              console.log('User document does not exist for userId:', userId);
              return null;
          }
      } catch (error) {
          console.error('Error fetching user data:', error);
          throw error;
      }
  };
  
  export const updateUserTours = async (userId, tourId, isAdding = true) => {
      try {
          const userRef = doc(db, 'users', userId);
          await updateDoc(userRef, {
              tourIds: isAdding ? arrayUnion(tourId) : arrayRemove(tourId)
          });
          return true;
      } catch (error) {
          console.error('Error updating user tours:', error);
          throw error;
      }
  };
  
  export const getUserByUsername = async (username) => {
      try {
          const usersCollection = collection(db, 'users');
          const q = query(usersCollection, where("userName", "==", username));
  
          const querySnapshot = await getDocs(q);
          if (!querySnapshot.empty) {
              return querySnapshot.docs[0].data();
          }
          return null;
      } catch (error) {
          console.error('Error fetching user by username:', error);
          throw error;
      }
  };
  
  export const updateUserProfile = async (userId, profileData) => {
      try {
          const userRef = doc(db, 'users', userId);
          await updateDoc(userRef, profileData);
          return true;
      } catch (error) {
          console.error('Error updating user profile:', error);
          throw error;
      }
  };
  
  export const updateUserStats = async (userId, stats) => {
      try {
          const userRef = doc(db, 'users', userId);
          await updateDoc(userRef, {
              toursCount: stats.toursCount || 0,
              showsCount: stats.showsCount || 0,
              usersCount: stats.usersCount || 0
          });
          return true;
      } catch (error) {
          console.error('Error updating user stats:', error);
          throw error;
      }
  };
  
  export const getCurrentUser = () => {
      const auth = getAuth();
      return auth.currentUser;
  };


  export const incrementUserStats = async (userId, { tourCount = 0, showCount = 0 }) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        toursCount: increment(tourCount),
        showsCount: increment(showCount)
      });
      return true;
    } catch (error) {
      console.error('Error incrementing user stats:', error);
      throw error;
    }
  };
  
  export const decrementUserStats = async (userId, tourData) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        toursCount: increment(-1), // Decrease by 1 tour
        showsCount: increment(-tourData.tourDates.length) // Decrease by number of shows
      });
      return true;
    } catch (error) {
      console.error('Error decrementing user stats:', error);
      throw error;
    }
  };