// src/components/PostCard/PostCard.js
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faShare, 
  faEdit, 
  faTrash, 
  faVideo, 
  faMusic, 
  faImage, 
  faLink 
} from '@fortawesome/free-solid-svg-icons';
import { 
  FacebookEmbed, 
  InstagramEmbed, 
  LinkedInEmbed, 
  YouTubeEmbed,
  XEmbed 
} from 'react-social-media-embed';
import { getAudioEmbedCode } from '../../utils/embedUtils';
import './PostCard.css';
import ShareModal from './ShareModal';

const formatPostDate = (timestamp) => {
    if (!timestamp) return 'Date unavailable';
    
    // Handle both Firestore Timestamp and regular Date objects
    const date = timestamp.seconds ? new Date(timestamp.seconds * 1000) : new Date(timestamp);
    
    // Check if date is valid
    if (isNaN(date.getTime())) return 'Date unavailable';
    
    // Format as DD/MM/YYYY
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

const PostCard = ({ 
    post, 
    isOwner, 
    onDelete, 
    onEdit, 
    showHeader = true,
    className = '',
    isMobile = false  // Add this prop
  }) => {
  const [embedHtml, setEmbedHtml] = useState('');
  const [imageError, setImageError] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (post.type === 'audio') {
      const embed = getAudioEmbedCode(post.url);
      if (embed) {
        setEmbedHtml(embed);
      }
    }
  }, [post.url, post.type]);

  const handleImageError = () => {
    setImageError(true);
  };

  const [showShareModal, setShowShareModal] = useState(false);

  const handleShare = async (e) => {
    e.stopPropagation();
    e.preventDefault();
  
    const shareData = {
      title: `Check out this ${post.type} on OnTour`,
      text: post.caption || 'Check out this post',
      url: `${window.location.origin}/p/${post.postId}`
    };
  
    // Add hashtags if present
    if (post.tags?.length > 0) {
      const hashtags = post.tags.map(tag => `#${tag}`).join(' ');
      shareData.text = `${shareData.text}\n\n${hashtags}`;
    }
  
    // Check if it's mobile and has native sharing
    const isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    
    if (isMobileDevice && navigator.share) {
      try {
        await navigator.share(shareData);
      } catch (error) {
        if (error.name !== 'AbortError') {
          console.error('Error sharing:', error);
          setShowShareModal(true); // Fallback to modal if native share fails
        }
      }
    } else {
      // On desktop, always show the ShareModal
      setShowShareModal(true);
    }
  };

  
  


  const handleCloseShareModal = () => {
    setShowShareModal(false);
  };

  const handleEditClick = (e) => {
    e.stopPropagation();
    onEdit?.(post);
  };

  const handleDeleteClick = (e) => {
    e.stopPropagation();
    onDelete?.(post.postId);
  };

  const handlePostClick = () => {
    navigate(`/p/${post.postId}`);
  };



  const renderContent = () => {
    if (imageError) {
      return (
        <div className="post-fallback">
          <div className="fallback-content">
            <FontAwesomeIcon 
              icon={post.type === 'video' ? faVideo : post.type === 'audio' ? faMusic : faImage} 
              className="media-icon" 
            />
            <span>Media unavailable</span>
          </div>
        </div>
      );
    }

    const embedProps = {
      width: "100%",
      onError: handleImageError,
      fallback: (
        <div className="post-fallback">
          <div className="fallback-content">
            <FontAwesomeIcon icon={faLink} className="media-icon" />
            <span>Content unavailable</span>
          </div>
        </div>
      )
    };

    // Handle different embed types
    if (post.url.includes('facebook.com')) {
      return <FacebookEmbed url={post.url} {...embedProps} />;
    }
    if (post.url.includes('instagram.com')) {
      return <InstagramEmbed url={post.url} {...embedProps} />;
    }
    if (post.url.includes('linkedin.com')) {
      return <LinkedInEmbed url={post.url} postUrl={post.url} {...embedProps} />;
    }
    if (post.url.includes('youtube.com') || post.url.includes('youtu.be')) {
      return <YouTubeEmbed url={post.url} {...embedProps} />;
    }
    if (post.url.includes('twitter.com') || post.url.includes('x.com')) {
      return <XEmbed url={post.url} {...embedProps} />;
    }
    if (post.type === 'audio' && embedHtml) {
      return (
        <div 
          className="post-embed"
          dangerouslySetInnerHTML={{ __html: embedHtml }}
        />
      );
    }

    return (
      <div className="post-fallback">
        <a href={post.url} target="_blank" rel="noopener noreferrer" className="fallback-link">
          <div className="fallback-content">
            <FontAwesomeIcon 
              icon={post.type === 'video' ? faVideo : post.type === 'audio' ? faMusic : faImage} 
              className="media-icon" 
            />
            <span>View {post.type}</span>
          </div>
        </a>
      </div>
    );
  };

  return (
    <>
      <div className={`post-card ${className}`}>
        <div onClick={handlePostClick} className="post-content">
          {renderContent()}
        </div>
        <div className="post-info">
          <div className="caption-container">
            <p className="post-caption">{post.caption}</p>
            <div className="post-actions">
              <button 
                onClick={handleShare} 
                className="action-btn" 
                title="Share post"
              >
                <FontAwesomeIcon icon={faShare} />
              </button>
              {isOwner && (
                <>
                  <button 
                    onClick={handleEditClick} 
                    className="action-btn" 
                    title="Edit post"
                  >
                    <FontAwesomeIcon icon={faEdit} />
                  </button>
                  <button 
                    onClick={handleDeleteClick} 
                    className="action-btn" 
                    title="Delete post"
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </>
              )}
            </div>
          </div>
          <div className="post-tags">
            {post.tags.map((tag, index) => (
              <span key={index} className="tag">#{tag}</span>
            ))}
          </div>
          <div className="post-date">
            {formatPostDate(post.createdAt)}
          </div>
        </div>
      </div>
       
    

      {showShareModal && (
      <ShareModal
        isOpen={showShareModal}
        onClose={() => setShowShareModal(false)}
        postData={post}
      />
    )}
  </>
);
};

export default PostCard;