import React, { createContext, useState, useEffect, useRef } from 'react';
import { getAuth, onAuthStateChanged, signInWithEmailLink } from 'firebase/auth';
import { getFirestoreInstance } from '../utils/firebaseHelper';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { getUserData, updateUserProfile } from '../utils/actions/userActions';
import queryString from 'query-string';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [userData, setUserData] = useState(null);
  const [authToken, setAuthToken] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const userDataCache = useRef(new Map());
  const bandDataCache = useRef(new Map());

  const clearCache = () => {
    userDataCache.current.clear();
    bandDataCache.current.clear();
  };

  const getUserDataFromCache = async (userId) => {
    const cached = userDataCache.current.get(userId);
    if (cached) return cached.data;
    
    const data = await getUserData(userId);

    // If user has associated acts, fetch latest band data
    if (data.associatedActs?.length) {
      const updatedActs = await Promise.all(
        data.associatedActs.map(async act => {
          if (act.type === 'band') {
            const bandData = await getBandDataFromCache(act.id);
            return {
              ...act,
              name: bandData?.bandFullName || act.name,
              photoUrl: bandData?.bandProfilePictureUrl || act.photoUrl,
              genre: bandData?.bandGenres?.join(', ') || act.genre
            };
          }
          return act;
        })
      );
      data.associatedActs = updatedActs;
    }

    userDataCache.current.set(userId, { data });
    return data;
  };

  const getBandDataFromCache = async (bandId) => {
    const cached = bandDataCache.current.get(bandId);
    if (cached) return cached.data;

    try {
      const db = getFirestoreInstance();
      const bandDoc = await getDoc(doc(db, 'bands', bandId));
      if (!bandDoc.exists()) return null;

      const data = bandDoc.data();
      bandDataCache.current.set(bandId, { data });
      return data;
    } catch (error) {
      console.error('Error fetching band data:', error);
      return null;
    }
  };

  const updateUserData = async (newData) => {
    try {
      await updateUserProfile(newData.userId, newData);
      
      // If updating associated acts, refresh band data
      if (newData.associatedActs) {
        const updatedActs = await Promise.all(
          newData.associatedActs.map(async act => {
            if (act.type === 'band') {
              const bandData = await getBandDataFromCache(act.id);
              return {
                ...act,
                name: bandData?.bandFullName || act.name,
                photoUrl: bandData?.bandProfilePictureUrl || act.photoUrl,
                genre: bandData?.bandGenres?.join(', ') || act.genre
              };
            }
            return act;
          })
        );
        newData.associatedActs = updatedActs;
      }

      setUserData(newData);
      userDataCache.current.set(newData.userId, { data: newData });
    } catch (error) {
      console.error('Error updating user data:', error);
      throw error;
    }
  };

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setIsLoading(true);
      if (user) {
        try {
          const [token, userDataResult] = await Promise.all([
            user.getIdToken(),
            getUserDataFromCache(user.uid)
          ]);
          setUserData(userDataResult);
          setAuthToken(token);
        } catch (error) {
          console.error('Error:', error);
          setUserData(null);
          setAuthToken(null);
        }
      } else {
        const { search, pathname } = window.location;
        const queryParams = queryString.parse(search);
        
        if (pathname === '/verify-email' && queryParams.oobCode) {
          try {
            const email = localStorage.getItem('emailForSignIn');
            if (email) {
              const result = await signInWithEmailLink(auth, email, window.location.href);
              if (result.user) {
                const token = await result.user.getIdToken();
                const userDataResult = await getUserDataFromCache(result.user.uid);
                setUserData(userDataResult);
                setAuthToken(token);
                localStorage.removeItem('emailForSignIn');
              }
            }
          } catch (error) {
            console.error('Error verifying email:', error);
          }
        } else if (pathname === '/reset-password' && queryParams.oobCode) {
          // Let ResetPassword component handle the token
        } else {
          clearCache();
          setUserData(null);
          setAuthToken(null);
        }
      }
      setIsLoading(false);
    });
  
    return () => unsubscribe();
  }, []);

  


  return (
    <UserContext.Provider 
      value={{
        userData,
        setUserData,
        authToken,
        getUserDataFromCache,
        getBandDataFromCache,
        updateUserData,
        clearCache,
        isLoading
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;