import React, { useState, useEffect, useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faTimes,
  faDownload,
  faTrash,
  faSpinner,
  faHome,
  faUser,
  faWallet,
  faCog,
  faChartLine,
  faMoneyBill,
} from '@fortawesome/free-solid-svg-icons';
import TopBar from '../TopBar';
import LeftMenu from '../LeftMenu';
import RightMenu from '../RightMenu';
import { UserContext } from '../../context/UserContext';
import { TourContext } from '../../context/TourContext';
import { getFirestore, doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import defaultUserProfilePhoto from '../../assets/Images/defaultUserProfilePicture.png'
import styles from './Expense.module.css';
import * as XLSX from 'xlsx';
import moment from 'moment';

const MAX_NOTE_LENGTH = 50;

const Expense = () => {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const { selectedTour, fetchExpenseHistory, expenseHistory, updateExpenseHistory } = useContext(TourContext);
  
  // Set default tab to 'record'
  const [activeTab, setActiveTab] = useState('record');
  const [amount, setAmount] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [note, setNote] = useState('');
  const [currency, setCurrency] = useState('₹');
  const [whoOwes, setWhoOwes] = useState([]);
  const [selectedHistoryDate, setSelectedHistoryDate] = useState(null);
  const [totalExpense, setTotalExpense] = useState(0);
  const [amountOwed, setAmountOwed] = useState(0);
  const [detailedExpenses, setDetailedExpenses] = useState([]);
  const [detailedOwed, setDetailedOwed] = useState([]);
  const [isSelfExpense, setIsSelfExpense] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSettling, setIsSettling] = useState(false);
  const [selectedDatesForReport, setSelectedDatesForReport] = useState([]);
  const [showDateSelection, setShowDateSelection] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // All users from the tour
  const allUsers = useMemo(() => {
    if (!selectedTour) return [];

    const userMap = new Map();

    // Add current user
    userMap.set(userData.userId, {
      userId: userData.userId,
      fullName: userData.fullName,
      profilePictureUrl: userData.profilePictureUrl,
      isCurrentUser: true
    });

    // Add artists
    selectedTour.artistDetails?.forEach(artist => {
      if (!userMap.has(artist.userId)) {
        userMap.set(artist.userId, {
          ...artist,
          fullName: artist.fullName || artist.fullName,
          isCurrentUser: artist.userId === userData.userId
        });
      }
    });

    // Add crew
    selectedTour.crewDetails?.forEach(crew => {
      if (!userMap.has(crew.userId)) {
        userMap.set(crew.userId, {
          ...crew,
          fullName: crew.fullName || crew.fullName,
          isCurrentUser: crew.userId === userData.userId
        });
      }
    });

    // Add Manager
    if (selectedTour.createdBy) {
      const manager = selectedTour.createdBy;
      if (!userMap.has(manager.userId)) {
        userMap.set(manager.userId, {
          userId: manager.userId,
          fullName: manager.fullName,
          isCurrentUser: manager.userId === userData.userId,
          userType: "Manager"
        });
      }
    }

    return Array.from(userMap.values());
  }, [selectedTour, userData]);

  // Fetch expense history when tour changes
  useEffect(() => {
    if (selectedTour?.id) {
      fetchExpenseHistory(selectedTour.id);
    }
  }, [selectedTour, fetchExpenseHistory]);

  // Update split amounts when total amount changes
  useEffect(() => {
    if (amount && whoOwes.length > 0) {
      const equalSplit = parseFloat(amount) / whoOwes.length;
      setWhoOwes(prevUsers => prevUsers.map(user => ({
        ...user,
        amountOwed: equalSplit.toFixed(2)
      })));
    }
  }, [amount, whoOwes.length]);

  // Calculate totals when expense history changes
  useEffect(() => {
    if (selectedTour?.id && expenseHistory[selectedTour.id]) {
      calculateTotalExpenses(expenseHistory[selectedTour.id]);
    }
  }, [expenseHistory, selectedTour, userData.userId]);

  // Sort dates for the history view
  const sortedDates = useMemo(() => {
    if (!selectedTour?.id || !expenseHistory[selectedTour.id]) return [];
    return Object.keys(expenseHistory[selectedTour.id]).sort((a, b) => moment(b).diff(moment(a)));
  }, [expenseHistory, selectedTour]);

  useEffect(() => {
    // Reset history-related states when tour changes
    setSelectedHistoryDate(null);
    setActiveTab('record');
  }, [selectedTour]);
  
  // Update your calculateTotalExpenses function to handle undefined data safely
  const calculateTotalExpenses = (expenseData) => {
    if (!expenseData) {
      setTotalExpense(0);
      setAmountOwed(0);
      setDetailedExpenses([]);
      setDetailedOwed([]);
      return;
    }
  
    let totalPaid = 0;
    let totalOwed = 0;
    let detailedPaid = [];
    let detailedOwedTemp = [];
  
    Object.entries(expenseData).forEach(([date, dateExpenses]) => {
      if (!dateExpenses?.expenses) return; // Add safety check
  
      dateExpenses.expenses.forEach(expense => {
        if (expense.paidBy === userData.userId) {
          const settledAmount = expense.whoOwes?.reduce((sum, ower) => 
            ower.isSettled ? sum + parseFloat(ower.amountOwed) : sum, 0
          ) || 0;
          const effectiveAmount = parseFloat(expense.amount) - settledAmount;
          totalPaid += effectiveAmount;
  
          detailedPaid.push({
            id: expense.id,
            date,
            amount: parseFloat(expense.amount),
            effectiveAmount,
            currency: expense.currency,
            note: expense.note,
            whoOwes: expense.whoOwes || [],
            isSettled: expense.whoOwes?.every(ower => ower.isSettled) || false,
            isSelfExpense: expense.isSelfExpense
          });
        }
  
        if (!expense.isSelfExpense && expense.paidBy !== userData.userId) {
          const userOwed = expense.whoOwes?.find(user => user.userId === userData.userId);
          if (userOwed && !userOwed.isSettled) {
            totalOwed += parseFloat(userOwed.amountOwed);
            detailedOwedTemp.push({
              id: expense.id,
              date,
              amount: parseFloat(userOwed.amountOwed),
              currency: expense.currency,
              paidBy: allUsers.find(u => u.userId === expense.paidBy)?.fullName || 'Unknown',
              note: expense.note
            });
          }
        }
      });
    });
  
    setTotalExpense(totalPaid);
    setAmountOwed(totalOwed);
    setDetailedExpenses(detailedPaid.sort((a, b) => new Date(b.date) - new Date(a.date)));
    setDetailedOwed(detailedOwedTemp.sort((a, b) => new Date(b.date) - new Date(a.date)));
  };

  // Handle expense submission
  const handleSubmit = async () => {
    if (!amount || !selectedDate) {
      alert('Please fill in all required fields');
      return;
    }

    setIsSubmitting(true);
    try {
      const db = getFirestore();
      const expenseData = {
        id: Date.now().toString(),
        amount: parseFloat(amount),
        date: selectedDate,
        paidBy: userData.userId,
        note,
        currency,
        createdBy: userData.userId,
        createdAt: new Date(),
        isSelfExpense,
        whoOwes: isSelfExpense ? [] : whoOwes.map(user => ({
          userId: user.userId,
          fullName: user.fullName,
          amountOwed: parseFloat(user.amountOwed),
          isSettled: false
        })),
      };

      const expenseRef = doc(db, 'ExpenseRecords', selectedTour.id);
      await setDoc(expenseRef, {
        tourName: selectedTour.tourName,
        tourDates: {
          [selectedDate]: {
            expenses: expenseHistory[selectedTour.id]?.[selectedDate] 
              ? [...expenseHistory[selectedTour.id][selectedDate].expenses, expenseData]
              : [expenseData]
          }
        }
      }, { merge: true });

      alert('Expense recorded successfully');
      await updateExpenseHistory(selectedTour.id);
      resetForm();
      setActiveTab('history');
    } catch (error) {
      console.error('Error submitting expense:', error);
      alert('Failed to record expense. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  // Reset form fields
  const resetForm = () => {
    setAmount('');
    setSelectedDate('');
    setNote('');
    setCurrency('₹');
    setWhoOwes([]);
    setIsSelfExpense(false);
  };

  // Handle user selection for expense splitting
  const toggleUserOwes = (user) => {
    setWhoOwes(prevUsers => {
      if (prevUsers.some(u => u.userId === user.userId)) {
        return prevUsers.filter(u => u.userId !== user.userId);
      } else {
        const equalSplit = amount ? parseFloat(amount) / (prevUsers.length + 1) : 0;
        return [...prevUsers, { ...user, amountOwed: equalSplit.toFixed(2) }];
      }
    });
  };

  // Update individual amount owed
  const updateUserAmountOwed = (userId, newAmount) => {
    setWhoOwes(prevUsers => prevUsers.map(user =>
      user.userId === userId ? { ...user, amountOwed: newAmount } : user
    ));
  };

  // Handle settling of expenses
  const handleSettle = async (expenseId, userId) => {
    setIsSettling(true);
    try {
      const db = getFirestore();
      const expenseRef = doc(db, 'ExpenseRecords', selectedTour.id);
      const expenseDoc = await getDoc(expenseRef);
      
      if (expenseDoc.exists()) {
        const data = expenseDoc.data();
        const updatedTourDates = {...data.tourDates};

        for (const [date, dateData] of Object.entries(updatedTourDates)) {
          dateData.expenses = dateData.expenses.map(expense => {
            if (expense.id === expenseId) {
              const updatedWhoOwes = expense.whoOwes.map(ower =>
                ower.userId === userId ? {...ower, isSettled: true} : ower
              );
              return {...expense, whoOwes: updatedWhoOwes};
            }
            return expense;
          });
        }

        await updateDoc(expenseRef, { tourDates: updatedTourDates });
        await updateExpenseHistory(selectedTour.id);
        alert('Expense settled successfully');
      }
    } catch (error) {
      console.error('Error settling expense:', error);
      alert('Failed to settle expense. Please try again.');
    } finally {
      setIsSettling(false);
    }
  };

  // Handle expense deletion
  const handleDeleteExpense = async (expenseId, date) => {
    if (!window.confirm('Are you sure you want to delete this expense?')) {
      return;
    }

    try {
      const db = getFirestore();
      const expenseRef = doc(db, 'ExpenseRecords', selectedTour.id);
      const expenseDoc = await getDoc(expenseRef);
      
      if (expenseDoc.exists()) {
        const data = expenseDoc.data();
        const updatedTourDates = {...data.tourDates};

        updatedTourDates[date].expenses = updatedTourDates[date].expenses.filter(
          expense => expense.id !== expenseId
        );

        if (updatedTourDates[date].expenses.length === 0) {
          delete updatedTourDates[date];
        }

        await updateDoc(expenseRef, { tourDates: updatedTourDates });
        await updateExpenseHistory(selectedTour.id);
        alert('Expense deleted successfully');
      }
    } catch (error) {
      console.error('Error deleting expense:', error);
      alert('Failed to delete expense. Please try again.');
    }
  };

  const getFormattedDate = (dateObj) => {
    if (dateObj.rescheduleDate?.isRescheduled) {
      return new Date(dateObj.rescheduleDate.newDate);
    }
    return new Date(dateObj.date);
  };


  const calculateUserExpenses = (expenses) => {
    const userTotals = new Map();

    expenses.forEach(expense => {
      // Add to payer's total
      const payerName = allUsers.find(u => u.userId === expense.paidBy)?.fullName || 'Unknown';
      const currentPayerTotal = userTotals.get(payerName) || 0;
      userTotals.set(payerName, currentPayerTotal + parseFloat(expense.amount));

      // Subtract settled amounts from payer's total
      if (!expense.isSelfExpense && expense.whoOwes) {
        const settledAmount = expense.whoOwes.reduce((sum, ower) => 
          ower.isSettled ? sum + parseFloat(ower.amountOwed) : sum, 0
        );
        userTotals.set(payerName, userTotals.get(payerName) - settledAmount);
      }
    });

    return Array.from(userTotals.entries()).map(([fullName, amount]) => ({
      fullName,
      amount: amount.toFixed(2)
    }));
  };


  // Updated generateExcelReport function
  const generateExcelReport = async (selectedDates) => {
    try {
      const workbook = XLSX.utils.book_new();
      const sortedSelectedDates = selectedDates.sort((a, b) => moment(b).diff(moment(a)));
      let totalExpenseSum = 0;
      let totalAmountOwed = 0;
      let allExpenses = [];

      // Collect all expenses first
      sortedSelectedDates.forEach(date => {
        const dateExpenses = expenseHistory[selectedTour.id][date]?.expenses || [];
        allExpenses = [...allExpenses, ...dateExpenses];

        const ws_data = [
          ['Date', 'Amount', 'Paid By', 'Note', 'Type', 'Who Owes', 'Amount Remaining'],
          ...dateExpenses.map(expense => {
            // Calculate remaining amount excluding self-owed portions
            const amountRemaining = expense.isSelfExpense 
              ? ''
              : expense.whoOwes
                  .filter(ower => !ower.isSettled && ower.userId !== expense.paidBy)
                  .map(ower => `${ower.fullName}: ${expense.currency} ${ower.amountOwed}`)
                  .join(', ');

            // Update totals
            if (expense.paidBy === userData.userId) {
              const settledAmount = expense.whoOwes?.reduce((sum, ower) => 
                ower.isSettled ? sum + parseFloat(ower.amountOwed) : sum, 0
              ) || 0;
              totalExpenseSum += parseFloat(expense.amount) - settledAmount;
            }

            // Calculate amount owed (excluding self-owed amounts)
            if (!expense.isSelfExpense) {
              const userOwed = expense.whoOwes?.find(ower => 
                ower.userId === userData.userId && 
                !ower.isSettled && 
                ower.userId !== expense.paidBy
              );
              if (userOwed) {
                totalAmountOwed += parseFloat(userOwed.amountOwed);
              }
            }

            return [
              moment(expense.date).format('DD-MM-YYYY'),
              `${expense.currency} ${expense.amount}`,
              allUsers.find(u => u.userId === expense.paidBy)?.fullName || 'Unknown',
              expense.note,
              expense.isSelfExpense ? 'Self Expense' : 'Shared Expense',
              expense.isSelfExpense ? 'Self Expense' : expense.whoOwes.map(ower => 
                `${ower.fullName}: ${expense.currency} ${ower.amountOwed}${ower.isSettled ? ' (Settled)' : ''}`
              ).join(', '),
              amountRemaining
            ];
          })
        ];

        // Calculate user-wise expense breakdown
        const userExpenses = calculateUserExpenses(dateExpenses);

        // Add spacing and summary
        ws_data.push(
          [''], // Empty row for spacing
          ['Summary'],
          ['Total Expenses', `₹ ${totalExpenseSum.toFixed(2)}`],
          [''],
          ['Expense Breakdown by User:']
        );

        // Add user-wise breakdown
        userExpenses.forEach(({ fullName, amount }) => {
          ws_data.push([fullName, `₹ ${amount}`]);
        });

        ws_data.push(
          [''],
          ['Amount You Owe', `₹ ${totalAmountOwed.toFixed(2)}`],
          ['Report Generated', moment().format('DD-MM-YYYY HH:mm:ss')]
        );

        const ws = XLSX.utils.aoa_to_sheet(ws_data);

        // Set column widths
        const colWidths = [
          { wch: 12 }, // Date
          { wch: 15 }, // Amount
          { wch: 20 }, // Paid By
          { wch: 30 }, // Note
          { wch: 15 }, // Type
          { wch: 40 }, // Who Owes
          { wch: 40 }  // Amount Remaining
        ];
        ws['!cols'] = colWidths;

        XLSX.utils.book_append_sheet(workbook, ws, moment(date).format('DD-MM-YYYY'));
      });

      const wbout = XLSX.write(workbook, { type: 'array', bookType: 'xlsx' });
      const blob = new Blob([wbout], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `Expense_Report_${moment().format('YYYY-MM-DD_HH-mm-ss')}.xlsx`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error generating report:', error);
      alert('Failed to generate expense report. Please try again.');
    }
  };


  const BottomNav = () => (
    <div className={styles['bottom-nav']}>
      <button onClick={() => navigate('/dashboard')} className={styles['nav-item']}>
        <FontAwesomeIcon icon={faHome} />
        <span>Home</span>
      </button>
      <button onClick={() => navigate('/profile')} className={styles['nav-item']}>
        <FontAwesomeIcon icon={faUser} />
        <span>Profile</span>
      </button>
      <button onClick={() => navigate('/membership')} className={styles['nav-item']}>
        <FontAwesomeIcon icon={faWallet} />
        <span>Membership</span>
      </button>
      <button onClick={() => navigate('/settings')} className={styles['nav-item']}>
        <FontAwesomeIcon icon={faCog} />
        <span>Settings</span>
      </button>
    </div>
  );

  if (!selectedTour) {
    return (
      <div className={styles.container}>
        <TopBar />
        <div className={styles['main-layout']}>
          {!isMobile && <LeftMenu />}
          <div className={styles.content}>
            <h2>Please select a tour to view expenses</h2>
          </div>
          {!isMobile && <RightMenu />}
        </div>
        {isMobile && <BottomNav />}
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <TopBar />
      <div className={styles['main-layout']}>
        {!isMobile && <LeftMenu />}
        <div className={styles.content}>
          <div className={styles['expense-header']}>
            <h1>Expenses</h1>
            
            <div className={styles['summary-section']}>
              <div className={styles['summary-cards']}>
                <div className={styles['summary-card']}>
                  <FontAwesomeIcon icon={faChartLine} className={styles['card-icon']} />
                  <div className={styles['card-content']}>
                    <h3>Total Expenses</h3>
                    <p className={styles.amount}>{currency} {totalExpense.toFixed(2)}</p>
                  </div>
                </div>
                <div className={styles['summary-card']}>
                  <FontAwesomeIcon icon={faMoneyBill} className={styles['card-icon']} />
                  <div className={styles['card-content']}>
                    <h3>Amount You Owe</h3>
                    <p className={styles.amount}>{currency} {amountOwed.toFixed(2)}</p>
                  </div>
                </div>
              </div>
              
              {/* Moved generate report button here */}
              {userData?.membershipTier === 'Elite' && (
                <button 
                  className={styles['generate-report-button']}
                  onClick={() => setShowDateSelection(true)}
                >
                  <FontAwesomeIcon icon={faDownload} />
                  Generate Report
                </button>
              )}
            </div>
          </div>

          <div className={styles['tab-container']}>
            <div className={styles.tabs}>
              <div 
                className={`${styles.tab} ${activeTab === 'record' ? styles.active : ''}`}
                onClick={() => setActiveTab('record')}
              >
                Record Expense
              </div>
              <div 
                className={`${styles.tab} ${activeTab === 'history' ? styles.active : ''}`}
                onClick={() => setActiveTab('history')}
              >
                Expense History
              </div>
            </div>
          </div>

          {activeTab === 'record' && (
            <div className={styles['record-form']}>
              <div className={styles['form-grid']}>
              <div className={styles['form-group']}>
                  <label>Amount</label>
                  <div className={styles['form-input-group']}>
                    <span className={styles['currency-symbol']}>₹</span>
                    <input
                      type="number"
                      className={styles['form-input']}
                      value={amount}
                      onChange={(e) => setAmount(e.target.value)}
                      placeholder="Enter amount"
                    />
                  </div>
                </div>
                <div className={styles['form-group']}>
                  <label>Date</label>
                  <select
                    className={styles['form-input']}
                    value={selectedDate}
                    onChange={(e) => setSelectedDate(e.target.value)}
                  >
                    <option value="">Select date</option>
                    {selectedTour?.tourDates?.map((date) => {
                      const displayDate = getFormattedDate(date);
                      return (
                        <option key={date.id} value={date.date}>
                          {moment(displayDate).format('DD-MM-YYYY')}
                          {date.rescheduleDate?.isRescheduled && ' (Rescheduled)'}
                        </option>
                      );
                    })}
                  </select>
                </div>

                <div className={styles['form-group']}>
                  <label>Note</label>
                  <input
                    type="text"
                    className={styles['form-input']}
                    value={note}
                    onChange={(e) => e.target.value.length <= MAX_NOTE_LENGTH && setNote(e.target.value)}
                    placeholder="Add a note"
                    maxLength={MAX_NOTE_LENGTH}
                  />
                  <span className={styles['char-count']}>{note.length}/{MAX_NOTE_LENGTH}</span>
                </div>

                <div className={styles['form-group']}>
                  <label className={styles['checkbox-label']}>
                    <input
                      type="checkbox"
                      checked={isSelfExpense}
                      onChange={() => setIsSelfExpense(!isSelfExpense)}
                      className={styles['checkbox-input']}
                    />
                    Mark as self expense
                  </label>
                </div>

                {!isSelfExpense && (
                  <div className={styles['form-group']}>
                    <label>Split With</label>
                    <div className={styles['users-grid']}>
                      {allUsers.map((user) => (
                        <div key={user.userId} className={styles['user-item']}>
                          <div className={styles['user-select']}>
                            <div className={styles['checkbox-container']}>
                              <input
                                type="checkbox"
                                checked={whoOwes.some(u => u.userId === user.userId)}
                                onChange={() => toggleUserOwes(user)}
                                className={styles['checkbox-input']}
                              />
                                
                                 <img 
            src={user.profilePictureUrl || defaultUserProfilePhoto} 
            alt={user.profilePictureUrl ? "Profile" : "Default Profile"}
            className={styles['profile-picture']} 
          />
                              <span className={styles['user-name']}>
                             
                                {user.fullName}
                                {user.isCurrentUser && " (You)"}
                                {user.userType === "Manager" && " (Manager)"}
                              </span>
                            </div>
                            {whoOwes.some(u => u.userId === user.userId) && (
                              <input
                                type="number"
                                className={styles['amount-input']}
                                value={whoOwes.find(u => u.userId === user.userId).amountOwed}
                                onChange={(e) => updateUserAmountOwed(user.userId, e.target.value)}
                                placeholder="Amount"
                              />
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}

                <button 
                  className={styles['action-button']}
                  onClick={handleSubmit}
                  disabled={isSubmitting}
                >
                  {isSubmitting ? (
                    <><FontAwesomeIcon icon={faSpinner} spin /> Processing...</>
                  ) : (
                    'Submit Expense'
                  )}
                </button>
              </div>
            </div>
          )}

          {activeTab === 'history' && (
  <div className={styles['history-content']}>
    {selectedHistoryDate ? (
      <div>
        <button 
          className={`${styles['action-button']} ${styles.secondary}`}
          onClick={() => setSelectedHistoryDate(null)}
        >
          ← Back to dates
        </button>
        <h3 className={styles['history-date']}>
          {moment(selectedHistoryDate).format('DD-MM-YYYY')}
        </h3>
        <div className={styles['expense-list']}>
          {expenseHistory[selectedTour?.id]?.[selectedHistoryDate]?.expenses.map((expense, index) => (
                      <div key={index} className={styles['expense-card']}>
                        <div className={styles['expense-header']}>
                          <div className={styles['expense-info']}>
                            <p className={styles['expense-amount']}>
                              {expense.currency} {expense.amount}
                            </p>
                            <p className={styles['paid-by']}>
                              Paid by: {allUsers.find(u => u.userId === expense.paidBy)?.fullName}
                              {expense.paidBy === userData.userId && " (You)"}
                            </p>
                          </div>
                          {expense.createdBy === userData.userId && (
                            <button
                              className={styles['delete-button']}
                              onClick={() => handleDeleteExpense(expense.id, selectedHistoryDate)}
                            >
                              <FontAwesomeIcon icon={faTrash} />
                            </button>
                          )}
                        </div>
                        <p className={styles['expense-note']}>{expense.note}</p>
                        {expense.isSelfExpense ? (
                          <p className={styles['self-expense']}>Self Expense</p>
                        ) : (
                          <div className={styles['split-details']}>
                            <h4>Split Details:</h4>
                            {expense.whoOwes.map((ower, idx) => (
                              <div key={idx} className={styles['split-item']}>
                                <div className={styles['split-info']}>
                                  <span>
                                    {ower.fullName}
                                    {ower.userId === userData.userId && " (You)"}
                                  </span>
                                  <span>- {expense.currency} {ower.amountOwed}</span>
                                </div>
                                {expense.paidBy === userData.userId && 
                                 !ower.isSettled && 
                                 ower.userId !== userData.userId && (
                <button 
                  className={styles['settle-button']}
                  onClick={() => handleSettle(expense.id, ower.userId)}
                  disabled={isSettling}
                >
                  {isSettling ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    'Mark as Settled'
                  )}
                </button>
                )}
                                {ower.isSettled && (
                                  <span className={styles['settled-badge']}>
                                    <FontAwesomeIcon icon={faCheck} /> Settled
                                  </span>
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                  ))}
        </div>
      </div>
    ) : (
      <div className={styles['dates-list']}>
        {sortedDates.length > 0 ? (
          sortedDates.map((date) => (
                    <div
                      key={date}
                      className={styles['date-card']}
                      onClick={() => setSelectedHistoryDate(date)}
                    >
                      <span className={styles['date']}>
                        {moment(date).format('DD-MM-YYYY')}
                      </span>
                      <span className={styles['expense-count']}>
                        {expenseHistory[selectedTour.id][date].expenses.length} expense(s)
                      </span>
                    </div>
                    ))
        ) : (
          <div className={styles['empty-state']}>
            <p>No expense records found</p>
          </div>
        )}
      </div>
    )}
  </div>
)}
             
            
         
        </div>
        {!isMobile && <RightMenu />}
      </div>
      {isMobile && <BottomNav />}

 {/* Date Selection Modal */}
 {showDateSelection && (
    <div className={styles['modal-overlay']}>
      <div className={styles['modal-content']}>
        <div className={styles['modal-header']}>
          <h3>Select Dates for Report</h3>
          <button 
            className={styles['close-button']}
            onClick={() => setShowDateSelection(false)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        <div className={styles['modal-body']}>
          <div className={styles['dates-list']}>
            {sortedDates.map(date => (
              <div
                key={date}
                className={`${styles['date-selection-item']} ${
                  selectedDatesForReport.includes(date) ? styles.selected : ''
                }`}
                onClick={() => {
                  setSelectedDatesForReport(prev =>
                    prev.includes(date) 
                      ? prev.filter(d => d !== date)
                      : [...prev, date]
                  );
                }}
              >
                <span>{moment(date).format('DD-MM-YYYY')}</span>
                {selectedDatesForReport.includes(date) && (
                  <FontAwesomeIcon icon={faCheck} className={styles['check-icon']} />
                )}
              </div>
            ))}
          </div>
        </div>
        <div className={styles['modal-footer']}>
          <button
            className={`${styles['action-button']} ${styles.secondary}`}
            onClick={() => setSelectedDatesForReport(sortedDates)}
          >
            Select All
          </button>
          <button
            className={styles['action-button']}
            onClick={() => {
              if (selectedDatesForReport.length === 0) {
                alert('Please select at least one date');
                return;
              }
              generateExcelReport(selectedDatesForReport);
              setShowDateSelection(false);
              setSelectedDatesForReport([]);
            }}
          >
            Generate Report
          </button>
        </div>
      </div>
    </div>
  )}

  {/* Loading Overlay */}
  {(isSubmitting || isSettling) && (
    <div className={styles['loading-overlay']}>
      <div className={styles['loading-spinner']} />
    </div>
  )}
</div>
);
};

export default Expense;