import { 
  getFirestore, 
  collection, 
  doc,
  setDoc,
  deleteDoc,
  updateDoc,
  arrayUnion,
  arrayRemove,
  getDoc,
  getDocs,
  query, 
  where, 
  orderBy,
  limit,
  serverTimestamp ,
  increment,
} from 'firebase/firestore';
  
export const createPost = async (userId, postData) => {
  try {
    const db = getFirestore();
    const timestamp = serverTimestamp();
    
    // Get and increment user's post number
    const userRef = doc(db, 'users', userId);
    const userDoc = await getDoc(userRef);
    const currentPostNumber = userDoc.data()?.postsCount || 0;
    const newPostNumber = currentPostNumber + 1;
    
    // Create post ID with timestamp and post number
    const timestampStr = Date.now().toString();
    const postId = `${timestampStr}${newPostNumber}`;
    
    const finalPostData = {
      ...postData,
      createdAt: timestamp,
      postId,
      userId,
      postNumber: newPostNumber
    };

    // Create document references with same ID for both collections
    const userPostRef = doc(db, `users/${userId}/posts`, postId);
    const centralPostRef = doc(db, 'posts', postId);
    
    // Add to user's posts subcollection
    await setDoc(userPostRef, finalPostData);
    
    // Add to central posts collection
    await setDoc(centralPostRef, finalPostData);

    // Update user's postTags array and postsCount
    await updateDoc(userRef, {
      postTags: arrayUnion(...postData.tags),
      postsCount: increment(1)
    });

    return finalPostData;
  } catch (error) {
    console.error('Error creating post:', error);
    throw error;
  }
};

export const deletePost = async (userId, postId) => {
  try {
    const db = getFirestore();
    
    // Get post data to remove tags
    const postRef = doc(db, `users/${userId}/posts`, postId);
    const postDoc = await getDoc(postRef);
    const postData = postDoc.data();
    
    // Delete from both collections
    await deleteDoc(postRef);
    await deleteDoc(doc(db, 'posts', postId));

    // Get user's posts to check if tags are still in use
    const userPostsRef = collection(db, `users/${userId}/posts`);
    const postsSnapshot = await getDocs(userPostsRef);
    const remainingTags = new Set();
    postsSnapshot.docs.forEach(doc => {
      const data = doc.data();
      if (data.tags) {
        data.tags.forEach(tag => remainingTags.add(tag));
      }
    });

    // Remove tags that are no longer used in any posts
    const tagsToRemove = postData.tags.filter(tag => !remainingTags.has(tag));
    if (tagsToRemove.length > 0) {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, {
        postTags: arrayRemove(...tagsToRemove),
        postsCount: increment(-1)
      });
    }

    return true;
  } catch (error) {
    console.error('Error deleting post:', error);
    throw error;
  }
};

export const updatePost = async (userId, postId, updatedData) => {
  try {
    const db = getFirestore();
    const timestamp = serverTimestamp();
    
    // Prepare update data
    const updateData = {
      ...updatedData,
      updatedAt: timestamp,
    };

    // Get original post to compare tags
    const postRef = doc(db, `users/${userId}/posts`, postId);
    const postDoc = await getDoc(postRef);
    const originalData = postDoc.data();

    // Update both locations
    await updateDoc(postRef, updateData);
    await updateDoc(doc(db, 'posts', postId), updateData);

    // Update user's tags if they've changed
    if (originalData.tags?.join(',') !== updatedData.tags?.join(',')) {
      const userRef = doc(db, 'users', userId);
      
      // Get all posts to determine which tags are still in use
      const userPostsRef = collection(db, `users/${userId}/posts`);
      const postsSnapshot = await getDocs(userPostsRef);
      const currentTags = new Set();
      
      postsSnapshot.docs.forEach(doc => {
        if (doc.id !== postId) { // Exclude current post
          const data = doc.data();
          if (data.tags) {
            data.tags.forEach(tag => currentTags.add(tag));
          }
        }
      });

      // Add new tags from updated post
      updatedData.tags?.forEach(tag => currentTags.add(tag));

      // Update user's tags
      await updateDoc(userRef, {
        postTags: Array.from(currentTags)
      });
    }

    return updateData;
  } catch (error) {
    console.error('Error updating post:', error);
    throw error;
  }
};
  
export const getUserPosts = async (userId, postsLimit = 2, tagFilter = null) => {
  try {
    const db = getFirestore();
    const postsRef = collection(db, `users/${userId}/posts`);
    let q;

    if (tagFilter) {
      // Convert tagFilter to lowercase for consistency
      const normalizedTagFilter = tagFilter.toLowerCase().replace(/\s+/g, '');
      q = query(
        postsRef,
        where('tags', 'array-contains', normalizedTagFilter),
        orderBy('createdAt', 'desc'),
        limit(postsLimit)
      );
    } else {
      q = query(
        postsRef,
        orderBy('createdAt', 'desc'),
        limit(postsLimit)
      );
    }

    const snapshot = await getDocs(q);
    return snapshot.docs.map(doc => ({
      postId: doc.id,
      ...doc.data()
    }));
  } catch (error) {
    console.error('Error fetching user posts:', error);
    throw error;
  }
};
  
  export const validatePostUrl = (url, type) => {
    if (!url) return false;
    
    try {
      const urlObj = new URL(url);
      
      switch(type) {
        case 'audio':
          return (
            urlObj.hostname.includes('spotify.com') ||
            urlObj.hostname.includes('soundcloud.com') ||
            urlObj.hostname.includes('music.apple.com')
          );
        
        case 'video':
          return (
            urlObj.hostname.includes('youtube.com') ||
            urlObj.hostname.includes('youtu.be') ||
            urlObj.hostname.includes('facebook.com') ||
            urlObj.hostname.includes('instagram.com') ||
            urlObj.hostname.includes('twitter.com') ||
            urlObj.hostname.includes('x.com') ||
            urlObj.hostname.includes('linkedin.com')
          );
        
        case 'photo':
          return (
            urlObj.hostname.includes('instagram.com') ||
            urlObj.hostname.includes('facebook.com') ||
            urlObj.hostname.includes('twitter.com') ||
            urlObj.hostname.includes('x.com') ||
            urlObj.hostname.includes('linkedin.com')
          );
        
        default:
          return false;
      }
    } catch (error) {
      console.error('Error validating URL:', error);
      return false;
    }
  };
  
  export const getUserTags = async (userId) => {
    try {
      const db = getFirestore();
      const userRef = doc(db, 'users', userId);
      const userDoc = await getDoc(userRef);
      
      if (userDoc.exists()) {
        return userDoc.data()?.postTags || [];
      }
      return [];
    } catch (error) {
      console.error('Error fetching user tags:', error);
      return [];
    }
  };