import React, { useState, useContext, useEffect, useCallback } from 'react';
import { doc, updateDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faTrash,
  faMapMarker,
  faCar,
  faPhone,
  faPlus,
  faSpinner,
  faHotel
} from '@fortawesome/free-solid-svg-icons';
import TourContext from '../../context/TourContext';
import UserContext from '../../context/UserContext';
import { getFirestoreInstance } from '../../utils/firebaseHelper';
import HotelSearchModal from './HotelSearchModal';
import moment from 'moment';
import defaultHotelImage from '../../assets/Images/defaulthotelpicture.png';
import styles from './Hotel.module.css';

const Hotel = () => {
  const { selectedTour, getSelectedTourDate, updateTourDate } = useContext(TourContext);
  const { userData } = useContext(UserContext);
  const [hotels, setHotels] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [loadingText, setLoadingText] = useState('');
  const [editingHotel, setEditingHotel] = useState(null);

  const tourDate = getSelectedTourDate();

  useEffect(() => {
    const loadHotels = async () => {
      try {
        if (tourDate?.hotel) {
          const hotelData = Array.isArray(tourDate.hotel) ? tourDate.hotel : [];
          setHotels(hotelData);
        } else {
          setHotels([]);
        }
      } catch (error) {
        console.error('Error loading hotels:', error);
      } finally {
        setIsLoading(false);
      }
    };

    loadHotels();
  }, [tourDate]);

  const handleDeleteHotel = async (hotelIndex) => {
    if (!window.confirm('Are you sure you want to delete this hotel?')) return;

    setIsLoading(true);
    setLoadingText('Deleting hotel...');

    try {
      const db = getFirestoreInstance();
      const tourRef = doc(db, 'Tours', selectedTour.id);
      const tourDateRef = doc(tourRef, 'tourDates', tourDate.id);

      const updatedHotels = hotels.filter((_, index) => index !== hotelIndex);

      await updateDoc(tourDateRef, {
        hotel: updatedHotels
      });

      await updateTourDate(selectedTour.id, tourDate.id);
      setHotels(updatedHotels);
    } catch (error) {
      console.error('Error deleting hotel:', error);
      alert('Failed to delete hotel. Please try again.');
    } finally {
      setIsLoading(false);
      setLoadingText('');
    }
  };

  const handleEditHotel = useCallback((index) => {
    const hotelToEdit = hotels[index];
    setEditingHotel(hotelToEdit);
    setIsModalVisible(true);
  }, [hotels]);

  const getVisibilityText = useCallback((hotel) => {
    switch (hotel.visibility) {
      case 'All':
        return 'Everyone';
      case 'Artist':
        return 'Artists Only';
      case 'Crew':
        return 'Crew Only';
      case 'Me':
        return 'Only Me';
      case 'Custom':
        if (hotel.selectedUsers?.length > 0) {
          const names = hotel.selectedUsers.join(', ');
          return names.length > 30 ? `${names.substring(0, 27)}...` : names;
        }
        return 'Custom Users';
      default:
        return 'Everyone';
    }
  }, []);

  const handleMapClick = (hotel) => {
    if (!hotel?.venueDetails?.venueData?.googleMapsUri) {
      alert('Map link not available');
      return;
    }
    window.open(hotel.venueDetails.venueData.googleMapsUri, '_blank');
  };

  const handleRideClick = (hotel) => {
    // Implementation for ride functionality
    alert('Ride functionality coming soon');
  };

  const handleCallClick = (hotel) => {
    if (!hotel?.venueDetails?.venueData?.nationalPhoneNumber) {
      alert('Phone number not available');
      return;
    }
    window.location.href = `tel:${hotel.venueDetails.venueData.nationalPhoneNumber}`;
  };

  const renderHotel = useCallback((hotel, index) => {
    if (!hotel?.venueDetails?.venueData) return null;
  
    const {
      displayName,
      formattedAddress,
      placeCoverPhotoUrl
    } = hotel.venueDetails.venueData;
  
    const checkInMoment = moment(hotel.checkInDateTime);
    const checkOutMoment = moment(hotel.checkOutDateTime);
  
    return (
      <div key={index} className={styles['hotel-card']}>
        <div className={styles['group-header']}>
          <div className={styles['group-info']}>
            <div className={styles['group-name']}>
              {hotel.group === 'Custom' && hotel.customGroupName
                ? hotel.customGroupName
                : hotel.group}
            </div>
            <div className={styles['visibility-text']}>
              Visible to: {getVisibilityText(hotel)}
            </div>
          </div>
          <div className={styles['group-actions']}>
            <button 
              className={styles['action-button']}
              onClick={() => handleEditHotel(index)}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
            <button 
              className={styles['action-button']}
              onClick={() => handleDeleteHotel(index)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        </div>
  
        <div className={styles['hotel-content']}>
          <div className={styles['hotel-header']}>
            <img
              src={placeCoverPhotoUrl || defaultHotelImage}
              alt={displayName}
              className={styles['hotel-image']}
            />
            <div className={styles['hotel-time-details']}>
              <div className={styles['time-block']}>
                <span className={styles['time-label']}>Check-In</span>
                <span className={styles['time-date']}>{checkInMoment.format('DD MMM')}</span>
                <span className={styles['time-hour']}>{checkInMoment.format('h:mm A')}</span>
              </div>
  
              <div className={styles['time-block']}>
                <span className={styles['time-label']}>Check-Out</span>
                <span className={styles['time-date']}>{checkOutMoment.format('DD MMM')}</span>
                <span className={styles['time-hour']}>{checkOutMoment.format('h:mm A')}</span>
              </div>
  
              <div className={styles['time-block']}>
                <span className={styles['time-label']}>Rooms</span>
                <span className={styles['room-count']}>{hotel.rooms || 0}</span>
              </div>
            </div>
          </div>
  
          <div className={styles['hotel-info']}>
            <h3 className={styles['hotel-name']}>{displayName}</h3>
            <p className={styles['hotel-address']}>{formattedAddress}</p>
          </div>
  
          <div className={styles['hotel-actions']}>
            <button 
              className={styles['action-button']} 
              onClick={() => handleMapClick(hotel)}
            >
              <FontAwesomeIcon icon={faMapMarker} />
              <span>View Map</span>
            </button>
            <button 
              className={styles['action-button']} 
              onClick={() => handleRideClick(hotel)}
            >
              <FontAwesomeIcon icon={faCar} />
              <span>Get a Ride</span>
            </button>
            <button 
              className={styles['action-button']} 
              onClick={() => handleCallClick(hotel)}
            >
              <FontAwesomeIcon icon={faPhone} />
              <span>Call</span>
            </button>
          </div>
        </div>
      </div>
    );
  }, [getVisibilityText, handleEditHotel, handleMapClick, handleRideClick, handleCallClick]);

  if (isLoading) {
    return (
      <div className={styles['loading-container']}>
        <FontAwesomeIcon icon={faSpinner} spin className={styles['loading-spinner']} />
        <p className={styles['loading-text']}>{loadingText || 'Loading...'}</p>
      </div>
    );
  }
  
  return (
    <div className={styles['hotel-container']}>
      <button
        className={styles['add-hotel-button']}
        onClick={() => setIsModalVisible(true)}
      >
        Add Hotel
      </button>
  
      {hotels.length === 0 ? (
        <div className={styles['empty-state']}>
          <FontAwesomeIcon icon={faHotel} className={styles['empty-state-icon']} />
          <p className={styles['empty-text']}>No hotels added yet</p>
        </div>
      ) : (
        <div className={styles['hotels-list']}>
          {hotels.map((hotel, index) => renderHotel(hotel, index))}
        </div>
      )}
  
      {isModalVisible && (
        <HotelSearchModal
          isOpen={isModalVisible}
          onClose={() => {
            setIsModalVisible(false);
            setEditingHotel(null);
          }}
          onSave={() => {}}
          editingHotel={editingHotel}
          tourData={selectedTour}
          dateId={tourDate?.id}
          membershipTier={userData?.membershipTier}
        />
      )}
  
      {loadingText && (
        <div className={styles['loading-overlay']}>
          <FontAwesomeIcon icon={faSpinner} spin size="2x" color="white" />
          <p className={styles['overlay-text']}>{loadingText}</p>
        </div>
      )}
    </div>
  );
};

export default Hotel;