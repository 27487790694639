import React, { useState, useEffect } from 'react';
import { getFirestore, doc, setDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './CreateTourMapSearch.module.css';
import defaultVenueImage from '../../assets/Images/defaulthotelpicture.png'


const CreateTourMapSearch = ({ show, onHide, onSelect, db }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [sessionToken, setSessionToken] = useState('');
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [isSearchButtonPressed, setIsSearchButtonPressed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isSelecting, setIsSelecting] = useState(false);

  useEffect(() => {
    generateSessionToken();
  }, []);

  const generateSessionToken = () => {
    const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      const r = (Math.random() * 16) | 0;
      const v = c === 'x' ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    });
    setSessionToken(uuid);
  };

  const fetchSuggestions = async (query) => {
    try {
      const response = await fetch('https://places.googleapis.com/v1/places:autocomplete', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Goog-Api-Key': 'AIzaSyBB7qZR3v2RlLctMh3MsApdVGoDhzLpXJM',
        },
        body: JSON.stringify({
          input: query,
          sessionToken: sessionToken,
        }),
      });

      const data = await response.json();
      setSuggestions(data.suggestions || []);
    } catch (error) {
      console.error('Error fetching suggestions:', error);
    }
  };

  const handleSearch = () => {
    if (searchQuery.length > 0) {
      setIsLoading(true);
      setIsSearchButtonPressed(true);
      fetchSuggestions(searchQuery);
    } else {
      setIsLoading(false);
      setIsSearchButtonPressed(false);
      setSuggestions([]);
    }
  };

  const fetchPhotoUrl = async (placeId, photoName) => {
    const apiKey = 'AIzaSyBB7qZR3v2RlLctMh3MsApdVGoDhzLpXJM';
  
    try {
      const requestUrl = `https://places.googleapis.com/v1/${photoName}/media?maxWidthPx=400&maxHeightPx=400&key=${apiKey}`;
      const response = await fetch(requestUrl, {
        method: 'GET',
      });

      if (response.ok) {
        // Just return the URL directly
        return response.url;
      }
      return '';
    } catch (error) {
      console.error('Error fetching photo:', error);
      return '';
    }
  };

  const handleSelectPlace = async (placeId) => {
    try {
      const response = await fetch(`https://places.googleapis.com/v1/places/${placeId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-Goog-Api-Key': 'AIzaSyBB7qZR3v2RlLctMh3MsApdVGoDhzLpXJM',
          'X-Goog-FieldMask': 'id,name,photos,addressComponents,adrFormatAddress,formattedAddress,location,plusCode,shortFormattedAddress,types,viewport,accessibilityOptions,businessStatus,displayName,googleMapsUri,iconBackgroundColor,iconMaskBaseUri,primaryType,primaryTypeDisplayName,subDestinations,utcOffsetMinutes,currentOpeningHours,currentSecondaryOpeningHours,internationalPhoneNumber,nationalPhoneNumber,priceLevel,rating,regularOpeningHours,regularSecondaryOpeningHours,userRatingCount,websiteUri',
        },
      });

      const data = await response.json();

      const photoName = data.photos && data.photos.length > 0 ? data.photos[0].name : '';
      const placeCoverPhotoUrl = photoName ? await fetchPhotoUrl(data.id, photoName) : '';

      const placeDetails = {
        id: data.id || '',
        name: data.name || '',
        displayName: data.displayName?.text || '',
        formattedAddress: data.formattedAddress || '',
        shortFormattedAddress: data.shortFormattedAddress || '',
        adrFormatAddress: data.adrFormatAddress || '',
        location: data.location ? { latitude: data.location.latitude, longitude: data.location.longitude } : null,
        plusCode: data.plusCode?.globalCode || '',
        types: data.types || [],
        viewport: data.viewport
          ? {
              northeast: {
                latitude: data.viewport.high?.latitude || '',
                longitude: data.viewport.high?.longitude || '',
              },
              southwest: {
                latitude: data.viewport.low?.latitude || '',
                longitude: data.viewport.low?.longitude || '',
              },
            }
          : null,
        accessibilityOptions: data.accessibilityOptions || {},
        businessStatus: data.businessStatus || '',
        googleMapsUri: data.googleMapsUri || '',
        iconBackgroundColor: data.iconBackgroundColor || '',
        iconMaskBaseUri: data.iconMaskBaseUri || '',
        primaryType: data.primaryType || '',
        primaryTypeDisplayName: data.primaryTypeDisplayName?.text || '',
        subDestinations: data.subDestinations ? data.subDestinations.map(destination => destination.name).join(', ') : '',
        utcOffsetMinutes: data.utcOffsetMinutes || '',
        currentOpeningHours: data.currentOpeningHours?.periods
          ? data.currentOpeningHours.periods.map(
              (period) => `${period.openDay} ${period.openTime} - ${period.closeDay} ${period.closeTime}`
            ).join(', ')
          : '',
        currentSecondaryOpeningHours: data.currentSecondaryOpeningHours?.periods
          ? data.currentSecondaryOpeningHours.periods.map(
              (period) => `${period.openDay} ${period.openTime} - ${period.closeDay} ${period.closeTime}`
            ).join(', ')
          : '',
        internationalPhoneNumber: data.internationalPhoneNumber || '',
        nationalPhoneNumber: data.nationalPhoneNumber || '',
        priceLevel: data.priceLevel || '',
        rating: data.rating || '',
        regularOpeningHours: data.regularOpeningHours?.periods
          ? data.regularOpeningHours.periods.map(
              (period) => `${period.openDay} ${period.openTime} - ${period.closeDay} ${period.closeTime}`
            ).join(', ')
          : '',
        regularSecondaryOpeningHours: data.regularSecondaryOpeningHours?.periods
          ? data.regularSecondaryOpeningHours.periods.map(
              (period) => `${period.openDay} ${period.openTime} - ${period.closeDay} ${period.closeTime}`
            ).join(', ')
          : '',
        userRatingCount: data.userRatingCount || '',
        websiteUri: data.websiteUri || '',
        placeCoverPhotoUrl: placeCoverPhotoUrl || '',
      };

      setSelectedPlace(placeDetails);
      setSearchQuery('');
      setIsSearchButtonPressed(false);
      setSuggestions([]);
    } catch (error) {
      console.error('Error fetching place details:', error);
    }
  };

  const handleConfirmSelection = async () => {
    if (selectedPlace) {
      try {
        setIsSelecting(true);
        const placeId = selectedPlace.id;
        if (placeId && db) {
          const placeRef = doc(db, 'Places', placeId);
          await setDoc(placeRef, selectedPlace);
        }

        onSelect(selectedPlace);
        setIsSelecting(false);
        onHide();
      } catch (error) {
        console.error('Error saving place details:', error);
        alert('Failed to save place details.');
      }
    }
  };

  if (!show) return null;

  return (
    <div className={styles.modalOverlay} onClick={onHide}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <h2 className={styles.modalTitle}>Search Map</h2>
        
        <div className={styles.searchContainer}>
          <div className={styles.searchInputWrapper}>
            <input
              type="text"
              className={styles.searchInput}
              placeholder="Search for a location"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <button
              className={`${styles.searchButton} ${!searchQuery.trim() && styles.disabledButton}`}
              onClick={handleSearch}
              disabled={!searchQuery.trim() || isLoading}
            >
              {isLoading ? (
                <div className={styles.spinner} />
              ) : (
                'Search'
              )}
            </button>
          </div>

          {isSearchButtonPressed && suggestions.length > 0 && (
            <div className={styles.suggestionsContainer}>
              {suggestions.map((suggestion) => (
                <div
                  key={suggestion.placePrediction.placeId}
                  className={styles.suggestionItem}
                  onClick={() => handleSelectPlace(suggestion.placePrediction.placeId)}
                >
                  <span>{suggestion.placePrediction.text?.text || suggestion.placePrediction.text}</span>
                </div>
              ))}
            </div>
          )}
          
          {selectedPlace && (
            <div className={styles.selectedPlace}>
              <h3 className={styles.selectedLocationText}>Selected Location</h3>
              <div className={styles.venueDetailsContainer}>
              <div className={styles.venueImageContainer}>
  {selectedPlace.placeCoverPhotoUrl ? (
    <img
      src={selectedPlace.placeCoverPhotoUrl}
      alt={selectedPlace.displayName || 'Venue'}
      className={styles.venueImage}
      onError={(e) => {
        // Use a local fallback image instead
        e.target.src = {defaultVenueImage};
      }}
    />
  ) : (
    <div className={styles.venueImagePlaceholder} />
  )}
</div>
                <div className={styles.venueTextContainer}>
                  <h4 className={styles.venueDisplayName}>{selectedPlace.displayName}</h4>
                  <p className={styles.venueAddress}>{selectedPlace.formattedAddress}</p>
                </div>
              </div>

              <div className={styles.mapContainer}>
                <iframe
                  title="Location Map"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  style={{ border: 0 }}
                  referrerPolicy="no-referrer-when-downgrade"
                  src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyDX3zmGo2kfdaD1jmTOzjoCP4D2rfZ3c3A&q=${encodeURIComponent(selectedPlace.formattedAddress)}&zoom=20&maptype=satellite&language=en`}
                  allowFullScreen
                />
              </div>
            </div>
          )}
        </div>

        <div className={styles.modalActions}>
          <button
            className={`${styles.selectButton} ${(!selectedPlace || isSelecting) && styles.disabledButton}`}
            onClick={handleConfirmSelection}
            disabled={!selectedPlace || isSelecting}
          >
            {isSelecting ? (
              <div className={styles.spinner} />
            ) : (
              'Select'
            )}
          </button>
          <button className={styles.closeButton} onClick={onHide}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default CreateTourMapSearch;