import React, { useState, useContext, useEffect } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPlus, 
  faPaperclip, 
  faTasks,
  faCalendarAlt,
  faSave,
  faChevronRight,
  faInfoCircle,
  faCalendarCheck,
  faBuilding,
  faHotel,
  faRoute,
  faCalendar,
  faCheckCircle,
  faBan,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import TourContext from '../../context/TourContext';
import UserContext from '../../context/UserContext';
import AddScheduleModal from './AddScheduleModal';
import ScheduleCard from './ScheduleCard';
import TopBar from '../TopBar';
import LeftMenu from '../LeftMenu';
import RightMenu from '../RightMenu';
import { doc, updateDoc, getDoc, Timestamp, deleteDoc, increment } from 'firebase/firestore';
import { getFirestoreInstance } from '../../utils/firebaseHelper';
import Event from './Event';
import Hotel from './Hotel';
import Route from './Route';
import styles from './Schedule.module.css';

const Schedule = () => {
  const { 
    selectedTour, 
    selectedDate, 
    updateTourDate,  
    getSelectedSectionData, 
    selectTourDate,
    getSelectedTourDate,
    setSelectedTour,
    setSelectedDate,
  } = useContext(TourContext);
  const { userData, setUserData } = useContext(UserContext);
  const navigate = useNavigate();
  const { tourId, dateId } = useParams();
  const location = useLocation();

  // State declarations
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [notes, setNotes] = useState('');
  const [isNotesExpanded, setIsNotesExpanded] = useState(false);
  const [isSavingNotes, setIsSavingNotes] = useState(false);
  const [visibilityFilter, setVisibilityFilter] = useState('All');
  const [schedules, setSchedules] = useState([]);
  const [attachmentCount, setAttachmentCount] = useState(0);
  const [taskCount, setTaskCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [tours, setTours] = useState([]);
  const [activeTab, setActiveTab] = useState('schedule');


  const [isCancelled, setIsCancelled] = useState(false);
  const [isRescheduled, setIsRescheduled] = useState(false);
  const [rescheduledDate, setRescheduledDate] = useState(null);
  const [showRescheduleModal, setShowRescheduleModal] = useState(false);
  const [newRescheduleDate, setNewRescheduleDate] = useState(new Date());
  const [isProcessing, setIsProcessing] = useState(false);


  useEffect(() => {
    const tourDate = getSelectedTourDate();
    if (tourDate) {
      setIsCancelled(tourDate.cancelled || false);
      setIsRescheduled(tourDate.rescheduleDate?.isRescheduled || false);
      setRescheduledDate(tourDate.rescheduleDate?.newDate || null);
    }
  }, [selectedDate, getSelectedTourDate]);


  // Effects
  useEffect(() => {
    const sectionData = getSelectedSectionData('schedule');
    if (sectionData) {
      setSchedules(sectionData.scheduleList || []);
      setNotes(sectionData.notes || '');
      setAttachmentCount(sectionData.attachments?.length || 0);
      setTaskCount(sectionData.tasks?.length || 0);
    } else {
      setSchedules([]);
      setNotes('');
      setAttachmentCount(0);
      setTaskCount(0);
    }
  }, [selectedDate, getSelectedSectionData]);

  


  useEffect(() => {
    console.log('Schedule received params:', { tourId, dateId });
    console.log('Schedule current tours:', tours);
    console.log('Schedule selectedTour:', selectedTour);
    
    if (tourId && dateId) {
      if (!selectedTour || selectedTour.id !== tourId) {
        const tour = tours.find(t => t.id === tourId);
        console.log('Found tour in Schedule:', tour);
        if (tour) {
          setSelectedTour(tour);
        }
      }
      if (dateId) {
        setSelectedDate(dateId);
      }
    }
  }, [tourId, dateId, tours, selectedTour, setSelectedTour, setSelectedDate]);
  
  useEffect(() => {
    if (selectedTour) {
      // If we have a tour but no date, select the first date
      if (!selectedDate && selectedTour.tourDates?.length > 0) {
        const firstDate = selectedTour.tourDates[0];
        setSelectedDate(firstDate.id);
        navigate(`/${selectedTour.id}/${firstDate.id}`);
        return;
      }
  
      // If we have both tour and date
      if (selectedDate) {
        const currentTourDate = selectedTour.tourDates.find(date => date.id === selectedDate);
        if (!currentTourDate) {
          // If the selected date no longer exists in the tour, navigate back
          navigate(`/${userData.userName}`);
          return;
        }
  
        const newPath = `/${selectedTour.id}/${selectedDate}`;
        if (location.pathname !== newPath) {
          navigate(newPath);
        }
      }
    }
  }, [selectedTour, selectedDate, navigate, location, userData.userName]);

  const CancelConfirmationModal = ({ isOpen, onClose, onConfirm, tourName, isProcessing, isCancelled }) => {
    if (!isOpen) return null;
  
    return (
      <div className={styles['modal-overlay']}>
        <div className={styles['modal-content']}>
          <h2 className={styles['modal-title']}>{isCancelled ? 'Un-cancel Tour Date' : 'Cancel Tour Date'}</h2>
          <p className={styles['modal-message']}>
            Are you sure you want to {isCancelled ? 'un-cancel' : 'cancel'} this date from {tourName}?
          </p>
          <div className={styles['modal-buttons']}>
            <button 
              className={`${styles['modal-button']} ${styles['cancel-button']}`}
              onClick={onClose}
              disabled={isProcessing}
            >
              Close
            </button>
            <button 
              className={`${styles['modal-button']} ${styles['delete-button']}`}
              onClick={onConfirm}
              disabled={isProcessing}
            >
              {isProcessing ? 'Processing...' : (isCancelled ? 'Un-cancel' : 'Cancel')}
            </button>
          </div>
        </div>
      </div>
    );
  };
  
  // Add this state near your other state declarations
  const [showCancelModal, setShowCancelModal] = useState(false);
  
  // Replace the handleCancelTourDate function with this:
  const handleCancelTourDate = () => {
    setShowCancelModal(true);
  };
  
  // Add this new function to handle the confirmation
  const confirmCancel = async () => {
    setIsProcessing(true);
    try {
      const db = getFirestoreInstance();
      const tourRef = doc(db, 'Tours', selectedTour.id);
      const tourDateRef = doc(tourRef, 'tourDates', selectedDate);
      const timestamp = Timestamp.now();
  
      await updateDoc(tourDateRef, {
        cancelled: !isCancelled,
        dateLastUpdated: timestamp
      });
  
      // Update user's showsCount
      const userRef = doc(db, 'users', userData.userId);
      await updateDoc(userRef, {
        showsCount: increment(isCancelled ? 1 : -1) // Increase if uncancelling, decrease if cancelling
      });
  
      // Update UserContext
      setUserData(prevUserData => ({
        ...prevUserData,
        showsCount: Math.max(0, prevUserData.showsCount + (isCancelled ? 1 : -1))
      }));
  
      setIsCancelled(!isCancelled);
      await updateTourDate(selectedTour.id, selectedDate);
    } catch (error) {
      console.error('Error cancelling tour date:', error);
      alert('Failed to update tour date. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };


  

  const handleRescheduleTourDate = () => {
    setShowRescheduleModal(true);
  };

 // In handleSaveReschedule function, rename the date variable
const handleSaveReschedule = async () => {
  if (!selectedTour?.id || !selectedDate) return;

  // Validate the new date
  const currentDate = new Date();
  currentDate.setHours(0, 0, 0, 0);
  const newDate = new Date(newRescheduleDate);
  newDate.setHours(0, 0, 0, 0);

  if (newDate < currentDate) {
    alert('Cannot reschedule to a past date.');
    return;
  }

  setIsProcessing(true);
  try {
    const db = getFirestoreInstance();
    const tourRef = doc(db, 'Tours', selectedTour.id);
    const tourDateRef = doc(tourRef, 'tourDates', selectedDate);
    const timestamp = Timestamp.now();

    await updateDoc(tourDateRef, {
      rescheduleDate: {
        isRescheduled: true,
        newDate: newRescheduleDate.toISOString().split('T')[0]
      },
      dateLastUpdated: timestamp
    });

    setIsRescheduled(true);
    setRescheduledDate(newRescheduleDate.toISOString().split('T')[0]);
    setShowRescheduleModal(false);
    
    // Update TourContext
    await updateTourDate(selectedTour.id, selectedDate);

  } catch (error) {
    console.error('Error rescheduling tour date:', error);
    alert('Failed to reschedule tour date. Please try again.');
  } finally {
    setIsProcessing(false);
  }
};

  const RescheduleModal = () => (
    <div className={styles.modalOverlay} onClick={() => setShowRescheduleModal(false)}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <h2 className={styles.modalTitle}>
          {isRescheduled ? 'Update Rescheduled Date' : 'Reschedule Tour Date'}
        </h2>
        <input
          type="date"
          className={styles.dateInput}
          value={newRescheduleDate.toISOString().split('T')[0]}
          onChange={(e) => setNewRescheduleDate(new Date(e.target.value))}
          min={new Date().toISOString().split('T')[0]}
        />
        <div className={styles.modalButtons}>
          {isRescheduled && (
            <button 
              className={`${styles.modalButton} ${styles.cancelButton}`}
              onClick={handleCancelReschedule}
            >
              Remove Rescheduled Date
            </button>
          )}
          <button 
            className={`${styles.modalButton} ${styles.saveButton}`}
            onClick={handleSaveReschedule}
          >
            Save
          </button>
          <button 
            className={`${styles.modalButton} ${styles.closeButton}`}
            onClick={() => setShowRescheduleModal(false)}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );

  const handleCancelReschedule = async () => {
    if (!selectedTour?.id || !selectedDate) return;
    if (!window.confirm('Are you sure you want to remove the rescheduled date?')) return;

    setIsProcessing(true);
    try {
      const db = getFirestoreInstance();
      const tourRef = doc(db, 'Tours', selectedTour.id);
      const tourDateRef = doc(tourRef, 'tourDates', selectedDate);
      const timestamp = Timestamp.now();

      await updateDoc(tourDateRef, {
        rescheduleDate: {
          isRescheduled: false,
          newDate: null
        },
        dateLastUpdated: timestamp
      });

      setIsRescheduled(false);
      setRescheduledDate(null);
      setShowRescheduleModal(false);
      
      // Update TourContext
      await updateTourDate(selectedTour.id, selectedDate);

    } catch (error) {
      console.error('Error removing rescheduled date:', error);
      alert('Failed to remove rescheduled date. Please try again.');
    } finally {
      setIsProcessing(false);
    }
  };

 
const DeleteConfirmationModal = ({ isOpen, onClose, onConfirm, tourName, isProcessing }) => {
  if (!isOpen) return null;

  return (
    <div className={styles['modal-overlay']}>
      <div className={styles['modal-content']}>
        <h2 className={styles['modal-title']}>Delete Tour Date</h2>
        <p className={styles['modal-message']}>
          Are you sure you want to remove this date from {tourName}?
        </p>
        <div className={styles['modal-buttons']}>
          <button 
            className={`${styles['modal-button']} ${styles['cancel-button']}`}
            onClick={onClose}
            disabled={isProcessing}
          >
            Cancel
          </button>
          <button 
            className={`${styles['modal-button']} ${styles['delete-button']}`}
            onClick={onConfirm}
            disabled={isProcessing}
          >
            {isProcessing ? 'Deleting...' : 'Delete'}
          </button>
        </div>
      </div>
    </div>
  );
};

// Update the delete functionality
const [showDeleteModal, setShowDeleteModal] = useState(false);

const handleDeleteTourDate = async () => {
  if (!selectedTour?.id || !selectedDate) return;
  setShowDeleteModal(true);
};

const confirmDelete = async () => {
  try {
    setIsProcessing(true);
    const db = getFirestoreInstance();
    const tourRef = doc(db, 'Tours', selectedTour.id);
    const tourDateRef = doc(tourRef, 'tourDates', selectedDate);

    // Delete the tour date
    await deleteDoc(tourDateRef);

    // Update user's showsCount
    const userRef = doc(db, 'users', userData.userId);
    await updateDoc(userRef, {
      showsCount: increment(-1) // Decrement showsCount by 1
    });

    // Update UserContext
    setUserData(prevUserData => ({
      ...prevUserData,
      showsCount: Math.max(0, (prevUserData.showsCount || 0) - 1)
    }));

    // Update the TourContext
    const updatedTourDates = selectedTour.tourDates.filter(date => date.id !== selectedDate);
    const updatedTour = {
      ...selectedTour,
      tourDates: updatedTourDates
    };

    // Update tours in context
    setTours(prevTours => 
      prevTours.map(tour => 
        tour.id === selectedTour.id ? updatedTour : tour
      )
    );

    // Update selected tour
    setSelectedTour(updatedTour);

    // Navigate back to profile
    navigate(`/${userData.userName}`);
  } catch (error) {
    console.error('Error deleting tour date:', error);
    alert('Failed to delete tour date. Please try again.');
  } finally {
    setIsProcessing(false);
    setShowDeleteModal(false);
  }
};

  // Handler functions
  const handleTourSelect = (tour) => {
    setSelectedTour(tour);
  };

  const handleSaveNotes = async () => {
    if (!selectedTour?.id || !selectedDate) return;

    setIsSavingNotes(true);
    try {
      const db = getFirestoreInstance();
      const tourRef = doc(db, 'Tours', selectedTour.id);
      const tourDateRef = doc(tourRef, 'tourDates', selectedDate);

      const updatedData = {
        schedule: {
          ...(getSelectedSectionData('schedule') || {}),
          notes
        }
      };

      await updateDoc(tourDateRef, updatedData);
      await updateTourDate(selectedTour.id, selectedDate);
    } catch (error) {
      console.error('Error saving notes:', error);
      alert('Failed to save notes. Please try again.');
    } finally {
      setIsSavingNotes(false);
    }
  };

  const handleAddSchedule = async (scheduleData) => {
    if (!selectedTour?.id || !selectedDate) return;

    setIsLoading(true);
    try {
      const db = getFirestoreInstance();
      const tourRef = doc(db, 'Tours', selectedTour.id);
      const tourDateRef = doc(tourRef, 'tourDates', selectedDate);

      const currentData = getSelectedSectionData('schedule') || {};
      const updatedSchedules = [...(currentData.scheduleList || []), scheduleData];

      const updatedData = {
        schedule: {
          ...currentData,
          scheduleList: updatedSchedules
        }
      };

      await updateDoc(tourDateRef, updatedData);
      await updateTourDate(selectedTour.id, selectedDate);
      setSchedules(updatedSchedules);
      setIsModalVisible(false);
    } catch (error) {
      console.error('Error adding schedule:', error);
      alert('Failed to add schedule. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleNavigateToAttachments = () => {
    // Using the tourId and dateId from the current tour
    if (selectedTour?.id && selectedDate) {
      navigate(`/${selectedTour.id}/${selectedDate}/attachments`);
    }
  };

  const handleNavigateToTasks = () => {
    navigate(`/${selectedTour?.id}/${selectedDate}/tasks`);
  };

  const getFormattedDate = () => {
    const tourDate = getSelectedTourDate();
    if (!tourDate?.date) return '';
    
    try {
      let date = tourDate.date;
      if (date.seconds) {
        date = new Date(date.seconds * 1000);
      } else if (typeof date.toDate === 'function') {
        date = date.toDate();
      } else {
        date = new Date(date);
      }

      if (!date || isNaN(date.getTime())) {
        console.error('Invalid date value:', date);
        return '';
      }

      return date.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  };

  const getVenueName = () => {
    const tourDate = getSelectedTourDate();
    return tourDate?.event?.venueDetails?.venueData?.displayName || '';
  };

  // Filter schedules based on visibility
  const filteredSchedules = schedules.filter(schedule => {
    if (visibilityFilter === 'All') return true;
    return schedule.visibility === visibilityFilter;
  });

  // Render functions
  const renderScheduleTab = () => (
    <>
     
     <button 
                  className={styles['add-schedule-btn']}
                  onClick={() => setIsModalVisible(true)}
                >
                  <FontAwesomeIcon icon={faPlus} />
                  Add Schedule
                </button>           
      
      <button className={styles['section-button']} onClick={handleNavigateToAttachments}>
        <span className={styles['section-button-text']}>
          <FontAwesomeIcon icon={faPaperclip} />
          Attachments
          {attachmentCount > 0 && (
            <span className={styles['counter-badge']}>{attachmentCount}</span>
          )}
        </span>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>

      <button className={styles['section-button']} onClick={handleNavigateToTasks}>
        <span className={styles['section-button-text']}>
          <FontAwesomeIcon icon={faTasks} />
          Tasks
          {taskCount > 0 && (
            <span className={styles['counter-badge']}>{taskCount}</span>
          )}
        </span>
        <FontAwesomeIcon icon={faChevronRight} />
      </button>

      <div className={`${styles['notes-section']} ${isNotesExpanded ? '' : styles.collapsed}`}>
        <div className={styles['notes-header']}>
          <h3 className={styles['section-title']}>Notes</h3>
        </div>
        <div className={styles['notes-content']}>
          <textarea
            className={styles['notes-textarea']}
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            placeholder="Enter notes here..."
          />
          {notes.length > 100 && (
            <button 
              className={styles['expand-btn']}
              onClick={() => setIsNotesExpanded(!isNotesExpanded)}
            >
              {isNotesExpanded ? 'Show Less' : 'Show More'}
            </button>
          )}
          <button 
            className={styles['save-notes-btn']}
            onClick={handleSaveNotes}
            disabled={isSavingNotes}
          >
            <FontAwesomeIcon icon={faSave} />
            {isSavingNotes ? 'Saving...' : 'Save Notes'}
          </button>
        </div>
      </div>

      <div className={styles['schedules-section']}>
        <div className={styles['section-header']}>
          <h3 className={styles['section-title']}>Schedule</h3>
          <select
            className={styles['filter-dropdown']}
            value={visibilityFilter}
            onChange={(e) => setVisibilityFilter(e.target.value)}
          >
            <option value="All">All</option>
            <option value="Artist">Artists Only</option>
            <option value="Crew">Crew Only</option>
            <option value="Me">Only Me</option>
            <option value="Custom">Custom</option>
          </select>
        </div>

        <div className={styles['schedules-list']}>
          {filteredSchedules.length === 0 ? (
            <div className={styles['empty-state']}>
              <p>No schedules created yet</p>
            </div>
          ) : (
            filteredSchedules.map((schedule, index) => (
              <ScheduleCard
                key={schedule.id || index}
                schedule={schedule}
                tourId={selectedTour.id}
                dateId={selectedDate}
                onUpdate={updateTourDate}
              />
            ))
          )}
        </div>
      </div>
    </>
  );

  const renderTabs = () => (
    <div className={styles['schedule-tabs']}>
      <button
        className={`${styles['tab-button']} ${activeTab === 'schedule' ? styles.active : ''}`}
        onClick={() => setActiveTab('schedule')}
      >
        <FontAwesomeIcon icon={faCalendarCheck} />
        <span>Schedule</span>
      </button>
      <button
        className={`${styles['tab-button']} ${activeTab === 'event' ? styles.active : ''}`}
        onClick={() => setActiveTab('event')}
      >
        <FontAwesomeIcon icon={faBuilding} />
        <span>Event</span>
      </button>
      <button
        className={`${styles['tab-button']} ${activeTab === 'hotel' ? styles.active : ''}`}
        onClick={() => setActiveTab('hotel')}
      >
        <FontAwesomeIcon icon={faHotel} />
        <span>Hotel</span>
      </button>
      <button
        className={`${styles['tab-button']} ${activeTab === 'route' ? styles.active : ''}`}
        onClick={() => setActiveTab('route')}
      >
        <FontAwesomeIcon icon={faRoute} />
        <span>Route</span>
      </button>
    </div>
  );

  const renderHeader = () => {
    const tourDate = getSelectedTourDate();
    let displayDate = getFormattedDate();
    let originalDate = null;
  
    if (isRescheduled && rescheduledDate) {
      displayDate = new Date(rescheduledDate).toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      });
      originalDate = new Date(tourDate.date).toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      });
    }
  
    return (
      <div className={styles['schedule-header']}>
        <div className={styles['header-date']}>
          <h2>{displayDate}</h2>
          {isRescheduled && originalDate && (
            <div className={styles['rescheduled-info']}>
              Rescheduled from: {originalDate}
            </div>
          )}
          {isCancelled && <div className={styles['cancelled-info']}>Cancelled</div>}
        </div>
        <div className={styles['header-actions']}>
          <button 
            className={styles.actionButton}
            onClick={handleRescheduleTourDate}
            title="Reschedule Tour Date"
          >
            <FontAwesomeIcon icon={faCalendar} />
          </button>
          <button 
  className={styles.actionButton}
  onClick={handleCancelTourDate}
  title={isCancelled ? "Un-cancel Tour Date" : "Cancel Tour Date"}
>
  <FontAwesomeIcon icon={isCancelled ? faCheckCircle : faBan} />
</button>
          <button 
            className={styles.actionButton}
            onClick={handleDeleteTourDate}
            title="Delete Tour Date"
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
        <div className={styles['header-address']}>
          <p>{getVenueName()}</p>
        </div>
      </div>
    );
  };


  const renderTabContent = () => {
    switch (activeTab) {
      case 'schedule':
        return renderScheduleTab();
      case 'event':
        return <Event />;
      case 'hotel':
        return <Hotel />;
      case 'route':
        return <Route />;
      default:
        return null;
    }
  };

  if (!selectedTour || !selectedDate) {
    return (
      <div className="schedule-layout">
        <main className="schedule-main">
          <div className="empty-state">
            <FontAwesomeIcon icon={faInfoCircle} size="2x" />
            <p>Please select a tour and date to view schedule</p>
          </div>
        </main>
      </div>
    );
  }
  

  return (
    <div className={styles['schedule-layout']}>
      <TopBar />
      <div className={styles['main-layout']}>
        {!isMobile && <LeftMenu selectedTour={selectedTour} />}
        {!isMobile && (
          <RightMenu
            selectedTour={selectedTour}
            tours={tours}
            onTourSelect={handleTourSelect}
          />
        )}
        <main className={styles['schedule-main']}>
          {renderHeader()}
          {renderTabs()}
          <div className={styles['content-section']}>
            {renderTabContent()}
          </div>
  
          {/* Modals */}
          {isModalVisible && (
            <AddScheduleModal
              isOpen={isModalVisible}
              onClose={() => setIsModalVisible(false)}
              onSave={handleAddSchedule}
              tourData={selectedTour}
              dateId={selectedDate}
              membershipTier={userData?.membershipTier}
            />
          )}
  
          {showRescheduleModal && <RescheduleModal />}
  
          <DeleteConfirmationModal 
            isOpen={showDeleteModal}
            onClose={() => setShowDeleteModal(false)}
            onConfirm={confirmDelete}
            tourName={selectedTour?.tourName}
            isProcessing={isProcessing}
          />

{showCancelModal && (
  <CancelConfirmationModal 
    isOpen={showCancelModal}
    onClose={() => setShowCancelModal(false)}
    onConfirm={confirmCancel}
    tourName={selectedTour?.tourName}
    isProcessing={isProcessing}
    isCancelled={isCancelled}
  />
)}
      
          {isProcessing && (
            <div className={styles['loading-overlay']}>
              <div className={styles['loading-spinner']}></div>
              <p className={styles['loading-text']}>Processing...</p>
            </div>
          )}
        </main>
      </div>
    </div>
  );
};


export default Schedule;