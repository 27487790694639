export const Button = ({ children, onClick, variant = 'default', className = '', ...props }) => {
    const baseClasses = 'px-4 py-2 rounded-md font-medium transition-colors';
    const variants = {
      default: 'bg-red-600 text-white hover:bg-red-700',
      outline: 'border border-gray-300 hover:bg-gray-50'
    };
  
    return (
      <button 
        className={`${baseClasses} ${variants[variant]} ${className}`}
        onClick={onClick}
        {...props}
      >
        {children}
      </button>
    );
  };
  