// utils/notificationService.js

import { getFirestoreInstance } from './firebaseHelper';
import { 
    collection, 
    addDoc, 
    doc, 
    getDoc, 
    query, 
    where, 
    getDocs, 
    updateDoc, 
    orderBy,
    Timestamp ,
    onSnapshot,
    limit,
    startAfter
} from 'firebase/firestore';

const EXPO_PROJECT_ID = '59cb1761-71d9-40b8-a6f3-a4c779aebaa9';

export async function sendPushNotification(expoPushToken, title, body, data) {
  try {
    const message = {
      to: expoPushToken,
      sound: 'default',
      title,
      body,
      data,
      _displayInForeground: true,
    };

    // Option 1: Using no-cors mode
    const response = await fetch('https://exp.host/--/api/v2/push/send', {
      method: 'POST',
      mode: 'no-cors', // Add this line to handle CORS
      headers: {
        'Accept': 'application/json',
        'Accept-encoding': 'gzip, deflate',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(message),
    });

    // Note: With no-cors mode, we can't read the response
    // So we'll just log success if the request didn't throw an error
    console.log('Push notification request sent');
    return true;
  } catch (error) {
    console.error('Error sending push notification:', error);
    // Don't throw the error, just log it and continue
    return false;
  }
}

export async function sendTourInvitationNotification(recipientUserId, invitationData) {
  try {
    const db = getFirestoreInstance();
    
    // First create the notification in Firestore
    const notificationDoc = await addDoc(collection(db, 'Notifications'), {
      userId: recipientUserId,
      type: 'TOUR_INVITATION',
      title: 'New Tour Invitation',
      message: `You've been invited to join ${invitationData.tourName} by ${invitationData.createdBy.fullName}`,
      tourId: invitationData.tourId,
      tourName: invitationData.tourName,
      senderId: invitationData.createdBy.userId,
      senderName: invitationData.createdBy.fullName,
      invitationId: invitationData.invitationId,
      timestamp: new Date().toISOString(),
      read: false
    });

    // Then try to send push notification if user has mobile app
    const recipientDoc = await getDoc(doc(db, 'users', recipientUserId));
    if (!recipientDoc.exists()) {
      console.log('Recipient user not found');
      return;
    }

    const recipientData = recipientDoc.data();
    const expoPushToken = recipientData.expoPushToken;

    if (expoPushToken) {
      const notificationTitle = 'New Tour Invitation';
      const notificationBody = `You've been invited to join ${invitationData.tourName} by ${invitationData.createdBy.fullName}`;
      const notificationData = {
        type: 'TOUR_INVITATION',
        tourId: invitationData.tourId,
        tourName: invitationData.tourName,
        senderId: invitationData.createdBy.userId,
        invitationId: invitationData.invitationId
      };

      // Try to send push notification, but don't let it block the process
      try {
        await sendPushNotification(
          expoPushToken,
          notificationTitle,
          notificationBody,
          notificationData
        );
      } catch (pushError) {
        console.log('Push notification failed, but notification saved to database:', pushError);
      }
    }

    return notificationDoc.id;
  } catch (error) {
    console.error('Error in sendTourInvitationNotification:', error);
    // Don't throw the error, as we want the invitation process to continue
    return null;
  }
}

export async function sendTourRemovalNotification(recipientUserId, data) {
  try {
    const db = getFirestoreInstance();
    
    // Create the notification in Firestore
    const notificationDoc = await addDoc(collection(db, 'Notifications'), {
      userId: recipientUserId,
      type: 'TOUR_DATE_REMOVAL',
      title: 'Tour Date Update',
      message: data.message,
      tourId: data.tourId,
      tourName: data.tourName,
      removedDates: data.dates,
      timestamp: new Date().toISOString(),
      read: false
    });

    // Try to send push notification if user has mobile app
    const recipientDoc = await getDoc(doc(db, 'users', recipientUserId));
    if (!recipientDoc.exists()) {
      console.log('Recipient user not found');
      return;
    }

    const recipientData = recipientDoc.data();
    const expoPushToken = recipientData.expoPushToken;

    if (expoPushToken) {
      const notificationTitle = 'Tour Date Update';
      const notificationBody = data.message;
      const notificationData = {
        type: 'TOUR_DATE_REMOVAL',
        tourId: data.tourId,
        tourName: data.tourName,
        removedDates: data.dates
      };

      try {
        await sendPushNotification(
          expoPushToken,
          notificationTitle,
          notificationBody,
          notificationData
        );
      } catch (pushError) {
        console.log('Push notification failed, but notification saved to database:', pushError);
      }
    }

    return notificationDoc.id;
  } catch (error) {
    console.error('Error in sendTourRemovalNotification:', error);
    return null;
  }
}

export async function getNotifications(userId, pageSize = 8, lastDoc = null) {
  const db = getFirestoreInstance();
  try {
      let q = query(
          collection(db, 'Notifications'),
          where('userId', '==', userId),
          orderBy('timestamp', 'desc'),
          limit(pageSize)
      );

      // If lastDoc is provided, start after it (for pagination)
      if (lastDoc) {
          q = query(q, startAfter(lastDoc));
      }

      const querySnapshot = await getDocs(q);
      const notifications = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
      }));

      // Check if there are more notifications
      const hasMore = notifications.length === pageSize;

      // Get the last document for next pagination
      const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

      // If this is the first fetch (no lastDoc), mark all as read
      if (!lastDoc) {
          const unreadNotifications = notifications.filter(n => !n.read);
          if (unreadNotifications.length > 0) {
              const updatePromises = unreadNotifications.map(notification =>
                  updateDoc(doc(db, 'Notifications', notification.id), {
                      read: true,
                      readAt: Timestamp.now()
                  })
              );
              await Promise.all(updatePromises);
          }
      }

      return {
          notifications,
          hasMore,
          lastDoc: lastVisible
      };
  } catch (error) {
      console.error('Error fetching notifications:', error);
      return {
          notifications: [],
          hasMore: false,
          lastDoc: null
      };
  }
}

export function subscribeToNotifications(userId, onUpdate) {
  const db = getFirestoreInstance();
  
  // Create a query for unread notifications
  const q = query(
      collection(db, 'Notifications'),
      where('userId', '==', userId),
      where('read', '==', false),
      limit(1)  // We only need to know if there are any unread notifications
  );

  // Set up the real-time listener
  const unsubscribe = onSnapshot(q, (snapshot) => {
      const hasUnread = !snapshot.empty;
      onUpdate(hasUnread);
  }, (error) => {
      console.error('Error in notification subscription:', error);
  });

  // Return unsubscribe function
  return unsubscribe;
}

export async function markNotificationAsRead(notificationId) {
  const db = getFirestoreInstance();
  try {
      const notificationRef = doc(db, 'Notifications', notificationId);
      await updateDoc(notificationRef, {
          read: true,
          readAt: Timestamp.now()
      });
  } catch (error) {
      console.error('Error marking notification as read:', error);
  }
}


export async function sendTaskNotification(recipientUserId, taskData, tourData, dateFormatted) {
  const db = getFirestoreInstance();
  
  try {
      // Create the notification in Firestore
      const notificationDoc = await addDoc(collection(db, 'Notifications'), {
          userId: recipientUserId,
          type: 'task',
          title: 'New Task Assigned',
          body: `You have been assigned a task: ${taskData.title} for ${dateFormatted}`,
          data: {
              taskId: taskData.id,
              tourId: tourData.id,
              tourDateId: taskData.tourDateId,
              tourName: tourData.tourName,
              tourCoverPhotoUrl: tourData.coverPhotoUrl
          },
          timestamp: Timestamp.now(),
          read: false
      });

      return notificationDoc.id;
  } catch (error) {
      console.error('Error sending task notification:', error);
      return null;
  }
}