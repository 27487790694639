import React, { useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import UserContext from '../context/UserContext';
import ChatContext from '../context/ChatContext';
import { useTheme } from '../context/ThemeContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSun, 
  faMoon, 
  faBell,
  faMessage,
  faUser,
  faCrown,
  faInfoCircle,
  faSignInAlt,
  faUserPlus
} from '@fortawesome/free-solid-svg-icons';
import { subscribeToNotifications } from '../utils/NotificationService';
import Notifications from '../components/ManagerDashboard/Notifications';
import styles from './TopBar.module.css';
import logo from '../assets/Images/logo.png';
import defaultUserProfilePhoto from '../assets/Images/defaultUserProfilePicture.png';

const TopBar = () => {
  const { userData } = useContext(UserContext);
  const { isDark, toggleTheme } = useTheme();
  const [showNotifications, setShowNotifications] = useState(false);
  const [hasUnread, setHasUnread] = useState(false);
  const [showTrialInfo, setShowTrialInfo] = useState(false);
  const { unreadCounts = {}, setActiveChat } = useContext(ChatContext);
  const navigate = useNavigate();
  const location = useLocation();

  const hasUnreadMessages = Object.values(unreadCounts).some(count => count > 0);
  
  useEffect(() => {
    // Check if trial info should be shown
    const shouldShowTrialInfo = 
      // Show if user is not on Elite plan and hasn't started a trial
      (userData && userData.membershipTier !== 'Elite' && !userData.membershipTrial?.active && !userData.hasCompletedTrial) ||
      // Or if trial has expired and user went back to Free
      (userData && userData.membershipTier === 'Free' && userData.hasCompletedTrial);
      
    setShowTrialInfo(shouldShowTrialInfo);
  }, [userData]);

  useEffect(() => {
    let unsubscribe;

    if (userData?.userId) {
      unsubscribe = subscribeToNotifications(userData.userId, (hasUnread) => {
        setHasUnread(hasUnread);
      });
    }

    return () => {
      if (unsubscribe) {
        unsubscribe();
      }
    };
  }, [userData?.userId]);

  const handleProfileClick = () => {
    if (userData?.userName) {
      navigate(`/${userData.userName}`);
    }
  };

  const handleNotificationsClick = () => {
    setShowNotifications(prev => !prev);
  };


  const handleChatClick = () => {
    // Close notifications panel if open
    if (showNotifications) {
      setShowNotifications(false);
    }
    
    // Only navigate if we're not already on the messages page
    if (location.pathname !== '/messages') {
      // Reset active chat when navigating to messages screen
      setActiveChat(null);
      navigate('/messages');
    }
  };

  const handleNotificationsPanelClose = () => {
    setShowNotifications(false);
  };
  
  const goToMembership = () => {
    navigate('/membership');
  };

  const goToLogin = () => {
    navigate('/login');
  };

  const goToSignup = () => {
    navigate('/signup');
  };

  const goToHome = () => {
    navigate('/');
  };

  return (
    <>
      <div className={styles['top-bar']}>
        <div className={styles['top-bar-left']}>
          <img 
            src={logo} 
            alt="On Tour Logo" 
            className={styles.logo} 
            onClick={goToHome}
            style={{ cursor: 'pointer' }}
          />
          <span className={styles['logo-text']} onClick={goToHome} style={{ cursor: 'pointer' }}>On Tour</span>
        </div>
        
        {/* Center section with trial info - only for logged in users */}
        {userData && showTrialInfo && (
          <div className={styles['trial-card']}>
            <div className={styles['trial-info']}>
              <FontAwesomeIcon icon={faCrown} className={styles['trial-icon']} />
              <div className={styles['trial-text']}>
                {userData?.hasCompletedTrial ? (
                  <span>Upgrade to Elite to unlock all premium features!</span>
                ) : (
                  <span>Try Elite free for 14 days! Unlock all premium features.</span>
                )}
              </div>
            </div>
            <button className={styles['trial-action']} onClick={goToMembership}>
              {userData?.hasCompletedTrial ? 'Upgrade Now' : 'Start Free Trial'}
            </button>
          </div>
        )}
        
        {/* Show different actions based on auth status */}
        {userData ? (
          // Logged In User Actions
          <div className={styles['top-bar-actions']}>
            <button className={styles['icon-button']} onClick={toggleTheme}>
              <FontAwesomeIcon icon={isDark ? faSun : faMoon} />
            </button>
            
            {/* Chat Icon */}
            <button 
              className={`${styles['icon-button']} ${styles['chat-button']} ${location.pathname === '/messages' ? styles['active'] : ''}`}
              onClick={handleChatClick}
              data-testid="chat-button"
            >
              <FontAwesomeIcon icon={faMessage} />
              {hasUnreadMessages && (
                <div className={styles['chat-dot']} />
              )}
            </button>

            {/* Notifications Icon */}
            <button 
              className={`${styles['icon-button']} ${styles['notification-button']}`}
              onClick={handleNotificationsClick}
            >
              <FontAwesomeIcon icon={faBell} />
              {hasUnread && <div className={styles['notification-dot']} />}
            </button>

            <button className={styles['profile-button']} onClick={handleProfileClick}>
              <img 
                src={userData?.profilePictureUrl || defaultUserProfilePhoto} 
                alt={userData?.profilePictureUrl ? "Profile" : "Default Profile"}
                className={styles['profile-picture']} 
              />
            </button>
          </div>
        ) : (
          // Non-Logged In User Actions
          <div className={styles['auth-buttons']}>
            <button className={styles['icon-button']} onClick={toggleTheme}>
              <FontAwesomeIcon icon={isDark ? faSun : faMoon} />
            </button>
            
            <button 
              className={`${styles['auth-button']} ${styles['login-button']}`}
              onClick={goToLogin}
            >
              <FontAwesomeIcon icon={faSignInAlt} className={styles['auth-icon']} />
              <span>Login</span>
            </button>
            
            <button 
              className={`${styles['auth-button']} ${styles['signup-button']}`}
              onClick={goToSignup}
            >
              <FontAwesomeIcon icon={faUserPlus} className={styles['auth-icon']} />
              <span>Sign Up</span>
            </button>
          </div>
        )}
      </div>

      {showNotifications && userData && (
        <>
          <div 
            className={styles.overlay}
            onClick={handleNotificationsPanelClose}
          />
          <Notifications
            onClose={handleNotificationsPanelClose}
          />
        </>
      )}
    </>
  );
};

export default TopBar;