// src/utils/embedUtils.js

export const getAudioEmbedCode = (url) => {
    try {
      if (!url) return null;
  
      // Spotify
      if (url.includes('spotify.com')) {
        const trackId = extractSpotifyTrackId(url);
        return `<iframe style="border-radius:12px" src="https://open.spotify.com/embed/track/${trackId}" width="100%" height="352" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>`;
      }
  
      // Apple Music
      if (url.includes('music.apple.com')) {
        return `<iframe allow="autoplay *; encrypted-media *; fullscreen *; clipboard-write" frameborder="0" height="300" style="width:100%;max-width:660px;overflow:hidden;border-radius:10px;" sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation" src="${url.replace('music.apple.com', 'embed.music.apple.com')}"></iframe>`;
      }
  
      // SoundCloud
      if (url.includes('soundcloud.com')) {
        return `<iframe width="100%" height="300" scrolling="no" frameborder="no" allow="autoplay" src="https://w.soundcloud.com/player/?url=${encodeURIComponent(url)}&color=%23ff5500&auto_play=false&hide_related=false&show_comments=true&show_user=true&show_reposts=false&show_teaser=true&visual=true"></iframe>`;
      }
  
      return null;
    } catch (error) {
      console.error('Error generating audio embed code:', error);
      return null;
    }
  };
  
  // Helper function to extract Spotify track ID
  const extractSpotifyTrackId = (url) => {
    const regExp = /track\/([a-zA-Z0-9]+)/;
    const match = url.match(regExp);
    return match ? match[1] : null;
  };