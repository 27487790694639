import React, { useState, useRef, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTimes, 
  faCamera, 
  faExclamationTriangle 
} from '@fortawesome/free-solid-svg-icons';
import { 
  faInstagram, 
  faTwitter, 
  faFacebook, 
  faYoutube,
  faLinkedin 
} from '@fortawesome/free-brands-svg-icons';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getFirestoreInstance } from '../../utils/firebaseHelper';
import { collection, addDoc, serverTimestamp } from 'firebase/firestore';
import { checkUsernameAvailability } from '../../utils/usernameHelpers';
import { UserContext } from '../../context/UserContext';
import styles from './BandModal.module.css';
import defaultBandPic from '../../assets/Images/defaultUserProfilePicture.png';
import defaultCoverPhoto from '../../assets/Images/defaultUserCoverPicture.png';
import appleMusicLogo from '../../assets/Images/applemusicicon.png';
import spotifyLogo from '../../assets/Images/spotifyicon.png';


const GENRE_OPTIONS = [
  'Rock', 'Pop', 'Hip Hop', 'Jazz', 'Blues', 'Classical', 'Electronic',
  'Folk', 'Country', 'R&B', 'Soul', 'Metal', 'Punk', 'Reggae',
  'World Music', 'Latin', 'Alternative', 'Indie', 'Dance'
];

const BandModal = ({ isOpen, onClose }) => {
  const { userData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    bandFullName: '',
    bandUserName: '',
    bandBio: '',
    bandEmail: '',
    bandWebsite: '',
    city: '',
    state: '',
    country: '',
    bandGenres: [],
    managerName: '',
    managerEmail: '',
    managerPhone: '',
    socialLinks: {
      instagramUrl: '',
      twitterUrl: '',
      facebookUrl: '',
      youtubeUrl: '',
      linkedInUrl: '',
      spotifyUrl: '',
      appleMusicUrl: ''
    }
  });

  const [profilePicture, setProfilePicture] = useState({
    file: null,
    preview: defaultBandPic
  });
  const [coverPhoto, setCoverPhoto] = useState({
    file: null,
    preview: defaultCoverPhoto
  });

  const profilePicRef = useRef(null);
  const coverPhotoRef = useRef(null);

  const handleInputChange = (name, value) => {
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSocialLinkChange = (platform, value) => {
    setFormData(prev => ({
      ...prev,
      socialLinks: {
        ...prev.socialLinks,
        [platform]: value
      }
    }));
  };

  const handleGenreToggle = (genre) => {
    setFormData(prev => {
      const genres = prev.bandGenres.includes(genre)
        ? prev.bandGenres.filter(g => g !== genre)
        : [...prev.bandGenres, genre];
      return { ...prev, bandGenres: genres };
    });
  };

  const handleProfilePicClick = () => {
    profilePicRef.current?.click();
  };

  const handleCoverPhotoClick = () => {
    coverPhotoRef.current?.click();
  };

  const handleProfilePicChange = (e) => {
    if (e.target.files?.[0]) {
      const file = e.target.files[0];
      setProfilePicture({
        file,
        preview: URL.createObjectURL(file)
      });
    }
  };

  const handleCoverPhotoChange = (e) => {
    if (e.target.files?.[0]) {
      const file = e.target.files[0];
      setCoverPhoto({
        file,
        preview: URL.createObjectURL(file)
      });
    }
  };

  const uploadImage = async (file, path) => {
    if (!file) return null;
    const storage = getStorage();
    const storageRef = ref(storage, path);
    await uploadBytes(storageRef, file);
    return getDownloadURL(storageRef);
  };

  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    title: '',
    message: '',
    type: ''
  });

  const showCustomAlert = (config) => {
    setAlertConfig(config);
    setShowAlert(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      // Check username availability
      const isAvailable = await checkUsernameAvailability(formData.bandUserName);
      if (!isAvailable) {
        showCustomAlert({
          title: 'Username Not Available',
          message: 'This username is already taken by another user or band. Please choose a different username.',
          type: 'error'
        });
        setIsLoading(false);
        return;
      }

      // Upload images
      const [profilePicUrl, coverPhotoUrl] = await Promise.all([
        profilePicture.file 
          ? uploadImage(profilePicture.file, `bandProfilePictures/${formData.bandUserName}/${Date.now()}_profile`)
          : Promise.resolve(defaultBandPic),
        coverPhoto.file
          ? uploadImage(coverPhoto.file, `bandCoverPhotos/${formData.bandUserName}/${Date.now()}_cover`)
          : Promise.resolve(defaultCoverPhoto)
      ]);

      const db = getFirestoreInstance();
      
      // Create band document
      const bandDoc = await addDoc(collection(db, 'bands'), {
        ...formData,
        bandProfilePictureUrl: profilePicUrl,
        bandCoverPictureUrl: coverPhotoUrl,
        createdAt: serverTimestamp(),
        createdBy: {
          userId: userData.userId,
          userName: userData.userName,
          fullName: userData.fullName,
          profilePictureUrl: userData.profilePictureUrl
        },
        admins: [{
          userId: userData.userId,
          userName: userData.userName,
          fullName: userData.fullName,
          profilePictureUrl: userData.profilePictureUrl,
          role: 'creator'
        }],
        members: [],
        memberRequests: []
      });

      // Create initial notification in bandNotifications collection
      await addDoc(collection(db, 'bandNotifications'), {
        bandId: bandDoc.id,
        type: 'BAND_CREATED',
        title: 'Band Page Created',
        message: `${userData.fullName} created the band page for ${formData.bandFullName}`,
        createdAt: serverTimestamp(),
        readBy: {}
      });

      onClose();
    } catch (error) {
      console.error('Error creating band:', error);
      // Handle error appropriately
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2>Create Band Page</h2>
          <button className={styles.closeButton} onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        <form onSubmit={handleSubmit} className={styles.form}>
          {/* Profile Pictures Section */}
          <div className={styles.picturesSection}>
            <div className={styles.coverPhotoContainer}>
              <img 
                src={coverPhoto.preview}
                alt="Cover"
                className={styles.coverPhoto}
              />
              <button 
                type="button"
                className={styles.editCoverPhotoButton}
                onClick={handleCoverPhotoClick}
              >
                <FontAwesomeIcon icon={faCamera} />
              </button>
              <input
                type="file"
                accept="image/*"
                ref={coverPhotoRef}
                onChange={handleCoverPhotoChange}
                className={styles.hiddenInput}
              />
            </div>

            <div className={styles.profilePictureContainer}>
              <img 
                src={profilePicture.preview}
                alt="Profile"
                className={styles.profilePicture}
              />
              <button 
                type="button"
                className={styles.editProfilePictureButton}
                onClick={handleProfilePicClick}
              >
                <FontAwesomeIcon icon={faCamera} />
              </button>
              <input
                type="file"
                accept="image/*"
                ref={profilePicRef}
                onChange={handleProfilePicChange}
                className={styles.hiddenInput}
              />
            </div>
          </div>

          {/* Basic Information */}
          <div className={styles.section}>
            <h3>Basic Information</h3>
            
            <div className={styles.field}>
              <label>Band Name</label>
              <input
                type="text"
                value={formData.bandFullName}
                onChange={(e) => handleInputChange('bandFullName', e.target.value)}
                required
              />
            </div>

            <div className={styles.field}>
              <label>Username</label>
              <input
                type="text"
                value={formData.bandUserName}
                onChange={(e) => handleInputChange('bandUserName', e.target.value.toLowerCase())}
                pattern="^[a-z0-9_]+$"
                title="Only lowercase letters, numbers, and underscore allowed"
                required
              />
              <small>Only lowercase letters, numbers, and underscore (_) allowed</small>
            </div>

            <div className={styles.field}>
              <label>Bio</label>
              <textarea
                value={formData.bandBio}
                onChange={(e) => handleInputChange('bandBio', e.target.value)}
                maxLength={500}
                rows={4}
              />
              <small>{formData.bandBio.length}/500 characters</small>
            </div>

            <div className={styles.field}>
              <label>Genres</label>
              <div className={styles.genreGrid}>
                {GENRE_OPTIONS.map(genre => (
                  <button
                    key={genre}
                    type="button"
                    className={`${styles.genreButton} ${
                      formData.bandGenres.includes(genre) ? styles.selected : ''
                    }`}
                    onClick={() => handleGenreToggle(genre)}
                  >
                    {genre}
                  </button>
                ))}
              </div>
            </div>
          </div>

          {/* Contact Information */}
          <div className={styles.section}>
            <h3>Contact Information</h3>
            
            <div className={styles.field}>
              <label>Email</label>
              <input
                type="email"
                value={formData.bandEmail}
                onChange={(e) => handleInputChange('bandEmail', e.target.value)}
              />
            </div>

            <div className={styles.field}>
              <label>Website</label>
              <input
                type="url"
                value={formData.bandWebsite}
                onChange={(e) => handleInputChange('bandWebsite', e.target.value)}
              />
            </div>

            <div className={styles.locationFields}>
              <div className={styles.field}>
                <label>City</label>
                <input
                  type="text"
                  value={formData.city}
                  onChange={(e) => handleInputChange('city', e.target.value)}
                />
              </div>

              <div className={styles.field}>
                <label>State</label>
                <input
                  type="text"
                  value={formData.state}
                  onChange={(e) => handleInputChange('state', e.target.value)}
                />
              </div>

              <div className={styles.field}>
                <label>Country</label>
                <input
                  type="text"
                  value={formData.country}
                  onChange={(e) => handleInputChange('country', e.target.value)}
                />
              </div>
            </div>
          </div>

          {/* Manager Details */}
          <div className={styles.section}>
            <h3>Manager/Contact Person Details</h3>
            
            <div className={styles.field}>
              <label>Name</label>
              <input
                type="text"
                value={formData.managerName}
                onChange={(e) => handleInputChange('managerName', e.target.value)}
                required
              />
            </div>

            <div className={styles.field}>
              <label>Email</label>
              <input
                type="email"
                value={formData.managerEmail}
                onChange={(e) => handleInputChange('managerEmail', e.target.value)}
                required
              />
            </div>

            <div className={styles.field}>
              <label>Phone</label>
              <input
                type="tel"
                value={formData.managerPhone}
                onChange={(e) => handleInputChange('managerPhone', e.target.value)}
                required
              />
            </div>
          </div>

          {/* Social Links */}
          <div className={styles.section}>
            <h3>Social Links</h3>
            
            <div className={styles.field}>
              <div className={styles.socialInputGroup}>
                <FontAwesomeIcon icon={faInstagram} className={styles.socialIcon} />
                <input
                  type="url"
                  value={formData.socialLinks.instagramUrl}
                  onChange={(e) => handleSocialLinkChange('instagramUrl', e.target.value)}
                  placeholder="Instagram URL"
                />
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.socialInputGroup}>
                <FontAwesomeIcon icon={faTwitter} className={styles.socialIcon} />
                <input
                  type="url"
                  value={formData.socialLinks.twitterUrl}
                  onChange={(e) => handleSocialLinkChange('twitterUrl', e.target.value)}
                  placeholder="Twitter URL"
                />
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.socialInputGroup}>
                <FontAwesomeIcon icon={faFacebook} className={styles.socialIcon} />
                <input
                  type="url"
                  value={formData.socialLinks.facebookUrl}
                  onChange={(e) => handleSocialLinkChange('facebookUrl', e.target.value)}
                  placeholder="Facebook URL"
                />
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.socialInputGroup}>
                <FontAwesomeIcon icon={faYoutube} className={styles.socialIcon} />
                <input
                  type="url"
                  value={formData.socialLinks.youtubeUrl}
                  onChange={(e) => handleSocialLinkChange('youtubeUrl', e.target.value)}
                  placeholder="YouTube URL"
                />
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.socialInputGroup}>
                <FontAwesomeIcon icon={faLinkedin} className={styles.socialIcon} />
                <input
                  type="url"
                  value={formData.socialLinks.linkedInUrl}
                  onChange={(e) => handleSocialLinkChange('linkedInUrl', e.target.value)}
                  placeholder="LinkedIn URL"
                />
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.socialInputGroup}>
                <div className={styles.socialIconContainer}>
                  <img src={spotifyLogo} alt="Spotify" className={styles.brandSocialIcon} />
                </div>
                <input
                  type="url"
                  value={formData.socialLinks.spotifyUrl}
                  onChange={(e) => handleSocialLinkChange('spotifyUrl', e.target.value)}
                  placeholder="Spotify URL"
                />
              </div>
            </div>

            <div className={styles.field}>
              <div className={styles.socialInputGroup}>
                <div className={styles.socialIconContainer}>
                  <img src={appleMusicLogo} alt="Apple Music" className={styles.brandSocialIcon} />
                </div>
                <input
                  type="url"
                  value={formData.socialLinks.appleMusicUrl}
                  onChange={(e) => handleSocialLinkChange('appleMusicUrl', e.target.value)}
                  placeholder="Apple Music URL"
                />
              </div>
            </div>
          </div>

          {/* Submit Button */}
          <button 
            type="submit" 
            className={styles.submitButton}
            disabled={isLoading}
          >
            {isLoading ? 'Creating...' : 'Create Band Page'}
          </button>
        </form>
      </div>
    </div>
  );
};

export default BandModal;