export const Card = ({ children, className = '' }) => {
    return (
      <div className={`bg-white rounded-lg border border-gray-200 shadow-sm ${className}`}>
        {children}
      </div>
    );
  };
  
  export const CardContent = ({ children, className = '' }) => {
    return (
      <div className={`p-4 ${className}`}>
        {children}
      </div>
    );
  };