import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import './dialog.module.css';

export const Dialog = ({ children, open, onOpenChange }) => {
  if (!open) return null;

  return (
    <>
      <div className="dialog-overlay" onClick={() => onOpenChange(false)} />
      <div className="dialog-container">
        <div className="dialog-content">
          {children}
        </div>
      </div>
    </>
  );
};

export const DialogContent = ({ children, className = '' }) => {
  return (
    <div className={`dialog-content-wrapper ${className}`}>
      {children}
    </div>
  );
};

export const DialogHeader = ({ children, className = '' }) => {
  return (
    <div className={`dialog-header ${className}`}>
      {children}
    </div>
  );
};

export const DialogTitle = ({ children, className = '' }) => {
  return (
    <h2 className={`dialog-title ${className}`}>
      {children}
    </h2>
  );
};

export const DialogClose = ({ onClose }) => {
  return (
    <button className="dialog-close" onClick={onClose}>
      <FontAwesomeIcon icon={faTimes} />
    </button>
  );
};