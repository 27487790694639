import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import UserContext from './context/UserContext';
import TourContext from './context/TourContext';
import { ChatProvider } from './context/ChatContext';
import { BrowserRouter as Router, Route, Routes, useParams, Navigate } from 'react-router-dom';
import queryString from 'query-string';
import WelcomePage from './components/WelcomePage';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
import ContactUs from './components/ContactUs';
import Help from './components/Help';
import RefundAndCancellation from './components/RefundAndCancellation';
import SignupPage from './components/SignupPage';
import LoginPage from './components/LoginPage';
import Dashboard from './components/Dashboard';
import AccountDetails from './components/AccountDetails'
import PaymentInfo from './components/PaymentInfo';
import { UserProvider } from './context/UserContext'; 
import Users from './components/AdminDashboard/Users'
import UserDetails from './components/AdminDashboard/UserDetails';
import {getUserData} from './utils/actions/authActions'
import ForgotPassword from './components/ForgotPassword';
import Profile from './components/Profile';
import PublicProfile from './components/PublicProfile'; // New component
import PostPage from './components/common/PostPage';
import Schedule from './components/ManagerDashboard/Schedule';
import { ThemeProvider } from './context/ThemeContext';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDoc, getFirestore, query, collection, where, getDocs } from 'firebase/firestore';
import { firebaseInstances } from './utils/firebaseHelper';
import { TourProvider } from './context/TourContext';
import Event from './components/ManagerDashboard/Event';
import Search from './components/ManagerDashboard/Search';
import Crew from './components/ManagerDashboard/Crew';
import Tickets from './components/ManagerDashboard/Tickets';
import CreateTour from './components/ManagerDashboard/CreateTour';
import Membership from './components/ManagerDashboard/Membership';
import Downloads from './components/ManagerDashboard/Downloads';
import Expense from './components/ManagerDashboard/Expense';
import ProfileSettings from './components/ManagerDashboard/ProfileSettings';
import ProfileVerification from './components/ManagerDashboard/ProfileVerification';
import Attachments from './components/ManagerDashboard/Attachments';
import Tasks from './components/ManagerDashboard/Tasks';
import MessagesLayout from './components/ManagerDashboard/MessagesLayout';
import EditProfile from './components/ManagerDashboard/EditProfile';
import BrandsImporter from './components/BrandsImporter';

const { firestore: db } = firebaseInstances;

// Auth Route component that checks if user is authenticated
const ProtectedRoute = ({ children }) => {
  const { userData, isLoading } = useContext(UserContext);
  const location = useLocation();
  
  if (isLoading) {
    // Show loading state while checking auth
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }
  
  if (!userData) {
    // Redirect to login page if not authenticated
    return <Navigate to="/login" state={{ from: location.pathname }} replace />;
  }

  return children;
};


// Home route redirect component
const HomeRedirect = () => {
  const { userData, isLoading } = useContext(UserContext);
  const navigate = useNavigate();
  
  useEffect(() => {
    if (!isLoading && userData?.userName) {
      // If user is logged in, redirect to their profile
      navigate(`/${userData.userName}`);
    }
  }, [userData, isLoading, navigate]);
  
  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }
  
  // If not authenticated, show welcome page
  return <WelcomePage />;
};

// Profile wrapper to handle both authenticated and public views
// Profile wrapper to support both authenticated and public views
const ProfileWrapper = () => {
  const { username } = useParams();
  const { userData, isLoading } = useContext(UserContext);
  const [profileExists, setProfileExists] = useState(true); // Assume profile exists initially
  const [profileData, setProfileData] = useState(null);
  const [isProfileLoading, setIsProfileLoading] = useState(true);
  
  // Check if the requested profile exists and load basic profile data
  useEffect(() => {
    const checkProfileExists = async () => {
      if (!username) return;
      
      try {
        setIsProfileLoading(true);
        const db = getFirestore();
        const userQuery = query(collection(db, 'users'), where('userName', '==', username));
        const userSnapshot = await getDocs(userQuery);
        
        if (!userSnapshot.empty) {
          // Profile exists
          setProfileExists(true);
          
          // Get the user data for the public profile
          const userId = userSnapshot.docs[0].id;
          const userData = {
            userId,
            ...userSnapshot.docs[0].data()
          };
          setProfileData(userData);
        } else {
          // Profile doesn't exist
          setProfileExists(false);
        }
      } catch (error) {
        console.error('Error checking profile existence:', error);
        // Even if there's an error, we shouldn't immediately assume profile doesn't exist
        // This is important for permission errors vs. actual not-found errors
      } finally {
        setIsProfileLoading(false);
      }
    };
    
    checkProfileExists();
  }, [username]);
  
  // Show loading while checking profile existence
  if (isLoading || isProfileLoading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }
  
  // If profile doesn't exist, show not found
  if (!profileExists) {
    return (
      <div className="not-found-container">
        <h2>User Not Found</h2>
        <p>The profile you're looking for doesn't exist or may have been removed.</p>
      </div>
    );
  }
  
  // If user is authenticated, show the regular profile component
  if (userData) {
    return <Profile />;
  }
  
  // If user is not logged in but profile exists, show public profile
  return <PublicProfile profileData={profileData} />;
};

const UserDetailsWrapper = () => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const userDocRef = doc(db, 'users', userId);
        const userDocSnapshot = await getDoc(userDocRef);
        if (userDocSnapshot.exists()) {
          setUser(userDocSnapshot.data());
        } else {
          console.error('User not found');
        }
      } catch (error) {
        console.error('Error fetching user:', error);
      }
    };

    fetchUser();
  }, [userId]);

  return <UserDetails user={user} />;
};

// Schedule wrapper with auth check
const ScheduleWrapper = () => {
  const { tourId, dateId } = useParams();
  const { userData } = useContext(UserContext);
  const { tours, selectedTour, setSelectedTour, fetchToursData } = useContext(TourContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initializeTourData = async () => {
      if (!userData?.userId) {
        navigate('/login', { state: { from: `/schedule/${tourId}/${dateId}` } });
        return;
      }

      // If we don't have any tours loaded, fetch them
      if (tours.length === 0) {
        await fetchToursData(userData);
      }

      if (tourId) {
        // Check if the tour exists in user's tours
        const tour = tours.find(t => t.id === tourId);
        if (tour) {
          setSelectedTour(tour);
        } else if (userData.tourIds?.includes(tourId)) {
          // If tour exists in tourIds but not in tours, fetch it
          await fetchToursData(userData);
        } else {
          // Tour doesn't exist or user doesn't have access
          navigate(`/${userData.userName}`);
        }
      } else if (!selectedTour && tours.length > 0) {
        // If no tour is selected but we have tours, select the first one
        setSelectedTour(tours[0]);
      }

      setIsLoading(false);
    };

    initializeTourData();
  }, [userData, tourId, tours, fetchToursData, setSelectedTour, navigate, dateId]);

  if (isLoading) {
    return (
      <div className="loading-container">
        <div className="loading-spinner"></div>
        <p>Loading...</p>
      </div>
    );
  }

  return <Schedule />;
};

function App() {
  const [userData, setUserData] = useState(null);
  const [authToken, setAuthToken] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // User is signed in
        const token = user.getIdToken();
        getUserData(user.uid)
          .then((userData) => {
            setUserData(userData);
            setAuthToken(token);
          })
          .catch((error) => {
            console.error('Error fetching user data:', error);
            // Handle the error scenario
          });
      } else {
        // User is signed out
        const parsedUrl = queryString.parseUrl(window.location.href);
        const { email, token } = parsedUrl.query;
  
        if (token) {
          // Auto-login with token
          signInWithToken(email, token)
            .then((userData) => {
              setUserData(userData);
              setAuthToken(token);
            })
            .catch((error) => {
              console.error('Error during auto-login:', error);
              // Handle the error scenario
            });
        } else {
          setUserData(null);
          setAuthToken(null);
        }
      }
    });
  
    return () => unsubscribe();
  }, []);
  
  const signInWithToken = async (email, token) => {
    try {
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, token);
      const user = userCredential.user;
      const userData = await getUserData(user.uid);
      return userData;
    } catch (error) {
      console.error('Error during sign-in with token:', error);
      throw error;
    }
  };

  return (
    <ThemeProvider>
      <UserProvider>
      <TourProvider>
      <ChatProvider>
    <Router>
      <Routes>
        {/* Main URL route - check if logged in and redirect if needed */}
        <Route path="/" element={<HomeRedirect />} />
        
        {/* Public routes - accessible to everyone */}
        <Route path="/import-brands" element={<BrandsImporter />} />
        <Route path="/Terms-and-Conditions" element={<TermsAndConditions />} />
        <Route path="/Contact-Us" element={<ContactUs />} />
        <Route path="/Privacy-Policy" element={<PrivacyPolicy />} />
        <Route path="/help" element={<Help />} />
        <Route path="/Refund-and-Cancellation" element={<RefundAndCancellation />} />
        <Route path="/signup" element={<SignupPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/p/:postId" element={<PostPage />} />
        
        {/* Profile routes - special handling for both public and private views */}
        <Route path="/:username" element={<ProfileWrapper />} />
        
        {/* Protected routes - only accessible when logged in */}
        <Route path="/dashboard" element={<ProtectedRoute><Dashboard /></ProtectedRoute>} />
        <Route path="/Account-Details" element={<ProtectedRoute><AccountDetails /></ProtectedRoute>} />
        <Route path="/edit-profile" element={<ProtectedRoute><EditProfile /></ProtectedRoute>} />
        <Route path="/Payment-Info" element={<ProtectedRoute><PaymentInfo /></ProtectedRoute>} />
        <Route path="/users" element={<ProtectedRoute><Users /></ProtectedRoute>} />
        <Route path="/users/:userId" element={<ProtectedRoute><UserDetailsWrapper /></ProtectedRoute>} />
        <Route path="/event/:tourId/:dateId" element={<ProtectedRoute><Event /></ProtectedRoute>} />
        <Route path="/schedule" element={<ProtectedRoute><ScheduleWrapper /></ProtectedRoute>} />
        <Route path="/:tourId/:dateId/attachments" element={<ProtectedRoute><Attachments /></ProtectedRoute>} />
        <Route path="/:tourId/:dateId/tasks" element={<ProtectedRoute><Tasks /></ProtectedRoute>} />
        <Route path="/search" element={<ProtectedRoute><Search /></ProtectedRoute>} />
        <Route path="/Crew" element={<ProtectedRoute><Crew /></ProtectedRoute>} />
        <Route path="/Tickets" element={<ProtectedRoute><Tickets /></ProtectedRoute>} />
        <Route path="/Membership" element={<ProtectedRoute><Membership /></ProtectedRoute>} />
        <Route path="/Downloads" element={<ProtectedRoute><Downloads /></ProtectedRoute>} />
        <Route path="/Expense" element={<ProtectedRoute><Expense /></ProtectedRoute>} />
        <Route path="/Settings" element={<ProtectedRoute><ProfileSettings /></ProtectedRoute>} />
        <Route path="/verification" element={<ProtectedRoute><ProfileVerification /></ProtectedRoute>} />
        <Route path="/create-tour" element={<ProtectedRoute><CreateTour /></ProtectedRoute>} />
        <Route path="/messages" element={<ProtectedRoute><MessagesLayout /></ProtectedRoute>} />
        <Route path="/:tourId/:dateId" element={<ProtectedRoute><ScheduleWrapper /></ProtectedRoute>} />
        <Route path="/schedule" element={<ProtectedRoute><ScheduleWrapper /></ProtectedRoute>} />
      </Routes>
    </Router>
    </ChatProvider>
    </TourProvider>
    </UserProvider>
       </ThemeProvider>
  );
};

export default App;