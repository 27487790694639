import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import ArtistProfile from './ArtistDashboard/Profile';
import CrewProfile from './CrewDashboard/Profile';
import ManagerProfile from './ManagerDashboard/Profile';
import VendorProfile from './VendorDashboard/Profile';
import VenueProfile from './VenueDashboard/Profile';
import AdminProfile from './AdminDashboard/Profile';
import PublicProfile from './PublicProfile'; // Import the new public profile
import UserContext from '../context/UserContext';

const Profile = () => {
  const { username } = useParams();
  const { userData, getUserDataFromCache } = useContext(UserContext);
  const [profileData, setProfileData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const navigate = useNavigate();

  // Fetch profile data if it's not the current user's profile
  useEffect(() => {
    const fetchProfileData = async () => {
      setIsLoading(true);
      try {
        // If viewing own profile or the username matches current user
        if (userData?.userName === username) {
          setProfileData(userData);
          setIsOwnProfile(true);
          setIsLoading(false);
          return;
        }

        // Otherwise, fetch the other user's data
        const db = getFirestore();
        const userQuery = query(collection(db, 'users'), where('userName', '==', username));
        const userSnapshot = await getDocs(userQuery);
        
        if (!userSnapshot.empty) {
          const userId = userSnapshot.docs[0].id;
          const otherUserData = await getUserDataFromCache(userId);
          setProfileData(otherUserData);
          setIsOwnProfile(false);
        } else {
          // User not found
          setProfileData(null);
          // Navigate to not found or show not found message
          console.error("User not found");
        }
      } catch (error) {
        console.error('Error fetching profile data:', error);
        setProfileData(null);
      } finally {
        setIsLoading(false);
      }
    };

    if (userData || username) {
      fetchProfileData();
    }
  }, [username, userData, getUserDataFromCache]);

  // Loading state
  if (isLoading) {
    return <div className="loading-container">
      <div className="loading-spinner"></div>
      <p>Loading...</p>
    </div>;
  }

  // User not found state
  if (!profileData) {
    return <div className="not-found-container">
      <h2>User Not Found</h2>
      <p>The profile you're looking for doesn't exist or may have been removed.</p>
    </div>;
  }

  // If it's the current user's profile, route to the appropriate dashboard
  if (isOwnProfile) {
    switch (userData.userType) {
      case 'Artist':
        return <ArtistProfile username={username} />;
      case 'Crew':
        return <CrewProfile username={username} />;
      case 'Manager':
        return <ManagerProfile username={username} />;
      case 'Vendor':
        return <VendorProfile username={username} />;
      case 'Venue':
        return <VenueProfile username={username} />;
      case 'Admin':
        return <AdminProfile username={username} />;
      default:
        return <div>User type not recognized: {userData.userType}</div>;
    }
  }

  // If viewing someone else's profile, still route to the appropriate dashboard
  // but with an indication that we're viewing another user's profile
  switch (profileData.userType) {
    case 'Artist':
      return <ArtistProfile username={username} isOtherUser={true} profileData={profileData} />;
    case 'Crew':
      return <CrewProfile username={username} isOtherUser={true} profileData={profileData} />;
    case 'Manager':
      return <ManagerProfile username={username} isOtherUser={true} profileData={profileData} />;
    case 'Vendor':
      return <VendorProfile username={username} isOtherUser={true} profileData={profileData} />;
    case 'Venue':
      return <VenueProfile username={username} isOtherUser={true} profileData={profileData} />;
    case 'Admin':
      return <AdminProfile username={username} isOtherUser={true} profileData={profileData} />;
    default:
      return <div>User type not recognized: {profileData.userType}</div>;
  }
};

export default Profile;