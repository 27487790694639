import React, { useState, useContext, useEffect, useRef } from 'react';
import { collection, query, where, getDocs, doc, getDoc, updateDoc, limit, arrayUnion } from 'firebase/firestore';
import { getFirestoreInstance } from '../../utils/firebaseHelper';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { getStorage } from 'firebase/storage';
import { UserContext } from '../../context/UserContext';
import { TourContext } from '../../context/TourContext';
import TopBar from '../TopBar';
import LeftMenu from '../LeftMenu';
import RightMenu from '../RightMenu';
import styles from './EditProfile.module.css';
import AssociatedActsSection from '../common/AssociatedActsSection';
import { createBandNotification, BAND_NOTIFICATION_TYPES } from '../../utils/BandNotificationService';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUpload, 
  faCamera, 
  faExclamationTriangle, 
  faSearch, 
  faTimes, 
  faCheckCircle, 
  faTimesCircle, 
  faInfoCircle, 
  faExternalLinkAlt
} from '@fortawesome/free-solid-svg-icons';
import { 
  faInstagram, 
  faTwitter, 
  faFacebook, 
  faYoutube, 
  faLinkedin 
} from '@fortawesome/free-brands-svg-icons';
import defaultProfilePic from '../../assets/Images/defaultUserProfilePicture.png';
import defaultCoverPhoto from '../../assets/Images/defaultUserCoverPicture.png';
import spotifyLogo from '../../assets/Images/spotifyicon.png';
import appleMusicLogo from '../../assets/Images/applemusicicon.png';
const EditProfile = () => {
  const { userData, setUserData } = useContext(UserContext);
  const { tours, updateTourData } = useContext(TourContext);
  const [isLoading, setIsLoading] = useState(false);
  const [fullName, setFullName] = useState('');
  const [bio, setBio] = useState('');
  const [userName, setUserName] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [country, setCountry] = useState('');
  const [showUsernameDialog, setShowUsernameDialog] = useState(false);
  const [associatedActs, setAssociatedActs] = useState([]);

  // For profile photos
  const [profilePictureUrl, setProfilePictureUrl] = useState('');
  const [profileCoverPictureUrl, setProfileCoverPictureUrl] = useState('');
  const [profilePictureFile, setProfilePictureFile] = useState(null);
  const [coverPhotoFile, setCoverPhotoFile] = useState(null);
  const profilePictureRef = useRef(null);
  const coverPhotoRef = useRef(null);
  
  // For social links
  const [socialLinks, setSocialLinks] = useState({
    instagramUrl: '',
    twitterUrl: '',
    facebookUrl: '',
    youtubeUrl: '',
    linkedInUrl: '',
    spotifyUrl: '',
    appleMusicUrl: ''
  });
  
  // For endorsements
  const [showBrandSearchModal, setShowBrandSearchModal] = useState(false);
  const [brandSearchTerm, setBrandSearchTerm] = useState('');
  const [brandSearchResults, setBrandSearchResults] = useState([]);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  
  // For alerts
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({
    title: '',
    message: '',
    type: '', // 'success', 'error', 'warning'
    onConfirm: null,
    showCancel: false
  });

  useEffect(() => {
    if (userData) {
      // Set basic user data
      setFullName(userData.fullName || '');
      setBio(userData.bio || '');
      setUserName(userData.userName || '');
      setCity(userData.city || '');
      setState(userData.state || '');
      setCountry(userData.country || '');
      setProfilePictureUrl(userData.profilePictureUrl || '');
      setProfileCoverPictureUrl(userData.profileCoverPictureUrl || '');
      setAssociatedActs(userData.associatedActs || []);
      // Set social links
      setSocialLinks({
        instagramUrl: userData.socialLinks?.instagramUrl || '',
        twitterUrl: userData.socialLinks?.twitterUrl || '',
        facebookUrl: userData.socialLinks?.facebookUrl || '',
        youtubeUrl: userData.socialLinks?.youtubeUrl || '',
        linkedInUrl: userData.socialLinks?.linkedInUrl || '',
        spotifyUrl: userData.socialLinks?.spotifyUrl || '',
        appleMusicUrl: userData.socialLinks?.appleMusicUrl || ''
      });
      
      // Set endorsements
      if (userData.endorsedBrands && Array.isArray(userData.endorsedBrands)) {
        setSelectedBrands(userData.endorsedBrands);
      }
    }
  }, [userData]);

  const showCustomAlert = (config) => {
    setAlertConfig(config);
    setShowAlert(true);
  };

  const isValidUsername = (username) => {
    const usernameRegex = /^[a-z0-9_]+$/;
    return usernameRegex.test(username);
  };

  // Username availability check
  const checkUsernameAvailability = async (username) => {
    const firestore = getFirestoreInstance();
    const usersRef = collection(firestore, 'users');
    const q = query(
      usersRef,
      where('userName', '==', username),
      limit(1)
    );
    try {
      const querySnapshot = await getDocs(q);
      return querySnapshot.empty;
    } catch (error) {
      console.error('Error checking username availability:', error);
      return false;
    }
  };

  // Update username function
  const handleUpdateUsername = async () => {
    if (!isValidUsername(userName)) {
      showCustomAlert({
        title: 'Invalid Username',
        message: 'Username can only contain lowercase letters, numbers, and "_". No spaces or "@" symbol allowed.',
        type: 'error'
      });
      return;
    }

    if (userName === userData.userName) {
      showCustomAlert({
        title: 'No Change',
        message: 'The new username is the same as the current one.',
        type: 'warning'
      });
      return;
    }

    setIsLoading(true);
    console.log('Starting username update');
    
    try {
      const isAvailable = await checkUsernameAvailability(userName);
      if (!isAvailable) {
        showCustomAlert({
          title: 'Username Taken',
          message: 'This username is already in use. Please choose a different one.',
          type: 'error'
        });
        setIsLoading(false);
        return;
      }

      const newProfileUrl = `https://ontour.co.in/${userName}`;
      const db = getFirestoreInstance();
      const userRef = doc(db, 'users', userData.userId);

      // Update user document
      await updateDoc(userRef, {
        userName,
        profileUrl: newProfileUrl
      });
      console.log('Username updated in users collection');

      // Update all associated tours
      if (userData.tourIds && userData.tourIds.length > 0) {
        console.log('Updating username in tours:', userData.tourIds);
        
        for (const tourId of userData.tourIds) {
          const tourRef = doc(db, 'Tours', tourId);
          const tourDoc = await getDoc(tourRef);

          if (tourDoc.exists()) {
            const tourData = tourDoc.data();
            let needsUpdate = false;
            let updatedTourData = { ...tourData };

            // Helper function to update username in arrays
            const updateUsernameInArray = (array) => {
              if (!array) return array;
              return array.map(item => {
                if (item.userId === userData.userId) {
                  needsUpdate = true;
                  return { 
                    ...item, 
                    userName,
                    profileUrl: newProfileUrl
                  };
                }
                return item;
              });
            };

            // Update all relevant arrays based on user type
            if (userData.userType === 'Artist' && updatedTourData.artistDetails) {
              updatedTourData.artistDetails = updateUsernameInArray(updatedTourData.artistDetails);
            }
            if (userData.userType === 'Crew' && updatedTourData.crewDetails) {
              updatedTourData.crewDetails = updateUsernameInArray(updatedTourData.crewDetails);
            }
            if (userData.userType === 'Vendor' && updatedTourData.vendorDetails) {
              updatedTourData.vendorDetails = updateUsernameInArray(updatedTourData.vendorDetails);
            }
            if (userData.userType === 'Staff' && updatedTourData.staffDetails) {
              updatedTourData.staffDetails = updateUsernameInArray(updatedTourData.staffDetails);
            }

            // Update createdBy if user is manager
            if (userData.userType === 'Manager' && 
                tourData.createdBy?.userId === userData.userId) {
              needsUpdate = true;
              updatedTourData.createdBy = {
                ...tourData.createdBy,
                userName,
                profileUrl: newProfileUrl
              };
            }

            if (needsUpdate) {
              console.log('Updating tour:', tourId);
              await updateDoc(tourRef, updatedTourData);
              if (updateTourData) {
                await updateTourData(tourId);
              }
            }
          }
        }
      }

      // Update context
      setUserData(prev => ({
        ...prev,
        userName,
        profileUrl: newProfileUrl
      }));

      setShowUsernameDialog(false);
      showCustomAlert({
        title: 'Success',
        message: 'Username updated successfully',
        type: 'success'
      });
    } catch (error) {
      console.error('Error updating username:', error);
      showCustomAlert({
        title: 'Error',
        message: 'Failed to update username. Please try again.',
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleBrandSearch = async () => {
    if (brandSearchTerm.trim()) {
      setIsLoading(true);
      try {
        const db = getFirestoreInstance();
        const brandsRef = doc(db, 'musicalBrands', 'brandsList');
        const brandsDoc = await getDoc(brandsRef);

        if (brandsDoc.exists()) {
          const brandsData = brandsDoc.data();
          const brandsDetails = brandsData.brandsDetails || [];

          // Case insensitive search
          const searchTerm = brandSearchTerm.toLowerCase().trim();
          const results = brandsDetails.filter(brand => 
            brand.brandName.toLowerCase().includes(searchTerm)
          ).slice(0, 5);  // Limit to 5 results

          setBrandSearchResults(results);
        } else {
          setBrandSearchResults([]);
        }
        setHasSearched(true);
      } catch (error) {
        console.error('Error searching brands:', error);
        showCustomAlert({
          title: 'Error',
          message: 'Failed to search brands. Please try again.',
          type: 'error'
        });
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleBrandSelection = (brand) => {
    if (!selectedBrands.some(b => b.brandName === brand.brandName)) {
      setSelectedBrands(prev => [...prev, brand]);
    }
  };

  const removeSelectedBrand = (brandName) => {
    setSelectedBrands(prev => prev.filter(b => b.brandName !== brandName));
  };


  // Add these handlers for associated acts
const handleAssociatedActAdd = async (act) => {
    try {
      setIsLoading(true);
      const db = getFirestoreInstance();
      const userRef = doc(db, 'users', userData.userId);
  
      // Check if the act is already associated
      if (associatedActs.some(a => a.id === act.id)) {
        showCustomAlert({
          title: 'Already Associated',
          message: 'This act is already in your associated acts.',
          type: 'warning'
        });
        return;
      }
  
      // Prepare the act data
      const actData = {
        id: act.id,
        type: act.type || 'artist', // 'artist' or 'band'
        name: act.name,
        username: act.username,
        photoUrl: act.photoUrl,
        subType: act.subType,
        genre: act.genre,
        verified: act.verified || false
      };
  
      // Update Firestore
      await updateDoc(userRef, {
        associatedActs: arrayUnion(actData)
      });
  
      // If it's a band, create notification for band admins
      if (act.type === 'band') {
        await createBandNotification({
          bandId: act.id,
          type: BAND_NOTIFICATION_TYPES.USER_ADDED,
          title: 'New Association',
          message: `${userData.fullName} has added ${act.name} to their associated acts`,
          recipientIds: act.adminIds // This should come from the band data
        });
      }
  
      // Update local state
      setAssociatedActs(prev => [...prev, actData]);
      
      // Update UserContext
      setUserData(prev => ({
        ...prev,
        associatedActs: [...(prev.associatedActs || []), actData]
      }));
  
      showCustomAlert({
        title: 'Success',
        message: 'Associated act added successfully',
        type: 'success'
      });
    } catch (error) {
      console.error('Error adding associated act:', error);
      showCustomAlert({
        title: 'Error',
        message: 'Failed to add associated act. Please try again.',
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleAssociatedActRemove = async (actId) => {
    try {
      setIsLoading(true);
      const db = getFirestoreInstance();
      const userRef = doc(db, 'users', userData.userId);
  
      const actToRemove = associatedActs.find(a => a.id === actId);
      if (!actToRemove) return;
  
      // Update Firestore - arrayRemove doesn't work well with objects, so we need to filter
      const updatedActs = associatedActs.filter(a => a.id !== actId);
      await updateDoc(userRef, {
        associatedActs: updatedActs
      });
  
      // If it's a band, create notification for band admins
      if (actToRemove.type === 'band') {
        await createBandNotification({
          bandId: actId,
          type: BAND_NOTIFICATION_TYPES.USER_REMOVED,
          title: 'Association Removed',
          message: `${userData.fullName} has removed ${actToRemove.name} from their associated acts`,
          recipientIds: actToRemove.adminIds
        });
      }
  
      // Update local state
      setAssociatedActs(updatedActs);
      
      // Update UserContext
      setUserData(prev => ({
        ...prev,
        associatedActs: updatedActs
      }));
  
      showCustomAlert({
        title: 'Success',
        message: 'Associated act removed successfully',
        type: 'success'
      });
    } catch (error) {
      console.error('Error removing associated act:', error);
      showCustomAlert({
        title: 'Error',
        message: 'Failed to remove associated act. Please try again.',
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleProfilePictureClick = () => {
    profilePictureRef.current.click();
  };

  const handleCoverPhotoClick = () => {
    coverPhotoRef.current.click();
  };

  const handleProfilePictureChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setProfilePictureFile(file);
      setProfilePictureUrl(URL.createObjectURL(file));
    }
  };

  const handleCoverPhotoChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      const file = e.target.files[0];
      setCoverPhotoFile(file);
      setProfileCoverPictureUrl(URL.createObjectURL(file));
    }
  };

  const uploadImageFile = async (file, path) => {
    if (!file) return null;

    try {
      const storage = getStorage();
      const storageRef = ref(storage, path);
      await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(storageRef);
      return downloadURL;
    } catch (error) {
      console.error('Error uploading image:', error);
      throw error;
    }
  };

  const handleSocialLinkChange = (platform, value) => {
    setSocialLinks(prev => ({
      ...prev,
      [platform]: value
    }));
  };

  const handleUpdateProfile = async () => {
    setIsLoading(true);
    try {
      const db = getFirestoreInstance();
      const userRef = doc(db, 'users', userData.userId);

      let updatedProfilePictureUrl = profilePictureUrl;
      let updatedCoverPhotoUrl = profileCoverPictureUrl;

      // Upload new profile picture if changed
      if (profilePictureFile) {
        const path = `profilePictures/${userData.userId}/${Date.now()}_${profilePictureFile.name}`;
        updatedProfilePictureUrl = await uploadImageFile(profilePictureFile, path);
      }

      // Upload new cover photo if changed
      if (coverPhotoFile) {
        const path = `coverPhotos/${userData.userId}/${Date.now()}_${coverPhotoFile.name}`;
        updatedCoverPhotoUrl = await uploadImageFile(coverPhotoFile, path);
      }

      // Prepare update data
      const updateData = {
        fullName,
        bio,
        city,
        state,
        country,
        socialLinks,
        profilePictureUrl: updatedProfilePictureUrl,
        profileCoverPictureUrl: updatedCoverPhotoUrl,
        endorsedBrands: selectedBrands
      };

      // Update user document
      await updateDoc(userRef, updateData);

      // Update any associated tours with the new data
      if (userData.tourIds && userData.tourIds.length > 0) {
        for (const tourId of userData.tourIds) {
          const tourRef = doc(db, 'Tours', tourId);
          const tourDoc = await getDoc(tourRef);

          if (tourDoc.exists()) {
            const tourData = tourDoc.data();
            let needsUpdate = false;
            let updatedTourData = { ...tourData };

            // Helper function to update user data in arrays
            const updateUserDetailsInArray = (array) => {
              if (!array) return array;
              return array.map(item => {
                if (item.userId === userData.userId) {
                  needsUpdate = true;
                  return { 
                    ...item, 
                    fullName,
                    profilePictureUrl: updatedProfilePictureUrl
                  };
                }
                return item;
              });
            };

            // Update all relevant arrays based on user type
            if (userData.userType === 'Artist' && updatedTourData.artistDetails) {
              updatedTourData.artistDetails = updateUserDetailsInArray(updatedTourData.artistDetails);
            }
            if (userData.userType === 'Crew' && updatedTourData.crewDetails) {
              updatedTourData.crewDetails = updateUserDetailsInArray(updatedTourData.crewDetails);
            }
            if (userData.userType === 'Vendor' && updatedTourData.vendorDetails) {
              updatedTourData.vendorDetails = updateUserDetailsInArray(updatedTourData.vendorDetails);
            }
            if (userData.userType === 'Staff' && updatedTourData.staffDetails) {
              updatedTourData.staffDetails = updateUserDetailsInArray(updatedTourData.staffDetails);
            }

            // Update createdBy if user is manager
            if (userData.userType === 'Manager' && 
                tourData.createdBy?.userId === userData.userId) {
              needsUpdate = true;
              updatedTourData.createdBy = {
                ...tourData.createdBy,
                fullName,
                profilePictureUrl: updatedProfilePictureUrl
              };
            }

            if (needsUpdate) {
              await updateDoc(tourRef, updatedTourData);
              if (updateTourData) {
                await updateTourData(tourId);
              }
            }
          }
        }
      }

      // Update UserContext
      setUserData(prev => ({
        ...prev,
        ...updateData
      }));

      showCustomAlert({
        title: 'Success',
        message: 'Profile updated successfully',
        type: 'success'
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      showCustomAlert({
        title: 'Error',
        message: 'Failed to update profile. Please try again.',
        type: 'error'
      });
    } finally {
      setIsLoading(false);
    }
  };

  const AlertModal = () => {
    const getAlertIcon = () => {
      switch (alertConfig.type) {
        case 'success':
          return faCheckCircle;
        case 'error':
          return faTimesCircle;
        case 'warning':
          return faExclamationTriangle;
        default:
          return faInfoCircle;
      }
    };
  
    return (
      <div className={styles.alertModalOverlay}>
        <div className={styles.alertModal}>
          <div className={styles.alertHeader}>
            <FontAwesomeIcon 
              icon={getAlertIcon()} 
              className={`${styles.alertIcon} ${styles[alertConfig.type]}`}
            />
            <h3 className={styles.alertTitle}>{alertConfig.title}</h3>
          </div>
          <p className={styles.alertMessage}>{alertConfig.message}</p>
          <div className={styles.alertActions}>
            {alertConfig.showCancel && (
              <button 
                className={`${styles.alertButton} ${styles.cancel}`}
                onClick={() => setShowAlert(false)}
              >
                Cancel
              </button>
            )}
            <button 
              className={`${styles.alertButton} ${styles[alertConfig.type]}`}
              onClick={() => {
                if (alertConfig.onConfirm) alertConfig.onConfirm();
                setShowAlert(false);
              }}
            >
              OK
            </button>
          </div>
        </div>
      </div>
    );
  };

  const LoadingSpinner = () => (
    <div className={styles.loadingOverlay}>
      <div className={styles.spinner}></div>
    </div>
  );

  return (
    <div className={styles.mainLayout}>
      <TopBar />
      <LeftMenu />
      <div className={styles.editProfileMain}>
        <div className={styles.editProfileContent}>
          <div className={styles.editProfileHeader}>
            <h1 className={styles.title}>Edit Profile</h1>
          </div>

          <div className={styles.formContainer}>
            {/* Profile Pictures Section */}
            <div className={styles.picturesSection}>
              <div className={styles.coverPhotoContainer}>
                <img 
                  src={profileCoverPictureUrl || defaultCoverPhoto}
                  alt="Cover"
                  className={styles.coverPhoto}
                />
                <button 
                  className={styles.editCoverPhotoButton}
                  onClick={handleCoverPhotoClick}
                >
                  <FontAwesomeIcon icon={faCamera} />
                </button>
                <input
                  type="file"
                  accept="image/*"
                  ref={coverPhotoRef}
                  onChange={handleCoverPhotoChange}
                  className={styles.hiddenInput}
                />
              </div>

              <div className={styles.profilePictureContainer}>
                <img 
                  src={profilePictureUrl || defaultProfilePic}
                  alt="Profile"
                  className={styles.profilePicture}
                />
                <button 
                  className={styles.editProfilePictureButton}
                  onClick={handleProfilePictureClick}
                >
                  <FontAwesomeIcon icon={faCamera} />
                </button>
                <input
                  type="file"
                  accept="image/*"
                  ref={profilePictureRef}
                  onChange={handleProfilePictureChange}
                  className={styles.hiddenInput}
                />
              </div>
            </div>

            {/* Basic Information */}
            <div className={styles.section}>
              <h2 className={styles.sectionTitle}>Basic Information</h2>
              
              <div className={styles.field}>
                <label>Full Name</label>
                <input
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  className={styles.input}
                  placeholder="Your full name"
                />
              </div>

              <div className={styles.field}>
                <label>Bio</label>
                <textarea
                  value={bio}
                  onChange={(e) => setBio(e.target.value)}
                  className={styles.textarea}
                  placeholder="Tell us about yourself..."
                  rows={4}
                  maxLength={500}
                />
                <div className={styles.charCount}>
                  {bio.length}/500 characters
                </div>
              </div>

              <div className={styles.field}>
                <label>Username</label>
                <div className={styles.inputGroup}>
                  <input
                    type="text"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                    className={styles.input}
                    placeholder="username"
                  />
                  <button 
                    onClick={() => setShowUsernameDialog(true)}
                    className={styles.updateButton}
                  >
                    Update
                  </button>
                </div>
                <div className={styles.helperText}>
                  Only lowercase letters, numbers, and underscore (_) are allowed. No spaces.
                </div>
              </div>
            </div>

            {/* Location Information */}
            <div className={styles.section}>
              <h2 className={styles.sectionTitle}>Location</h2>
              
              <div className={styles.field}>
                <label>City</label>
                <input
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  className={styles.input}
                  placeholder="Your city"
                />
              </div>

              <div className={styles.field}>
                <label>State</label>
                <input
                  type="text"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                  className={styles.input}
                  placeholder="Your state"
                />
              </div>

              <div className={styles.field}>
                <label>Country</label>
                <input
                  type="text"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  className={styles.input}
                  placeholder="Your country"
                />
              </div>
            </div>

            {/* Social Links */}
            <div className={styles.section}>
              <h2 className={styles.sectionTitle}>Social Links</h2>
              
              <div className={styles.field}>
                <div className={styles.socialInputGroup}>
                  <FontAwesomeIcon icon={faInstagram} className={styles.socialIcon} />
                  <input
                    type="url"
                    value={socialLinks.instagramUrl}
                    onChange={(e) => handleSocialLinkChange('instagramUrl', e.target.value)}
                    className={styles.input}
                    placeholder="Instagram URL"
                  />
                </div>
              </div>

              <div className={styles.field}>
                <div className={styles.socialInputGroup}>
                  <FontAwesomeIcon icon={faTwitter} className={styles.socialIcon} />
                  <input
                    type="url"
                    value={socialLinks.twitterUrl}
                    onChange={(e) => handleSocialLinkChange('twitterUrl', e.target.value)}
                    className={styles.input}
                    placeholder="Twitter URL"
                  />
                </div>
              </div>

              <div className={styles.field}>
                <div className={styles.socialInputGroup}>
                  <FontAwesomeIcon icon={faFacebook} className={styles.socialIcon} />
                  <input
                    type="url"
                    value={socialLinks.facebookUrl}
                    onChange={(e) => handleSocialLinkChange('facebookUrl', e.target.value)}
                    className={styles.input}
                    placeholder="Facebook URL"
                  />
                </div>
              </div>

              <div className={styles.field}>
                <div className={styles.socialInputGroup}>
                  <FontAwesomeIcon icon={faYoutube} className={styles.socialIcon} />
                  <input
                    type="url"
                    value={socialLinks.youtubeUrl}
                    onChange={(e) => handleSocialLinkChange('youtubeUrl', e.target.value)}
                    className={styles.input}
                    placeholder="YouTube URL"
                  />
                </div>
              </div>

              <div className={styles.field}>
                <div className={styles.socialInputGroup}>
                  <FontAwesomeIcon icon={faLinkedin} className={styles.socialIcon} />
                  <input
                    type="url"
                    value={socialLinks.linkedInUrl}
                    onChange={(e) => handleSocialLinkChange('linkedInUrl', e.target.value)}
                    className={styles.input}
                    placeholder="LinkedIn URL"
                  />
                </div>
              </div>

              <div className={styles.field}>
                <div className={styles.socialInputGroup}>
                  <div className={styles.socialIconContainer}>
                    <img src={spotifyLogo} alt="Spotify" className={styles.brandSocialIcon} />
                  </div>
                  <input
                    type="url"
                    value={socialLinks.spotifyUrl}
                    onChange={(e) => handleSocialLinkChange('spotifyUrl', e.target.value)}
                    className={styles.input}
                    placeholder="Spotify URL"
                  />
                </div>
              </div>

              <div className={styles.field}>
                <div className={styles.socialInputGroup}>
                  <div className={styles.socialIconContainer}>
                    <img src={appleMusicLogo} alt="Apple Music" className={styles.brandSocialIcon} />
                  </div>
                  <input
                    type="url"
                    value={socialLinks.appleMusicUrl}
                    onChange={(e) => handleSocialLinkChange('appleMusicUrl', e.target.value)}
                    className={styles.input}
                    placeholder="Apple Music URL"
                  />
                </div>
              </div>
            </div>

            <AssociatedActsSection 
  associatedActs={associatedActs}
  onAssociatedActAdd={handleAssociatedActAdd}
  onAssociatedActRemove={handleAssociatedActRemove}
/>

            {/* Endorsements */}
            <div className={styles.section}>
              <h2 className={styles.sectionTitle}>Endorsements</h2>
              
              <button 
                className={styles.searchBrandButton}
                onClick={() => setShowBrandSearchModal(true)}
              >
                <FontAwesomeIcon icon={faSearch} />
                Search Brands
              </button>
              
              {selectedBrands.length > 0 && (
  <div className={styles.selectedBrandsContainer}>
    <h3 className={styles.subsectionTitle}>Endorsed By</h3>
    <div className={styles.selectedBrandsGrid}>
      {selectedBrands.map((brand) => (
        <div key={brand.brandName} className={styles.selectedBrandTag}>
          <img 
            src={brand.logo || defaultProfilePic} 
            alt={brand.brandName}
            className={styles.selectedBrandLogo}
          />
          <span className={styles.selectedBrandName}>{brand.brandName}</span>
          <button 
            className={styles.removeBrandButton}
            onClick={() => removeSelectedBrand(brand.brandName)}
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      ))}
    </div>
  </div>
)}
            </div>

            {/* Save Button */}
            <button 
              onClick={handleUpdateProfile}
              className={styles.saveButton}
              disabled={isLoading}
            >
              {isLoading ? 'Updating...' : 'Save Profile'}
            </button>
          </div>
        </div>
      </div>
      <RightMenu />

      {/* Username Update Confirmation Dialog */}
      {showUsernameDialog && (
        <div className={styles.alertModalOverlay}>
          <div className={styles.alertModal}>
            <div className={styles.alertHeader}>
              <FontAwesomeIcon 
                icon={faExclamationTriangle} 
                className={styles.alertIcon} 
              />
              <h3 className={styles.alertTitle}>Update Username</h3>
            </div>
            <p className={styles.alertMessage}>
              Are you sure you want to update your username? This will update your username across all your tours.
            </p>
            <div className={styles.alertActions}>
              <button 
                className={`${styles.alertButton} ${styles.cancel}`}
                onClick={() => setShowUsernameDialog(false)}
                disabled={isLoading}
              >
                Cancel
              </button>
              <button 
                className={`${styles.alertButton} ${styles.confirm}`}
                onClick={handleUpdateUsername}
                disabled={isLoading}
              >
                Update
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Brand Search Modal */}
      {showBrandSearchModal && (
        <div className={styles.modalOverlay}>
          <div className={styles.modal}>
            <div className={styles.modalHeader}>
              <h2>Search Brands</h2>
              <button 
                className={styles.closeButton}
                onClick={() => {
                  setShowBrandSearchModal(false);
                  setBrandSearchTerm('');
                  setBrandSearchResults([]);
                  setHasSearched(false);
                }}
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            </div>

            <div className={styles.searchBar}>
              <input
                type="text"
                placeholder="Search brands..."
                value={brandSearchTerm}
                onChange={(e) => setBrandSearchTerm(e.target.value)}
                onKeyDown={(e) => e.key === 'Enter' && handleBrandSearch()}
              />
              <button 
                className={styles.searchButton}
                onClick={handleBrandSearch}
                disabled={!brandSearchTerm.trim()}
              >
                <FontAwesomeIcon icon={faSearch} />
                Search
              </button>
            </div>

            <div className={styles.searchResults}>
              {isLoading ? (
                <div className={styles.loading}>Loading...</div>
              ) : hasSearched ? (
                brandSearchResults.length > 0 ? (
                  <div className={styles.resultsGrid}>
    {brandSearchResults.map(brand => (
      <div 
        key={brand.brandName} 
        className={styles.resultCard}
        onClick={() => handleBrandSelection(brand)}
      >
        <div className={styles.brandResultContent}>
          <img 
            src={brand.logo || defaultProfilePic} 
            alt={brand.brandName}
            className={styles.brandLogo}
          />
          <div className={styles.brandInfo}>
            <h3>{brand.brandName}</h3>
            {brand.website && (
              <div className={styles.brandWebsite}>
                <FontAwesomeIcon icon={faExternalLinkAlt} />
                <span>{brand.website}</span>
              </div>
            )}
          </div>
        </div>
      </div>
    ))}
  </div>
                ) : (
                  <div className={styles.noResults}>
                    No brands found for "{brandSearchTerm}"
                  </div>
                )
              ) : (
                <div className={styles.searchPrompt}>
                  Search for musical brands you endorse
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Brand website viewing will be handled in Profile.js */}

      {/* Custom Alert Dialog */}
      {showAlert && <AlertModal />}
      
      {/* Loading Spinner */}
      {isLoading && <LoadingSpinner />}
    </div>
  );
};

export default EditProfile;