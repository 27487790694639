import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faPlus,
  faPlane,
  faBus,
  faTrain,
  faTimes,
  faUpload,
  faChevronDown, 
  faChevronUp, 
} from '@fortawesome/free-solid-svg-icons';
import { doc, updateDoc, getDoc, Timestamp } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { getStorage, ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Add this
import { TourContext } from '../../context/TourContext';
import { UserContext } from '../../context/UserContext'; // Add this
import styles from './TicketModal.module.css'
import defaultProfilePhoto from '../../assets/Images/defaultUserProfilePicture.png';

const TicketModal = ({ editingTicket = null, isOpen, onClose }) => {
    const { selectedTour, selectedDate, setSelectedTour, updateTourData } = useContext(TourContext);
    const { userData } = useContext(UserContext); // Add this
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedMode, setSelectedMode] = useState(null);
    const [isLoading, setIsLoading] = useState(false); // Add this
    const [selectedFile, setSelectedFile] = useState(null); // Add this
    const [selectedVisibility, setSelectedVisibility] = useState([]); // Add this
    const [selectedPassengers, setSelectedPassengers] = useState([]);
    const [isPassengerDropdownOpen, setIsPassengerDropdownOpen] = useState(false);
    const storage = getStorage(); // Add this
    
  const [ticketData, setTicketData] = useState({
    passengerName: '',
    origin: '',
    destination: '',
    departureTime: '',
    arrivalTime: '',
    flightNumber: '',
    trainNumber: '',
    busNumber: '',
    pnrNumber: '',
    notes: '',
    airlines: '',
    travelMode: '',
  });

  const resetForm = () => {
    setTicketData({
      passengerName: '',
      origin: '',
      destination: '',
      departureTime: '',
      arrivalTime: '',
      flightNumber: '',
      trainNumber: '',
      busNumber: '',
      pnrNumber: '',
      notes: '',
      airlines: '',
      travelMode: '',
    });
    setSelectedMode(null);
    onClose(); // Add this line to notify parent component
  };

 



  useEffect(() => {
    if (editingTicket) {
      setSelectedMode(editingTicket.travelMode);
      setTicketData({
        passengerName: editingTicket.passengerName || '',
        origin: editingTicket.origin || '',
        destination: editingTicket.destination || '',
        departureTime: editingTicket.departureTime.seconds ? 
          new Date(editingTicket.departureTime.seconds * 1000).toISOString() : '',
        arrivalTime: editingTicket.arrivalTime.seconds ? 
          new Date(editingTicket.arrivalTime.seconds * 1000).toISOString() : '',
        flightNumber: editingTicket.flightNumber || '',
        trainNumber: editingTicket.trainNumber || '',
        busNumber: editingTicket.busNumber || '',
        pnrNumber: editingTicket.pnrNumber || '',
        notes: editingTicket.notes || '',
        airlines: editingTicket.airlines || '',
        travelMode: editingTicket.travelMode || '',
      });
      setSelectedPassengers(editingTicket.passengers || []);
      
      // Get the members with full details for the visibility selection
      const members = getTourMembers();
      if (editingTicket.visibility && editingTicket.visibility.length > 0) {
        const visibilityMembers = editingTicket.visibility
          .map(userId => members.find(member => member.userId === userId))
          .filter(Boolean); // Remove any undefined values
        setSelectedVisibility(visibilityMembers);
      } else {
        // If no visibility is set, default to all members
        setSelectedVisibility(members);
      }
    } else {
      // Reset state for new ticket
      const members = getTourMembers();
      if (userData?.membershipTier !== 'Elite') {
        setSelectedVisibility(members); // Make visible to all by default
      } else {
        // For Elite members, start with only the manager selected
        const manager = members.find(m => m.userType === 'Manager');
        setSelectedVisibility(manager ? [manager] : []);
      }
    }
  }, [editingTicket, userData?.membershipTier]);


  const handleModeSelect = (mode) => {
    setSelectedMode(mode);
    setTicketData(prev => ({
      ...prev,
      travelMode: mode
    }));
  };



const getTourMembers = () => {
    if (!selectedTour || !selectedDate) return [];
    
    let members = [];
    const tourDate = selectedTour.tourDates?.find(date => date.id === selectedDate);
    const actualDate = tourDate?.rescheduleDate?.isRescheduled 
      ? tourDate.rescheduleDate.newDate 
      : tourDate?.date;
  
    // Add manager
    if (selectedTour.createdBy) {
      members.push({
        name: selectedTour.createdBy.fullName,
        userId: selectedTour.createdBy.userId,
        profilePictureUrl: selectedTour.createdBy.profilePictureUrl,
        username: selectedTour.createdBy.userName,
        userType: 'Manager'
      });
    }
  
    // Filter artists - using same logic as Crew.js
    const filteredArtists = selectedTour.artistDetails?.filter(artist => 
      artist.assignedDates?.some(ad => {
        // Convert both dates to YYYY-MM-DD format for comparison
        const assignedDate = new Date(ad.date).toISOString().split('T')[0];
        const compareDate = new Date(actualDate).toISOString().split('T')[0];
        
        // Also check if this date matches with tourDateDocId
        return assignedDate === compareDate || ad.tourDateDocId === selectedDate;
      })
    ) || [];
  
    // Filter crew - using same logic as Crew.js
    const filteredCrew = selectedTour.crewDetails?.filter(crew => 
      crew.assignedDates?.some(ad => {
        // Convert both dates to YYYY-MM-DD format for comparison
        const assignedDate = new Date(ad.date).toISOString().split('T')[0];
        const compareDate = new Date(actualDate).toISOString().split('T')[0];
        
        // Also check if this date matches with tourDateDocId
        return assignedDate === compareDate || ad.tourDateDocId === selectedDate;
      })
    ) || [];
  
    // Add filtered artists and crew to members
    members = [
      ...members,
      ...filteredArtists.map(artist => ({
        name: artist.name,
        userId: artist.userId,
        profilePictureUrl: artist.profilePictureUrl,
        username: artist.userName,
        userType: 'Artist'
      })),
      ...filteredCrew.map(crew => ({
        name: crew.name,
        userId: crew.userId,
        profilePictureUrl: crew.profilePictureUrl,
        username: crew.userName,
        userType: 'Crew'
      }))
    ];
  
    console.log('Available members for date:', actualDate, members); // Debug log
    return members;
  };

  useEffect(() => {
    setSelectedPassengers([]);
  }, [selectedDate]);


  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  const formatDateForInput = (date) => {
    if (!date) return '';
    const d = new Date(date);
    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, '0');
    const day = String(d.getDate()).padStart(2, '0');
    const hours = String(d.getHours()).padStart(2, '0');
    const minutes = String(d.getMinutes()).padStart(2, '0');
    return `${year}-${month}-${day}T${hours}:${minutes}`;
  };

  const formatDateForDisplay = (dateString) => {
    if (!dateString) return '';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: false
    });
  };



  // Add airlines data
  const airlines = [
    { name: 'Aegean Airlines', userId: 'Aegean Airlines' },
    { name: 'Aer Lingus', userId: 'Aer Lingus' },
    { name: 'Aeroflot', userId: 'Aeroflot' },
    { name: 'Aerolineas Argentinas', userId: 'Aerolineas Argentinas' },
    { name: 'Aeromexico', userId: 'Aeromexico' },
    { name: 'Air Arabia', userId: 'Air Arabia' },
    { name: 'Air Astana', userId: 'Air Astana' },
    { name: 'Air Austral', userId: 'Air Austral' },
    { name: 'Air Baltic', userId: 'Air Baltic' },
    { name: 'Air Belgium', userId: 'Air Belgium' },
    { name: 'Air Canada', userId: 'Air Canada' },
    { name: 'Air Caraibes', userId: 'Air Caraibes' },
    { name: 'Air China', userId: 'Air China' },
    { name: 'Air Corsica', userId: 'Air Corsica' },
    { name: 'Air Dolomiti', userId: 'Air Dolomiti' },
    { name: 'Air Europa', userId: 'Air Europa' },
    { name: 'Air France', userId: 'Air France' },
    { name: 'Air India', userId: 'Air India' },
    { name: 'Air India Express', userId: 'Air India Express' },
    { name: 'Air Macau', userId: 'Air Macau' },
    { name: 'Air Malta', userId: 'Air Malta' },
    { name: 'Air Mauritius', userId: 'Air Mauritius' },
    { name: 'Air Namibia', userId: 'Air Namibia' },
    { name: 'Air New Zealand', userId: 'Air New Zealand' },
    { name: 'Air North', userId: 'Air North' },
    { name: 'Air Seoul', userId: 'Air Seoul' },
    { name: 'Air Serbia', userId: 'Air Serbia' },
    { name: 'Air Tahiti Nui', userId: 'Air Tahiti Nui' },
    { name: 'Air Transat', userId: 'Air Transat' },
    { name: 'Air Vanuatu', userId: 'Air Vanuatu' },
    { name: 'AirAsia', userId: 'AirAsia' },
    { name: 'AirAsia X', userId: 'AirAsia X' },
    { name: 'Aircalin', userId: 'Aircalin' },
    { name: 'Akasa Air', userId: 'Akasa Air' },
    { name: 'Alaska Airlines', userId: 'Alaska Airlines' },
    { name: 'Alitalia', userId: 'Alitalia' },
    { name: 'Allegiant', userId: 'Allegiant' },
    { name: 'American Airlines', userId: 'American Airlines' },
    { name: 'ANA', userId: 'ANA' },
    { name: 'Asiana', userId: 'Asiana' },
    { name: 'Austrian', userId: 'Austrian' },
    { name: 'Avianca', userId: 'Avianca' },
    { name: 'Azerbaijan Hava Yollary', userId: 'Azerbaijan Hava Yollary' },
    { name: 'Azores Airlines', userId: 'Azores Airlines' },
    { name: 'Azul', userId: 'Azul' },
    { name: 'Bamboo Airways', userId: 'Bamboo Airways' },
    { name: 'Bangkok Airways', userId: 'Bangkok Airways' },
    { name: 'British Airways', userId: 'British Airways' },
    { name: 'Brussels Airlines', userId: 'Brussels Airlines' },
    { name: 'Caribbean Airlines', userId: 'Caribbean Airlines' },
    { name: 'Cathay Dragon', userId: 'Cathay Dragon' },
    { name: 'Cathay Pacific', userId: 'Cathay Pacific' },
    { name: 'Cayman Airways', userId: 'Cayman Airways' },
    { name: 'CEBU Pacific Air', userId: 'CEBU Pacific Air' },
    { name: 'China Airlines', userId: 'China Airlines' },
    { name: 'China Eastern', userId: 'China Eastern' },
    { name: 'China Southern', userId: 'China Southern' },
    { name: 'Condor', userId: 'Condor' },
    { name: 'Copa Airlines', userId: 'Copa Airlines' },
    { name: 'Croatia Airlines', userId: 'Croatia Airlines' },
    { name: 'Czech Airlines', userId: 'Czech Airlines' },
    { name: 'Delta', userId: 'Delta' },
    { name: 'easyJet', userId: 'easyJet' },
    { name: 'Edelweiss Air', userId: 'Edelweiss Air' },
    { name: 'Egyptair', userId: 'Egyptair' },
    { name: 'EL AL', userId: 'EL AL' },
    { name: 'Emirates', userId: 'Emirates' },
    { name: 'Ethiopian Airlines', userId: 'Ethiopian Airlines' },
    { name: 'Etihad', userId: 'Etihad' },
    { name: 'Eurowings', userId: 'Eurowings' },
    { name: 'EVA Air', userId: 'EVA Air' },
    { name: 'Fiji Airways', userId: 'Fiji Airways' },
    { name: 'Finnair', userId: 'Finnair' },
    { name: 'flydubai', userId: 'flydubai' },
    { name: 'FlyOne', userId: 'FlyOne' },
    { name: 'French bee', userId: 'French bee' },
    { name: 'Frontier', userId: 'Frontier' },
    { name: 'Garuda Indonesia', userId: 'Garuda Indonesia' },
    { name: 'Gol', userId: 'Gol' },
    { name: 'Gulf Air', userId: 'Gulf Air' },
    { name: 'Hainan Airlines', userId: 'Hainan Airlines' },
    { name: 'Hawaiian Airlines', userId: 'Hawaiian Airlines' },
    { name: 'Helvetic Airways', userId: 'Helvetic Airways' },
    { name: 'HK Express', userId: 'HK Express' },
    { name: 'Hong Kong Airlines', userId: 'Hong Kong Airlines' },
    { name: 'Iberia', userId: 'Iberia' },
    { name: 'Icelandair', userId: 'Icelandair' },
    { name: 'IndiGo', userId: 'IndiGo' },
    { name: 'InterJet', userId: 'InterJet' },
    { name: 'ITA Airways', userId: 'ITA Airways' },
    { name: 'Japan Airlines', userId: 'Japan Airlines' },
    { name: 'Jeju Air', userId: 'Jeju Air' },
    { name: 'Jet2', userId: 'Jet2' },
    { name: 'JetBlue', userId: 'JetBlue' },
    { name: 'Jetstar', userId: 'Jetstar' },
    { name: 'Jin Air', userId: 'Jin Air' },
    { name: 'Kenya Airways', userId: 'Kenya Airways' },
    { name: 'KLM', userId: 'KLM' },
    { name: 'Korean Air', userId: 'Korean Air' },
    { name: 'Kulula', userId: 'Kulula' },
    { name: 'La Compagnie', userId: 'La Compagnie' },
    { name: 'LATAM', userId: 'LATAM' },
    { name: 'Lion Airlines', userId: 'Lion Airlines' },
    { name: 'LOT Polish Airlines', userId: 'LOT Polish Airlines' },
    { name: 'Lufthansa', userId: 'Lufthansa' },
    { name: 'Luxair', userId: 'Luxair' },
    { name: 'Malaysia Airlines', userId: 'Malaysia Airlines' },
    { name: 'Mango', userId: 'Mango' },
    { name: 'Middle East Airlines', userId: 'Middle East Airlines' },
    { name: 'Nok Air', userId: 'Nok Air' },
    { name: 'Nordwind Airlines', userId: 'Nordwind Airlines' },
    { name: 'Norwegian Air International', userId: 'Norwegian Air International' },
    { name: 'Norwegian Air Shuttle', userId: 'Norwegian Air Shuttle' },
    { name: 'Norwegian Air Sweden', userId: 'Norwegian Air Sweden' },
    { name: 'Norwegian Air UK', userId: 'Norwegian Air UK' },
    { name: 'Oman Air', userId: 'Oman Air' },
    { name: 'Pakistan International Airlines', userId: 'Pakistan International Airlines' },
    { name: 'Peach', userId: 'Peach' },
    { name: 'Pegasus Airlines', userId: 'Pegasus Airlines' },
    { name: 'Philippine Airlines', userId: 'Philippine Airlines' },
    { name: 'Porter', userId: 'Porter' },
    { name: 'Qantas', userId: 'Qantas' },
    { name: 'Qatar Airways', userId: 'Qatar Airways' },
    { name: 'Regional Express', userId: 'Regional Express' },
    { name: 'Rossiya - Russian Airlines', userId: 'Rossiya - Russian Airlines' },
    { name: 'Royal Air Maroc', userId: 'Royal Air Maroc' },
    { name: 'Royal Brunei', userId: 'Royal Brunei' },
    { name: 'Royal Jordanian', userId: 'Royal Jordanian' },
    { name: 'RwandAir', userId: 'RwandAir' },
    { name: 'Ryanair', userId: 'Ryanair' },
    { name: 'S7 Airlines', userId: 'S7 Airlines' },
    { name: 'SAS', userId: 'SAS' },
    { name: 'Saudia', userId: 'Saudia' },
    { name: 'Scoot Airlines', userId: 'Scoot Airlines' },
    { name: 'Shanghai Airlines', userId: 'Shanghai Airlines' },
    { name: 'Silkair', userId: 'Silkair' },
    { name: 'Silver', userId: 'Silver' },
    { name: 'Singapore Airlines', userId: 'Singapore Airlines' },
    { name: 'Skylanes', userId: 'Skylanes' },
    { name: 'South African Airways', userId: 'South African Airways' },
    { name: 'Southwest', userId: 'Southwest' },
    { name: 'SpiceJet', userId: 'SpiceJet' },
    { name: 'Spirit', userId: 'Spirit' },
    { name: 'Spring Airlines', userId: 'Spring Airlines' },
    { name: 'Spring Japan', userId: 'Spring Japan' },
    { name: 'SriLankan Airlines', userId: 'SriLankan Airlines' },
    { name: 'Sun Country', userId: 'Sun Country' },
    { name: 'Sunclass Airlines', userId: 'Sunclass Airlines' },
    { name: 'Sunwing', userId: 'Sunwing' },
    { name: 'SWISS', userId: 'SWISS' },
    { name: 'Swoop', userId: 'Swoop' },
    { name: 'TAAG', userId: 'TAAG' },
    { name: 'TACA', userId: 'TACA' },
    { name: 'TAP Portugal', userId: 'TAP Portugal' },
    { name: 'THAI', userId: 'THAI' },
    { name: 'tigerair Australia', userId: 'tigerair Australia' },
    { name: 'Transavia Airlines', userId: 'Transavia Airlines' },
    { name: 'TUI UK', userId: 'TUI UK' },
    { name: 'TUIfly', userId: 'TUIfly' },
    { name: 'Tunis Air', userId: 'Tunis Air' },
    { name: 'Turkish Airlines', userId: 'Turkish Airlines' },
    { name: 'Ukraine International', userId: 'Ukraine International' },
    { name: 'United', userId: 'United' },
    { name: 'Ural Airlines', userId: 'Ural Airlines' },
    { name: 'UTair Aviation', userId: 'UTair Aviation' },
    { name: 'Uzbekistan Airways', userId: 'Uzbekistan Airways' },
    { name: 'Vietnam Airlines', userId: 'Vietnam Airlines' },
    { name: 'Virgin Atlantic', userId: 'Virgin Atlantic' },
    { name: 'Virgin Australia', userId: 'Virgin Australia' },
    { name: 'Vistara', userId: 'Vistara' },
    { name: 'Viva Aerobus', userId: 'Viva Aerobus' },
    { name: 'Volaris', userId: 'Volaris' },
    { name: 'Volotea', userId: 'Volotea' },
    { name: 'Vueling Airlines', userId: 'Vueling Airlines' },
    { name: 'WestJet', userId: 'WestJet' },
    { name: 'Wizzair', userId: 'Wizzair' },
    { name: 'Xiamen Airlines', userId: 'Xiamen Airlines' }
  ];
  

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (selectedPassengers.length === 0) {
      alert('Please select at least one passenger');
      return;
    }
  
    try {
      setIsLoading(true);
      
      let ticketPdfUrl = '';
      if (selectedFile) {
        const fileName = `${Date.now()}_${selectedFile.name}`;
        const storageRef = ref(storage, `tickets/${selectedTour.id}/${fileName}`);
        await uploadBytes(storageRef, selectedFile);
        ticketPdfUrl = await getDownloadURL(storageRef);
      }
  
      const db = getFirestore();
      const tourRef = doc(db, 'Tours', selectedTour.id);
      const tourDoc = await getDoc(tourRef);
      const tourData = tourDoc.data();
  
      const tourDate = selectedTour.tourDates.find(date => date.id === selectedDate);
      const actualDate = tourDate?.date;
      
      const updatedTicket = {
        ...ticketData,
        id: editingTicket ? editingTicket.id : Date.now().toString(),
        departureTime: Timestamp.fromDate(new Date(ticketData.departureTime)),
        arrivalTime: Timestamp.fromDate(new Date(ticketData.arrivalTime)),
        ticketPdfUrl: ticketPdfUrl || editingTicket?.ticketPdfUrl || '',
        originalFileName: selectedFile?.name || editingTicket?.originalFileName || '',
        passengers: selectedPassengers.map(p => ({
          userId: p.userId,
          name: p.name,
          userType: p.userType,
          profilePictureUrl: p.profilePictureUrl
        })),
        visibility: selectedVisibility.map(member => member.userId)
      };
  
      let ticketDetails = tourData.ticketDetails || [];
      const dateIndex = ticketDetails.findIndex(item => item.date === actualDate);
  
      if (dateIndex !== -1) {
        if (editingTicket) {
          const ticketIndex = ticketDetails[dateIndex].tickets.findIndex(
            t => t.id === editingTicket.id
          );
          if (ticketIndex !== -1) {
            ticketDetails[dateIndex].tickets[ticketIndex] = updatedTicket;
          }
        } else {
          ticketDetails[dateIndex].tickets.push(updatedTicket);
        }
      } else {
        ticketDetails.push({
          date: actualDate,
          tickets: [updatedTicket]
        });
      }
  
      await updateDoc(tourRef, { ticketDetails });
      await updateTourData(selectedTour.id);
  
      resetForm();
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error updating ticket:', error);
      alert('Failed to update ticket. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const renderForm = () => {
    if (!selectedMode) {
      return (
        <div className={styles['travel-mode-options']}>
          <div 
            className={styles['travel-mode-card']}
            onClick={() => handleModeSelect('Flight')}
          >
            <FontAwesomeIcon icon={faPlane} className={styles['travel-mode-icon']} />
            <span className={styles['travel-mode-text']}>Flight</span>
          </div>
          <div 
            className={styles['travel-mode-card']}
            onClick={() => handleModeSelect('Train')}
          >
            <FontAwesomeIcon icon={faTrain} className={styles['travel-mode-icon']} />
            <span className={styles['travel-mode-text']}>Train</span>
          </div>
          <div 
            className={styles['travel-mode-card']}
            onClick={() => handleModeSelect('Bus')}
          >
            <FontAwesomeIcon icon={faBus} className={styles['travel-mode-icon']} />
            <span className={styles['travel-mode-text']}>Bus</span>
          </div>
        </div>
      );
    }

   
  const members = getTourMembers();

  return (
    <form onSubmit={handleSubmit} className={styles['ticket-form']}>
      <div className={styles['form-section']}>
      <div className={styles['form-group']}>
  <label className={styles['required-field']}>Passengers</label>
  <div className={styles['passenger-dropdown']}>
    <button
      type="button"
      className={styles['dropdown-button']}
      onClick={() => setIsPassengerDropdownOpen(!isPassengerDropdownOpen)}
    >
      <span>{selectedPassengers.length ? `${selectedPassengers.length} passenger(s) selected` : 'Select passengers'}</span>
      <FontAwesomeIcon icon={isPassengerDropdownOpen ? faChevronUp : faChevronDown} />
    </button>
    
    {isPassengerDropdownOpen && (
      <div className={styles['dropdown-content']}>
        {getTourMembers().map(member => (
          <div
            key={member.userId}
            className={`${styles['passenger-option']} ${
              selectedPassengers.some(p => p.userId === member.userId) ? styles['selected'] : ''
            }`}
            onClick={() => {
              const isSelected = selectedPassengers.some(p => p.userId === member.userId);
              if (isSelected) {
                setSelectedPassengers(prev => prev.filter(p => p.userId !== member.userId));
              } else {
                setSelectedPassengers(prev => [...prev, member]);
              }
            }}
          >
            <img
              src={member.profilePictureUrl || defaultProfilePhoto}
              alt={member.name}
              className={styles['passenger-avatar']}
            />
            <div className={styles['passenger-info']}>
              <span className={styles['passenger-name']}>{member.name}</span>
              <span className={styles['passenger-username']}>@{member.username}</span>
              <span className={styles['passenger-usertype']}>{member.userType}</span>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>

  {selectedPassengers.length > 0 && (
    <div className={styles['selected-passengers']}>
      {selectedPassengers.map(passenger => (
        <div key={passenger.userId} className={styles['selected-passenger-tag']}>
          <img
            src={passenger.profilePictureUrl || defaultProfilePhoto}
            alt={passenger.name}
          />
          <span>{passenger.name}</span>
          <span 
            className={styles['remove-passenger']}
            onClick={() => setSelectedPassengers(prev => 
              prev.filter(p => p.userId !== passenger.userId)
            )}
          >
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </div>
      ))}
    </div>
  )}
</div>
  
  {selectedMode === 'Flight' && (
  <div className={styles['form-group']}>
    <label className={styles['required-field']}>Airlines</label>
    <select
      required
      value={ticketData.airlines}
      onChange={(e) => setTicketData(prev => ({
        ...prev,
        airlines: e.target.value
      }))}
      className={styles['form-input']}
    >
      <option value="">Select Airline</option>
      {airlines.map(airline => (
        <option key={airline.userId} value={airline.name}>
          {airline.name}
        </option>
      ))}
    </select>
  </div>
)}
  
        <div className={styles['form-group']}>
          <label>Upload Ticket (PDF)</label>
          <div className={styles['file-upload']}>
            <label className={styles['file-upload-label']}>
              <input
                type="file"
                accept="application/pdf"
                onChange={(e) => {
                  setSelectedFile(e.target.files[0]);
                }}
              />
              <FontAwesomeIcon icon={faUpload} />
              {selectedFile ? 'Change file' : 'Choose PDF file'}
            </label>
            {selectedFile && (
              <div className={styles['file-name']}>
                Selected: {selectedFile.name}
              </div>
            )}
          </div>
        </div>
  
        {userData?.membershipTier === 'Elite' && (
          <div className={styles['form-group']}>
          <label className={styles['required-field']}>Ticket Visibility</label>
            <div className={styles['visibility-selector']}>
              {members.map(member => {
                const isManager = member.userType === 'Manager';
                return (
                  <div 
                    key={member.userId} 
                    className={`${styles['visibility-option']} ${isManager ? styles['manager'] : ''}`}
                  >
                    <input
                      type="checkbox"
                      id={member.userId}
                      checked={isManager || selectedVisibility.some(v => v.userId === member.userId)}
                      onChange={(e) => {
                        if (!isManager) {
                          if (e.target.checked) {
                            setSelectedVisibility([...selectedVisibility, member]);
                          } else {
                            setSelectedVisibility(selectedVisibility.filter(v => v.userId !== member.userId));
                          }
                        }
                      }}
                      disabled={isManager}
                    />
                    <label htmlFor={member.userId}>
                      {member.name} ({member.userType})
                    </label>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
  
      <div className={styles['form-section']}>
    <div className={styles['form-group']}>
  <label className={styles['required-field']}>From</label>
  <input
    type="text"
    required
    value={ticketData.origin}
    onChange={(e) => setTicketData(prev => ({
      ...prev,
      origin: e.target.value
    }))}
    className={styles['form-input']}
    placeholder="Enter departure city"
  />
</div>
        <div className={styles['form-group']}>
        <label className={styles['required-field']}>To</label>
          <input
            type="text"
            required
            value={ticketData.destination}
            onChange={(e) => setTicketData(prev => ({
              ...prev,
              destination: e.target.value
            }))}
            className={styles['form-input']}
            placeholder="Enter arrival city"
          />
        </div>
      </div>
  
      <div className={styles['form-section']}>
      <div className={styles['form-group']}>
      <label className={styles['required-field']}>Departure Time</label>
            <input
              type="datetime-local"
              required
              value={formatDateForInput(ticketData.departureTime)}
              onChange={(e) => {
                setTicketData(prev => ({
                  ...prev,
                  departureTime: e.target.value
                }));
              }}
              className={styles['form-input']}
            />
           
          </div>

          <div className={styles['form-group']}>
          <label className={styles['required-field']}>Arrival Time</label>
            <input
              type="datetime-local"
              required
              value={formatDateForInput(ticketData.arrivalTime)}
              onChange={(e) => {
                setTicketData(prev => ({
                  ...prev,
                  arrivalTime: e.target.value
                }));
              }}
              className={styles['form-input']}
            />
            
          </div>
        </div>

      
  
      <div className={styles['form-row']}>
          {selectedMode === 'Flight' && (
            <div className={styles['form-group']}>
            <label className={styles['required-field']}>Flight Number</label>
              <input
                type="text"
                value={ticketData.flightNumber}
                onChange={(e) => setTicketData(prev => ({
                  ...prev,
                  flightNumber: e.target.value
                }))}
                className={styles['form-input']}
              />
            </div>
          )}
          {selectedMode === 'Train' && (
            <div className={styles['form-group']}>
             <label className={styles['required-field']}>Train Number</label>
              <input
                type="text"
                value={ticketData.trainNumber}
                onChange={(e) => setTicketData(prev => ({
                  ...prev,
                  trainNumber: e.target.value
                }))}
                className={styles['form-input']}
              />
            </div>
          )}
          {selectedMode === 'Bus' && (
            <div className={styles['form-group']}>
            <label className={styles['required-field']}>Bus Number</label>
              <input
                type="text"
                value={ticketData.busNumber}
                onChange={(e) => setTicketData(prev => ({
                  ...prev,
                  busNumber: e.target.value
                }))}
                className={styles['form-input']}
              />
            </div>
          )}
          <div className={styles['form-group']}>
            <label>PNR Number</label>
            <input
              type="text"
              value={ticketData.pnrNumber}
              onChange={(e) => setTicketData(prev => ({
                ...prev,
                pnrNumber: e.target.value
              }))}
              className={styles['form-input']}
            />
          </div>
        </div>

        <div className={styles['form-row']}>
          <div className={`${styles['form-group']} ${styles['full-width']}`}>
            <label>Notes</label>
            <input
              type="text"
              value={ticketData.notes}
              onChange={(e) => setTicketData(prev => ({
                ...prev,
                notes: e.target.value
              }))}
              className={styles['form-input']}
            />
          </div>
        </div>
  
      <div className={styles['modal-actions']}>
        <button
          type="button"
          className={`${styles['modal-button']} ${styles['secondary']}`}
          onClick={() => {
            if (selectedMode) {
              setSelectedMode(null);
            } else {
              setIsModalOpen(false);
              resetForm();
            }
          }}
        >
          {selectedMode ? 'Back' : 'Cancel'}
        </button>
        {selectedMode && (
            <button type="submit" className={`${styles['modal-button']} ${styles['primary']}`}>
  {editingTicket ? 'Update Ticket' : 'Add Ticket'}
</button>
        )}
      </div>
    </form>
  );
  };

  return (
    <>
     

     {isModalOpen && (
  <div className={styles['modal-overlay']}>
    <div className={styles['modal-content']}>
      {isLoading && (
        <div className={styles['loading-overlay']}>
          <div className={styles['loading-spinner']} />
        </div>
      )}
      <div className={styles['modal-header']}>
  <h2>{selectedMode ? `${editingTicket ? 'Update' : 'Add'} ${selectedMode} Ticket` : 'Select Travel Mode'}</h2>
  <button 
    className={styles['close-button']}
    onClick={() => {
      setIsModalOpen(false);
      resetForm();
    }}
  >
    <FontAwesomeIcon icon={faTimes} />
  </button>
</div>
            {renderForm()}
          </div>
        </div>
      )}
    </>
  );
};

export default TicketModal;