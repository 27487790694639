// ShareModal.js
import React, { useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFacebook,
  faTwitter,
  faWhatsapp,
  faLinkedin,
  faTelegram,
  faInstagram
} from '@fortawesome/free-brands-svg-icons';
import { 
  faLink,
  faShare,
  faEnvelope,
  faCircleXmark,
  faCheck,
  faCopy,
  faVideo,
  faMusic,
  faImage,
  faUser
} from '@fortawesome/free-solid-svg-icons';
import './ShareModal.css';

const ShareModal = ({ isOpen, onClose, postData }) => {
  const [copied, setCopied] = useState(false);
  const urlInputRef = useRef(null);
  
  // Check if we're sharing a profile or a regular post
  const isProfile = postData.type === 'profile';
  
  // Generate the appropriate URL based on content type
  const shareUrl = isProfile 
    ? window.location.href // Current URL for profiles
    : `${window.location.origin}/p/${postData.postId}`; // Post-specific URL
  
  // Prepare metadata for sharing with conditional content
  const shareTitle = isProfile 
    ? `Check out this profile on On Tour` 
    : `Check out this ${postData.type} on On Tour.`;
  
  const shareDescription = postData.caption || (isProfile ? 'Check out this profile' : 'Check out this post');
  const hashTags = postData.tags && postData.tags.length > 0 
    ? postData.tags.map(tag => `#${tag}`).join(' ') 
    : '';
  
  const getShareText = (platform) => {
    switch(platform) {
      case 'twitter':
        // Twitter has a 280 char limit
        const twitterText = `${shareDescription}\n\n${hashTags}`;
        return twitterText.length > 250 ? twitterText.substring(0, 247) + '...' : twitterText;
      case 'whatsapp':
        return `${shareTitle}\n\n${shareDescription}\n\n${hashTags}\n\n${shareUrl}`;
      case 'telegram':
        return `${shareTitle}\n\n${shareDescription}\n\n${hashTags}`;
      case 'email':
        return `${shareDescription}\n\n${hashTags}\n\nView ${isProfile ? 'profile' : 'post'}: ${shareUrl}`;
      default:
        return shareDescription;
    }
  };

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy:', err);
    }
  };

  const handleNativeShare = async () => {
    try {
      if (navigator.share) {
        await navigator.share({
          title: shareTitle,
          text: getShareText('default'),
          url: shareUrl
        });
      }
    } catch (err) {
      console.error('Error sharing:', err);
    }
  };

  const socialShareLinks = [
    {
      name: 'Facebook',
      icon: faFacebook,
      color: '#1877f2',
      url: `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(shareUrl)}&quote=${encodeURIComponent(getShareText('facebook'))}`
    },
    {
      name: 'Twitter',
      icon: faTwitter,
      color: '#1da1f2',
      url: `https://twitter.com/intent/tweet?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(getShareText('twitter'))}`
    },
    {
      name: 'WhatsApp',
      icon: faWhatsapp,
      color: '#25d366',
      url: `https://wa.me/?text=${encodeURIComponent(getShareText('whatsapp'))}`
    },
    {
      name: 'LinkedIn',
      icon: faLinkedin,
      color: '#0a66c2',
      url: `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(shareUrl)}`
    },
    {
      name: 'Telegram',
      icon: faTelegram,
      color: '#0088cc',
      url: `https://t.me/share/url?url=${encodeURIComponent(shareUrl)}&text=${encodeURIComponent(getShareText('telegram'))}`
    },
    {
      name: 'Email',
      icon: faEnvelope,
      color: '#333333',
      url: `mailto:?subject=${encodeURIComponent(shareTitle)}&body=${encodeURIComponent(getShareText('email'))}`
    }
  ];

  const handleSocialShare = (url) => {
    window.open(url, '_blank', 'width=600,height=400');
  };

  const renderPreview = () => {
    if (isProfile) {
      return (
        <div className="share-preview">
          <div className="preview-header">
            <FontAwesomeIcon icon={faUser} className="preview-type-icon" />
            <span>Profile</span>
          </div>
          <div className="preview-content">
            <p className="preview-caption">{postData.caption}</p>
          </div>
        </div>
      );
    }
    
    return (
      <div className="share-preview">
        <div className="preview-header">
          <FontAwesomeIcon 
            icon={
              postData.type === 'video' ? faVideo : 
              postData.type === 'audio' ? faMusic : 
              faImage
            } 
            className="preview-type-icon"
          />
          <span>{postData.type}</span>
        </div>
        <div className="preview-content">
          <p className="preview-caption">{postData.caption}</p>
          {postData.tags?.length > 0 && (
            <div className="preview-tags">
              {postData.tags.map((tag, index) => (
                <span key={index} className="preview-tag">#{tag}</span>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  if (!isOpen) return null;

  return (
    <div className="share-modal-wrapper">
      <div className="share-modal-overlay" onClick={onClose}>
        <div className="share-modal-content" onClick={e => e.stopPropagation()}>
          <div className="modal-header">
            <h2 className="share-modal-title">
              {isProfile ? 'Share Profile' : 'Share Post'}
            </h2>
            <button className="share-modal-close" onClick={onClose}>
              <FontAwesomeIcon icon={faCircleXmark} size="lg" />
            </button>
          </div>

          {renderPreview()}

          <div className="share-url-container">
            <div className="share-url-input-container">
              <div className="url-wrapper">
                <input
                  ref={urlInputRef}
                  type="text"
                  value={shareUrl}
                  readOnly
                  className="share-url-input"
                />
              </div>
              <button 
                className={`copy-button ${copied ? 'copied' : ''}`}
                onClick={handleCopyClick}
              >
                <FontAwesomeIcon icon={copied ? faCheck : faCopy} />
                <span>{copied ? 'Copied!' : 'Copy'}</span>
              </button>
            </div>
          </div>

          {navigator.share && (
            <button className="native-share-button" onClick={handleNativeShare}>
              <FontAwesomeIcon icon={faShare} />
              Share using device
            </button>
          )}

          <div className="share-divider">
            <span>Share via</span>
          </div>

          <div className="social-share-grid">
            {socialShareLinks.map((platform) => (
              <button
                key={platform.name}
                className="social-share-button"
                onClick={() => handleSocialShare(platform.url)}
                style={{ '--platform-color': platform.color }}
              >
                <FontAwesomeIcon icon={platform.icon} />
                <span>{platform.name}</span>
              </button>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;