import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faSearch } from '@fortawesome/free-solid-svg-icons';
import { liteClient } from 'algoliasearch/lite';
import styles from './SearchBandsModal.module.css';
import defaultBandPic from '../../assets/Images/defaultUserProfilePicture.png';
import verifiedBadge from '../../assets/Images/profileverifiedtick.png';

// Initialize Algolia client
const client = liteClient('6DJNAZSX4P', '342932cbe26f7751ec87fa2b1ff32e4c');

const SearchBandsModal = ({ isOpen, onClose, onSelect }) => {
  const [activeTab, setActiveTab] = useState('Artists');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasSearched, setHasSearched] = useState(false);

  const handleSearch = async () => {
    if (!searchTerm.trim()) return;

    setIsLoading(true);
    try {
      let searchIndex;
      let filters = '';

      if (activeTab === 'Artists') {
        searchIndex = 'users_index';
        filters = 'userType:Artist';
      } else {
        searchIndex = 'bands_index';
      }

      const { results } = await client.search([{
        indexName: searchIndex,
        query: searchTerm,
        filters,
        hitsPerPage: 10
      }]);

      const mappedResults = results[0].hits.map(hit => {
        if (activeTab === 'Artists') {
          return {
            id: hit.objectID,
            name: hit.fullName,
            username: hit.userName,
            subType: hit.userSubType,
            photoUrl: hit.profilePictureUrl,
            verified: hit.idVerificationStatus === 'Verified',
            location: [hit.city, hit.state, hit.country].filter(Boolean).join(', ')
          };
        } else {
          return {
            id: hit.objectID,
            name: hit.bandFullName,
            username: hit.bandUserName,
            genre: hit.bandGenres?.join(', '),
            photoUrl: hit.bandProfilePictureUrl,
            location: [hit.city, hit.state, hit.country].filter(Boolean).join(', ')
          };
        }
      });

      setSearchResults(mappedResults);
      setHasSearched(true);
    } catch (error) {
      console.error('Search error:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <div className={styles.modalHeader}>
          <h2>Search {activeTab}</h2>
          <button className={styles.closeButton} onClick={onClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        <div className={styles.tabs}>
          <button 
            className={`${styles.tab} ${activeTab === 'Artists' ? styles.active : ''}`}
            onClick={() => setActiveTab('Artists')}
          >
            Artists
          </button>
          <button 
            className={`${styles.tab} ${activeTab === 'Bands' ? styles.active : ''}`}
            onClick={() => setActiveTab('Bands')}
          >
            Bands
          </button>
        </div>

        <div className={styles.searchBar}>
          <input
            type="text"
            placeholder={`Search ${activeTab.toLowerCase()}...`}
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSearch()}
          />
          <button 
            className={styles.searchButton}
            onClick={handleSearch}
            disabled={!searchTerm.trim() || isLoading}
          >
            <FontAwesomeIcon icon={faSearch} />
            Search
          </button>
        </div>

        <div className={styles.searchResults}>
          {isLoading ? (
            <div className={styles.loading}>
              <div className={styles.spinner}></div>
              <p>Searching...</p>
            </div>
          ) : hasSearched ? (
            searchResults.length > 0 ? (
              <div className={styles.resultsList}>
                {searchResults.map(result => (
                  <div 
                    key={result.id}
                    className={styles.resultCard}
                    onClick={() => onSelect(result)}
                  >
                    <img 
                      src={result.photoUrl || defaultBandPic}
                      alt={result.name}
                      className={styles.resultPhoto}
                    />
                    <div className={styles.resultInfo}>
                      <div className={styles.nameContainer}>
                        <h3>{result.name}</h3>
                        {result.verified && (
                          <img 
                            src={verifiedBadge} 
                            alt="Verified" 
                            className={styles.verifiedBadge} 
                          />
                        )}
                      </div>
                      <p className={styles.username}>@{result.username}</p>
                      {activeTab === 'Artists' ? (
                        <p className={styles.subtype}>{result.subType}</p>
                      ) : (
                        <p className={styles.genre}>{result.genre}</p>
                      )}
                      <p className={styles.location}>{result.location}</p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div className={styles.noResults}>
                No {activeTab.toLowerCase()} found for "{searchTerm}"
              </div>
            )
          ) : (
            <div className={styles.searchPrompt}>
              Search for {activeTab.toLowerCase()} by name or username
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchBandsModal;