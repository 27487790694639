// Attachments.js
import React, { useState, useContext, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faFile,
  faPlus,
  faChevronLeft,
  faDownload,
  faTrash,
  faFilePdf,
  faFileImage,
  faFileAlt,
  faInfoCircle,
  faExclamationTriangle,
  faFileWord,
  faFileExcel,
  faEdit,
  faGlobe,
  faUsers,
  faUserCircle,
  faLock,
  faCrown
} from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useParams } from 'react-router-dom';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { doc, updateDoc } from 'firebase/firestore';
import { getFirestoreInstance } from '../../utils/firebaseHelper';
import TourContext from '../../context/TourContext';
import UserContext from '../../context/UserContext';
import FileViewerModal from '../common/FileViewerModal';
import AttachmentDetails from './AttachmentDetails';
import TopBar from '../TopBar';
import LeftMenu from '../LeftMenu';
import RightMenu from '../RightMenu';
import styles from './Attachments.module.css';

const AlertModal = ({ config, onClose }) => {
  return (
    <div className={styles.alertModalOverlay}>
      <div className={styles.alertModal}>
        <div className={styles.alertHeader}>
          <FontAwesomeIcon 
            icon={faExclamationTriangle} 
            className={`${styles.alertIcon} ${styles[config.type]}`}
          />
          <h3 className={styles.alertTitle}>{config.title}</h3>
        </div>
        <p className={styles.alertMessage}>{config.message}</p>
        <div className={styles.alertActions}>
          {config.showCancel && (
            <button 
              className={`${styles.alertButton} ${styles.cancel}`}
              onClick={onClose}
            >
              Cancel
            </button>
          )}
          <button 
            className={`${styles.alertButton} ${styles[config.type]}`}
            onClick={() => {
              if (config.onConfirm) config.onConfirm();
              onClose();
            }}
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

const VisibilityEditModal = ({ attachment, onClose, onSave }) => {
  const { userData } = useContext(UserContext);
  const { selectedTour } = useContext(TourContext);
  const [visibilityType, setVisibilityType] = useState(attachment.visibilityType || 'all');
  const [customVisibility, setCustomVisibility] = useState(attachment.visibility || []);
  const [showCustomSelection, setShowCustomSelection] = useState(visibilityType === 'custom');

  const getTourMembers = () => {
    if (!selectedTour) return [];
    
    const members = [];
    
    // Add manager
    if (selectedTour.createdBy) {
      members.push({
        userId: selectedTour.createdBy.userId,
        fullName: selectedTour.createdBy.fullName,
        userType: 'Manager'
      });
    }

    // Add artists
    selectedTour.artistDetails?.forEach(artist => {
      members.push({
        userId: artist.userId,
        fullName: artist.fullName,
        userType: 'Artist'
      });
    });

    // Add crew
    selectedTour.crewDetails?.forEach(crew => {
      members.push({
        userId: crew.userId,
        fullName: crew.fullName,
        userType: 'Crew'
      });
    });

    return members;
  };

  const handleSave = () => {
    onSave({
      ...attachment,
      visibilityType,
      visibility: visibilityType === 'custom' ? customVisibility : getMembersByType(visibilityType)
    });
  };

  const getMembersByType = (type) => {
    const members = getTourMembers();
    switch (type) {
      case 'artist':
        return members.filter(m => m.userType === 'Artist').map(m => m.userId);
      case 'crew':
        return members.filter(m => m.userType === 'Crew').map(m => m.userId);
      case 'me':
        return [userData.userId];
      case 'custom':
        return customVisibility;
      default:
        return []; // empty array means visible to all
    }
  };

  return (
    <div className={styles.modalOverlay}>
      <div className={styles.modalContent}>
        <h2 className={styles.modalTitle}>Edit Visibility</h2>
        
        <div className={styles.visibilityControl}>
          <div className={styles.visibilityOptions}>
            <label className={styles.visibilityOption}>
              <input
                type="radio"
                name="visibility"
                value="all"
                checked={visibilityType === 'all'}
                onChange={(e) => {
                  setVisibilityType(e.target.value);
                  setShowCustomSelection(false);
                }}
              />
              <span>All Members</span>
            </label>
            <label className={styles.visibilityOption}>
              <input
                type="radio"
                name="visibility"
                value="artist"
                checked={visibilityType === 'artist'}
                onChange={(e) => {
                  setVisibilityType(e.target.value);
                  setShowCustomSelection(false);
                }}
              />
              <span>Artists Only</span>
            </label>
            <label className={styles.visibilityOption}>
              <input
                type="radio"
                name="visibility"
                value="crew"
                checked={visibilityType === 'crew'}
                onChange={(e) => {
                  setVisibilityType(e.target.value);
                  setShowCustomSelection(false);
                }}
              />
              <span>Crew Only</span>
            </label>
            <label className={styles.visibilityOption}>
              <input
                type="radio"
                name="visibility"
                value="me"
                checked={visibilityType === 'me'}
                onChange={(e) => {
                  setVisibilityType(e.target.value);
                  setShowCustomSelection(false);
                }}
              />
              <span>Only Me</span>
            </label>
            <label className={styles.visibilityOption}>
              <input
                type="radio"
                name="visibility"
                value="custom"
                checked={visibilityType === 'custom'}
                onChange={(e) => {
                  setVisibilityType(e.target.value);
                  setShowCustomSelection(true);
                }}
              />
              <span>Custom</span>
            </label>
          </div>

          {showCustomSelection && (
            <div className={styles.customVisibilitySelector}>
              <p className={styles.customVisibilityTitle}>Select specific members:</p>
              {getTourMembers().map(member => {
                const isManager = member.userType === 'Manager';
                return (
                  <div 
                    key={member.userId} 
                    className={`${styles.customVisibilityOption} ${isManager ? styles.manager : ''}`}
                  >
                    <input
                      type="checkbox"
                      id={`custom-${member.userId}`}
                      checked={isManager || customVisibility.includes(member.userId)}
                      onChange={(e) => {
                        if (!isManager) {
                          if (e.target.checked) {
                            setCustomVisibility([...customVisibility, member.userId]);
                          } else {
                            setCustomVisibility(customVisibility.filter(id => id !== member.userId));
                          }
                        }
                      }}
                      disabled={isManager}
                    />
                    <label htmlFor={`custom-${member.userId}`}>
                      {member.fullName} ({member.userType})
                    </label>
                  </div>
                );
              })}
            </div>
          )}
        </div>

        <div className={styles.modalActions}>
          <button 
            className={styles.cancelButton} 
            onClick={onClose}
          >
            Cancel
          </button>
          <button 
            className={styles.submitButton} 
            onClick={handleSave}
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

const AttachmentCard = ({ attachment, onEdit, onDelete, onView }) => {
  const navigate = useNavigate();
  const { userData } = useContext(UserContext);
  const { selectedTour } = useContext(TourContext);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showAllUsers, setShowAllUsers] = useState(false);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  // Show edit button only if user is the uploader AND has Elite membership
  const canEditVisibility = userData.userId === attachment.uploaderId && 
                           userData.membershipTier === 'Elite';


                           const UpgradeModal = () => (
                            <div className={styles.upgradeModalOverlay}>
                              <div className={styles.upgradeModal}>
                                <h3 className={styles.upgradeModalTitle}>Upgrade to Elite</h3>
                                <p className={styles.upgradeModalMessage}>
                                  Upgrade to 'Elite' membership to edit visibility settings for your attachments
                                </p>
                                <div className={styles.upgradeModalActions}>
                                  <button
                                    type="button"
                                    onClick={() => setShowUpgradeModal(false)}
                                    className={styles.cancelButton}
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="button"
                                    onClick={() => {
                                      setShowUpgradeModal(false);
                                      navigate('/membership');
                                    }}
                                    className={styles.submitButton}
                                  >
                                    Upgrade
                                  </button>
                                </div>
                              </div>
                            </div>
                          );

                          
                          const handleEditClick = (e) => {
                            e.stopPropagation();
                            if (userData.membershipTier === 'Elite') {
                              setShowEditModal(true);
                            } else {
                              setShowUpgradeModal(true);
                            }
                          };

                          
  const getVisibilityIcon = () => {
    switch (attachment.visibilityType) {
      case 'all':
        return <FontAwesomeIcon icon={faGlobe} title="Visible to all" />;
      case 'artist':
        return <FontAwesomeIcon icon={faUsers} title="Artists only" />;
      case 'crew':
        return <FontAwesomeIcon icon={faUsers} title="Crew only" />;
      case 'me':
        return <FontAwesomeIcon icon={faUserCircle} title="Only me" />;
      case 'custom':
        return <FontAwesomeIcon icon={faLock} title="Custom visibility" />;
      default:
        return <FontAwesomeIcon icon={faGlobe} title="Visible to all" />;
    }
  };

  const getCustomVisibilityNames = () => {
    if (!selectedTour || attachment.visibilityType !== 'custom') return [];

    const allMembers = [
      ...(selectedTour.artistDetails || []),
      ...(selectedTour.crewDetails || []),
      selectedTour.createdBy ? [selectedTour.createdBy] : []
    ];

    return attachment.visibility
      .map(userId => {
        const member = allMembers.find(m => m.userId === userId);
        return member ? member.fullName : null;
      })
      .filter(name => name !== null);
  };

  const getVisibilityText = () => {
    switch (attachment.visibilityType) {
      case 'all':
        return 'Visible to all';
      case 'artist':
        return 'Artists only';
      case 'crew':
        return 'Crew only';
      case 'me':
        return 'Only me';
      case 'custom': {
        const names = getCustomVisibilityNames();
        if (names.length === 0) return 'Custom visibility';
        
        if (!showAllUsers) {
          const displayNames = names.slice(0, 2);
          const remaining = names.length - 2;
          return (
            <div className={styles.customNames}>
              <span>{displayNames.join(', ')}</span>
              {remaining > 0 && (
                <button 
                  className={styles.showMoreButton}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAllUsers(true);
                  }}
                >
                  +{remaining} more
                </button>
              )}
            </div>
          );
        } else {
          return (
            <div className={styles.customNames}>
              <span>{names.join(', ')}</span>
              <button 
                className={styles.showLessButton}
                onClick={(e) => {
                  e.stopPropagation();
                  setShowAllUsers(false);
                }}
              >
                Show less
              </button>
            </div>
          );
        }
      }
      default:
        return 'Visible to all';
    }
  };


  const getFileIcon = (fileType) => {
    switch (fileType) {
      case 'application/pdf':
        return faFilePdf;
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        return faFileImage;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return faFileWord;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return faFileExcel;
      default:
        return faFile;
    }
  };

  return (
    <>
      <div className={styles.attachmentCard}>
        <div className={styles.attachmentInfo} onClick={onView}>
          <FontAwesomeIcon 
            icon={getFileIcon(attachment.fileType)} 
            className={styles.fileIcon}
          />
          <div className={styles.attachmentDetails}>
            <h3>{attachment.fileName}</h3>
            <p>{attachment.description}</p>
            <div className={styles.attachmentMeta}>
              <span className={styles.uploadedBy}>
                Uploaded by {attachment.uploadedBy}
              </span>
              <div className={styles.visibility}>
                {getVisibilityIcon()}
                <span className={styles.visibilityText}>{getVisibilityText()}</span>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.attachmentActions}>
          {userData.userId === attachment.uploaderId && (
            <button 
              className={`${styles.actionButton} ${!canEditVisibility ? styles.upgradeButton : ''}`}
              onClick={handleEditClick}
              title={canEditVisibility ? "Edit visibility" : "Upgrade to edit visibility"}
            >
              <FontAwesomeIcon icon={faEdit} />
              {!canEditVisibility && (
                <FontAwesomeIcon icon={faCrown} className={styles.crownIcon} />
              )}
            </button>
          )}
          <button 
            className={styles.actionButton}
            onClick={onView}
            title="View attachment"
          >
            <FontAwesomeIcon icon={faDownload} />
          </button>
          {userData.userId === attachment.uploaderId && (
            <button 
              className={`${styles.actionButton} ${styles.deleteButton}`}
              onClick={onDelete}
              title="Delete attachment"
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          )}
        </div>
      </div>

      {showEditModal && (
        <VisibilityEditModal 
          attachment={attachment}
          onClose={() => setShowEditModal(false)}
          onSave={(updatedAttachment) => {
            onEdit(updatedAttachment);
            setShowEditModal(false);
          }}
        />
      )}

      {showUpgradeModal && <UpgradeModal />}
    </>
  );
};

const Attachments = () => {
  const { tourId, dateId } = useParams();
  const navigate = useNavigate();
  const { 
    selectedTour, 
    selectedDate, 
    getSelectedSectionData, 
    updateTourDate,
    tours,
    setSelectedTour,
    setTours,
    getSelectedTourDate,
  } = useContext(TourContext);
  const { userData } = useContext(UserContext);
  
  const [attachments, setAttachments] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isFileModalOpen, setIsFileModalOpen] = useState(false);
  const [selectedAttachment, setSelectedAttachment] = useState(null);
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [isRescheduled, setIsRescheduled] = useState(false);
  const [rescheduledDate, setRescheduledDate] = useState(null);
  const [showAlert, setShowAlert] = useState(false);
  const [alertConfig, setAlertConfig] = useState({});
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const tourDate = getSelectedTourDate();
    if (tourDate) {
      setIsCancelled(tourDate.cancelled || false);
      setIsRescheduled(tourDate.rescheduleDate?.isRescheduled || false);
      setRescheduledDate(tourDate.rescheduleDate?.newDate || null);
    }
  }, [selectedDate, getSelectedTourDate]);

  useEffect(() => {
    const sectionData = getSelectedSectionData('schedule');
    if (sectionData) {
      setAttachments(sectionData.attachments || []);
    }
  }, [selectedDate, getSelectedSectionData]);



  const handleAttachmentClick = (attachment) => {
    setSelectedAttachment(attachment);
    setIsFileModalOpen(true);
  };

  const handleAttachmentAdded = (newAttachment) => {
    setAttachments([...attachments, newAttachment]);
    // Update the tour date to reflect the new attachment
    updateTourDate(tourId, dateId);
  };


  const handleDeleteAttachment = async (attachmentToDelete) => {
    try {
      const storage = getStorage();
      const fileRef = ref(storage, attachmentToDelete.fileUrl);
      await deleteObject(fileRef);

      const db = getFirestoreInstance();
      const tourDateRef = doc(db, 'Tours', tourId, 'tourDates', dateId);

      const updatedAttachments = attachments.filter(a => a.id !== attachmentToDelete.id);
      await updateDoc(tourDateRef, {
        'schedule.attachments': updatedAttachments
      });

      setAttachments(updatedAttachments);
      await updateTourDate(tourId, dateId);
    } catch (error) {
      console.error('Error deleting attachment:', error);
      setAlertConfig({
        title: 'Error',
        message: 'Failed to delete attachment. Please try again.',
        type: 'error',
        showCancel: false
      });
      setShowAlert(true);
    }
  };

  const showDeleteConfirmation = (attachment) => {
    setAlertConfig({
      title: 'Delete Attachment',
      message: 'Are you sure you want to delete this attachment? This action cannot be undone.',
      type: 'warning',
      onConfirm: () => handleDeleteAttachment(attachment),
      showCancel: true
    });
    setShowAlert(true);
  };

  const handleBack = () => {
    navigate(`/${selectedTour.id}/${selectedDate}`);
  };

  const handleTourSelect = (tour) => {
    setSelectedTour(tour);
  };

  const handleEditAttachment = async (updatedAttachment) => {
    try {
      const db = getFirestoreInstance();
      const tourDateRef = doc(db, 'Tours', tourId, 'tourDates', dateId);
      
      // Update the attachment in the array
      const updatedAttachments = attachments.map(att => 
        att.id === updatedAttachment.id ? updatedAttachment : att
      );

      await updateDoc(tourDateRef, {
        'schedule.attachments': updatedAttachments
      });

      setAttachments(updatedAttachments);
    } catch (error) {
      console.error('Error updating attachment:', error);
      setAlertConfig({
        title: 'Error',
        message: 'Failed to update attachment. Please try again.',
        type: 'error',
        showCancel: false
      });
      setShowAlert(true);
    }
  };

  const getFormattedDate = () => {
    const tourDate = getSelectedTourDate();
    if (!tourDate?.date) return '';
    
    try {
      let date = tourDate.date;
      if (isRescheduled && rescheduledDate) {
        date = new Date(rescheduledDate);
      } else {
        if (date.seconds) {
          date = new Date(date.seconds * 1000);
        } else if (typeof date.toDate === 'function') {
          date = date.toDate();
        } else {
          date = new Date(date);
        }
      }
  
      if (!date || isNaN(date.getTime())) {
        console.error('Invalid date value:', date);
        return '';
      }
  
      return date.toLocaleDateString('en-US', {
        weekday: 'long',
        month: 'long',
        day: 'numeric',
        year: 'numeric'
      });
    } catch (error) {
      console.error('Error formatting date:', error);
      return '';
    }
  };
  
  // Add getVenueName function
  const getVenueName = () => {
    const tourDate = getSelectedTourDate();
    return tourDate?.event?.venueDetails?.venueData?.displayName || '';
  };
  
  if (!selectedTour || !selectedDate) {
    return (
      <div className={styles.layout}>
        <TopBar />
        <main className={styles.main}>
          <div className={styles.emptyState}>
            <FontAwesomeIcon icon={faInfoCircle} size="2x" />
            <p>Please select a tour and date to view attachments</p>
          </div>
        </main>
      </div>
    );
  }

  const getFileIcon = (fileType) => {
    switch (fileType) {
      case 'application/pdf':
        return faFilePdf;
      case 'image/jpeg':
      case 'image/png':
      case 'image/gif':
        return faFileImage;
      default:
        return faFileAlt;
    }
  };

  return (
    <div className={styles.layout}>
      <TopBar />
      <div className={styles.mainLayout}>
        {!isMobile && <LeftMenu selectedTour={selectedTour} />}
        <main className={styles.content}>
        <div className={styles.header}>
  <button className={styles.backButton} onClick={handleBack}>
    <FontAwesomeIcon icon={faChevronLeft} />
    Back to Schedule
  </button>
  <div className={styles.headerInfo}>
    <h1>{selectedTour.tourName}</h1>
    <div className={styles.headerDate}>
      <h2>{getFormattedDate()}</h2>
      {isRescheduled && rescheduledDate && (
        <div className={styles.rescheduledInfo}>
          Rescheduled from: {new Date(getSelectedTourDate()?.date?.seconds * 1000).toLocaleDateString('en-US', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            year: 'numeric'
          })}
        </div>
      )}
      {isCancelled && <div className={styles.cancelledInfo}>Cancelled</div>}
    </div>
    <div className={styles.headerAddress}>
      <p>{getVenueName()}</p>
    </div>
  </div>
</div>


  <div className={styles.uploadSection}>
            {!isCancelled && (
              <button 
                className={styles.uploadButton}
                onClick={() => setIsDetailsOpen(true)}
                aria-label="Add attachment"
              >
                <FontAwesomeIcon icon={faPlus} className={styles.uploadIcon} />
                <span>Add Attachment</span>
              </button>
            )}
          </div>
          
          <div className={styles.attachmentsList}>
        {attachments.length === 0 ? (
          <div className={styles.emptyState}>
            <FontAwesomeIcon icon={faFile} size="3x" />
            <p>No attachments yet</p>
          </div>
        ) : (
          attachments.map((attachment) => (
            <AttachmentCard
              key={attachment.id}
              attachment={attachment}
              onEdit={handleEditAttachment}
              onDelete={() => showDeleteConfirmation(attachment)}
              onView={() => handleAttachmentClick(attachment)}
            />
          ))
        )}
      </div>
        </main>
        {!isMobile && (
          <RightMenu
            selectedTour={selectedTour}
            tours={tours}
            onTourSelect={handleTourSelect}
          />
        )}
      </div>

      {isFileModalOpen && selectedAttachment && (
  <FileViewerModal
    isOpen={isFileModalOpen}
    onClose={() => {
      setIsFileModalOpen(false);
      setSelectedAttachment(null);
    }}
    file={selectedAttachment}
  />
)}

{showAlert && (
        <AlertModal 
          config={alertConfig}
          onClose={() => setShowAlert(false)}
        />
      )}

      {isDetailsOpen && (
          <AttachmentDetails
            isOpen={isDetailsOpen}
            onClose={() => setIsDetailsOpen(false)}
            tourId={tourId}
            dateId={dateId}
            onAttachmentAdded={handleAttachmentAdded}
          />
        )}

    </div>
  );
};


export default Attachments;