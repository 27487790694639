import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';
import UserContext from '../../context/UserContext';
import './Profile.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarkerAlt, faCalendarCheck, faRoute, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import logo from '../../assets/Images/logo.png';
import defaultCoverPhoto from '../../assets/Images/defaultUserCoverPicture.png';
import defaultProfilePhoto from '../../assets/Images/defaultUserProfilePicture.png';
import verifiedBadge from '../../assets/Images/profileverifiedtick.png';

const formatDateRange = (startDate, endDate) => {
  if (!startDate || !endDate) return '';
  const start = new Date(startDate);
  const end = new Date(endDate);
  return `${start.toLocaleDateString()} - ${end.toLocaleDateString()}`;
};

const formatDate = (date) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString();
};

const Profile = () => {
  const { username } = useParams();
  const navigate = useNavigate();
  const { userData: currentUserData } = useContext(UserContext);
  const [profileData, setProfileData] = useState(null);
  const [isOwnProfile, setIsOwnProfile] = useState(false);
  const [activeTab, setActiveTab] = useState('tours');
  const [tours, setTours] = useState([]);
const [dates, setDates] = useState([]);
const [isLoading, setIsLoading] = useState(true);

// Add this to your useEffect
useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      try {
        const db = getFirestore();
        
        // Fetch user profile
        const userQuery = query(collection(db, 'users'), where('userName', '==', username));
        const userSnapshot = await getDocs(userQuery);
        
        if (!userSnapshot.empty) {
          const userData = userSnapshot.docs[0].data();
          setProfileData(userData);
          setIsOwnProfile(currentUserData?.userId === userData.userId);
          
          // Fetch tours only if user has any
          if (userData.tourIds?.length > 0) {
            const toursQuery = query(collection(db, 'Tours'), where('id', 'in', userData.tourIds));
            const toursSnapshot = await getDocs(toursQuery);
            
            const toursData = toursSnapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data()
            }));
            
            setTours(toursData);
            
            // Process dates from all tours
            const allDates = toursData.flatMap(tour => {
              return tour.tourDates.map(date => ({
                ...date,
                tourName: tour.tourName,
                actName: tour.actName
              }));
            });
            
            setDates(allDates);
          } else {
            setTours([]);
            setDates([]);
          }
        } else {
          navigate('/404');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // You might want to show an error message to the user here
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchUserData();
  }, [username, currentUserData, navigate]);

  const handleEditProfile = () => {
    navigate('/edit-profile');
  };

  const handleShareProfile = () => {
    navigator.clipboard.writeText(window.location.href);
    // You could add a toast notification here
  };

  const handleEmailClick = () => {
    window.location.href = `mailto:${profileData.email}`;
  };

  if (!profileData) {
    return <div className="loading">Loading...</div>;
  }

  const SocialLinks = () => (
    <div className="social-links">
      {profileData.socialLinks?.instagramUrl && (
        <a href={profileData.socialLinks.instagramUrl} target="_blank" rel="noopener noreferrer">
          <i className="fab fa-instagram"></i>
        </a>
      )}
      {/* Add other social media links similarly */}
    </div>
  );


  const TourCard = ({ tour }) => {
    return (
      <div className="tour-card">
        <img 
          src={tour.tourCoverPhotoUrl || '/default-tour-cover.png'} 
          alt={tour.tourName} 
          className="tour-cover"
        />
        <div className="tour-info">
          <h3>{tour.tourName}</h3>
          <p className="act-name">{tour.actName}</p>
          <div className="tour-dates">
            {formatDateRange(tour.tourDates[0]?.date, tour.tourDates[tour.tourDates.length - 1]?.date)}
          </div>
          <div className="shows-count">
            {tour.tourDates.length} {tour.tourDates.length === 1 ? 'show' : 'shows'}
          </div>
        </div>
      </div>
    );
  };
  
  const TourDates = ({ dates }) => {
    const [displayDays, setDisplayDays] = useState(30);
    const [filter, setFilter] = useState('all');
  
    const filteredDates = dates.filter(date => {
      const today = new Date();
      const tourDate = new Date(date.date);
      
      switch(filter) {
        case 'upcoming':
          return tourDate >= today;
        case 'past':
          return tourDate < today;
        default:
          return true;
      }
    }).slice(0, displayDays);
  
    return (
      <div className="tour-dates">
        <div className="date-filters">
          <button 
            className={`filter-btn ${filter === 'all' ? 'active' : ''}`}
            onClick={() => setFilter('all')}
          >
            All
          </button>
          <button 
            className={`filter-btn ${filter === 'upcoming' ? 'active' : ''}`}
            onClick={() => setFilter('upcoming')}
          >
            Upcoming
          </button>
          <button 
            className={`filter-btn ${filter === 'past' ? 'active' : ''}`}
            onClick={() => setFilter('past')}
          >
            Past
          </button>
        </div>
  
        <div className="dates-list">
          {filteredDates.map((date, index) => (
            <div key={index} className="date-card">
              <div className="date-header">
                <div className="date">{formatDate(date.date)}</div>
                <div className={`status ${date.status.toLowerCase()}`}>
                  {date.status}
                </div>
              </div>
              <div className="venue-info">
                <h4>{date.venue?.name}</h4>
                <p>{date.venue?.location}</p>
              </div>
            </div>
          ))}
        </div>
  
        {dates.length > displayDays && (
          <button 
            className="load-more"
            onClick={() => setDisplayDays(prev => prev + 30)}
          >
            Load More
          </button>
        )}
      </div>
    );
  };


  return (
    <div className="profile-container">
      {/* Cover Photo */}
      <div className="cover-photo-container">
        <img 
          src={profileData.coverPhotoUrl || defaultCoverPhoto} 
          alt="Cover" 
          className="cover-photo"
        />
      </div>

      {/* Profile Section */}
      <div className="profile-content">
        <div className="profile-header">
          <img 
            src={profileData.profilePhotoUrl || defaultProfilePhoto} 
            alt="Profile" 
            className="profile-photo"
          />
          
          <div className="profile-info">
            <div className="name-container">
              <h1>{profileData.fullName}</h1>
              {profileData.idVerificationStatus === 'Verified' && (
                <img src={verifiedBadge} alt="Verified" className="verified-badge" />
              )}
            </div>
            
            <div className="user-type">
              <span>{profileData.userType}</span>
              {profileData.userSubType && <span> · {profileData.userSubType}</span>}
            </div>

            <div className="location">
              <FontAwesomeIcon icon={faMapMarkerAlt} />
              <span>{profileData.city}, {profileData.state}, {profileData.country}</span>
            </div>

            <div className="status">
              <FontAwesomeIcon icon={profileData.isOnTour ? faRoute : faCalendarCheck} />
              <span className={profileData.isOnTour ? 'on-tour' : 'available'}>
                {profileData.isOnTour ? 'On Tour' : 'Available'}
              </span>
            </div>

            {profileData.bio && <p className="bio">{profileData.bio}</p>}
          </div>

          <div className="profile-actions">
            {isOwnProfile ? (
              <button onClick={handleEditProfile} className="edit-button">
                Edit Profile
              </button>
            ) : (
              <button onClick={handleEmailClick} className="contact-button">
                <FontAwesomeIcon icon={faEnvelope} /> Contact
              </button>
            )}
            <button onClick={handleShareProfile} className="share-button">
              Share Profile
            </button>
          </div>
        </div>

        <div className="profile-stats">
          <div className="stat">
            <span className="stat-value">{profileData.toursCount || 0}</span>
            <span className="stat-label">Tours</span>
          </div>
          <div className="stat">
            <span className="stat-value">{profileData.showsCount || 0}</span>
            <span className="stat-label">Shows</span>
          </div>
          {(profileData.userType === 'Manager' || profileData.userType === 'Artist Management Company') && (
            <div className="stat">
              <span className="stat-value">{profileData.usersCount || 0}</span>
              <span className="stat-label">Users</span>
            </div>
          )}
        </div>

        <div className="profile-tabs">
          <button 
            className={`tab ${activeTab === 'tours' ? 'active' : ''}`}
            onClick={() => setActiveTab('tours')}
          >
            Tours
          </button>
          <button 
            className={`tab ${activeTab === 'dates' ? 'active' : ''}`}
            onClick={() => setActiveTab('dates')}
          >
            Tour Dates
          </button>
        </div>
<div className="tab-content">
  {activeTab === 'tours' ? (
    <div className="tours-grid">
      {tours.map(tour => (
        <TourCard key={tour.id} tour={tour} />
      ))}
    </div>
  ) : (
    <TourDates dates={dates} />
  )}
</div>
      </div>
    </div>
  );
};

export default Profile;