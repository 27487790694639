import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { resetPassword } from '../utils/actions/authActions';
import styles from './ForgotPassword.module.css';
import logo from '../assets/Images/logo.png';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const navigate = useNavigate();

  const handleEmailChange = (e) => {
    setEmail(e.target.value.toLowerCase());
    setError(null);
    setSuccess(null);
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();
    
    if (email.trim() === '') {
      setError('Please enter your email address');
      return;
    }

    setIsLoading(true);
    setError(null);
    setSuccess(null);

    try {
      await resetPassword(email);
      setSuccess('Password reset email sent. Please check your inbox.');
      
      // Redirect to login page after 3 seconds
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    } catch (error) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className={styles['forgot-password-page']}>
      <div className={styles['forgot-password-container']}>
        <div className={styles['forgot-password-left']}>
          <div className={styles['logo-section']}>
            <img src={logo} alt="On Tour Logo" className={styles.logo} />
            <h1 className={styles['brand-title']}>On Tour</h1>
          </div>
          <h2 className={styles['instruction-text']}>Reset Your Password</h2>
          <p className={styles['reset-description']}>
            Don't worry! It happens to the best of us. Enter your registered email address below,
            and we'll send you instructions to reset your password.
          </p>
        </div>

        <div className={styles['forgot-password-right']}>
          <div className={styles['reset-form-container']}>
            <h2>Password Recovery</h2>
            {error && <div className={styles.error}>{error}</div>}
            {success && <div className={styles.success}>{success}</div>}
            <form onSubmit={handleResetPassword}>
              <div className={styles['form-group']}>
                <label htmlFor="email">Email Address</label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  placeholder="Enter your registered email"
                  required
                />
              </div>
              <button type="submit" disabled={isLoading}>
                {isLoading ? 'Sending Instructions...' : 'Reset Password'}
              </button>
            </form>
            <div className={styles['back-to-login']}>
              Remember your password?
              <Link to="/login"> Back to Login</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;