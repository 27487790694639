// WelcomePage.js
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import styles from './WelcomePage.module.css';
import logo from '../assets/Images/logo.png';
import { firebaseConfig } from '../utils/firebaseHelper';
import feature1Image from '../assets/Images/feature1.png';
import feature2Image from '../assets/Images/feature2.png';
import feature3Image from '../assets/Images/feature3.png';
import playStoreLogo from '../assets/Images/playstorelogo.png';
import appStoreLogo from '../assets/Images/appstorelogo.png';

const WelcomePage = () => {
  const navigate = useNavigate();
  const [isScrolled, setIsScrolled] = useState(false);
  const app = initializeApp(firebaseConfig);
  const db = getFirestore(app);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
      
      // Get the app-download section position
      const appDownload = document.querySelector('.get-started-btn');
      const mobileCTA = document.querySelector('.mobile-cta');
      
      if (appDownload && mobileCTA) {
        const appDownloadRect = appDownload.getBoundingClientRect();
        const appDownloadBottom = appDownloadRect.bottom;
        
        // Show CTA when app download section moves above viewport
        if (appDownloadBottom < 0) {
          mobileCTA.style.display = 'block';
        } else {
          mobileCTA.style.display = 'none';
        }
      }
    };
  
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleGetStarted = () => {
    navigate('/signup');
  };

  const handleSignIn = () => {
    navigate('/login');
  };

  const features = [
    {
      title: "Tour Date Management",
      description: "Effortlessly manage your tour schedule, venue details, and team coordination. Keep track of every show with integrated calendars, document storage, and real-time updates for your entire crew.",
      image: feature1Image
    },
    {
      title: "Create & Plan Tours",
      description: "Design your perfect tour from start to finish. Add team members, manage logistics, assign roles, and handle equipment inventory all in one place. Seamlessly coordinate with venues and vendors.",
      image: feature2Image
    },
    {
      title: "Professional Profiles",
      description: "Showcase your experience with a comprehensive profile. Track completed shows, connect with industry professionals, and build your network. Perfect for artists, crew members, and vendors alike.",
      image: feature3Image
    }
  ];

  const reasons = [
    { emoji: "🎵", title: "All-in-One Solution", text: "Everything from schedules to travel tickets in one place" },
    { emoji: "🌎", title: "Industry Network", text: "Connect with professionals across the music industry" },
    { emoji: "📊", title: "Smart Management", text: "Streamlined logistics and team coordination" },
    { emoji: "🔒", title: "Secure Platform", text: "Your tour data is safe and protected" },
    { emoji: "🎯", title: "Industry Focused", text: "Built specifically for music tours and events" },
    { emoji: "📱", title: "Always Connected", text: "Stay updated with real-time notifications" }
  ];

  return (
    <div className={styles['welcome-page']}>
      <header className={`${styles['sticky-header']} ${isScrolled ? styles['scrolled'] : ''}`}>
        <div className={styles['header-content']}>
          <div className={styles['logo-section']}>
            <img src={logo} alt="On Tour Logo" className={styles.logo} />
            <h1 className={styles['header-title']}>On Tour</h1>
          </div>
          <button className={styles['sign-in-btn']} onClick={handleSignIn}>
            Sign In
          </button>
        </div>
      </header>

      <div className={styles['welcome-content']}>
        <main>
          <p className={styles.h2}>Your Ultimate Tour Management Platform!</p>
          <p className={styles.subtitle}>Perfect for Artists, Managers, Crew Members, and Vendors</p>
          <div className={styles['cta-section']}>
            <button className={styles['get-started-btn']} onClick={handleGetStarted}>
              Get Started
            </button>
          </div>
          <div className={styles['app-download']}>
            <p>Download On Tour Mobile App</p>
            <div className={styles['app-store-logos']}>
              <img 
                src={playStoreLogo} 
                alt="Google Play" 
                className={styles.playstorelogo}
              />
              <img 
                src={appStoreLogo} 
                alt="App Store" 
                className={styles.appstorelogo}
              />
            </div>
          </div>
        </main>
      </div>

      <div className={styles['features-section']}>
        <div className={styles['features-scroll']}>
          {features.map((feature, index) => (
            <div key={index} className={styles.feature}>
              <div className={styles['feature-text']}>
                <h3>{feature.title}</h3>
                <p>{feature.description}</p>
              </div>
              <div className={styles['feature-image']}>
                <img src={feature.image} alt={feature.title} />
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className={styles['reasons-section']}>
        <h2>Why Choose On Tour?</h2>
        <div className={styles['reasons-grid']}>
          {reasons.map((reason, index) => (
            <div key={index} className={styles['reason-card']}>
              <span className={styles['reason-emoji']}>{reason.emoji}</span>
              <h3>{reason.title}</h3>
              <p>{reason.text}</p>
            </div>
          ))}
        </div>
      </div>

      <footer className={styles.footer}>
        <ul>
          <li><a href="https://ontour.co.in/help">Help</a></li>
          <li><a href="https://ontour.co.in/contact-us">Contact Us</a></li>
          <li><a href="https://ontour.co.in/Terms-and-Conditions">Terms and Conditions</a></li>
          <li><a href="https://ontour.co.in/privacy-Policy">Privacy</a></li>
          <li><a href="https://ontour.co.in/Refund-and-Cancellation">Refund and Cancellation</a></li>
          <li><a href="https://ontour.co.in/faq">FAQ</a></li>
        </ul>
      </footer>

      <div className={styles['mobile-cta']}>
        <button className={styles['get-started-btn']} onClick={handleGetStarted}>
          Get Started
        </button>
      </div>
    </div>
  );
};

export default WelcomePage;