import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import styles from './ProfileModals.module.css';

// Tours Count Modal Component
const ToursModal = ({ isOpen, onClose, tours, userData }) => {
  const [displayedTours, setDisplayedTours] = useState(6);

  const formatDateRange = (startDate, endDate) => {
    const formatDate = (date) => {
      if (!date) return '';
      const d = new Date(date);
      return d.toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: 'numeric' });
    };
    return `${formatDate(startDate)} - ${formatDate(endDate)}`;
  };

  const getAssignedTours = () => {
    if (userData.userType === 'Manager') {
      return tours;
    } else {
      return tours.filter(tour => {
        let userDetails;
        switch (userData.userType) {
          case 'Artist':
            userDetails = tour.artistDetails;
            break;
          case 'Crew':
            userDetails = tour.crewDetails;
            break;
          case 'Vendor':
            userDetails = tour.vendorDetails;
            break;
          case 'Staff':
            userDetails = tour.staffDetails;
            break;
          default:
            return false;
        }
        return userDetails?.some(user => user.userId === userData.userId);
      });
    }
  };

  const assignedTours = getAssignedTours();
  
  // Sort tours based on start date in descending order
  const sortedTours = [...assignedTours].sort((a, b) => {
    const aStartDate = new Date(a.tourDates[0]?.date);
    const bStartDate = new Date(b.tourDates[0]?.date);
    return bStartDate - aStartDate;
  });

  if (!isOpen) return null;

  return (
    <div className={styles['modal-overlay']}>
      <div className={styles['modal-content']}>
        <button className={styles['modal-close']} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2>Tours</h2>
        <div className={styles['tours-list']}>
          {sortedTours.slice(0, displayedTours).map(tour => (
            <div key={tour.id} className={styles['tour-card']}>
              <img 
                src={tour.tourCoverPhotoUrl || '/default-tour-cover.png'} 
                alt={tour.tourName}
                className={styles['tour-cover-photo']}
              />
              <div className={styles['tour-card-content']}>
                <h3>{tour.tourName}</h3>
                <p className={styles['act-name']}>{tour.actName}</p>
                <p className={styles['tour-dates']}>
                  {formatDateRange(
                    tour.tourDates[0]?.date, 
                    tour.tourDates[tour.tourDates.length - 1]?.date
                  )}
                </p>
                <p className={styles['shows-count']}>
                  {tour.tourDates.length} {tour.tourDates.length === 1 ? 'show' : 'shows'}
                </p>
              </div>
            </div>
          ))}
        </div>
        {displayedTours < sortedTours.length && (
          <button 
            className={styles['load-more-button']}
            onClick={() => setDisplayedTours(prev => prev + 6)}
          >
            Show More
          </button>
        )}
      </div>
    </div>
  );
};

// Shows Count Modal Component
 const ShowsModal = ({ isOpen, onClose, tours, userData }) => {
  const [displayedShows, setDisplayedShows] = useState(15);

  const parseDate = (dateString) => {
    if (!dateString) return null;
    return new Date(dateString);
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const date = parseDate(dateString);
    if (!date || isNaN(date.getTime())) return 'Invalid Date';
    return date.toLocaleDateString('en-GB', { 
      day: '2-digit', 
      month: 'short', 
      year: 'numeric'
    });
  };

  const getAssignedShows = () => {
    let allShows = [];
    if (userData.userType === 'Manager') {
      allShows = tours.flatMap(tour => 
        tour.tourDates
          .filter(date => !date.cancelled)
          .map(date => ({
            ...date,
            tourName: tour.tourName,
            actName: tour.actName,
            tourId: tour.id,
            event: date.event
          }))
      );
    } else {
      tours.forEach(tour => {
        let userDetails;
        switch (userData.userType) {
          case 'Artist':
            userDetails = tour.artistDetails;
            break;
          case 'Crew':
            userDetails = tour.crewDetails;
            break;
          case 'Vendor':
            userDetails = tour.vendorDetails;
            break;
          case 'Staff':
            userDetails = tour.staffDetails;
            break;
          default:
            return;
        }
        
        const userAssignment = userDetails?.find(user => user.userId === userData.userId);
        if (userAssignment?.assignedDates) {
          allShows.push(...userAssignment.assignedDates
            .map(date => {
              const tourDate = tour.tourDates.find(td => td.date === date.date);
              if (!tourDate || tourDate.cancelled) return null;
              return {
                ...date,
                tourName: tour.tourName,
                actName: tour.actName,
                tourId: tour.id,
                event: tourDate?.event,
                rescheduleDate: tourDate?.rescheduleDate
              };
            })
            .filter(Boolean)
          );
        }
      });
    }
    return allShows;
  };

  const assignedShows = getAssignedShows();
  
  // Sort shows by date in descending order
  const sortedShows = assignedShows.sort((a, b) => {
    const dateA = a.rescheduleDate?.isRescheduled ? parseDate(a.rescheduleDate.newDate) : parseDate(a.date);
    const dateB = b.rescheduleDate?.isRescheduled ? parseDate(b.rescheduleDate.newDate) : parseDate(b.date);
    return dateB - dateA;
  });

  if (!isOpen) return null;

  return (
    <div className={styles['modal-overlay']}>
      <div className={styles['modal-content']}>
        <button className={styles['modal-close']} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2>Shows</h2>
        <div className={styles['shows-list']}>
          {sortedShows.slice(0, displayedShows).map((show, index) => {
            // Get the correct date to display based on reschedule status
            const showDate = show.rescheduleDate?.isRescheduled 
              ? formatDate(show.rescheduleDate.newDate)
              : formatDate(show.date);

            return (
              <div key={`${show.tourId}-${show.date}-${index}`} className={styles['show-card']}>
                <div className={styles['show-date']}>{showDate}</div>
                <div className={styles['show-details']}>
                  <h3>{show.tourName}</h3>
                  <p className={styles['act-name']}>{show.actName}</p>
                  <p className={styles['venue-name']}>
                    {show.event?.venueDetails?.venueData?.displayName || 'Venue not specified'}
                  </p>
                  <p className={styles['venue-address']}>
                    {show.event?.venueDetails?.venueData?.formattedAddress || 'Address not specified'}
                  </p>
                  {show.rescheduleDate?.isRescheduled && (
                    <p className={styles['rescheduled-note']}>
                      Rescheduled from {formatDate(show.date)}
                    </p>
                  )}
                </div>
              </div>
            );
          })}
        </div>
        {displayedShows < sortedShows.length && (
          <button 
            className={styles['load-more-button']}
            onClick={() => setDisplayedShows(prev => prev + 15)}
          >
            Show More
          </button>
        )}
      </div>
    </div>
  );
};

// Users Count Modal Component
const UsersModal = ({ isOpen, onClose, tours, currentUser, onUserSelect }) => {
  const [displayedUsers, setDisplayedUsers] = useState(15);
  
  const getUniqueUsers = () => {
    const uniqueUsers = new Map();
    
    tours.forEach(tour => {
      tour.artistDetails?.forEach(artist => {
        const existingUser = uniqueUsers.get(artist.userId) || { ...artist, userType: 'Artist', tours: [] };
        existingUser.tours.push(tour.id);
        uniqueUsers.set(artist.userId, existingUser);
      });
      
      tour.crewDetails?.forEach(crew => {
        const existingUser = uniqueUsers.get(crew.userId) || { ...crew, userType: 'Crew', tours: [] };
        existingUser.tours.push(tour.id);
        uniqueUsers.set(crew.userId, existingUser);
      });
    });
    
    return Array.from(uniqueUsers.values());
  };

  const users = getUniqueUsers();

  if (!isOpen) return null;

  return (
    <div className={styles['modal-overlay']}>
      <div className={styles['modal-content']}>
        <button className={styles['modal-close']} onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2>Users</h2>
        <div className={styles['users-list']}>
          {users.slice(0, displayedUsers).map(user => (
            <div 
              key={user.userId} 
              className={styles['user-card']}
              onClick={() => {
                onUserSelect(user);
                onClose();
              }}
            >
              <img
                src={user.profilePictureUrl || '/defaultUserProfilePicture.png'}
                alt={user.name}
                className={styles['user-photo']}
              />
              <div className={styles['user-info']}>
                <div className={styles['user-name-container']}>
                  <h3>{user.name}</h3>
                  {user.idVerificationStatus === 'Verified' && (
                    <img
                      src="/verified-badge.png"
                      alt="Verified"
                      className={styles['verified-badge']}
                    />
                  )}
                </div>
                <p className={styles.username}>@{user.userName}</p>
                <p className={styles['user-type']}>{user.userType}</p>
                <p className={styles['user-subtype']}>{user.userSubType}</p>
              </div>
            </div>
          ))}
        </div>
        {displayedUsers < users.length && (
          <button 
            className={styles['load-more-button']}
            onClick={() => setDisplayedUsers(prev => prev + 15)}
          >
            Show More
          </button>
        )}
      </div>
    </div>
  );
};

// PropTypes definitions
ToursModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tours: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    tourName: PropTypes.string.isRequired,
    actName: PropTypes.string.isRequired,
    tourCoverPhotoUrl: PropTypes.string,
    tourDates: PropTypes.array.isRequired,
    artistDetails: PropTypes.array,
    crewDetails: PropTypes.array
  })).isRequired,
  userData: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired
  }).isRequired
};

ShowsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tours: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    tourName: PropTypes.string.isRequired,
    actName: PropTypes.string.isRequired,
    tourDates: PropTypes.array.isRequired,
    artistDetails: PropTypes.array,
    crewDetails: PropTypes.array
  })).isRequired,
  userData: PropTypes.shape({
    userId: PropTypes.string.isRequired,
    userType: PropTypes.string.isRequired
  }).isRequired
};

UsersModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tours: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    artistDetails: PropTypes.array,
    crewDetails: PropTypes.array
  })).isRequired,
  currentUser: PropTypes.shape({
    userId: PropTypes.string.isRequired
  }).isRequired,
  onUserSelect: PropTypes.func.isRequired
};

export { ToursModal, ShowsModal, UsersModal };