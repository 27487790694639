import { getFirestoreInstance } from './firebaseHelper';
import { collectionGroup, query, where, getDocs, limit } from 'firebase/firestore';

export const checkUsernameAvailability = async (username) => {
  const db = getFirestoreInstance();
  
  try {
    // Query both users and bands collections for the username
    const userQuery = query(
      collectionGroup(db, 'users'),
      where('userName', '==', username),
      limit(1)
    );

    const bandQuery = query(
      collectionGroup(db, 'bands'),
      where('bandUserName', '==', username),
      limit(1)
    );

    const [userSnapshot, bandSnapshot] = await Promise.all([
      getDocs(userQuery),
      getDocs(bandQuery)
    ]);

    return userSnapshot.empty && bandSnapshot.empty;
  } catch (error) {
    console.error('Error checking username availability:', error);
    throw error;
  }
};