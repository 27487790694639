import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTicket,
  faUtensils,
  faMugHot,
  faMicrophone,
  faVolumeUp,
  faPlane,
  faHotel,
  faCalendar,
  faInfoCircle,
  faCheckCircle,
  faCircle
} from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import UserContext from '../../context/UserContext';
import styles from './ScheduleCard.module.css';

const ScheduleCard = ({ schedule, tourId, dateId, onUpdate }) => {
  const { userData } = useContext(UserContext);
  const navigate = useNavigate();

  const getIconName = (title) => {
    const lowercaseTitle = title.toLowerCase();
  
    const concertKeywords = ['festival', 'gig', 'show', 'concert'];
    if (concertKeywords.some(keyword => lowercaseTitle.includes(keyword))) {
      return faTicket;
    }
  
    const mealKeywords = ['dinner', 'food', 'lunch', 'breakfast', 'brunch'];
    if (mealKeywords.some(keyword => lowercaseTitle.includes(keyword))) {
      return faUtensils;
    }
  
    const snackKeywords = ['snacks', 'high tea', 'tea break', 'tea', 'coffee', 'snack break', 'break', 'eat'];
    if (snackKeywords.some(keyword => lowercaseTitle.includes(keyword))) {
      return faMugHot;
    }
  
    const soundcheckKeywords = ['soundcheck', 'test', 'test run'];
    if (soundcheckKeywords.some(keyword => lowercaseTitle.includes(keyword))) {
      return faMicrophone;
    }
  
    const rehearsalKeywords = ['jam', 'rehearsal', 'practice', 'jam session'];
    if (rehearsalKeywords.some(keyword => lowercaseTitle.includes(keyword))) {
      return faVolumeUp;
    }
  
    const flightKeywords = ['flight', 'airport', 'plane', 'fly'];
    if (flightKeywords.some(keyword => lowercaseTitle.includes(keyword))) {
      return faPlane;
    }
  
    const hotelKeywords = ['hotel', 'hostel', 'accommodation', 'checkin', 'checkout'];
    if (hotelKeywords.some(keyword => lowercaseTitle.includes(keyword))) {
      return faHotel;
    }
  
    return faCalendar;
  };

  const formatTime = (timestamp, dayAfter) => {
    const date = moment(timestamp);
    const formattedTime = date.format('h:mm A');
    return dayAfter ? `${formattedTime} (tomorrow)` : formattedTime;
  };

  const isVisible = () => {
    // Always show all schedules to Managers
    if (userData.userType === 'Manager') {
      return true;
    }
  
    // Show to creator
    if (userData.userId === schedule.creatorId) {
      return true;
    }
  
    // Check other visibility conditions
    switch (schedule.visibility) {
      case 'Me':
        return userData.userId === schedule.creatorId;
      case 'Custom':
        return Array.isArray(schedule.customUsers) && schedule.customUsers.some(user => 
          user.userId === userData.userId || user.fullName === userData.fullName
        );
      case 'All':
        return true;
      default:
        return schedule.visibility === userData.userType;
    }
  };

  const handleCardClick = () => {
    navigate(`/dashboard/schedule/${tourId}/${dateId}/details/${schedule.id}`);
  };

  if (!isVisible()) return null;

  return (
    <div className={styles['schedule-card']} onClick={handleCardClick}>
      <div className={styles['schedule-card-content']}>
        <div className={styles['schedule-icon']}>
          <FontAwesomeIcon 
            icon={getIconName(schedule.title)} 
            className={styles['icon-primary']}
          />
        </div>
        
        <div className={styles['schedule-details']}>
          <div className={styles['schedule-header']}>
            <h3 className={styles['schedule-title']}>{schedule.title}</h3>
            <FontAwesomeIcon 
              icon={schedule.isConfirmed ? faCheckCircle : faCircle}
              className={`${styles['status-icon']} ${schedule.isConfirmed ? styles.confirmed : styles.unconfirmed}`}
            />
          </div>

          <div className={styles['schedule-time']}>
            {formatTime(schedule.startTime, schedule.startTimeDayAfter)} - 
            {formatTime(schedule.endTime, schedule.endTimeDayAfter)}
          </div>

          {schedule.notes && (
            <div className={styles['schedule-notes']}>
              {schedule.notes}
            </div>
          )}

          {(schedule.fromLocation || schedule.toLocation) && (
            <div className={styles['schedule-locations']}>
              {schedule.fromLocation && (
                <div className={styles.location}>
                  <span className={styles['location-label']}>From:</span>
                  <span className={styles['location-name']}>{schedule.fromLocation.displayName}</span>
                </div>
              )}
              {schedule.toLocation && (
                <div className={styles.location}>
                  <span className={styles['location-label']}>To:</span>
                  <span className={styles['location-name']}>{schedule.toLocation.displayName}</span>
                </div>
              )}
            </div>
          )}

          <div className={styles['schedule-footer']}>
            <span className={styles['visibility-badge']}>
              {schedule.visibility}
            </span>
            
            <button className={styles['details-button']} onClick={handleCardClick}>
              <FontAwesomeIcon icon={faInfoCircle} />
              <span>Details</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScheduleCard;