import React, { useState, useContext, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faChevronDown, 
  faChevronUp,
  faTrash,
  faAdd,
  faEdit
} from '@fortawesome/free-solid-svg-icons';
import TourContext from '../context/TourContext';
import UserContext from '../context/UserContext';
import { firebaseInstances } from '../utils/firebaseHelper';
import { doc, deleteDoc, updateDoc, arrayRemove, } from 'firebase/firestore';
import styles from './RightMenu.module.css';
import { decrementUserStats } from '../utils/actions/userActions';

const { firestore: db } = firebaseInstances;

const RightMenu = () => {
  const { 
    selectedTour, 
    setSelectedTour, 
    tours,
    setTours,
    selectedDate,
    setSelectedDate,
    fetchToursData 
  } = useContext(TourContext);
  const { userData, setUserData } = useContext(UserContext);
  const navigate = useNavigate();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isInitialLoad, setIsInitialLoad] = useState(true);
  const prevToursRef = useRef(tours);


  useEffect(() => {
    console.log('RightMenu tours updated:', tours);
    console.log('RightMenu selectedTour:', selectedTour);
  }, [tours, selectedTour]);



  useEffect(() => {
    const fetchTours = async () => {
      if (userData && isInitialLoad) {
        await fetchToursData(userData);
        setIsInitialLoad(false);
      }
    };
    fetchTours();
  }, [userData, fetchToursData, isInitialLoad]);
  

  useEffect(() => {
    console.log('RightMenu tours updated:', tours);
    console.log('RightMenu selectedTour:', selectedTour);
  
    if (tours.length > 0) {
      // If there's a selectedTour but it's not in the tours array
      if (selectedTour) {
        const tourExists = tours.some(t => t.id === selectedTour.id);
        if (!tourExists) {
          setSelectedTour(tours[tours.length - 1]);
        } else {
          // Update the selected tour with fresh data
          const updatedTour = tours.find(t => t.id === selectedTour.id);
          if (updatedTour && JSON.stringify(updatedTour) !== JSON.stringify(selectedTour)) {
            setSelectedTour(updatedTour);
          }
        }
      } else {
        // If no tour is selected, select the latest one
        setSelectedTour(tours[tours.length - 1]);
      }
    }
  }, [tours, selectedTour, setSelectedTour]);


  const selectedDateRef = useRef(null);
  
  useEffect(() => {
    if (selectedDate && selectedDateRef.current) {
      selectedDateRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest'
      });
    }
  }, [selectedDate]);

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      weekday: 'short',
      month: 'short',
      day: 'numeric'
    });
  };

  const handleDateClick = (tourDate) => {
    setSelectedDate(tourDate.id);
    navigate(`/${selectedTour.id}/${tourDate.id}`);
  };

  const handleSelectTour = (tour) => {
    setSelectedTour(tour);
    setSelectedDate(null);
    setIsDropdownOpen(false);
  };

  const handleDeleteTour = async (tour) => {
    if (window.confirm(`Are you sure you want to remove ${tour.tourName} - ${tour.actName}?`)) {
      try {
        const tourRef = doc(db, 'Tours', tour.id);
        await deleteDoc(tourRef);
  
        if (userData?.userId) {
          // Update user's tourIds
          const userRef = doc(db, 'users', userData.userId);
          await updateDoc(userRef, {
            tourIds: arrayRemove(tour.id)
          });
  
          // Update user's stats
          await decrementUserStats(userData.userId, tour);
  
          // Update UserContext with new counts
          setUserData(prevUserData => ({
            ...prevUserData,
            toursCount: Math.max(0, (prevUserData.toursCount || 0) - 1),
            showsCount: Math.max(0, (prevUserData.showsCount || 0) - tour.tourDates.length)
          }));
        }
  
        setTours(prevTours => {
          const updatedTours = prevTours.filter(t => t.id !== tour.id);
          if (selectedTour?.id === tour.id) {
            const newSelectedTour = updatedTours.length > 0 ? updatedTours[0] : null;
            setSelectedTour(newSelectedTour);
            setSelectedDate(null);
          }
          return updatedTours;
        });
      } catch (error) {
        console.error('Error deleting tour:', error);
        alert('Failed to delete tour. Please try again.');
      }
    }
  };

  const handleCreateTour = () => {
    // Check membership tier and tour limits
    const { membershipTier } = userData;
    const currentTourCount = tours.length;

    let canCreateTour = true;
    let message = '';

    switch (membershipTier) {
      case 'Free':
        if (currentTourCount >= 1) {
          canCreateTour = false;
          message = 'Free membership allows only 1 tour. Please upgrade to create more tours.';
        }
        break;
      case 'Basic':
        if (currentTourCount >= 10) {
          canCreateTour = false;
          message = 'Basic membership allows up to 10 tours. Please upgrade to create more tours.';
        }
        break;
      case 'Pro':
        if (currentTourCount >= 15) {
          canCreateTour = false;
          message = 'Pro membership allows up to 15 tours. Please upgrade to create more tours.';
        }
        break;
      case 'Elite':
        // No limit for Elite members
        break;
      default:
        canCreateTour = false;
        message = 'Unable to determine tour limits. Please check your membership status.';
    }

    if (!canCreateTour) {
      alert(message);
      return;
    }

    navigate('/create-tour');
    setIsDropdownOpen(false);
  };

  return (
    <div className={`${styles['right-menu']} ${isDropdownOpen ? styles['dropdown-open'] : ''}`}>
      <div className={styles['tour-selector']}>
        <button 
          className={styles['tour-selector-button']}
          onClick={() => setIsDropdownOpen(!isDropdownOpen)}
        >
          <div className={styles['tour-info']}>
            <span className={styles['tour-act-name']}>{selectedTour?.actName || 'Select Tour'}</span>
            <span className={styles['tour-name']}>{selectedTour?.tourName}</span>
          </div>
          <FontAwesomeIcon icon={isDropdownOpen ? faChevronUp : faChevronDown} />
        </button>
        
        {isDropdownOpen && (
          <div className={styles['tour-dropdown']}>
            {tours.map(tour => (
            
<div key={tour.id} className={styles['tour-dropdown-item-container']}>
  <button
    className={`${styles['tour-dropdown-item']} ${selectedTour?.id === tour.id ? styles.selected : ''}`}
    onClick={() => handleSelectTour(tour)}
  >
    <span className={styles['tour-act-name']}>{tour.actName}</span>
    <span className={styles['tour-name']}>{tour.tourName}</span>
  </button>
  <div className={styles['tour-actions']}>
    <button 
      className={styles['action-button']}
      onClick={(e) => {
        e.stopPropagation();
        navigate('/create-tour', { state: { tourData: tour } });
      }}
    >
      <FontAwesomeIcon icon={faEdit} />
    </button>
    <button 
      className={styles['action-button']}
      onClick={(e) => {
        e.stopPropagation();
        handleDeleteTour(tour);
      }}
    >
      <FontAwesomeIcon icon={faTrash} />
    </button>
  </div>
</div>
            ))}
            <button 
              className={styles['create-tour-button']}
              onClick={handleCreateTour}
              disabled={
                (userData.membershipTier === 'Free' && tours.length >= 1) ||
                (userData.membershipTier === 'Basic' && tours.length >= 10)
              }
            >
              <FontAwesomeIcon icon={faAdd} />
              <span>Create New Tour</span>
            </button>
          </div>
        )}
      </div>

      {selectedTour && selectedTour.tourDates && (
        <div className={styles['tour-dates-section']}>
          <div className={styles['dates-list']}>
          {selectedTour.tourDates
  .sort((a, b) => new Date(a.date) - new Date(b.date))
  .map((tourDate) => {
    const isRescheduled = tourDate.rescheduleDate?.isRescheduled;
    const rescheduledDate = isRescheduled ? new Date(tourDate.rescheduleDate.newDate) : null;
    const isCancelled = tourDate.cancelled;
    
    // Display rescheduled date if available, otherwise show original date
    const displayDate = isRescheduled ? rescheduledDate : new Date(tourDate.date);

    return (
      <div 
        key={tourDate.id}
        ref={selectedDate === tourDate.id ? selectedDateRef : null}
        className={`
          ${styles['date-card']} 
          ${selectedDate === tourDate.id ? styles.selected : ''}
          ${isCancelled ? styles['cancelled'] : ''}
          ${isRescheduled ? styles['rescheduled'] : ''}
        `}
        onClick={() => handleDateClick(tourDate)}
      >
        <div className={styles['date-card-header']}>
          <span className={styles.date}>{formatDate(displayDate)}</span>
          <div className={styles['status-badges']}>
            {isCancelled && <span className={styles['cancelled-badge']}>Cancelled</span>}
            {isRescheduled && <span className={styles['rescheduled-badge']}>Rescheduled</span>}
          </div>
        </div>
        {tourDate.event?.venueDetails?.venueData && (
          <div className={styles['date-card-content']}>
            <div className={styles['venue-info']}>
              <div className={styles['venue-details']}>
                <span>{tourDate.event.venueDetails.venueData.displayName}</span>
                <span className={styles['date-location']}>
                  {tourDate.event.venueDetails.venueData.formattedAddress}
                </span>
                {isRescheduled && (
                  <span className={styles['rescheduled-info']}>
                    Rescheduled from: {formatDate(new Date(tourDate.date))}
                  </span>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  })}
          </div>
        </div>
      )}
    </div>
  );
};

export default RightMenu;