import React, { useState, useEffect, useContext } from 'react';
import UserContext from '../../context/UserContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faDownload, 
  faSpinner, 
  faCreditCard, 
  faWallet,
  faUniversity,
  faMoneyBillWave,
  faFileInvoice,
  faCalendarAlt,
  faInfoCircle,
  faChevronDown
} from '@fortawesome/free-solid-svg-icons';
import { fetchMembershipHistory } from '../../utils/membershipUtils';
import { getAuth } from 'firebase/auth';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import styles from './Membership.module.css';

// Define membership plan tiers for comparison
const MEMBERSHIP_TIERS = ['Free', 'Basic', 'Pro', 'Elite'];

// Helper function to compare membership tier levels
const compareTiers = (tier1, tier2) => {
  const tier1Index = MEMBERSHIP_TIERS.indexOf(tier1);
  const tier2Index = MEMBERSHIP_TIERS.indexOf(tier2);
  return tier1Index - tier2Index;
};

// Helper function to display payment method
const PaymentMethodDisplay = ({ paymentMethod }) => {
  if (!paymentMethod) return <span>Payment Method: N/A</span>;
  
  let icon, methodText;
  
  if (paymentMethod.method === 'card' && paymentMethod.card) {
    icon = faCreditCard;
    methodText = `Card - ${paymentMethod.card.network?.toUpperCase() || ''} ${paymentMethod.card.type || ''} ****${paymentMethod.card.last4 || ''}`;
  } else if (paymentMethod.method === 'wallet') {
    icon = faWallet;
    methodText = `Wallet - ${paymentMethod.wallet || 'Digital Wallet'}`;
  } else if (paymentMethod.method === 'netbanking') {
    icon = faUniversity;
    methodText = `Netbanking - ${paymentMethod.bank || ''}`;
  } else if (paymentMethod.method === 'upi') {
    icon = faMoneyBillWave;
    methodText = `UPI`;
  } else {
    icon = faMoneyBillWave;
    methodText = paymentMethod.method || 'Online Payment';
  }
  
  return (
    <div className={styles['payment-method']}>
      <FontAwesomeIcon icon={icon} className={styles['payment-icon']} />
      <span>{methodText}</span>
    </div>
  );
};

const MembershipHistory = ({ userId }) => {
  const [history, setHistory] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [downloadingInvoice, setDownloadingInvoice] = useState(false);
  const [processingRecordId, setProcessingRecordId] = useState(null);
  const { userData, setUserData } = useContext(UserContext);
  
  // Load more functionality
  const [lastDoc, setLastDoc] = useState(null);
  const [hasMore, setHasMore] = useState(false);
  const [loadingMore, setLoadingMore] = useState(false);
  const recordsPerPage = 5;

  useEffect(() => {
    loadHistory(false);
  }, [userId]);

  const loadHistory = async (loadMore = false) => {
    if (!userId) return;
    
    try {
      if (loadMore) {
        setLoadingMore(true);
      } else {
        setIsLoading(true);
        setHistory([]);
      }
      
      const options = {
        limit: recordsPerPage,
        startAfterDoc: loadMore ? lastDoc : null
      };
      
      const result = await fetchMembershipHistory(userId, options);
      
      if (loadMore) {
        setHistory(prev => [...prev, ...result.memberships]);
      } else {
        setHistory(result.memberships);
      }
      
      setLastDoc(result.lastDoc);
      setHasMore(result.hasMore);
      
    } catch (err) {
      setError('Failed to load membership history');
      console.error(err);
    } finally {
      setIsLoading(false);
      setLoadingMore(false);
    }
  };

  const handleLoadMore = () => {
    if (hasMore && !loadingMore) {
      loadHistory(true);
    }
  };

  // Calculate next payment due date
  const getNextDueDate = (record) => {
    // If subscription is cancelled, there's no next due date
    if (record.status === 'cancelled' || record.subscriptionStatus === 'cancelled') {
      return null;
    }
    
    // Only calculate for active subscriptions
    if (record.status !== 'active' && record.status !== 'completed' && record.subscriptionStatus !== 'active') {
      return null;
    }
    
    // If end date exists, calculate next payment date
    if (record.endDate) {
      const endDate = record.endDate.toDate();
      const now = new Date();
      
      // Only show next due if the end date is in the future
      if (endDate > now) {
        return endDate;
      }
    }
    
    return null;
  };

  // Format date for display
  const formatDate = (date) => {
    if (!date) return 'N/A';
    
    return date.toLocaleDateString('en-IN', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
  };

  // Check if the next due date is approaching (within 7 days)
  const isPaymentApproaching = (nextDueDate) => {
    if (!nextDueDate) return false;
    
    const now = new Date();
    const daysDifference = Math.ceil((nextDueDate - now) / (1000 * 60 * 60 * 24));
    
    return daysDifference <= 7 && daysDifference >= 0;
  };

  const handleDownloadInvoice = async (record) => {
    setDownloadingInvoice(true);
    setProcessingRecordId(record.id);
    
    try {
      // Check if this record already has an invoice number
      let invoiceNumber = record.invoiceNumber;
      
      if (!invoiceNumber) {
        // Generate invoice number if missing
        console.warn('Invoice number missing for record, generating one now:', record.id);
        invoiceNumber = await getInvoiceNumber(record.startDate?.toDate() || record.createdAt?.toDate());
        
        // Update the record with the new invoice number
        const updateSuccess = await updateRecordWithInvoiceNumber(record, invoiceNumber);
        
        if (!updateSuccess) {
          throw new Error('Failed to update invoice number in database');
        }
      }
      
      // Generate the PDF with the invoice number
      await generateInvoicePDF(record, invoiceNumber);
      
    } catch (error) {
      console.error('Error downloading invoice:', error);
      alert('Failed to generate invoice. Please try again.');
    } finally {
      setDownloadingInvoice(false);
      setProcessingRecordId(null);
    }
  };

 // Invoice generation function
 const generateInvoicePDF = async (record) => {
  try {
    // First, get an invoice number if it doesn't exist
    let invoiceNumber = record.invoiceNumber;
    
    if (!invoiceNumber) {
      invoiceNumber = await getInvoiceNumber();
    }
    
    // Create PDF document
    const doc = new jsPDF();
    
    // Add styling and branding
    addCompanyBranding(doc);
    
    // Add invoice header information
    addInvoiceHeader(doc, {
      invoiceNumber,
      date: record.createdAt.toDate(),
      membershipTier: record.membershipTier
    });
    
    // Add customer information
    addCustomerInfo(doc, record.userDetails);
    
    // Add subscription details
    addSubscriptionDetails(doc, {
      membershipTier: record.membershipTier,
      startDate: record.startDate.toDate(),
      endDate: record.endDate.toDate(),
      subscriptionId: record.subscriptionId,
      paymentId: record.paymentId
    });
    
    // Add payment table
    addPaymentTable(doc, {
      baseAmount: record.baseAmount || 0,
      gstDetails: record.gstDetails,
      totalAmount: record.gstDetails?.totalAmount || 
        (record.baseAmount + (record.gstDetails?.amount || 0))
    });
    
    // Add payment method if available
    if (record.paymentMethod) {
      addPaymentMethod(doc, record.paymentMethod);
    }
    
    // Add footer
    addFooter(doc);
    
    // If it's a new invoice, update the record with the number
    if (!record.invoiceNumber) {
      await updateRecordWithInvoiceNumber(record.id, invoiceNumber);
      
      // Optionally store a copy in the invoices collection
      await storeInvoiceRecord({
        invoiceNumber,
        subscriptionId: record.subscriptionId,
        paymentId: record.paymentId,
        membershipTier: record.membershipTier,
        amount: record.baseAmount,
        gstDetails: record.gstDetails,
        userDetails: record.userDetails,
        startDate: record.startDate.toDate(),
        endDate: record.endDate.toDate(),
        paymentMethod: record.paymentMethod,
        status: 'issued',
        membershipRecordId: record.id
      });
    }
    
    // Save the PDF
    doc.save(`Invoice-${invoiceNumber}.pdf`);
    
    return { success: true, invoiceNumber };
  } catch (error) {
    console.error('Error generating invoice PDF:', error);
    return { success: false, error };
  }
};

// Helper function to get a new invoice number
const getInvoiceNumber = async (transactionDate) => {
try {
  const auth = getAuth();
  const idToken = await auth.currentUser.getIdToken();
  
  const response = await fetch('https://asia-south1-ontourauth.cloudfunctions.net/generateInvoiceNumber', {
    method: 'POST',
    headers: {
      'Authorization': `Bearer ${idToken}`,
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      transactionDate: transactionDate ? transactionDate.toISOString() : undefined
    })
  });
  
  if (!response.ok) {
    throw new Error('Failed to generate invoice number');
  }
  
  const data = await response.json();
  return data.formattedInvoiceNumber;
} catch (error) {
  console.error('Error getting invoice number:', error);
  throw error;
}
};

// Helper function to update record with new invoice number
const updateRecordWithInvoiceNumber = async (record, invoiceNumber) => {
  try {
    const auth = getAuth();
    const idToken = await auth.currentUser.getIdToken();
    
    // Extract the record ID from the record
    const recordId = record.id;
    
    console.log(`Updating membership record ${recordId} with invoice number ${invoiceNumber}`);
    
    const response = await fetch('https://asia-south1-ontourauth.cloudfunctions.net/updateMembershipInvoiceNumber', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        recordId,
        invoiceNumber
      })
    });
    
    if (!response.ok) {
      const errorData = await response.json();
      console.error('Error response from server:', errorData);
      throw new Error(errorData.error || errorData.details || 'Failed to update invoice number');
    }
    
    // Update local state in the history component
    setHistory(prevHistory => 
      prevHistory.map(item => 
        item.id === recordId 
          ? {...item, invoiceNumber: invoiceNumber} 
          : item
      )
    );
    
    // More importantly, update the userData in the context
    if (userData && userData.lastSubscriptionDetails) {
      // If this is the most recent subscription, update it there
      const isLatestSubscription = record.subscriptionId === userData.subscriptionId;
      
      if (isLatestSubscription) {
        setUserData(prevUserData => ({
          ...prevUserData,
          lastInvoiceNumber: invoiceNumber,
          lastSubscriptionDetails: {
            ...prevUserData.lastSubscriptionDetails,
            invoiceNumber: invoiceNumber
          }
        }));
      }
    }
    
    // If userData contains a membershipHistory array, update that too
    if (userData && userData.membershipHistory) {
      setUserData(prevUserData => ({
        ...prevUserData,
        membershipHistory: prevUserData.membershipHistory.map(item => 
          item.id === recordId 
            ? {...item, invoiceNumber: invoiceNumber} 
            : item
        )
      }));
    }
    
    return true;
  } catch (error) {
    console.error('Error updating invoice number:', error);
    return false;
  }
};

// Helper function to store invoice record
const storeInvoiceRecord = async (invoiceData) => {
  try {
    const auth = getAuth();
    const idToken = await auth.currentUser.getIdToken();
    
    const response = await fetch('https://asia-south1-ontourauth.cloudfunctions.net/storeInvoiceRecord', {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${idToken}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ invoiceData })
    });
    
    if (!response.ok) {
      throw new Error('Failed to store invoice record');
    }
    
    return true;
  } catch (error) {
    console.error('Error storing invoice record:', error);
    return false;
  }
};

// PDF generation helper functions
const addCompanyBranding = (doc) => {
  // Company name and header
  doc.setFontSize(20);
  doc.setTextColor(226, 56, 77); // On Tour brand color
  doc.text('On Tour', 105, 30, { align: 'center' });
  
  doc.setTextColor(0, 0, 0);
  doc.setFontSize(10);
  doc.text('On Tour Entertainment Pvt Ltd', 105, 40, { align: 'center' });
  
  doc.setFontSize(8);
  doc.text('Mumbai, Maharashtra', 105, 45, { align: 'center' });
  doc.text('GSTIN: GSTIN12345678901', 105, 50, { align: 'center' });
  
  // Horizontal line
  doc.setDrawColor(226, 56, 77);
  doc.setLineWidth(0.5);
  doc.line(20, 55, 190, 55);
};

const addInvoiceHeader = (doc, { invoiceNumber, date, membershipTier }) => {
  // Format date
  const formattedDate = new Date(date).toLocaleDateString('en-IN', {
    day: '2-digit',
    month: 'short',
    year: 'numeric'
  });
  
  // Add title
  doc.setFontSize(16);
  doc.setTextColor(0, 0, 0);
  doc.text('INVOICE', 105, 70, { align: 'center' });
  
  // Invoice details
  doc.setFontSize(10);
  doc.text(`Invoice Number: ${invoiceNumber}`, 20, 85);
  doc.text(`Date: ${formattedDate}`, 20, 92);
  doc.text(`Plan: ${membershipTier} Membership`, 20, 99);
};

const addCustomerInfo = (doc, userDetails) => {
  if (!userDetails) return;
  
  doc.setFontSize(12);
  doc.text('Bill To:', 130, 85);
  
  doc.setFontSize(10);
  doc.text(userDetails.fullName || 'Customer', 130, 92);
  if (userDetails.email) doc.text(userDetails.email, 130, 99);
  if (userDetails.phoneNumber) doc.text(userDetails.phoneNumber, 130, 106);
};

const addSubscriptionDetails = (doc, { membershipTier, startDate, endDate, subscriptionId, paymentId }) => {
  // Format dates
  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-IN', {
      day: '2-digit',
      month: 'short',
      year: 'numeric'
    });
  };
  
  doc.setFontSize(12);
  doc.text('Subscription Details:', 20, 115);
  
  doc.setFontSize(10);
  doc.text(`Billing Period: ${formatDate(startDate)} to ${formatDate(endDate)}`, 20, 122);
  
  if (subscriptionId) {
    doc.text(`Subscription ID: ${subscriptionId}`, 20, 129);
  }
  
  if (paymentId) {
    doc.text(`Payment ID: ${paymentId}`, 20, 136);
  }
};

const addPaymentTable = (doc, { baseAmount, gstDetails, totalAmount }) => {
  const startY = 150;
  
  doc.setFontSize(12);
  doc.text('Payment Details:', 20, startY - 10);
  
  // Create a table
  doc.autoTable({
    startY,
    head: [['Description', 'Amount (₹)']],
    body: [
      [`Membership Subscription`, baseAmount.toFixed(2)],
      [`GST (${(gstDetails?.rate || 0.18) * 100}%)`, (gstDetails?.amount || 0).toFixed(2)]
    ],
    theme: 'grid',
    headStyles: {
      fillColor: [226, 56, 77],
      textColor: 255,
      fontStyle: 'bold'
    },
    foot: [['Total', totalAmount.toFixed(2)]],
    footStyles: {
      fillColor: [240, 240, 240],
      fontStyle: 'bold',
      textColor: [0, 0, 0]
    },
    styles: {
      lineColor: [200, 200, 200]
    },
    columnStyles: {
      0: { cellWidth: 'auto' },
      1: { cellWidth: 40, halign: 'right' }
    }
  });
};

const addPaymentMethod = (doc, paymentMethod) => {
  // Get current Y position after the table
  const lastY = doc.lastAutoTable.finalY + 15;
  
  let methodText = paymentMethod.method || 'Online Payment';
  
  if (paymentMethod.method === 'card' && paymentMethod.card) {
    methodText = `Card - ${paymentMethod.card.network || ''} ${paymentMethod.card.type || ''} ****${paymentMethod.card.last4 || ''}`;
  } else if (paymentMethod.method === 'wallet') {
    methodText = `Wallet - ${paymentMethod.wallet || 'Digital Wallet'}`;
  } else if (paymentMethod.method === 'netbanking') {
    methodText = `Netbanking - ${paymentMethod.bank || ''}`;
  } else if (paymentMethod.method === 'upi') {
    methodText = `UPI`;
  }
  
  doc.setFontSize(10);
  doc.text('Payment Method:', 20, lastY);
  doc.text(methodText, 20, lastY + 7);
};

const addFooter = (doc) => {
  const pageHeight = doc.internal.pageSize.height;
  
  // Add footer
  doc.setFontSize(8);
  doc.text('This is a computer-generated invoice and does not require a signature.', 
    105, pageHeight - 30, { align: 'center' });
  doc.text('For any queries, please contact us at billing@ontour.co.in', 
    105, pageHeight - 25, { align: 'center' });
  doc.text('Thank you for your business!', 
    105, pageHeight - 20, { align: 'center' });
    
  // Add page number
  doc.text(`Page ${doc.internal.getCurrentPageInfo().pageNumber}`, 
    105, pageHeight - 10, { align: 'center' });
};


  if (isLoading && history.length === 0) {
    return (
      <div className={styles['loading-container']}>
        <FontAwesomeIcon icon={faSpinner} spin className={styles['loading-spinner']} />
      </div>
    );
  }

  if (error && history.length === 0) {
    return (
      <div className={styles['error-message']}>
        {error}
      </div>
    );
  }

  if (history.length === 0 && !isLoading) {
    return (
      <div className={styles['empty-state']}>
        <p>No membership history found</p>
      </div>
    );
  }

  return (
    <div className={styles['history-container']}>
      {history.map((record) => {
        const nextDueDate = getNextDueDate(record);
        const isApproaching = isPaymentApproaching(nextDueDate);
        
        return (
          // Update to history card display in MembershipHistory.js

<div key={record.id} className={styles['history-card']}>
  <div className={styles['history-header']}>
    <div>
    <h3 className={styles['history-title']}>
  {record.membershipTier} Plan
  {record.status === 'cancelled' && (
    <span className={styles['cancelled-badge']}>(cancelled)</span>
  )}
</h3>
      <span className={styles['history-date']}>
        {record.createdAt?.toDate().toLocaleDateString() || new Date().toLocaleDateString()}
      </span>
    </div>
    <div className={styles['history-amount']}>
      ₹{record.gstDetails?.totalAmount ? 
         record.gstDetails.totalAmount.toFixed(2) : 
         (typeof record.baseAmount === 'number' ? 
          (record.baseAmount * 1.18).toFixed(2) : '0.00')}
      <span className={styles['history-period']}>/month</span>
    </div>
  </div>
     
            
            <div className={styles['history-details']}>
              <div className={styles['detail-item']}>
                <span className={styles['detail-label']}>Start Date</span>
                <span className={styles['detail-value']}>
                  {record.startDate?.toDate().toLocaleDateString() || 'N/A'}
                </span>
              </div>
              {record.status === 'cancelled' || record.subscriptionStatus === 'cancelled' ? (
  <div className={styles['detail-item']}>
    <span className={styles['detail-label']}>Next Invoice</span>
    <span className={styles['detail-value']}>-</span>
  </div>
) : (
  <div className={styles['detail-item']}>
    <span className={styles['detail-label']}>Next Invoice</span>
    <span className={styles['detail-value']}>
      {record.endDate?.toDate().toLocaleDateString() || 'N/A'}
    </span>
  </div>
)}
              <div className={styles['detail-item']}>
                <span className={styles['detail-label']}>Payment ID</span>
                <span className={styles['detail-value']}>{record.paymentId || 'N/A'}</span>
              </div>
              <div className={styles['detail-item']}>
                <span className={styles['detail-label']}>Subscription ID</span>
                <span className={styles['detail-value']}>{record.subscriptionId || 'N/A'}</span>
              </div>
              
              {/* Payment Method Display */}
              {record.paymentMethod && (
                <div className={styles['detail-item']}>
                  <span className={styles['detail-label']}>Payment Method</span>
                  <span className={styles['detail-value']}> <PaymentMethodDisplay paymentMethod={record.paymentMethod} /> </span>
                </div>
              )}
              
              {/* GST Info Display */}
              {record.gstDetails && (
                <div className={styles['detail-item']}>
                  <span className={styles['detail-label']}>Payment Status</span>
                  <div className={styles['history-status']}>
                    <span 
                      className={`${styles['status-badge']} ${styles[`status-${record.paymentStatus?.toLowerCase()}`]}`}
                    >
                      {record.paymentStatus || 'Unknown'}
                    </span>
                  </div>
                </div>
              )}
            </div>

            {(record.status === 'completed' || record.status === 'active' || record.paymentStatus === 'completed') && (
              <button 
                className={styles['invoice-button']}
                onClick={() => handleDownloadInvoice(record)}
                disabled={downloadingInvoice && processingRecordId === record.id}
              >
                {downloadingInvoice && processingRecordId === record.id ? (
                  <>
                    <FontAwesomeIcon icon={faSpinner} spin className={styles['button-icon']} />
                    <span>Generating Invoice...</span>
                  </>
                ) : (
                  <>
                    <FontAwesomeIcon icon={faDownload} className={styles['button-icon']} />
                    <span>Download Invoice</span>
                  </>
                )}
              </button>
            )}
          </div>
        );
      })}

      {/* Load More Button */}
      {hasMore && (
        <button 
          className={styles['load-more-button']} 
          onClick={handleLoadMore}
          disabled={loadingMore}
        >
          {loadingMore ? (
            <>
              <FontAwesomeIcon icon={faSpinner} spin className={styles['button-icon']} />
              <span>Loading more...</span>
            </>
          ) : (
            <>
              <span>Load More</span>
              <FontAwesomeIcon icon={faChevronDown} className={styles['button-icon']} />
            </>
          )}
        </button>
      )}
    </div>
  );
};

export default MembershipHistory;