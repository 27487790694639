import React, { useState, useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import styles from './BrandWebsiteModal.module.css';

const BrandWebsiteModal = ({ isOpen, onClose, brand }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [loadError, setLoadError] = useState(false);
  const iframeRef = useRef(null);
  const timeoutRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      setIsLoading(true);
      setLoadError(false);

      // Set a timeout to check if loading takes too long or fails
      timeoutRef.current = setTimeout(() => {
        handleLoadFailure();
      }, 3000); // Wait 3 seconds before assuming failure
    }

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [isOpen]);

  const handleLoadFailure = () => {
    setIsLoading(false);
    setLoadError(true);
    
    // Redirect after showing error message
    setTimeout(() => {
      window.open(brand.website, '_blank', 'noopener,noreferrer');
      onClose();
    }, 1500);
  };

  const handleIframeLoad = () => {
    // Clear the timeout since load completed
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // Check if we can access the iframe content
    try {
      // This will throw an error if we can't access the iframe
      const iframeWindow = iframeRef.current.contentWindow;
      const iframeDoc = iframeRef.current.contentDocument;
      
      if (!iframeWindow || !iframeDoc) {
        throw new Error('Cannot access iframe content');
      }
      
      setIsLoading(false);
    } catch (error) {
      handleLoadFailure();
    }
  };

  if (!isOpen) return null;

  return (
    <div className={styles.modalOverlay} onClick={onClose}>
      <div className={styles.modalContent} onClick={e => e.stopPropagation()}>
        <div className={styles.modalHeader}>
          <h2>{brand.brandName} Official Website</h2>
          <div className={styles.modalActions}>
            <a 
              href={brand.website}
              target="_blank"
              rel="noopener noreferrer"
              className={styles.externalLink}
            >
              <FontAwesomeIcon icon={faExternalLinkAlt} />
              <span>Open in New Tab</span>
            </a>
            <button className={styles.closeButton} onClick={onClose}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        </div>
        
        <div className={styles.iframeContainer}>
          {isLoading && (
            <div className={styles.loaderContainer}>
              <div className={styles.spinner}></div>
              <p className={styles.loadingText}>Loading website...</p>
            </div>
          )}
          {loadError && (
            <div className={styles.loaderContainer}>
              <p className={styles.loadingText}>
                This website cannot be displayed in preview mode.
                <br />
                Redirecting to {brand.brandName}'s website...
              </p>
            </div>
          )}
          <iframe
            ref={iframeRef}
            src={brand.website}
            title={brand.brandName}
            className={styles.websiteIframe}
            onLoad={handleIframeLoad}
            onError={handleLoadFailure}
          />
        </div>
      </div>
    </div>
  );
};

export default BrandWebsiteModal;